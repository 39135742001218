/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import { Text, Stack, Group, Space, Center, Button, Tabs, Switch } from '@mantine/core';
import { Pagination, Badge } from '@mantine/core';
import { TextInput } from '@mantine/core';
import {
  IconSearch,
  IconCopy,
  IconEdit,
  IconTrash,
  IconPhoto,
  IconMessageCircle,
  IconRefresh,
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import AddMaterial from '../ProjectRequest/AddMaterial';
import { UserContext } from '../../context/userContext';
import CustomCardLoader from '../../components/CustomCardLoader';
import moment from 'moment';
import { pageContext } from '../../context/pageContext';
import homeServices from '../../services/homeServices';
import { DateDifference, convertDateTimeStringToDateTimeEST, RowColors, formatChange } from '../../utils/Utils';

function MaterialApproval() {
  const navigate = useNavigate();

  const {
    searchApproval,
    setSearchApproval,
    approvalPagination,
    setApprovalPagination,
    setTotalApproval,
  } = useContext(pageContext);

  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);

    setLoadingData(true);
    homeServices
      .getSkuDescriptionData({
        search: search,
        offset: (offset - 1) * 10,
        sort_column: sort_column,
        sort_type: sort_type,
      })
      .then((res) => {
        //console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(res?.data);
        setPages(res?.total);
        setLoadingData(false);
        setTotalApproval(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(1, searchApproval);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchApproval]);

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Card radius="xl">
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
              {}
            </Text>
          </Group>
        </Card.Body>
        <Card.Body>
          <Group position="apart">
            <Text
              size="xl"
              weight={700}
              color="yellow"
              style={{ fontSize: '18px' }}
            >{`SKU Description Approval`}</Text>
          </Group>
          <Space h="xl" />
          <TextInput
            radius={'xl'}
            placeholder={`SKU Description`}
            sx={{ width: '16vw' }}
            rightSection={
              <ActionIcon
                variant="filled"
                color={'yellow'}
                radius="xl"
                size="md"
                onClick={() => {
                  setLoadingData(true);
                  setSearchApproval('');
                  setApprovalPagination(1);
                }}
              >
                <IconRefresh size={14} color="black" />
              </ActionIcon>
            }
            value={searchApproval}
            onChange={(e) => {
              setSearchApproval(e.target.value);
              setApprovalPagination(1);
            }}
          />
        </Card.Body>
        <div className="table-responsive-sm">
          {loadingData ? (
            <CustomCardLoader />
          ) : (
            <Table hover>
              <thead>
                <tr>
                  {[
                    'Project Name',
                    'Type',
                    'SKU Description',
                    'Hold',
                    'SKU Request Date',
                    'SKU Requestor',
                    'Production Date',
                    'Due Date',
                    'Days To Production',
                  ].map((item, index) => (
                    <th
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Center fw={500}>
                        {item}
                        <>
                          {sortedColumnData !== item && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchApproval, item, 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == item && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchApproval, item, 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == item && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchApproval, null, null);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {materialApprovalList
                ?.filter((item) => item?.material_status == 1)
                ?.map((material, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(`/material/${material?.id}`);
                      // setSelectedMaterial(material)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td style={{ color: '#fab005' }}>{material?.project_name}</td>
                    <td style={{ color: '#fab005' }}>
                      {material?.project_type + ' ' + material?.project_year}
                    </td>
                    <td style={{ color: '#fab005' }}>{material?.sku_description}</td>
                    <td>{}</td>
                    <td>{material?.project_owner}</td>
                    <td>
                      {material?.production_date
                        ? moment(material?.production_date).format('MM-DD-YYYY')
                        : ''}
                    </td>
                  </tr>
                ))} */}
                {projectList?.map((project, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(
                        `/material/${project?.material_id}/${project?.plant_id}`,
                        //   {
                        //   state: {
                        //     plant_id: project?.plant_id,
                        //     plant: project?.plant,
                        //     status: project?.plant_status,
                        //   },
                        // }
                      );
                      // setSelectedMaterial(material)
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>{project?.project_name}</td>
                    <td>{project?.project_type + ' ' + project?.project_year}</td>
                    <td>
                      {project?.fg_type === 'Virtual'
                        ? project?.virtual_sku_description
                        : project?.sku_description}
                    </td>
                    <td>
                      <Switch label="Hold" size="xs" color="yellow" checked={project?.on_hold} />
                    </td>
                    <td>{convertDateTimeStringToDateTimeEST(project?.created_at)}</td>

                    <td>{project?.material_created_by_name}</td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {project?.production_date
                        ? moment(project?.production_date).format('MM-DD-YYYY')
                        : ''}
                    </td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {project?.due_date_by
                        ? formatChange(project?.due_date_by)
                        : ''}
                    </td>
                    <td>{RowColors(DateDifference(project?.production_date))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <Card.Footer>
          <Container>
            <Center>
              <Pagination
                total={pages + 1}
                color="yellow"
                radius="xl"
                withEdges
                page={approvalPagination}
                boundaries={1}
                initialPage={approvalPagination}
                value={approvalPagination}
                onChange={(e) => {
                  setApprovalPagination(e);
                  fetchData(e, searchApproval, sortedColumnData, sortType);
                }}
              />
            </Center>
          </Container>
        </Card.Footer>
      </Card>
    </div>
  );
}
export default MaterialApproval;
