import { Select } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import materialService from '../../services/materialService';

function SearchableSelect({ label, value, error, setId, setDescription, disabled = false }) {
  const [searchValue, setSearchValue] = useState('');
  const [itemList, setItemList] = useState([]);

  const fetchSearchValue = (val) => {
    materialService.getExistingSkuList(val).then((res) => {
      if (res && res.data) {
        setItemList(res?.data?.sku?.sort((a, b) => Number(b?.label) - Number(a?.label)));
        return true;
      }
    });
  };

  const handleSelectSearchValue = (val) => {
    materialService.getExistingSkuDesc(val).then((res) => {
      if (res && res.data) {
        setDescription(res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  useEffect(() => {
    if (value) {
      setSearchValue(value);
      // fetchSearchValue(value);
    }
  }, [value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= 4) {
        fetchSearchValue(searchValue);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue]);

  return (
    <>
      <Select
        label={label}
        placeholder={label}
        radius={'lg'}
        searchable
        onSearchChange={(e) => {
          setSearchValue(e);
          // if (e.length >= 2) {
          //   fetchSearchValue(e);
          // }
        }}
        searchValue={searchValue}
        onChange={(e) => {
          // console.log('ON CHANGE', e);
          setId(e);
          handleSelectSearchValue(e);
          setSearchValue(e);
        }}
        data={itemList?.length > 0 ? itemList : []}
        value={value}
        error={error}
        disabled={disabled}
        type="number"
      />
    </>
  );
}

export default SearchableSelect;
