import { Table } from '@mantine/core';
import React from 'react';

function DataTable({ data, columns }) {
  return (
    <Table striped highlightOnHover withBorder>
      <thead>
        <tr>
          {columns.map((column, i) => (
            <th key={i}>{column.title}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row, i) => (
          <tr key={i}>
            {columns.map((column, j) => (
              <td key={j}>{row[column.field]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DataTable;
