import { Center, Table, Pagination, Text, Group } from '@mantine/core';
import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { useTable } from 'react-table';

const DataTable = ({
  columns,
  data,
  getDataApi,
  records,
  page,
  setActiveForSave,
  setShowSaveModal,
  setActiveForDelete,
  setShowDeleteModal,
  hiddenColumns,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: records?.length > 0 ? records : [],
    initialState: {
      hiddenColumns: hiddenColumns,
    },
  });

  return (
    <>
      <div className="table-responsive" style={{ maxHeight: '40vh' }}>
        <Table
          className="table card-table table-hover table-outline text-nowrap table-vcenter card-table"
          {...getTableProps()}
          striped
          highlightOnHover
          withBorder
        >
          <thead>
            {headerGroups?.length > 0 &&
              headerGroups?.map((headerGroup) => (
                <tr {...headerGroup?.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th {...column?.getHeaderProps()}>
                      <Center>{column?.render('header')}</Center>
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows?.length > 0 &&
              rows?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row?.getRowProps()}>
                    {row?.cells?.map((cell) => {
                      return (
                        <td {...cell?.getCellProps()} className={cell?.className}>
                          <Center>{cell?.render('Cell')}</Center>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <Card.Footer>
        <Container>
          <Center>
            <Pagination
              position="center"
              boundaries={2}
              page={data?.page}
              onChange={(page) => getDataApi(page)}
              total={data?.page_list ? data?.page_list[data?.page_list?.length - 1] : 0}
              color="yellow"
              radius="xl"
              withEdges
              size="sm"
            />
          </Center>
        </Container>
      </Card.Footer>
    </>
  );
};

export default DataTable;
