import { urls } from '../utils/apiConfigs';
import axios from '../utils/axios';

/* eslint-disable import/no-anonymous-default-export */
export default {
  skuDescriptionApprovalMail: async function () {
    try {
      const response = await axios.get(urls.skuDescriptionApprovalMailRecipientURL);
      return response;
    } catch (e) {
      return e.response;
    }
  },

  skuCostingApprovalMail: async function () {
    try {
      const response = await axios.get(urls.skuCostingApprovalMailRecipientURL);
      return response;
    } catch (e) {
      return e.response;
    }
  },
};
