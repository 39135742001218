/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from 'react-bootstrap';
import {
  Text,
  Group,
  Space,
  Center,
  Button,
  Select,
  ActionIcon,
  TextInput,
  Input,
  Grid,
  Modal,
  Table,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { UserContext } from '../../context/userContext';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import plantService from '../../services/plantService';
import materialService from '../../services/materialService';
import { IconTrash, IconDeviceFloppy } from '@tabler/icons';
import { toast } from 'react-toastify';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';

function PlantTable({ disabled = false }) {
  const [userState, userDispatch] = useContext(ActiveUserContext);

  // CONTEXT
  const { setSelectedProjectMaterials } = useContext(UserContext);

  // NAVIGATION PARAMS
  const navigate = useNavigate();
  let params = useParams();

  // STATES
  const [plants, setPlants] = useState([]);
  const [stopShipDateDropDown, setStopShipDateDropDown] = useState([]);
  const [plantInput, setPlantInput] = useState({
    index: plants[plants?.length - 1]?.index + 1,
    id: Math.random() * 10001,
    plant: '',
    selling_region: '',
    production_date: null,
    str_date: null,
    stop_ship_date: null,
    fg_type: '',
    liquid_type: '',
  });
  const [opened, setOpened] = useState(false);
  const [plantDeletion, setPlantDeletion] = useState({
    id: '',
    comment: '',
  });
  const [plantDropDown, setPlantDropDown] = useState([]);
  const [materialInfo, setMaterialInfo] = useState({});

  // USE EFFECT
  useEffect(() => {
    getPlants();
  }, [params?.id]);

  useEffect(() => {
    materialService.getStopShipDate().then((res) => {
      if (res) {
        setStopShipDateDropDown(
          res && res.length > 0 ? res?.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
        );
      }
    });
  }, []);

  // FORMIK
  const formik = useFormik({
    initialValues: {
      plants: plants,
    },
    validationSchema: Yup.object({
      skuDescription: Yup.string().required('SKU Description is required'),
      plants: Yup.array().of(
        Yup.object().shape({
          index: Yup.number(),
          plant: Yup.string().required('Plant is required'),
          selling_region: Yup.string().required('Selling Region is required').nullable(),
          production_date: Yup.date().required('Production Date is required').nullable(),
          str_date: Yup.date().required('STR Date is required').nullable(),
          stop_ship_date: Yup.string().nullable(),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      //console.log('Form data', formik.errors);
    },
  });
  const plantInputFormik = useFormik({
    initialValues: {
      plant: plantInput.plant ? plantInput.plant : null,
      production_date: plantInput.production_date ? plantInput.production_date : null,
      str_date: plantInput.str_date ? plantInput.str_date : null,
      stop_ship_date: plantInput.stop_ship_date ? plantInput.stop_ship_date : '',
    },
    validationSchema: Yup.object({
      plant: Yup.string().required('Plant is required').nullable(),
      production_date: Yup.date().required('Production Date is required').nullable(),
      str_date: Yup.date().required('STR Date is required').nullable(),
      stop_ship_date: Yup.string().required('Stop Ship Date is required').nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      savePlants();
      setPlantInput({
        id: Math.random() * 10001,
        plant: '',
        selling_region: '',
        production_date: null,
        str_date: null,
        stop_ship_date: '',
        fg_type: '',
        liquid_type: '',
      });
    },
  });

  // UTILITY FUNCTIONS
  const addOneDay = (date) => {
    return new Date(date).setDate(new Date(date).getDate() + 1);
  };

  // FETCHING ALL PLANTS
  const getPlants = () => {
    materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
      if (res?.data) {
        //console.log('PLANT LENGTH', res?.data);
        setPlants(res?.data?.plants);
      }
    });
    materialService.getAllPlants().then((res) => {
      if (res && res.data) {
        setPlantDropDown(
          res?.data?.plant?.length > 0
            ? res?.data?.plant?.sort((a, b) => (a.label < b.label ? -1 : 1))
            : [],
        );
      }
    });
    materialService.getMaterialById(params?.id).then((res) => {
      if (res?.data) {
        //console.log(res?.data?.material);
        setMaterialInfo(res?.data?.material);
      }
    });
  };

  // SAVING ALL PLANTS
  const savePlants = () => {
    const body = {
      material_id: params?.id,
      plant: plantInput.plant,
      production_date: addOneDay(plantInput.production_date),
      str_date: addOneDay(plantInput.str_date),
      stop_ship_date: plantInput.stop_ship_date,
    };
    plantService.createNewPlant(body).then((res) => {
      getPlants();
      toast.success('Plant Added Successfully');
    });
  };

  // UPDATING PLANTS
  const updatePlant = (id, data) => {
    plantService
      .updateExistingPlant(id, {
        production_date: addOneDay(data?.production_date),
        str_date: addOneDay(data?.str_date),
        stop_ship_date: data?.stop_ship_date,
      })
      .then((res) => {
        toast.success('Plant Updated Successfully');
      });
  };

  // DELETING PLANTS
  const deletePlant = () => {
    plantService
      .deleteExistingPlant(plantDeletion.id, {
        deleted_comment: plantDeletion.comment,
      })
      .then((res) => {
        materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
          if (res?.data) {
            //console.log('PLANT LENGTH', res?.data?.plants.length);
            setPlants(res?.data?.plants);
            toast.success('Plant Deleted Successfully');

            if (res?.data?.plants?.length === 0) {
              materialService.deleteMaterial({
                id: params?.id,
              });
              setSelectedProjectMaterials([]);
              toast.success('Material Added Successfully');

              navigate(-1);
            }
          }
        });
        setOpened(false);
        setPlantDeletion({
          id: '',
          comment: '',
        });
      });
  };

  return (
    <Group grow>
      <Card.Body>
        <div className="table-responsive-sm">
          <Table className="addMaterial-table" striped highlightOnHover withBorder>
            <Space h="md" />
            <thead>
              <tr>
                <th>
                  <Center fw={500}>Production Plant</Center>
                </th>
                <th>
                  <Center fw={500}>FG Type</Center>
                </th>
                <th>
                  <Center fw={500}>Liquid Type</Center>
                </th>
                <th>
                  <Center fw={500}>Production Date</Center>
                </th>
                <th>
                  <Center fw={500}>STR Date</Center>
                </th>
                <th>
                  <Center fw={500}>Stop Ship Date</Center>
                </th>
                {check_roles(userState, ['admin', 'supply_planner', 'mdm']) && (
                  <>
                    <Space w="sm" />
                    <th style={{ width: '8%' }}>
                      <Input.Label>Action</Input.Label>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {plants?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <TextInput
                      placeholder="Production Plant"
                      radius={'lg'}
                      value={item?.plant}
                      onChange={(value) => {
                        let tempPlants = plants?.map((currPlant) => {
                          if (currPlant.id == item.id) {
                            currPlant.plant = value;
                          }
                          return currPlant;
                        });
                        setPlants(tempPlants);
                        // formik.setFieldValue('plants', tempPlants);
                      }}
                      disabled
                    />

                    {/* {plantDropDown &&
                          plantDropDown !== undefined &&
                          plantDropDown?.length > 0 && (
                            <Select
                              radius={'lg'}
                              data={plantDropDown}
                              placeholder={'Plant'}
                              searchable
                              required
                              value={plants[index]?.plant}
                              onChange={(value) => {
                                let tempPlants = plants?.map((currPlant) => {
                                  if (currPlant.id == plant.id) {
                                    currPlant.plant = value;
                                  }
                                  return currPlant;
                                });
                                setPlants(tempPlants);
                                // formik.setFieldValue('plants', tempPlants);
                              }}
                              // error={
                              //   formik.errors?.plants
                              //     ? formik.errors?.plants[index]?.plant
                              //       ? formik.errors?.plants[index]?.plant
                              //       : ''
                              //     : ''
                              // }
                            />
                          )} */}
                  </td>
                  <td>
                    <TextInput
                      placeholder="FG Type"
                      disabled
                      radius={'lg'}
                      value={materialInfo?.fg_type}
                    />
                  </td>
                  <td>
                    <TextInput
                      placeholder="Liquid Type"
                      disabled
                      radius={'lg'}
                      value={materialInfo?.liquid}
                    />
                  </td>
                  <td>
                    <DatePickerInput
                      inputFormat="MM/DD/YYYY"
                      placeholder="Production Date"
                      radius="xl"
                      value={item?.production_date ? new Date(item?.production_date) : null}
                      onChange={(value) => {
                        let tempPlants = plants?.map((currPlant) => {
                          if (currPlant.index == plants.index) {
                            currPlant.production_date = value;
                            currPlant.str_date = null;
                          }
                          return currPlant;
                        });
                        setPlants(tempPlants);
                        formik.setFieldValue('plants', tempPlants);
                      }}
                      error={
                        formik.errors?.plants
                          ? formik.errors?.plants[index]?.production_date
                            ? formik.errors?.plants[index]?.production_date
                            : ''
                          : ''
                      }
                      minDate={dayjs(new Date()).toDate()}
                    />
                  </td>
                  <td align="center">
                    <DatePickerInput
                      inputFormat="MM/DD/YYYY"
                      placeholder="STR Date"
                      radius={'lg'}
                      minDate={dayjs(item?.production_date).add(1, 'days').toDate()}
                      value={item?.str_date ? new Date(item?.str_date) : null}
                      clearable={true}
                      onChange={(value) => {
                        let tempPlants = plants?.map((currPlant) => {
                          if (currPlant.id == item.id) {
                            currPlant.str_date = value;
                          }
                          return currPlant;
                        });
                        setPlants(tempPlants);
                        // formik.setFieldValue('plants', tempPlants);
                      }}
                      error={
                        formik.errors?.plants
                          ? formik.errors?.plants[index]?.str_date
                            ? formik.errors?.plants[index]?.str_date
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td>
                    <Select
                      // inputFormat="MM/DD/YYYY"
                      placeholder="Stop Ship Date"
                      radius={'lg'}
                      value={item?.stop_ship_date}
                      data={stopShipDateDropDown}
                      searchable
                      onChange={(value) => {
                        //console.log(value);
                        let tempPlants = plants?.map((currPlant) => {
                          if (currPlant.id == item?.id) {
                            //console.log(item.id, currPlant);
                            currPlant.stop_ship_date = value;
                          }
                          return currPlant;
                        });
                        //console.log(tempPlants);
                        setPlants(tempPlants);
                      }}
                      error={
                        formik.errors?.plants
                          ? formik.errors?.plants[index]?.stop_ship_date
                            ? formik.errors?.plants[index]?.stop_ship_date
                            : ''
                          : ''
                      }
                    />
                  </td>
                  {check_roles(userState, ['admin', 'supply_planner', 'mdm']) && (
                    <>
                      <Space w="sm" />
                      <td align="center">
                        {!disabled && (
                          <Grid>
                            <Grid.Col xs={6}>
                              {' '}
                              <ActionIcon
                                variant="light"
                                color={'yellow'}
                                radius="xl"
                                disabled={disabled}
                              >
                                <IconDeviceFloppy
                                  size={24}
                                  color="black"
                                  onClick={() => {
                                    updatePlant(item?.id, {
                                      production_date: item?.production_date,
                                      str_date: item?.str_date,
                                      stop_ship_date: item?.stop_ship_date,
                                    });
                                  }}
                                />
                              </ActionIcon>
                            </Grid.Col>
                            {materialInfo?.fg_type !== 'Import' && (
                              <Grid.Col xs={6}>
                                <ActionIcon
                                  variant="light"
                                  color={'yellow'}
                                  radius="xl"
                                  disabled={disabled}
                                >
                                  <IconTrash
                                    size={24}
                                    color="black"
                                    onClick={() => {
                                      setOpened(true);
                                      setPlantDeletion({
                                        ...plantDeletion,
                                        id: item?.id,
                                      });
                                    }}
                                  />
                                  <Modal
                                    centered
                                    radius={'lg'}
                                    size="lg"
                                    opened={opened}
                                    title={
                                      <Text
                                        weight={700}
                                        className="golden-text"
                                      >{`Confirm Delete`}</Text>
                                    }
                                    onClose={() => setOpened(false)}
                                  >
                                    <Card>
                                      <Card.Body>
                                        <Text size="md" weight={700}>
                                          {' '}
                                          {plants?.length === 1
                                            ? 'Please provide your reason/comments to notify the requestor for deleting the only Plant (This will cause the request to be deleted)'
                                            : 'Please provide your reason/comments to notify the requestor for deleting the Plant'}
                                        </Text>
                                        <Space h={40} />
                                        <TextInput
                                          size="md"
                                          weight={700}
                                          placeholder="Comments"
                                          radius={'lg'}
                                          value={plantDeletion?.comment}
                                          onChange={(e) =>
                                            setPlantDeletion({
                                              ...plantDeletion,
                                              comment: e.target.value,
                                            })
                                          }
                                        />
                                        <Space h={40} />
                                        <Group position="right">
                                          <Button
                                            disabled={disabled}
                                            color="yellow"
                                            radius="xl"
                                            leftIcon={<IconTrash size={24} color="white" />}
                                            onClick={() => {
                                              deletePlant();

                                              // formik.setFieldValue('plants', formik.values.plants.filter((filterPlant) => filterPlant.index !== plant.index));
                                            }}
                                          >{`Delete Plant`}</Button>
                                        </Group>{' '}
                                      </Card.Body>
                                    </Card>
                                  </Modal>
                                </ActionIcon>
                              </Grid.Col>
                            )}
                          </Grid>
                        )}

                        <Space h="xl" />
                      </td>
                    </>
                  )}
                </tr>
              ))}
              {materialInfo?.fg_type !== 'Import' && (
                <tr>
                  <td>
                    {/* <TextInput
                        placeholder="Production Plant"
                        radius={'lg'}
                        value={plantInput.plant}
                        onChange={(e) => {
                          setPlantInput({ ...plantInput, plant: e.target.value });
                        }}
                      /> */}

                    <Select
                      radius={'lg'}
                      data={plantDropDown?.length > 0 ? plantDropDown : []}
                      placeholder={'Plant'}
                      searchable
                      required
                      value={plantInput.plant}
                      onChange={(e) => {
                        if (plants.map((item) => item.plant).includes(e)) {
                          return toast.error('Plant already added');
                        }
                        setPlantInput({ ...plantInput, plant: e });
                        plantInputFormik.setFieldValue('plant', e);
                      }}
                      error={plantInputFormik.errors?.plant}
                    />
                  </td>
                  <td>
                    <TextInput
                      placeholder="FG Type"
                      disabled
                      radius={'lg'}
                      value={plantInput?.fg_type}
                    />
                  </td>
                  {/* <td>
                  <TextInput
                    placeholder="FG Type"
                    disabled
                    radius={'lg'}
                    value={plantInput?.fg_type}
                  />
                </td> */}
                  <td>
                    <TextInput
                      placeholder="Liquid Type"
                      disabled
                      radius={'lg'}
                      value={plantInput?.liquid_type}
                    />
                  </td>
                  <td>
                    <DatePickerInput
                      placeholder="Production Date"
                      radius={'lg'}
                      value={plantInput.production_date}
                      onChange={(e) => {
                        //console.log(e);
                        setPlantInput({ ...plantInput, production_date: e, str_date: null });
                        plantInputFormik.setFieldValue('production_date', e);
                        plantInputFormik.setFieldValue('str_date', null);
                      }}
                      error={plantInputFormik.errors?.production_date}
                      minDate={dayjs(new Date()).toDate()}

                      // error={
                      //   formik.errors?.plants
                      //     ? formik.errors?.plants[index]?.production_date
                      //       ? formik.errors?.plants[index]?.production_date
                      //       : ''
                      //     : ''
                      // }
                    />
                  </td>
                  <td align="center">
                    <DatePickerInput
                      // inputFormat="MM/DD/YYYY"
                      placeholder="STR Date"
                      radius={'lg'}
                      minDate={dayjs(plantInput?.production_date).add(1, 'days').toDate()}
                      value={plantInput?.str_date}
                      disabled={!plantInput?.production_date}
                      onChange={(e) => {
                        //console.log(e);
                        setPlantInput({ ...plantInput, str_date: e });
                        plantInputFormik.setFieldValue('str_date', e);
                      }}
                      error={plantInputFormik.errors?.str_date}
                      // error={
                      //   formik.errors?.plants
                      //     ? formik.errors?.plants[index]?.str_date
                      //       ? formik.errors?.plants[index]?.str_date
                      //       : ''
                      //     : ''
                      // }
                    />
                  </td>
                  <td>
                    <Select
                      // inputFormat="MM/DD/YYYY"
                      placeholder="Stop Ship Date"
                      radius={'lg'}
                      value={plantInput?.stop_ship_date}
                      data={stopShipDateDropDown}
                      searchable
                      onChange={(e) => {
                        //console.log(e);
                        setPlantInput({ ...plantInput, stop_ship_date: e });
                        plantInputFormik.setFieldValue('stop_ship_date', e);
                      }}
                      error={plantInputFormik.errors?.stop_ship_date}
                      // error={
                      //   formik.errors?.plants
                      //     ? formik.errors?.plants[index]?.stop_ship_date
                      //       ? formik.errors?.plants[index]?.stop_ship_date
                      //       : ''
                      //     : ''
                      // }
                    />
                  </td>
                  {check_roles(userState, ['admin', 'supply_planner', 'mdm']) && (
                    <>
                      <Space w="sm" />
                      <td align="center">
                        {!disabled && (
                          <ActionIcon
                            variant="light"
                            color={'yellow'}
                            radius="xl"
                            disabled={disabled}
                          >
                            <IconDeviceFloppy
                              size={24}
                              color="black"
                              onClick={() => {
                                plantInputFormik.handleSubmit();
                              }}
                            />
                          </ActionIcon>
                        )}
                        <Space h="xl" />
                      </td>
                    </>
                  )}
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Group>
  );
}

export default PlantTable;
