import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  Checkbox,
  Group,
  Loader,
  Modal,
  Pagination,
  Select,
  SimpleGrid,
  Space,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  IconCheck,
  IconEdit,
  IconPlus,
  IconTrash,
  IconUserCheck,
  IconUserPlus,
  IconX,
  IconExclamationMark,
  IconChevronDown,
  IconChevronLeft,
} from '@tabler/icons';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { forwardRef, memo, useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import CustomCardLoader from '../../components/CustomCardLoader';
// import CustomLoader from '../../components/CustomLoader';
import { UserContext } from '../../context/userContext';
// import dataService from '../../services/dataService';
// import teamService from '../../services/teamService';
import userService from '../../services/userService';
import { pageContext } from '../../context/pageContext';

import validator from 'validator';
import plantDetailsService from '../../services/plantDetailsService';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';

function RoleDropdown({ roles }) {
  const [isOpen, setIsOpen] = useState(false);

  const selectedRole = roles[0];

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div hidden={isOpen}>{selectedRole}</div>
      {isOpen && (
        <div>
          {roles.map((role, index) => (
            <div
              key={index}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
            >
              {role}
            </div>
          ))}
        </div>
      )}
      {roles.length > 1 ? (
        <div>
          <IconChevronDown onClick={toggleDropdown} style={{ color: '#e3af32' }} />
        </div>
      ) : (
        <div style={{ marginLeft: '24px' }}></div>
      )}
    </div>
  );
}

function Plants() {
  const [pages, setPages] = React.useState(0);

  const [teamData, setTeamData] = useState();
  const [userDropdown, setUsersDropdown] = useState();
  const [userState, dispatch] = useContext(ActiveUserContext);

  // const [currentStakeholders, setCurrentStakeholders] = useState();
  const navigate = useNavigate();

  const [selectedStakeholders, setSelectedStakeholders] = useState([]);

  // const [userState, userDispatch] = useContext(UserContext);
  const [activeStakeholder, setActiveStakeholder] = useState();

  const [stakeholderPriorities, setStakeholderPriorities] = useState([]);
  const [stakeholderPeriodicity, setStakeholderPeriodicity] = useState([]);

  const [showTeamDataLoader, setShowTeamDataLoader] = useState();
  const [showTeamStakeholderDataLoader, setShowTeamStakeholderDataLoader] = useState();

  const [showAddStakeholderModal, setShowAddStakeholderModal] = useState(false);
  const [addStakeholderModalActivePage, setAddStakeholderModalActivePage] = useState('first');
  const [addStakeholderModalMessage, setAddStakeholderModalMessage] = useState();

  const [showEditStakeholderModal, setShowEditStakeholderModal] = useState(false);
  const [editStakeholderModalActivePage, setEditStakeholderModalActivePage] = useState('first');
  const [editStakeholderModalMessage, setEditStakeholderModalMessage] = useState();

  const [showRemoveStakeholderModal, setShowRemoveStakeholderModal] = useState(false);
  const [removeStakeholderModalActivePage, setRemoveStakeholderModalActivePage] = useState('first');
  const [showRemoveStakeholderModalLoader, setShowRemoveStakeholderModalLoader] = useState(false);
  const [removeStakeholderModalMessage, setRemoveStakeholderModalMessage] = useState();

  const [showRemoveSelectedStakeholdersModal, setShowRemoveSelectedStakeholdersModal] =
    useState(false);

  const [showApproveStakeholderModal, setShowApproveStakeholderModal] = useState(false);
  const [approveStakeholderModalActivePage, setApproveStakeholderModalActivePage] =
    useState('first');
  const [showApproveStakeholderModalLoader, setShowApproveStakeholderModalLoader] = useState(false);
  const [approveStakeholderModalMessage, setApproveStakeholderModalMessage] = useState();
  let teamName = '';
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plant_name = searchParams.get('value');
  let params = useParams();
  const plant_id = params.id;
  // const getPlantsDetails=async(id)=>{
  //     plantDetailsService.getPlantsByID(id).then((res) => {
  //         setTeamData();
  //         //console.log(res?.data);
  //         setShowTeamDataLoader(false);
  //         if (res?.data?.Success) setTeamData(res?.data?.data);
  //         teamName=res?.data?.data?.plant;
  //         //console.log(teamName)
  //       });
  // }

  // const [teamName, setTeamName] = useState()

  const [tablePage, setTablePage] = useState(1);

  // States for Saving User in Modal
  const [showSaveUserModal, setShowSaveUserModal] = useState(false);
  const [saveUserModalActivePage, setSaveUserModalActivePage] = useState('first');
  const [showSaveUserModalLoader, setShowSaveUserModalLoader] = useState(false);
  const [saveUserModalMessage, setSaveUserModalMessage] = useState();

  // States for Deleting User in Modal
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteUserModalActivePage, setDeleteUserModalActivePage] = useState('first');
  const [showDeleteUserModalLoader, setShowDeleteUserModalLoader] = useState(false);
  const [deleteUserModalMessage, setDeleteUserModalMessage] = useState();

  //State Management for Users
  const [activeUserForSave, setActiveUserForSave] = useState();
  const [activeUserForDelete, setActiveUserForDelete] = useState();

  const {
    searchPlantMembers,
    setSearchPlantMembers,
    plantMembersPagination,
    setPlantMembersPagination,
  } = useContext(pageContext);

  const getUsersApi = (page, name = null, email = null) => {
    userService.getAllUsers(true, page).then((res) => {
      if (res?.data?.Success) {
        setUsersDropdown(
          res?.data?.Users.map((val) => ({
            label: val?.name,
            value: val?.id,
            description: val?.email,
          })),
        );
      }
      //nothing
    });
  };

  const getPlantMembersApi = (offset) => {
    setTeamData();
    plantDetailsService.getAllPlantMembers(plant_id, (offset - 1) * 10).then((res) => {
      if (res?.data?.Success) {
        setTeamData(res?.data);
        setPages(res?.data?.total);
      }
    });
  };

  function addStakeholderModalCleanup() {
    setAddStakeholderModalActivePage('first');
    setShowSaveUserModalLoader('false');
    setShowAddStakeholderModal(false);
    addStakeholderFormik.resetForm();
    getPlantMembersApi(1);
  }

  function deleteUserModalCleanup() {
    setActiveUserForDelete();
    setDeleteUserModalActivePage('first');
    setDeleteUserModalMessage();
    setShowDeleteUserModalLoader(false);
    setShowDeleteUserModal(false);
    // getRequestorsApi();
  }

  const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar color={'yellow'} radius="xl" />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ));

  useEffect(() => {
    getPlantMembersApi(1);
    getUsersApi();
  }, []);

  const addStakeholderFormik = useFormik({
    initialValues: {
      plant_id: params.id,
      userID: activeUserForSave?.userID,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (!values.userID) errors.userID = 'Name is required';
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      // setSubmitting(false);
      //console.log(values);
      plantDetailsService.savePlantMember(values).then((res) => {
        setSubmitting(false);
        // setShowAddStakeholderModal(false);
        setAddStakeholderModalActivePage('response');
        setAddStakeholderModalMessage(res?.data?.message);
      });
    },
  });
  const RemoveSelectedStakeholdersModal = () => {
    const [activePage, setActivePage] = useState('first');
    const [showRemoveStakeholderModalLoader, setShowRemoveStakeholderModalLoader] = useState(false);
    const [removeStakeholderModalMessage, setRemoveStakeholderModalMessage] = useState();

    return (
      <Modal
        radius={'lg'}
        size="lg"
        opened={showRemoveSelectedStakeholdersModal}
        centered
        title={<Text weight={700} className="golden-text">{`Remove Users`}</Text>}
        onLoad={() => {
          setActivePage('first');
        }}
        onClose={() => {
          setActiveStakeholder();
          setShowRemoveSelectedStakeholdersModal(false);
        }}
      >
        {activePage === 'first' && (
          <>
            <Group position="left">
              <Text>{`Are you sure you want to remove the users from the team ?`}</Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  // addReusableComponentFormik.resetForm();
                  // setOpenAddComponentModal(false);
                  //   setActivePage('cancel');
                  setActiveStakeholder();
                  setShowRemoveSelectedStakeholdersModal(false);
                }}
              >{`No`}</Button>
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowRemoveStakeholderModalLoader(true);
                  plantDetailsService
                    .deletePlantMember({
                      plant_id: plant_id,
                      user_id: selectedStakeholders,
                    })
                    .then((res) => {
                      setShowRemoveStakeholderModalLoader(false);
                      setRemoveStakeholderModalMessage(res?.data?.message);
                      setActivePage('response');
                    });
                }}
              >{`Yes`}</Button>
            </Group>
          </>
        )}
        {activePage === 'response' && (
          <>
            <Group>
              <Text align="left">{removeStakeholderModalMessage}</Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setSelectedStakeholders([]);
                  setShowRemoveStakeholderModalLoader(false);
                  setShowRemoveSelectedStakeholdersModal(false);
                  getPlantMembersApi(1);
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}
        {showRemoveStakeholderModalLoader ? <CustomCardLoader /> : null}
      </Modal>
    );
  };
  const RemoveStakeholderModal = () => {
    const [activePage, setActivePage] = useState('first');
    const [showRemoveStakeholderModalLoader, setShowRemoveStakeholderModalLoader] = useState(false);
    const [removeStakeholderModalMessage, setRemoveStakeholderModalMessage] = useState();

    return (
      <Modal
        radius={'lg'}
        size="lg"
        opened={showRemoveStakeholderModal}
        centered
        title={<Text weight={700} className="golden-text">{`Remove Users`}</Text>}
        onLoad={() => {
          setActivePage('first');
        }}
        onClose={() => {
          setActiveStakeholder();
          setShowRemoveStakeholderModal(false);
        }}
      >
        {activePage === 'first' && (
          <>
            <Group position="left">
              <Text>{`Are you sure you want to remove ${activeUserForDelete?.name} from the team ?`}</Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  // addReusableComponentFormik.resetForm();
                  // setOpenAddComponentModal(false);
                  //   setActivePage('cancel');
                  setActiveStakeholder();
                  setShowRemoveStakeholderModal(false);
                }}
              >{`No`}</Button>
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowRemoveStakeholderModalLoader(true);
                  //console.log(activeUserForDelete.id);
                  plantDetailsService
                    .deletePlantMember({
                      plant_id: plant_id,
                      user_id: [activeUserForDelete.id],
                    })
                    .then((res) => {
                      setShowRemoveStakeholderModalLoader(false);
                      setRemoveStakeholderModalMessage(res?.data?.message);
                      setActivePage('response');
                    });
                  // addStakeholderFormik.resetForm();
                  // setAddStakeholderModalActivePage('first');
                  // setShowAddStakeholderModal(false);
                }}
              >{`Yes`}</Button>
            </Group>
          </>
        )}
        {activePage === 'response' && (
          <>
            <Group>
              <Text align="left">{removeStakeholderModalMessage}</Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowRemoveStakeholderModalLoader(false);
                  setShowRemoveStakeholderModal(false);
                  getPlantMembersApi(1);
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}
        {showRemoveStakeholderModalLoader ? <CustomCardLoader /> : null}
      </Modal>
    );
  };

  return (
    <Container>
      <Modal
        radius={'lg'}
        size="sm"
        opened={showAddStakeholderModal}
        centered
        title={<Text weight={700} className="golden-text">{`Add to Team`}</Text>}
        onClose={() => {
          addStakeholderFormik.resetForm();
          setAddStakeholderModalActivePage('first');
          setShowAddStakeholderModal(false);
        }}
        onLoad={() => {
          addStakeholderFormik.resetForm();
          setAddStakeholderModalActivePage('first');
        }}
      >
        {addStakeholderModalActivePage === 'first' && (
          <>
            <Row>
              <Col>
                <Select
                  label="User"
                  name="userID"
                  itemComponent={SelectItem}
                  placeholder="Please select user "
                  data={userDropdown}
                  searchable
                  radius={'lg'}
                  maxDropdownHeight={400}
                  onChange={(e) => {
                    //console.log(e);
                    addStakeholderFormik.setFieldValue('userID', e);
                  }}
                  value={addStakeholderFormik.values.userID}
                  error={addStakeholderFormik.errors.userID}
                  disabled={addStakeholderFormik.isSubmitting}
                  clearable
                />
              </Col>
            </Row>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  addStakeholderModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                className="btn btn-primary"
                style={{ borderRadius: '40px' }}
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={addStakeholderFormik.handleSubmit}
                disabled={addStakeholderFormik.isSubmitting}
              >{`Save`}</Button>
            </Group>
          </>
        )}

        {addStakeholderFormik.isSubmitting ? <CustomCardLoader /> : null}

        {addStakeholderModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {addStakeholderModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  addStakeholderModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {/* {addStakeholderModalMessage ? (
              <>
                <Space h="xl" />
                <Group>
                  <Text align="left">
                    {addStakeholderModalMessage}
                  </Text>
                </Group>
              </>
            ) : null} */}
      </Modal>
      <RemoveStakeholderModal />
      <RemoveSelectedStakeholdersModal />
      <Row>
        <Col xs={12} sm={12}>
          <Card>
            <>
              <Card.Body>
                <Group position="apart">
                  <Group position="left">
                    <ActionIcon
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <IconChevronLeft />
                    </ActionIcon>
                    <Text size="xl" weight={700} color="yellow" align="left">
                      Production Plant: {plant_name}
                    </Text>
                  </Group>
                  {/* <Group position='center'>
                                        <Text
                                            size="xl"
                                            weight={700}
                                            color="yellow"
                                            align="left"
                                        >Production Plant: {plant_name}</Text>
                                    </Group> */}
                  {check_roles(userState, ['admin']) && (
                    <Group position="right">
                      {selectedStakeholders.length > 0 && (
                        <>
                          <Tooltip
                            wrapLines
                            withArrow
                            transition="fade"
                            transitionDuration={200}
                            label="Remove from the Team"
                          >
                            {isMobile ? (
                              <ActionIcon
                                variant="filled"
                                color={'yellow'}
                                radius="xl"
                                size={'lg'}
                                onClick={() => {
                                  // setActiveStakeholder(val);
                                  setShowRemoveStakeholderModal(true);
                                }}
                              >
                                <IconTrash size={16} color="white" />
                              </ActionIcon>
                            ) : (
                              <Button
                                radius="xl"
                                color={'yellow'}
                                leftIcon={<IconTrash size={14} color="white" />}
                                onClick={() => {
                                  setShowRemoveSelectedStakeholdersModal(true);
                                  //console.log(selectedStakeholders);
                                }}
                              >{`Delete`}</Button>
                            )}
                          </Tooltip>
                        </>
                      )}
                      {isMobile ? (
                        <ActionIcon
                          variant="filled"
                          color={'yellow'}
                          radius="xl"
                          size="lg"
                          onClick={() => {
                            setShowAddStakeholderModal(true);
                          }}
                        >
                          <IconPlus size={16} color="black" />
                        </ActionIcon>
                      ) : (
                        <Button
                          radius="xl"
                          color={'yellow'}
                          leftIcon={<IconPlus size={14} color="white" />}
                          onClick={() => {
                            setShowAddStakeholderModal(true);
                          }}
                        >{`Add`}</Button>
                      )}
                    </Group>
                  )}
                </Group>
              </Card.Body>

              {!teamData && <CustomCardLoader />}
              {teamData && teamData?.PlantMembers && teamData?.PlantMembers.length === 0 && (
                <Card.Body>
                  <Group position="center">
                    <Text>{`No users found.`}</Text>
                  </Group>
                </Card.Body>
              )}
              {teamData && teamData?.PlantMembers && teamData?.PlantMembers.length > 0 && (
                <div className="table-responsive">
                  <Table className="table card-table table-outline text-nowrap table-vcenter card-table">
                    <thead>
                      <tr>
                        {check_roles(userState, ['admin']) && (
                          <th>
                            <Center>
                              <Checkbox
                                size="xs"
                                color="yellow"
                                // checked={selectedStakeholders.includes(val.stakeholder_id)}
                                // onChange={(e) => {
                                //     if (e.currentTarget.checked)
                                //         setSelectedStakeholders([
                                //             ...selectedStakeholders,
                                //             val.stakeholder_id,
                                //         ]);
                                //     else
                                //         setSelectedStakeholders(
                                //             selectedStakeholders.filter(
                                //                 (id) => id !== val.stakeholder_id,
                                //             ),
                                //         );
                                // }}
                              />
                            </Center>
                          </th>
                        )}
                        <th>
                          <Center>{`People`}</Center>
                        </th>
                        <th>
                          <Center>{`Email`}</Center>
                        </th>
                        <th>
                          <Center>{`Level 1 Manager`}</Center>
                        </th>
                        <th>
                          <Center>{`Level 2 Manager`}</Center>
                        </th>
                        <th>
                          <Center>{`Roles`}</Center>
                        </th>
                        {check_roles(userState, ['admin']) && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {teamData &&
                        teamData.PlantMembers.map((val) => (
                          <tr key={val.id}>
                            {check_roles(userState, ['admin']) && (
                              <td>
                                <Center>
                                  <Checkbox
                                    size="xs"
                                    color="yellow"
                                    checked={selectedStakeholders.includes(val.id)}
                                    onChange={(e) => {
                                      if (e.currentTarget.checked)
                                        setSelectedStakeholders([...selectedStakeholders, val.id]);
                                      else
                                        setSelectedStakeholders(
                                          selectedStakeholders.filter((id) => id !== val.id),
                                        );
                                    }}
                                  />
                                </Center>
                              </td>
                            )}

                            <td>
                              <Center>{val.name}</Center>
                            </td>
                            <td>
                              <Center>{val.email}</Center>
                            </td>
                            <td>
                              <Center>{val.step_manager_name}</Center>
                            </td>
                            <td>
                              <Center>{val.senior_manager_name}</Center>
                            </td>

                            <td>
                              {val?.roles && (
                                <Center>
                                  <RoleDropdown
                                    roles={val.roles.split(',').map((role) => role.trim())}
                                  />
                                </Center>
                              )}
                            </td>

                            {/* <th></th> */}
                            {check_roles(userState, ['admin']) && (
                              <td>
                                <ActionIcon
                                  variant="light"
                                  color={'yellow'}
                                  radius="xl"
                                  onClick={() => {
                                    setActiveUserForDelete(val);
                                    setShowRemoveStakeholderModal(true);
                                  }}
                                >
                                  <IconTrash size={16} color="black" />
                                </ActionIcon>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
              <Card.Footer>
                <Container>
                  <Center>
                    <Pagination
                      total={pages + 1}
                      color="yellow"
                      radius="xl"
                      withEdges
                      page={plantMembersPagination}
                      boundaries={1}
                      initialPage={plantMembersPagination}
                      value={plantMembersPagination}
                      onChange={(e) => {
                        setPlantMembersPagination(e);
                        getPlantMembersApi(e);
                      }}
                    />
                  </Center>
                </Container>
              </Card.Footer>
            </>
            {/* )} */}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Plants;
