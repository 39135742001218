/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Button, Group, TextInput, Space, Text, Select, Grid, MultiSelect } from '@mantine/core';
import {
  IconCheck,
  IconX,
  IconTrash,
  IconBuildingFactory2,
  IconCircleCheck,
  IconChevronDown,
  IconChevronRight,
  IconChevronLeft,
  IconCircle,
  IconCircleX,
  IconHourglass,
} from '@tabler/icons';
import { useState, useEffect } from 'react';
import { ActionIcon } from '@mantine/core';
import { Stepper } from '@mantine/core';
import { Col, Container, Row, Card } from 'react-bootstrap';
// import MaterialConfirmation from './MaterialConfirmation';
import MultiComponent from '../Comman/MultiComponent';
import { DatePickerInput } from '@mantine/dates';
import MaterialApproval from '../MaterialApproval/MaterialApproval';
import FgFormInternal from '../FgForm/FgFormInternal';
import materialService from '../../services/materialService';
import { useLocation, useParams } from 'react-router-dom';
import MDMview from './components/MDMview/MDMview';
import BomForm from '../BomForm/BomForm';
import { UserContext } from '../../context/userContext';
import PackagingBOMView from './components/PackagingBOMView/PackagingBOMView';
import MDMView2 from './components/MDMView2/MDMView2';
// import LiquidBOMView from '../../components/FgBomTable/LiquidBOMView';
import MDMView3 from './components/MDMView3/MDMView3';
import RmCosting from './components/RmCosting/RmCosting';
import CustomCardLoader from '../../components/CustomCardLoader';
import { mapStatusToValue } from '../../utils/Utils';
import ControllerView from './components/ControllerView/ControllerView';
import LiquidBOMView from './components/LiquidBOMView/LiquidBOMView';
import RmCosting2 from './components/RmCosting2/RmCosting2';
// import SummaryView from '../../components/SummaryView/SummaryView';
import plantService from '../../services/plantService';
import Completed from '../Home/Completed';
const countriesData = [
  { label: 'United States', value: 'US' },
  { label: 'Great Britain', value: 'GB' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Russia', value: 'RU' },
];
function MaterialForm() {
  const location = useLocation();

  // STATES
  const [
    showMaterialConfirmationModal,
    setShowMaterialConfirmationModal,
    setShowSkuDescriptionModal,
  ] = useState(false);
  const [active, setActive] = useState(0);
  const [currentStage, setCurrentStage] = useState(0);
  const [tableData, setTableDate] = useState([0]);

  const [currentProject, setCurrentProject] = useState({});
  let params = useParams();
  const { projectList } = useContext(UserContext);

  const [materialStatus, setMaterialStatus] = useState(0);

  const [plantStatus, setPlantStatus] = useState(0);
  const [fgType, setFgType] = useState('Virtual');
  const [existingItem, setExistingItem] = useState('Yes');

  const [stepList, setStepList] = useState([]);
  const [currentLoading, setCurrentLoading] = useState(false);

  const makeStepperList = (res) => {
    //console.log('Making Stepper List');
    if (res?.data?.material?.existing_item === 'Yes') {
      setStepList([
        'SKU Description Approval',
        'Packaging BOM',
        'BOMs Validation',
        'Liquid BOM',
        'SAP BOMs Creation',
        'SKU Costing',
        'Costing Approval',
        'SAP Cost Update',
        'Completed',
      ]);
    } else {
      if (
        res?.data?.material?.fg_type === 'Domestic' ||
        res?.data?.material?.fg_type === 'Copack' ||
        res?.data?.material?.fg_type === 'Repack'
      ) {
        setStepList([
          'SKU Description Approval',
          'Full Goods Form',
          'SKU ID Creation',
          'Packaging BOM',
          'BOMs Validation',
          'Liquid BOM',
          'SAP BOMs Creation',
          'SKU Costing',
          'Costing Approval',
          'SAP Cost Update',
          'Completed',
        ]);
      } else if (res?.data?.material?.fg_type === 'Virtual') {
        setStepList([
          'SKU Description Approval',
          'SKU ID Creation',
          'SKU Costing',
          'Costing Approval',
          'SAP Cost Update',
          'Completed',
        ]);
      } else if (res?.data?.material?.fg_type === 'Import') {
        setStepList([
          'SKU Description Approval',
          'Full Goods Form',
          'SKU ID Creation',
          'SKU Costing',
          'Costing Approval',
          'SAP Cost Update',
          'Completed',
        ]);
      }
    }
  };

  const nextStep = () =>
    setActive((current) => (current < stepList?.length - 1 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  // useEffect(() => {
  //   //console.log('PLANT STATUS', plantStatus);
  //   if (existingItem === 'Yes') {
  //     if (materialStatus == 1 && plantStatus == null) {
  //       setActive(0);
  //       setCurrentStage(0);
  //     } else if (materialStatus == 4 && plantStatus == 5) {
  //       setActive(1);
  //       setCurrentStage(1);
  //     } else if (materialStatus == 4 && plantStatus == 6) {
  //       setActive(2);
  //       setCurrentStage(2);
  //     } else if (materialStatus == 4 && plantStatus == 7) {
  //       setActive(3);
  //       setCurrentStage(3);
  //     } else if (materialStatus == 4 && plantStatus == 8) {
  //       setActive(4);
  //       setCurrentStage(4);
  //     } else if (materialStatus == 4 && plantStatus == 9) {
  //       setActive(5);
  //       setCurrentStage(5);
  //     } else if (materialStatus == 4 && plantStatus == 10) {
  //       setActive(6);
  //       setCurrentStage(6);
  //     } else if (materialStatus == 4 && plantStatus == 11) {
  //       setActive(7);
  //       setCurrentStage(7);
  //     } else if (materialStatus == 4 && plantStatus == 12) {
  //       setActive(7);
  //       setCurrentStage(7);
  //     }
  //   } else {
  //     if (
  //       existingItem === 'No' &&
  //       (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack')
  //     ) {
  //       if (materialStatus == 1 && plantStatus == null) {
  //         setActive(0);
  //         setCurrentStage(0);
  //       } else if (materialStatus == 2 && plantStatus == null) {
  //         setActive(1);
  //         setCurrentStage(1);
  //       } else if (materialStatus == 3 && plantStatus == null) {
  //         setActive(2);
  //         setCurrentStage(2);
  //       } else if (materialStatus == 4 && plantStatus == 5) {
  //         setActive(3);
  //         setCurrentStage(3);
  //       } else if (materialStatus == 4 && plantStatus == 6) {
  //         setActive(4);
  //         setCurrentStage(4);
  //       } else if (materialStatus == 4 && plantStatus == 7) {
  //         setActive(5);
  //         setCurrentStage(5);
  //       } else if (materialStatus == 4 && plantStatus == 8) {
  //         setActive(6);
  //         setCurrentStage(6);
  //       } else if (materialStatus == 4 && plantStatus == 9) {
  //         setActive(7);
  //         setCurrentStage(7);
  //       } else if (materialStatus == 4 && plantStatus == 10) {
  //         setActive(8);
  //         setCurrentStage(8);
  //       } else if (materialStatus == 4 && plantStatus == 11) {
  //         setActive(9);
  //         setCurrentStage(9);
  //       } else if (materialStatus == 4 && plantStatus == 12) {
  //         setActive(9);
  //         setCurrentStage(9);
  //       }
  //     } else if (fgType === 'Virtual') {
  //       if (materialStatus == 1 && plantStatus == null) {
  //         setActive(0);
  //         setCurrentStage(0);
  //       } else if (materialStatus == 3 && plantStatus == null) {
  //         setActive(1);
  //         setCurrentStage(1);
  //       } else if (materialStatus == 4 && plantStatus == null) {
  //         setActive(2);
  //         setCurrentStage(2);
  //       } else if (materialStatus == 4 && plantStatus == 9) {
  //         setActive(2);
  //         setCurrentStage(2);
  //       } else if (materialStatus == 4 && plantStatus == 10) {
  //         setActive(3);
  //         setCurrentStage(3);
  //       } else if (materialStatus == 4 && plantStatus == 11) {
  //         setActive(4);
  //         setCurrentStage(4);
  //       } else if (materialStatus == 4 && plantStatus == 12) {
  //         setActive(4);
  //         setCurrentStage(4);
  //       }
  //     } else if (fgType === 'Import') {
  //       if (materialStatus == 1 && plantStatus == null) {
  //         setActive(0);
  //         setCurrentStage(0);
  //       } else if (materialStatus == 2 && plantStatus == null) {
  //         setActive(1);
  //         setCurrentStage(1);
  //       } else if (materialStatus == 3 && plantStatus == null) {
  //         setActive(2);
  //         setCurrentStage(2);
  //       } else if (materialStatus == 4 && plantStatus == null) {
  //         setActive(3);
  //         setCurrentStage(3);
  //       } else if (materialStatus == 4 && plantStatus == 9) {
  //         setActive(3);
  //         setCurrentStage(3);
  //       } else if (materialStatus == 4 && plantStatus == 10) {
  //         setActive(4);
  //         setCurrentStage(4);
  //       } else if (materialStatus == 4 && plantStatus == 11) {
  //         setActive(5);
  //         setCurrentStage(5);
  //       } else if (materialStatus == 4 && plantStatus == 12) {
  //         setActive(5);
  //         setCurrentStage(5);
  //       }
  //     }
  //   }
  //   //console.log('Changed For Plant', params?.plant_id);
  // }, [materialStatus, params?.plant_id]);

  // USE EFFECTS
  useEffect(() => {
    // if (location?.state?.plant_id) {
    //   //console.log('IF EFFECT', location?.state);
    //   setPlantStatus(location?.state?.status);
    // }
    if (params?.plant_id && params?.id) {
      setCurrentLoading(true);
      plantService.getPlantById(params?.plant_id).then((res) => {
        materialService.getMaterialById(params?.id).then((res1) => {
          //console.log('RES', res);
          //console.log('RES1', res1);
          if (res1?.data?.material?.existing_item === 'Yes') {
            if (res1?.data?.material?.material_status == 1 && res?.data?.data?.status == null) {
              setActive(0);
              setCurrentStage(0);
            } else if (res1?.data?.material?.material_status == 4 && res?.data?.data?.status == 5) {
              setActive(1);
              setCurrentStage(1);
            } else if (res1?.data?.material?.material_status == 4 && res?.data?.data?.status == 6) {
              setActive(2);
              setCurrentStage(2);
            } else if (res1?.data?.material?.material_status == 4 && res?.data?.data?.status == 7) {
              setActive(3);
              setCurrentStage(3);
            } else if (res1?.data?.material?.material_status == 4 && res?.data?.data?.status == 8) {
              setActive(4);
              setCurrentStage(4);
            } else if (res1?.data?.material?.material_status == 4 && res?.data?.data?.status == 9) {
              setActive(5);
              setCurrentStage(5);
            } else if (
              res1?.data?.material?.material_status == 4 &&
              res?.data?.data?.status == 10
            ) {
              setActive(6);
              setCurrentStage(6);
            } else if (
              res1?.data?.material?.material_status == 4 &&
              res?.data?.data?.status == 11
            ) {
              setActive(7);
              setCurrentStage(7);
            } else if (
              res1?.data?.material?.material_status == 4 &&
              res?.data?.data?.status == 12
            ) {
              setActive(7);
              setCurrentStage(7);
            } else if (
              res1?.data?.material?.material_status == 4 &&
              res?.data?.data?.status == 13
            ) {
              setActive(8);
              setCurrentStage(8);
            }
          } else {
            if (
              res1?.data?.material?.existing_item === 'No' &&
              (res1?.data?.material?.fg_type === 'Domestic' ||
                res1?.data?.material?.fg_type === 'Copack' ||
                res1?.data?.material?.fg_type === 'Repack')
            ) {
              if (res1?.data?.material?.material_status == 1 && res?.data?.data?.status == null) {
                setActive(0);
                setCurrentStage(0);
              } else if (
                res1?.data?.material?.material_status == 2 &&
                res?.data?.data?.status == null
              ) {
                setActive(1);
                setCurrentStage(1);
              } else if (
                res1?.data?.material?.material_status == 3 &&
                res?.data?.data?.status == null
              ) {
                setActive(2);
                setCurrentStage(2);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 5
              ) {
                setActive(3);
                setCurrentStage(3);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 6
              ) {
                setActive(4);
                setCurrentStage(4);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 7
              ) {
                setActive(5);
                setCurrentStage(5);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 8
              ) {
                setActive(6);
                setCurrentStage(6);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 9
              ) {
                setActive(7);
                setCurrentStage(7);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 10
              ) {
                setActive(8);
                setCurrentStage(8);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 11
              ) {
                setActive(9);
                setCurrentStage(9);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 12
              ) {
                setActive(9);
                setCurrentStage(9);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 13
              ) {
                setActive(10);
                setCurrentStage(10);
              }
            } else if (res1?.data?.material?.fg_type === 'Virtual') {
              if (res1?.data?.material?.material_status == 1 && res?.data?.data?.status == null) {
                setActive(0);
                setCurrentStage(0);
              } else if (
                res1?.data?.material?.material_status == 3 &&
                res?.data?.data?.status == null
              ) {
                setActive(1);
                setCurrentStage(1);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == null
              ) {
                setActive(2);
                setCurrentStage(2);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 9
              ) {
                setActive(2);
                setCurrentStage(2);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 10
              ) {
                setActive(3);
                setCurrentStage(3);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 11
              ) {
                setActive(4);
                setCurrentStage(4);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 12
              ) {
                setActive(4);
                setCurrentStage(4);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 13
              ) {
                setActive(5);
                setCurrentStage(5);
              }
            } else if (res1?.data?.material?.fg_type === 'Import') {
              if (res1?.data?.material?.material_status == 1 && res?.data?.data?.status == null) {
                setActive(0);
                setCurrentStage(0);
              } else if (
                res1?.data?.material?.material_status == 2 &&
                res?.data?.data?.status == null
              ) {
                setActive(1);
                setCurrentStage(1);
              } else if (
                res1?.data?.material?.material_status == 3 &&
                res?.data?.data?.status == null
              ) {
                setActive(2);
                setCurrentStage(2);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == null
              ) {
                setActive(3);
                setCurrentStage(3);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 9
              ) {
                setActive(3);
                setCurrentStage(3);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 10
              ) {
                setActive(4);
                setCurrentStage(4);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 11
              ) {
                setActive(5);
                setCurrentStage(5);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 12
              ) {
                setActive(5);
                setCurrentStage(5);
              } else if (
                res1?.data?.material?.material_status == 4 &&
                res?.data?.data?.status == 13
              ) {
                setActive(6);
                setCurrentStage(6);
              }
            }
          }
          setCurrentLoading(false);
        });
      });
    }
  }, [location?.state?.plant_id, params?.plant_id]);

  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data?.material) {
          setCurrentProject(
            projectList?.find((item) => item?.id === res?.data?.material?.project_id),
          );
          //console.log('PROJECT', projectList);
          setFgType(res?.data?.material?.fg_type);
          setExistingItem(res?.data?.material?.existing_item);
          setMaterialStatus(res?.data?.material?.material_status);

          materialService.getPlantsDetailsForFgForm(params?.id).then((res2) => {
            if (res2?.data) {
              //console.log('PLANT LENGTH', res2?.data);
              if (!location?.state?.plant_id) {
                //console.log('IF', location?.state);
                setPlantStatus(res2?.data?.plants[0]?.status);
              }
              // else {
              //   //console.log('ELSE');
              //   let found = res2?.data?.plants?.find(
              //     (item) => item?.id === location?.state?.plant_id,
              //   );
              //   //console.log('FOUND', found);
              //   setPlantStatus(found?.status);
              // }
            }
          });
          makeStepperList(res);

          // materialService.getPlantsDetailsForFgForm(params?.id).then((res2) => {
          //   if (res2?.data) {
          //     //console.log('PLANT LENGTH', res2?.data);
          //     if (!location?.state?.plant_id) {
          //       setPlantStatus(res2?.data?.plants[0]?.status);
          //     }
          //   }
          // });
          // if (res?.data?.material?.material_status == 1) {
          //   setActive(0);
          // } else if (res?.data?.material?.material_status == 2) {
          //   setActive(1);
          // } else if (res?.data?.material?.material_status == 3) {
          //   setActive(2);
          // } else if (res?.data?.material?.material_status == 4) {
          //   setActive(3);
          // } else if (res?.data?.material?.material_status == 5) {
          //   setActive(5);
          // } else if (res?.data?.material?.material_status == 6) {
          //   setActive(6);
          // }
        }
      });
    }
  }, [params?.id, params?.plant_id]);

  useEffect(() => {
    //console.log('PARAMS', params);
  }, [params?.plant_id]);

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Card radius="xl">
        <Card.Body>
          <Text size="xl" weight={700} color="yellow" align="left">
            {currentProject?.project_name}
          </Text>
        </Card.Body>
        <Card.Body
          p="xl"
          style={{
            overflowX: 'scroll',
          }}
        >
          {stepList?.length > 0 ? (
            <Stepper
              color="yellow"
              active={active}
              onStepClick={setActive}
              // completedIcon={<IconCircleCheck />}
            >
              {stepList.map((item, index) => (
                <Stepper.Step
                  color={index < currentStage ? 'green' : index > currentStage ? 'red' : 'blue'}
                  icon={
                    index < currentStage ? (
                      <IconCircleCheck size={16} color={'green'} />
                    ) : index > currentStage ? (
                      <IconCircleX size={16} color={'red'} />
                    ) : item === 'Completed' ? (
                      <IconCircleCheck size={16} color={'green'} />
                    ) : (
                      <IconHourglass size={16} color={'blue'} />
                    )
                  }
                  completedIcon={
                    index < currentStage ? (
                      <IconCircleCheck size={16} color={'white'} />
                    ) : index > currentStage ? (
                      <IconCircleX size={16} color={'white'} />
                    ) : item === 'Completed' ? (
                      <IconCircleCheck size={16} color={'white'} />
                    ) : (
                      <IconHourglass size={16} color={'white'} />
                    )
                  }
                  label={item}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  // disabled={item === 'Completed'}
                />
              ))}
            </Stepper>
          ) : (
            <CustomCardLoader />
          )}
          {/* <Stepper
            color="yellow"
            active={active}
            onStepClick={setActive}
            completedIcon={<IconCircleCheck />}
          >
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="SKU Description Approval"
            />
            {existingItem != 'Yes' && fgType != 'Virtual' && (
              <Stepper.Step
                color="yellow"
                icon={<IconBuildingFactory2 size={16} color="black" />}
                label="Full Goods Form"
              />
            )}
            {existingItem != 'Yes' && fgType != 'Virtual' && (
              <Stepper.Step
                color="yellow"
                icon={<IconBuildingFactory2 size={16} color="black" />}
                label="MDM View"
              />
            )}

            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="Packaging BOM Form "
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="BOMs Validation "
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="Liquid BOM Form "
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="SAP BOMs Creation "
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="RM Costing"
            />
          </Stepper> */}
        </Card.Body>
        <Group position="apart" mt="xl" p="xl">
          <Button
            color="gray"
            radius="xl"
            onClick={prevStep}
            leftIcon={<IconChevronLeft size={16} color="white" />}
            disabled={active === 0}
          >
            {`Previous`}
          </Button>
          <Button
            color="yellow"
            radius="xl"
            onClick={nextStep}
            rightIcon={<IconChevronRight size={16} color="white" />}
            disabled={active >= stepList?.length - 1}
          >
            {`Next step`}
          </Button>
        </Group>
      </Card>
      <Space h={40}></Space>

      {/* {active === 0 && (
        <>
          <MaterialApproval />
        </>
      )}

      {active === 1 && existingItem != 'Yes' && fgType != 'Virtual' && (
        <>
          <FgFormInternal nextStep={nextStep} />
        </>
      )}

      {active === 2 && <MDMview />}
      {active === 3 && <PackagingBOMView
        setPlantStatus={setPlantStatus}
        />}
      {active === 4 && <MDMView2 nextStep={nextStep}
      setPlantStatus={setPlantStatus}
      />}
      {active === 5 && <LiquidBOMView
        setPlantStatus={setPlantStatus}
        />}
      {active === 6 && <MDMView3
        setPlantStatus={setPlantStatus}
        />}
      {active === 7 && <RmCosting
      setPlantStatus={setPlantStatus}
      />} */}

      <>
        {currentLoading ? (
          <CustomCardLoader />
        ) : (
          <>
            {stepList?.length > 0 ? (
              <>
                {/* RENDER FOR 'YES' */}
                {existingItem === 'Yes' && active === 0 && <MaterialApproval />}
                {existingItem === 'Yes' && active === 1 && (
                  <PackagingBOMView setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 2 && (
                  <MDMView2 nextStep={nextStep} setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 3 && (
                  <LiquidBOMView setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 4 && (
                  <MDMView3 setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 5 && (
                  <RmCosting setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 6 && (
                  <ControllerView setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 7 && (
                  <RmCosting2 setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'Yes' && active === 8 && (
                  <Completed active={currentStage === 8} />
                )}
                {/* {existingItem === 'Yes' && active === 8 && (
            <SummaryView setPlantStatus={setPlantStatus} />
          )} */}

                {/* RENDER FOR 'NO' */}
                {/* RENDER FOR 'DOMESTIC'/'COPACK'/'REPACK */}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 0 && <MaterialApproval />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 1 && <FgFormInternal nextStep={nextStep} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 2 && <MDMview />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 3 && <PackagingBOMView setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 4 && <MDMView2 nextStep={nextStep} setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 5 && <LiquidBOMView setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 6 && <MDMView3 setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 7 && <RmCosting setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 8 && <ControllerView setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 9 && <RmCosting2 setPlantStatus={setPlantStatus} />}
                {existingItem === 'No' &&
                  (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
                  active === 10 && <Completed active={currentStage === 10} />}
                {/* {existingItem === 'No' &&
            (fgType === 'Domestic' || fgType === 'Copack' || fgType === 'Repack') &&
            active === 10 && <SummaryView setPlantStatus={setPlantStatus} />} */}

                {/* RENDER FOR 'VIRTUAL' */}
                {existingItem === 'No' && fgType === 'Virtual' && active === 0 && (
                  <MaterialApproval />
                )}
                {existingItem === 'No' && fgType === 'Virtual' && active === 1 && <MDMview />}
                {existingItem === 'No' && fgType === 'Virtual' && active === 2 && (
                  <RmCosting setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Virtual' && active === 3 && (
                  <ControllerView setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Virtual' && active === 4 && (
                  <RmCosting2 setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Virtual' && active === 5 && (
                  <Completed active={currentStage === 5} />
                )}
                {/* {existingItem === 'No' && fgType === 'Virtual' && active === 5 && (
            <SummaryView setPlantStatus={setPlantStatus} />
          )} */}

                {/* RENDER FOR 'IMPORT' */}
                {existingItem === 'No' && fgType === 'Import' && active === 0 && (
                  <MaterialApproval />
                )}
                {existingItem === 'No' && fgType === 'Import' && active === 1 && (
                  <FgFormInternal nextStep={nextStep} />
                )}
                {existingItem === 'No' && fgType === 'Import' && active === 2 && <MDMview />}
                {existingItem === 'No' && fgType === 'Import' && active === 3 && (
                  <RmCosting setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Import' && active === 4 && (
                  <ControllerView setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Import' && active === 5 && (
                  <RmCosting2 setPlantStatus={setPlantStatus} />
                )}
                {existingItem === 'No' && fgType === 'Import' && active === 6 && (
                  <Completed active={currentStage === 6} />
                )}
                {/* {existingItem === 'No' && fgType === 'Import' && active === 6 && (
            <SummaryView setPlantStatus={setPlantStatus} />
          )} */}
              </>
            ) : (
              <CustomCardLoader />
            )}
          </>
        )}
      </>
    </div>
  );
}
export default MaterialForm;
