/* eslint-disable import/no-anonymous-default-export */
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';

export default {
  createNewPlant: async function (data) {
    try {
      const response = await axios.post(urls.addPlantsUrl, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateExistingPlant: async function (data) {
    try {
      const response = await axios.patch(urls.updatePlantsUrl, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteExistingPlant: async function (data) {
    try {
      const response = await axios.patch(urls.deletePlantsUrl, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllPlants: async function (offset) {
    try {
      const response = await axios.post(urls.getAllPlantsUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getPlantsByID: async function (data) {
    try {
      const response = await axios.get(urls.getPlantsByIDUrl, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },

  savePlantMember: async function (data) {
    try {
      // console.log(data);
      const response = axios.post(urls.savePlantMemberUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedPlant: async function (searchPlant) {
    try {
      const response = await axios.post(urls.getSearchedPlantUrl, { plant: searchPlant });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllPlantMembers: async function (id, offset) {
    try {
      const response = await axios.post(urls.getAllPlantMembersUrl, {
        plant_id: id,
        offset: offset,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  deletePlantMember: async function (plant_id, user_id) {
    try {
      const response = await axios.patch(urls.deletePlantMemberUrl, {
        plant_id: plant_id,
        user_id: user_id,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
