/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';
import { graphConfig } from '../utils/authConfig';

export default {
  getAllProjects: async function () {
    try {
      const response = await axios.get(urls.getAllProjectsURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllDraftProjects: async function (body) {
    try {
      const { data } = await axios.post(urls.getAllDraftProjectsURL, body);
      return data;
    } catch (error) {
      return error.response;
    }
  },
  createProject: async function (data) {
    try {
      const response = await axios.post(urls.createProjectURL, {
        data,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getProjectTypes: async function () {
    try {
      const response = await axios.get(urls.getProjectTypesURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getDraftProjectById: async function (id) {
    try {
      const response = await axios.get(urls.getDraftProjectByIdURL + id);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateProject: async function (data) {
    try {
      const response = await axios.patch(urls.updateProjectURL, {
        data,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getProjectById: async function (id) {
    try {
      const response = await axios.get(urls.getProjectByIdURL + id);
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
