/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Group,
  Space,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconAsteriskSimple, IconCheck, IconInfoCircle, IconX } from '@tabler/icons';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import materialService from '../../../../services/materialService';
import PlantTable from '../../../../components/PlantTable/PlantTable';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import { UserContext } from '../../../../context/userContext';
import ApproveMaterial from '../../../MaterialApproval/ApproveMaterial';
import DisabledTextInput from '../../../../components/DisabledTextInput/DisabledTextInput';
import { toast } from 'react-toastify';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';

function MDMview() {
  const [userState, userDispatch] = useContext(ActiveUserContext);
  // PARAMS
  let params = useParams();
  const navigate = useNavigate();

  // CONTECT
  const { setSelectedProjectMaterials } = useContext(UserContext);

  // STATES
  const [data, setData] = useState({});
  const [showRejectMaterialModal, setShowRejectMaterialModal] = useState(false);
  const [showCancelMaterialModal, setShowCancelMaterialModal] = useState(false);
  // const [enterSKU, setEnterSKU] = useState('');
  // const [enterPH, setEnterPH] = useState('');
  const [showApproveMaterialModal, setShowApproveMaterialModal] = useState(false);

  // use effects
  useEffect(() => {
    // console.log('loading material MDM');
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          // console.log(res?.data?.material);
          setData(res?.data?.material);
          // setEnterPH(res?.data?.material?.ph);
          formik.setFieldValue('ph', res?.data?.material?.ph);
          formik.setFieldValue('sku', res?.data?.material?.sku_id);

          // if (res?.data?.material?.existing_item === 'Yes') {
          //   // setEnterSKU(res?.data?.material?.existing_item_number);
          //   formik.setFieldValue('sku', res?.data?.material?.existing_item_number);
          // } else {
          //   // setEnterSKU(res?.data?.material?.sku_id);
          //   formik.setFieldValue('sku', res?.data?.material?.sku_id);
          // }
        }
      });
    }
  }, [params?.id]);

  // FORMIK
  const formik = useFormik({
    initialValues: {
      ph: '',
      sku: '',
    },
    validationSchema: Yup.object({
      ph: Yup.string()
        ?.test('len', 'Should be equal to 18 characters', (val) => val?.length === 18)
        .nullable(),
      sku: Yup.string().required('Required').nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setShowApproveMaterialModal(true);
    },
  });

  // functions
  const approveMaterial = () => {
    materialService
      .mdmApproval(params?.id, {
        sku_id: formik.values.sku,
        ph: formik.values.ph,
      })
      .then((res) => {
        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setShowApproveMaterialModal(false);
        } else {
          toast.success('Material Approved Successfully');
          navigate('/');
        }
      })
      .catch((err) => {
        toast.error('Something went wrong, Please try again later');
      });
  };

  // CANCEL
  const cancelRequest = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        setSelectedProjectMaterials([]);
        toast.success('Material Rejected Successfully');
        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, Please try again later');
      });
  };

  const rejectRequest = (comment) => {
    materialService
      .rejectFgForm({
        material_id: params?.id,
        comment,
      })
      .then((res) => {
        setSelectedProjectMaterials([]);
        toast.success('Material Rejected Successfully');
        navigate('/');
      })
      .catch((err) => {
        toast.error('Something went wrong, Please try again later');
      });
  };

  // constant
  const IS_DOM_CO_RE =
    data?.fg_type === 'Domestic' || data?.fg_type === 'Copack' || data?.fg_type === 'Repack';

  const IS_VIRTUAL = data?.fg_type === 'Virtual';

  const IS_IMPORT = data?.fg_type === 'Import';

  const IS_EXIST = data?.existing_item === 'Yes';

  return (
    <>
      <Card radius="xl">
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
              {'SKU ID Creation'}
            </Text>
          </Group>
        </Card.Body>
        <Card.Body align="left">
          <Grid>
            {IS_DOM_CO_RE && <Show_Domestic_Copack_Repack data={data} />}
            {IS_IMPORT && <Show_Import data={data} />}
            {IS_VIRTUAL && <Show_Virtual data={data} />}
            {IS_EXIST && <SHow_IsExisting data={data} />}
            <Grid.Col span={4}>
              <TextInput
                label="SKU ID"
                placeholder="SKU ID"
                radius={'lg'}
                value={formik?.values?.sku}
                onChange={(e) => {
                  formik.setFieldValue('sku', e.target.value);
                }}
                error={formik.errors.sku}
                required
                type="number"
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <TextInput
                placeholder="Enter PH"
                label="Enter PH"
                radius={'lg'}
                value={formik?.values?.ph}
                onChange={(e) => {
                  formik.setFieldValue('ph', e.target.value);
                }}
                error={formik.errors.ph}
                required
              />
              <Text color="yellow">PH Suggestion : {data?.ph_suggestion}</Text>
            </Grid.Col>
          </Grid>
        </Card.Body>
        <PlantTable disabled={data?.material_status != 3} materialData={data} />

        <Card.Footer>
          <Col>
            {check_roles(userState, ['admin', 'mdm']) && (
              <Group position="right">
                {data?.material_status == 3 && (
                  <>
                    <Button
                      color="gray"
                      radius="xl"
                      leftIcon={<IconX size={16} color="white" />}
                      onClick={() => setShowCancelMaterialModal(true)}
                    >{`Delete`}</Button>
                    <Button
                      color="gray"
                      radius="xl"
                      leftIcon={<IconX size={16} color="white" />}
                      onClick={() => setShowRejectMaterialModal(true)}
                    >{`Reject`}</Button>
                    <Button
                      color="yellow"
                      radius="xl"
                      className="btn btn-primary"
                      style={{ borderRadius: '40px' }}
                      leftIcon={<IconCheck size={16} color="white" />}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >{`Submit`}</Button>
                  </>
                )}
              </Group>
            )}
          </Col>
          <Space h="xl"></Space>
        </Card.Footer>
      </Card>

      <RejectMaterial
        showRejectMaterialModal={showCancelMaterialModal}
        closeRejectMaterialModal={() => setShowCancelMaterialModal(false)}
        rejectMaterial={(comment) => {
          cancelRequest(comment);
        }}
      />

      <RejectMaterial
        showRejectMaterialModal={showRejectMaterialModal}
        closeRejectMaterialModal={() => setShowRejectMaterialModal(false)}
        rejectMaterial={(comment) => {
          rejectRequest(comment);
        }}
        message={'Are you sure you want to Reject this request ?'}
      />

      <ApproveMaterial
        onClose={() => setShowApproveMaterialModal(false)}
        showApproveMaterialModal={showApproveMaterialModal}
        onSubmit={() => approveMaterial()}
        message={'Are you sure you want to Approve this request ?'}
      />
    </>
  );
}

export default MDMview;

function Show_Domestic_Copack_Repack({ data }) {
  return (
    <>
      <Grid.Col span={6}>
        <DisabledTextInput
          label={'SKU Description'}
          placeholder={'SKU Description'}
          value={data?.sku_description}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <DisabledTextInput label={'Brand'} placeholder={'Brand'} value={data?.brand} />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput label={'Sub Brand'} placeholder={'Sub Brand'} value={data?.sub_brand} />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Returntability"
          placeholder="Container Returntability"
          value={data?.container_returnable}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Type"
          placeholder="Container Type"
          value={data?.container_type}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Material"
          placeholder="Container Material"
          value={data?.container_material}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex
          gap="xs"
          justify="center"
          // align="center"
          direction="row"
          wrap="nowrap"
        >
          <TextInput
            disabled
            radius={'lg'}
            type="number"
            placeholder="Pack Size 1"
            label={
              <Flex gap="xm" justify="right" align="center" direction="row" wrap="nowrap">
                {'Pack Size '}
                <Tooltip
                  multiline
                  width={220}
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                >
                  <ActionIcon size="sm" variant="transparent">
                    <IconInfoCircle size={14} color={'#fab005'} />
                    <IconAsteriskSimple size={6} strokeWidth={3} color={'#fa5252'} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            }
            value={data?.pack_size_1}
          />
          <Text mt={'6vh'}>X</Text>
          <TextInput
            disabled
            radius={'lg'}
            required
            type="number"
            placeholder="Pack Size 2"
            label=" "
            value={data?.pack_size_2}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Volume"
          placeholder="Container Volume"
          value={data?.container_volume}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex
          gap="xs"
          justify="center"
          // align="center"
          direction="row"
          wrap="nowrap"
        >
          <TextInput
            disabled
            radius={'lg'}
            type="number"
            placeholder="Pack Size 1"
            label={'Palletization '}
            value={data?.palletization_1}
          />
          <Text mt={'6vh'}>X</Text>
          <TextInput
            disabled
            radius={'lg'}
            required
            type="number"
            placeholder="Pack Size 2"
            label=" "
            value={data?.palletization_2}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Similar SKU Id"
          placeholder="Similar SKU Id"
          value={data?.similar_sku_id}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Similar SKU Description"
          placeholder="Similar SKU Description"
          value={data?.similar_sku_description}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput label="FG Type" placeholder="FG Type" value={data?.fg_type} />
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput
          label="Liquid Class"
          placeholder="Liquid Class"
          value={data?.liquid_class}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Gross Weight"
          placeholder="Gross Weight"
          value={data?.gross_weight}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput label="Alcohol %" placeholder="Alcohol %" value={data?.alcohol} />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Price Class"
          placeholder="Price Class"
          value={data?.price_class}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        <Flex
          gap="sm"
          // justify="center"
          align="flex-end"
          direction="row"
          wrap="nowrap"
        >
          <DisabledTextInput placeholder="Length" label="Dimension" value={data?.length} />
          <Text mb={'1vh'}>X</Text>
          <DisabledTextInput placeholder="Width" label="" value={data?.width} />
          <Text mb={'1vh'}>X</Text>
          <DisabledTextInput placeholder="Height" label="" value={data?.height} />
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput
          label="Taste Segment"
          placeholder="Taste Segment"
          value={data?.taste_segment}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput label="UPC" placeholder="UPC" value={data?.upc_tbs_codes} />
      </Grid.Col>
    </>
  );
}

function Show_Import({ data }) {
  return (
    <>
      <Grid.Col span={4}>
        <DisabledTextInput
          label={'SKU Description'}
          placeholder={'SKU Description'}
          value={data?.sku_description}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput label={'Liquid Type'} placeholder={'Liquid Type'} value={data?.liquid} />
      </Grid.Col>
      <Grid.Col span={6}>
        <DisabledTextInput label={'Brand'} placeholder={'Brand'} value={data?.brand} />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput label={'Sub Brand'} placeholder={'Sub Brand'} value={data?.sub_brand} />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Returntability"
          placeholder="Container Returntability"
          value={data?.container_returnable}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Type"
          placeholder="Container Type"
          value={data?.container_type}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Material"
          placeholder="Container Material"
          value={data?.container_material}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex
          gap="xs"
          justify="center"
          // align="center"
          direction="row"
          wrap="nowrap"
        >
          <TextInput
            disabled
            radius={'lg'}
            type="number"
            placeholder="Pack Size 1"
            label={
              <Flex gap="xm" justify="right" align="center" direction="row" wrap="nowrap">
                {'Pack Size '}
                <Tooltip
                  multiline
                  width={220}
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                >
                  <ActionIcon size="sm" variant="transparent">
                    <IconInfoCircle size={14} color={'#fab005'} />
                    <IconAsteriskSimple size={6} strokeWidth={3} color={'#fa5252'} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            }
            value={data?.pack_size_1}
          />
          <Text mt={'6vh'}>X</Text>
          <TextInput
            disabled
            radius={'lg'}
            required
            type="number"
            placeholder="Pack Size 2"
            label=" "
            value={data?.pack_size_2}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Container Volume"
          placeholder="Container Volume"
          value={data?.container_volume}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Flex
          gap="xs"
          justify="center"
          // align="center"
          direction="row"
          wrap="nowrap"
        >
          <TextInput
            disabled
            radius={'lg'}
            type="number"
            placeholder="Pack Size 1"
            label={'Palletization '}
            value={data?.palletization_1}
          />
          <Text mt={'6vh'}>X</Text>
          <TextInput
            disabled
            radius={'lg'}
            required
            type="number"
            placeholder="Pack Size 2"
            label=" "
            value={data?.palletization_2}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Similar SKU Id"
          placeholder="Similar SKU Id"
          value={data?.similar_sku_id}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label="Similar SKU Description"
          placeholder="Similar SKU Description"
          value={data?.similar_sku_description}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput label="FG Type" placeholder="FG Type" value={data?.fg_type} />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput
          label="Liquid Class"
          placeholder="Liquid Class"
          value={data?.liquid_class}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput
          label="Gross Weight"
          placeholder="Gross Weight"
          value={data?.gross_weight}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput label="Alcohol %" placeholder="Alcohol %" value={data?.alcohol} />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput
          label="Price Class"
          placeholder="Price Class"
          value={data?.price_class}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        <Flex
          gap="sm"
          // justify="center"
          align="flex-end"
          direction="row"
          wrap="nowrap"
        >
          <DisabledTextInput placeholder="Length" label="Dimension" value={data?.length} />
          <Text mb={'1vh'}>X</Text>
          <DisabledTextInput placeholder="Width" label="" value={data?.width} />
          <Text mb={'1vh'}>X</Text>
          <DisabledTextInput placeholder="Height" label="" value={data?.height} />
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput
          label="Taste Segment"
          placeholder="Taste Segment"
          value={data?.taste_segment}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <DisabledTextInput label="UPC" placeholder="UPC" value={data?.upc_tbs_codes} />
      </Grid.Col>
    </>
  );
}

function Show_Virtual({ data }) {
  return (
    <>
      <Grid.Col span={3}>
        <DisabledTextInput
          label={'Real Item SKU ID'}
          placeholder={'Real Item SKU ID'}
          value={data?.real_item}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput
          label={'SKU Description'}
          placeholder={'SKU Description'}
          value={data?.sku_description}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput label={'FG Type'} placeholder={'FG Type'} value={data?.fg_type} />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput label="UPC" placeholder="UPC" value={data?.upc_tbs_codes} />
      </Grid.Col>
    </>
  );
}

function SHow_IsExisting({ data }) {
  return (
    <>
      <Grid.Col span={4}>
        <DisabledTextInput
          label={'SKU Description'}
          placeholder={'SKU Description'}
          value={data?.sku_description}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <DisabledTextInput
          label={'Existing Item SKU ID'}
          placeholder={'Existing Item SKU ID'}
          value={data?.existing_item_number}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <DisabledTextInput label="UPC" placeholder="UPC" value={data?.upc_tbs_codes} />
      </Grid.Col>
    </>
  );
}
