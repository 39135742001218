/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Group,
  TextInput,
  Space,
  Text,
  Modal,
  Select,
  Grid,
  Flex,
  MultiSelect,
} from '@mantine/core';
import { Input } from '@mantine/core';
import { IconAt } from '@tabler/icons';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import SkuDescription from './SkuDescription';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row, Col, Card } from 'react-bootstrap';
import materialService from '../../services/materialService';
import { NumberInput, Tooltip } from '@mantine/core';
import { InfoCircle, AsteriskSimple } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { UserContext } from '../../context/userContext';
import * as Yup from 'yup';

function CloneMaterial({ showCloneMaterialModal, closeCloneMaterilaModal, addMaterial, material }) {
  const [plants, setPlants] = useState([
    {
      index: 0,
      plant: '',
      selling_region: '',
      production_date: null,
      str_date: null,
      stop_ship_date: '',
    },
  ]);

  const { loading, loadProjects, generateSKUDescription } = useContext(UserContext);

  const [showSkuDescriptionModal, setShowSkuDescriptionModal] = useState(false);
  const [materialObject, setMaterialObject] = useState({});
  const [materialType, setMaterialType] = useState('Z001');
  const [valuationClass, setValuationClass] = useState('Z081');
  const [existingItem, setExistingItem] = useState();
  const [existingItemNumber, setExistingItemNumber] = useState();
  const [fgType, setFgType] = useState();
  const [liquid, setLiquid] = useState();
  const [brand, setBrand] = useState();
  const [subBrand, setSubBrand] = useState();
  const [packSize1, setPackSize1] = useState();
  const [packSize2, setPackSize2] = useState();
  const [containerType, setContainerType] = useState();
  const [containerMaterial, setContainerMaterial] = useState();
  const [containerVolume, setContainerVolume] = useState();
  const [decoType, setDecoType] = useState();
  const [palletization1, setPalletization1] = useState();
  const [palletization2, setPalletization2] = useState();
  const [suffix, setSuffix] = useState();
  const [alcohol, setAlcohol] = useState();
  const [grossWeight, setGrossWeight] = useState();
  const [language, setLanguage] = useState();
  const [importedFrom, setImportedFrom] = useState();
  const [virtualSku, setVirtualSku] = useState();
  const [realItem, setRealItem] = useState();
  const [containerReturnable, setContainerReturnable] = useState();
  const [length, setLength] = useState();
  const [tasteSegment, setTasteSegment] = useState();
  const [liquidClass, setLiquidClass] = useState();
  const [upcTbsCodes, setUpcTbsCodes] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [priceClass, setPriceClass] = useState();
  const [skuDescription, setSKUDescription] = useState();
  const [brandList, setBrandList] = useState([]);
  const [subBrandList, setSubBrandList] = useState([]);
  const [fgTypeList, setFGTypeList] = useState([]);
  const [containerTypeList, setContainerTypeList] = useState([]);
  const [containerVolumeList, setContainerVolumeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [liquidDropDown, setLiquidDropDown] = useState([]);
  const [brandDropDown, setBrandDropDown] = useState([]);
  const [subBrandDropDown, setSubBrandDropDown] = useState([]);
  const [plantDropDown, setPlantDropDown] = useState([]);
  const [regionDropDown, setRegionDropDown] = useState([]);
  const [priceClassDropDown, setPriceClassDropDown] = useState([]);
  const [importedFromDropDown, setImportedFromDropDown] = useState([]);
  const [tasteSegmentDropDown, setTasteSegmentDropDown] = useState([]);
  const [liquidClassDropDown, setLiquidClassDropDown] = useState([]);
  const [packSize2List, setPackSize2List] = useState([]);
  const [packSize1List, setPackSize1List] = useState([]);
  const [packSize2TempList, setPackSize2TempList] = useState([]);
  const [packSize1TempList, setPackSize1TempList] = useState([]);
  const [containerReturnableList, setContainerReturnableList] = useState([]);
  const [containerMaterialList, setContainerMaterialList] = useState([]);
  const [existingSkuSerachValue, setExistingSkuSerachValue] = useState('');
  const [existingItemNumberList, setExistingItemNumberList] = useState([]);
  const [packSize1CompareValue, setPackSize1CompareValue] = useState(true);
  const [packSize2CompareValue, setPackSize2CompareValue] = useState(true);
  const [brandSearchValue, setBrandSearchValue] = useState('');
  const [containerVolumeSearchValue, setContainerVolumeSearchValue] = useState('');
  const [realItemSearchValue, setRealItemSearchValue] = useState('');
  const [suffixList, setSuffixList] = useState(['N/A']);
  const [createNewValue, setCreateNewValue] = useState('');
  const [createNewValueType, setCreateNewValueType] = useState('');
  const [showCreateNewValueModal, setShowCreateNewValueModal] = useState(false);
  const [createNewBrand, setCreateNewBrand] = useState('');
  const [createNewSubBrand, setCreateNewSubBrand] = useState('');
  const [subBrandSearchValue, setSubBrandSearchValue] = useState('');
  const [prodhSuggestion, setProdhSuggestion] = useState('');
  const [stopShipDateDropDown, setStopShipDateDropDown] = useState([]);

  useEffect(() => {
    if (showCloneMaterialModal) {
      materialService.getMaterialFGTypes().then((res) => {
        if (Array.isArray(res.data?.fgTypes)) {
          setFGTypeList(res.data?.fgTypes?.map((fgType) => fgType.fg_type));
        }
      });
      // materialService.getMaterialContainerTypes()
      //   .then((res) => {
      //     if (res.data) {
      //       setContainerTypeList(res.data?.containerTypes?.map((containerType) => containerType.container_type))
      //     }
      //   });
      // materialService.getMaterialContainerVolumes()
      //   .then((res) => {
      //     if (res.data) {
      //       setContainerVolumeList(res.data?.containerVolumes?.map((containerVolume) => containerVolume.container_volume))
      //     }
      //   });
      materialService.getMaterialLanguages().then((res) => {
        if (res.data && Array.isArray(res.data?.languages)) {
          setLanguageList(res.data?.languages?.map((language) => language.language));
        }
      });
      materialService.getAllLiquids().then((res) => {
        if (Array.isArray(res?.data?.liquid)) {
          setLiquidDropDown(res?.data?.liquid);
          // console.log(res);
        }
      });
      materialService.getAllPlants().then((res) => {
        if (Array.isArray(res?.data?.plant)) {
          // console.log(res);
          setPlantDropDown(
            res?.data?.plant ? res?.data?.plant?.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
          );
        }
      });
      materialService.getAllRegions().then((res) => {
        if (res && res.data && res?.data?.region) {
          // console.log('REGION DROPDOWN', res);
          var templist1 = res?.data?.region;
          var templist2 = [
            { label: 'West - AB BC MB SK', value: 'West - AB BC MB SK' },
            { label: 'Atlantic - NS PE NB NL', value: 'Atlantic - NS PE NB NL' },
            { label: 'PE', value: 'PE' },
          ];
          // templist1 = templist1.concat(templist2);

          setRegionDropDown(
            Array.isArray(res?.data?.region)
              ? [...res?.data?.region, ...templist2]?.sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          );
        }
      });
      materialService.getAllImportedFrom().then((res) => {
        if (res) {
          // console.log(res);
          setImportedFromDropDown(
            Array.isArray(res?.data?.importedfrom) ? res?.data?.importedfrom : [],
          );
        }
      });
      materialService.getAllPriceClass().then((res) => {
        if (res) {
          // console.log(res);
          setPriceClassDropDown(
            Array.isArray(res?.data?.priceclass)
              ? res?.data?.priceclass?.sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          );
        }
      });
      materialService.getAllLiquidClass().then((res) => {
        if (res) {
          // console.log(res);
          setLiquidClassDropDown(
            Array.isArray(res?.data?.liquidclass)
              ? res?.data?.liquidclass?.sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          );
        }
      });
      materialService.getAllTasteSegment().then((res) => {
        if (res) {
          // console.log(res);
          setTasteSegmentDropDown(
            Array.isArray(res?.data?.tastesegment)
              ? res?.data?.tastesegment?.sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          );
        }
      });
      materialService.getStopShipDate().then((res) => {
        if (res) {
          setStopShipDateDropDown(
            res && res.length > 0 ? res?.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
          );
        }
      });
      handleChangeLiquid(material?.liquid, '');
      handleChangeBrand(material?.brand, '');
      handleReset();
      // setBrandDropDown(brandDropDown.concat([material?.brand]));
      // setSubBrandDropDown(subBrandDropDown.concat([material?.subBrand]));
      // setContainerReturnableList(containerReturnableList.concat([material?.containerReturnable]));
      // setContainerTypeList(containerTypeList.concat([material?.containerType]));
      // setContainerMaterialList(containerMaterialList.concat([material?.containerMaterial]));
      // setPackSize1List(packSize1List.concat([material?.packSize1]));
      // setPackSize2List(packSize2List.concat([material?.packSize2]));
      // setPackSize2TempList(packSize2TempList.concat([material?.packSize2]));
      // setPackSize1TempList(packSize1TempList.concat([material?.packSize1]));
      // setContainerVolumeList(containerVolumeList.concat([material?.containerVolume]));
      handleChangeSubBrand(material?.subBrand);
      setPlants(
        material?.plants?.length > 0
          ? material.plants
          : [
              {
                index: 0,
                plant: '',
                selling_region: '',
                production_date: null,
                str_date: null,
                stop_ship_date: '',
              },
            ],
      );
      // console.log(material.plants)
      // if(material.plants.some(plant => plant.plant === "Import")){
      //   setBrandDropDown(brandDropDown.concat([{label:"Import",value:"Import"}]));
      //   console.log(brandDropDown)
      //   console.log("Import works")
      // }
    }
  }, [showCloneMaterialModal]);

  const handleAddMaterial = (values) => {
    let materialObj = {
      ...values,
      plants: plants,
      draftStatus: false,
    };
    setMaterialObject(materialObj);
    setShowSkuDescriptionModal(true);
  };

  const handleSaveDraftMaterial = (values) => {
    let materialObj = {
      ...values,
      plants: plants,
      draftStatus: true,
    };
    setMaterialObject(materialObj);
    addMaterial(materialObj);
    handleReset();
  };

  const packSize2Compare = () => {
    if (formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem === 'No') {
      if (Number(formik?.values?.packSize1) == 1 && Number(formik?.values?.packSize2) > 1) {
        // console.log('Pack Size Compare1:', true);
        setPackSize1CompareValue(true);
        return;
      } else if (
        Number(formik?.values?.packSize1) != 1 &&
        Number(formik?.values?.packSize2) > 0 &&
        Number(formik?.values?.packSize1) > 1
      ) {
        // console.log('Pack Size Compare2:', false);
        setPackSize1CompareValue(false);
        return;
      } else if (Number(formik?.values?.packSize1) == 1 && Number(formik?.values?.packSize2) == 1) {
        // console.log('Pack Size Compare3:', false);
        setPackSize1CompareValue(false);
        return;
      } else if (Number(formik?.values?.packSize1) > 1 && Number(formik?.values?.packSize2) > 1) {
        // console.log('Pack Size Compare4:', false);
        setPackSize1CompareValue(false);
        return;
      } else {
        // console.log('Pack Size Compare5:', true);
        setPackSize1CompareValue(true);
        return;
      }
    } else if (formik?.values?.fgType == 'Virtual' || formik?.values?.existingItem === 'Yes') {
      // console.log('Pack Size Compare6:', false);
      setPackSize1CompareValue(false);
      return;
    } else {
      // console.log('Pack Size Compare7:', true);
      setPackSize1CompareValue(true);
      return;
    }
  };

  const packSize1Compare = () => {
    // console.log(
    //   'Pack Size Compare:',
    //   formik?.values?.fgType,
    //   formik?.values?.existingItem,
    //   Number(formik?.values?.packSize2),
    //   Number(formik?.values?.packSize1),
    // );
    if (formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem === 'No') {
      if (Number(formik?.values?.packSize1) > 0) {
        // console.log('Pack Size Compare:', false);
        setPackSize2CompareValue(false);
        return;
      } else {
        // console.log('Pack Size Compare:', true);
        setPackSize2CompareValue(true);
        return;
      }
    } else if (formik?.values?.fgType == 'Virtual' || formik?.values?.existingItem === 'Yes') {
      // console.log('Pack Size Compare:', false);
      setPackSize2CompareValue(false);
      return;
    } else {
      // console.log('Pack Size Compare:', true);
      setPackSize2CompareValue(true);
      return;
    }
  };

  const formik = useFormik({
    initialValues: {
      materialType: material?.materialType ? material?.materialType : '',
      valuationClass: material?.valuationClass ? material?.valuationClass : '',
      existingItem: material?.existingItem ? material?.existingItem : '',
      existingItemNumber: material?.existingItemNumber ? material?.existingItemNumber : '',
      fgType: material?.fgType ? material?.fgType : '',
      liquid: material?.liquid ? material?.liquid : '',
      brand: material?.brand ? material?.brand : '',
      subBrand: material?.subBrand ? material?.subBrand : '',
      packSize1: material?.packSize1 ? material?.packSize1 : '',
      packSize2: material?.packSize2 ? material?.packSize2 : '',
      containerType: material?.containerType ? material?.containerType : '',
      containerMaterial: material?.containerMaterial ? material?.containerMaterial : '',
      containerVolume: material?.containerVolume ? material?.containerVolume : '',
      decoType: material?.decoType ? material?.decoType : '',
      palletization1: material?.palletization1 ? material?.palletization1 : '',
      palletization2: material?.palletization2 ? material?.palletization2 : '',
      suffix: material?.suffix ? material?.suffix : '',
      alcohol: material?.alcohol ? material?.alcohol : '',
      grossWeight: material?.grossWeight ? material?.grossWeight : '',
      language: material?.language ? material?.language : '',
      importedFrom: material?.importedFrom ? material?.importedFrom : '',
      virtualSku: material?.virtualSku ? material?.virtualSku : '',
      realItem: material?.realItem ? material?.realItem : '',
      containerReturnable: material?.containerReturnable ? material?.containerReturnable : '',
      length: material?.length ? material?.length : '',
      tasteSegment: material?.tasteSegment ? material?.tasteSegment : '',
      liquidClass: material?.liquidClass ? material?.liquidClass : '',
      upcTbsCodes: material?.upcTbsCodes ? material?.upcTbsCodes : '',
      width: material?.width ? material?.width : '',
      height: material?.height ? material?.height : '',
      priceClass: material?.priceClass ? material?.priceClass : '',
      skuDescription: material?.skuDescription ? material?.skuDescription : '',
      plants: material?.plants ? material?.plants : '',
      prodhSuggestion: material?.prodhSuggestion ? material?.prodhSuggestion : '',
    },
    enableReinitialize: true,
    validateOnChange: true, // this one
    validateOnBlur: true,
    validationSchema: Yup.object({
      existingItem: Yup.string().required('Existing Item is required'),
      existingItemNumber: Yup.number().when('existingItem', {
        is: (existingItem) => existingItem == 'Yes',
        then: Yup.number().required('Existing Item Number is required'),
        otherwise: Yup.number().notRequired(),
      }),
      fgType: Yup.string().when('existingItem', {
        is: (existingItem) => existingItem == 'No',
        then: Yup.string().required('FG Type is required'),
        otherwise: Yup.string().notRequired(),
      }),
      liquid: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Liquid Type is required'),
        otherwise: Yup.string().notRequired(),
      }),
      brand: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Brand is required'),
        otherwise: Yup.string().notRequired(),
      }),
      subBrand: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Sub Brand is required'),
        otherwise: Yup.string().notRequired(),
      }),
      packSize1: Yup.string()
        .when('fgType', {
          is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
          then: Yup.string().required('Pack Size 2 is required'),
          otherwise: Yup.string().notRequired(),
        })
        .when('fgType', {
          is: (fgType) => packSize2CompareValue != false,
          then: Yup.string().min(20, 'Pack Size 2 should be more than 1'),
        }),
      packSize2: Yup.string()
        .when('fgType', {
          is: (fgType) => packSize1CompareValue != false,
          then: Yup.string().min(20, "Pack Size 2 should be more than Pack Size 1 if it's 1"),
        })
        .when('fgType', {
          is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
          then: Yup.string().required('Pack Size 1 is required'),
        }),
      containerType: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Container Type is required'),
        otherwise: Yup.string().notRequired(),
      }),
      containerMaterial: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Container Material is required'),
        otherwise: Yup.string().notRequired(),
      }),
      containerVolume: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Container Volume is required'),
        otherwise: Yup.string().notRequired(),
      }),
      decoType: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Deco Type is required'),
        otherwise: Yup.string().notRequired(),
      }),
      palletization1: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Palletization 1 is required'),
        otherwise: Yup.string().notRequired(),
      }),
      palletization2: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Palletization 2 is required'),
        otherwise: Yup.string().notRequired(),
      }),
      alcohol: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Alcohol % is required'),
        otherwise: Yup.string().notRequired(),
      }),
      language: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Language is required'),
        otherwise: Yup.string().notRequired(),
      }),
      containerReturnable: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Container Returnable is required'),
        otherwise: Yup.string().notRequired(),
      }),
      tasteSegment: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Taste Segment is required'),
        otherwise: Yup.string().notRequired(),
      }),
      liquidClass: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Liquid Class is required'),
        otherwise: Yup.string().notRequired(),
      }),
      suffix: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Suffix is required'),
        otherwise: Yup.string().notRequired(),
      }),
      priceClass: Yup.string().when('fgType', {
        is: (fgType) => fgType !== 'Virtual' && formik?.values?.existingItem === 'No',
        then: Yup.string().required('Price Class is required'),
        otherwise: Yup.string().notRequired(),
      }),
      importedFrom: Yup.string().when('fgType', {
        is: (fgType) => fgType === 'Import',
        then: Yup.string().required('Imported From is required'),
        otherwise: Yup.string().notRequired(),
      }),
      virtualSku: Yup.string().when('fgType', {
        is: (fgType) => fgType === 'Virtual',
        then: Yup.string().required('virtual Sku is required'),
        otherwise: Yup.string().notRequired(),
      }),
      realItem: Yup.string().when('fgType', {
        is: (fgType) => fgType === 'Virtual',
        then: Yup.string().required('Real Item is required'),
        otherwise: Yup.string().notRequired(),
      }),
      plants: Yup.array().of(
        Yup.object().shape({
          index: Yup.number(),
          plant: Yup.string().required('Plant is required'),
          selling_region: Yup.string().required('Selling Region is required'),
          production_date: Yup.date().required('Production Date is required'),
          str_date: Yup.date().required('STR Date is required'),
          stop_ship_date: Yup.string(),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // console.log('Form data', formik.errors);
      handleAddMaterial(values);
    },
  });

  const handleCreateNewValue = (type, value) => {
    if (type == 'Liquid') {
      formik.setFieldValue(liquid, value);
      setLiquidDropDown((liquidDropDown) => [...liquidDropDown, value]);
    } else if (type == 'Brand') {
      formik.setFieldValue(brand, value);
      setBrandDropDown((brandDropDown) => [...brandDropDown, value]);
      setCreateNewBrand(value);
    } else if (type == 'Sub Brand') {
      formik.setFieldValue(subBrand, value);
      setSubBrandDropDown((subBrandDropDown) => [...subBrandDropDown, value]);
      // console.log(subBrandDropDown);
      setCreateNewSubBrand(value);
    } else if (type == 'Container Returnable') {
      formik.setFieldValue(containerReturnable, value);
      setContainerReturnableList((containerReturnableList) => [...containerReturnableList, value]);
      // console.log(containerReturnableList);
    } else if (type == 'Container Material') {
      formik.setFieldValue(containerMaterial, value);
      setContainerMaterialList((containerMaterialList) => [...containerMaterialList, value]);
    } else if (type == 'Container Type') {
      formik.setFieldValue(containerType, value);
      setContainerTypeList((containerTypeList) => [...containerTypeList, value]);
    } else if (type == 'Pack Size 1') {
      formik.setFieldValue(packSize2, value);
      setPackSize2List((packSize2) => [...packSize2, value]);
      setPackSize2TempList((packSize2) => [...packSize2, value]);
    } else if (type == 'Pack Size 2') {
      formik.setFieldValue(packSize1, value);
      setPackSize1List((packSize1) => [...packSize1, value]);
      setPackSize1TempList((packSize1) => [...packSize1, value]);
    } else if (type == 'Container Volume') {
      formik.setFieldValue(packSize1, value);
      setContainerVolumeList((containerVolumeList) => [...containerVolumeList, value]);
    }
  };

  const checkSKUDescription = (prop, value) => {
    const {
      subBrand,
      packSize1,
      packSize2,
      containerVolume,
      containerType,
      decoType,
      language,
      palletization1,
      palletization2,
      suffix,
    } = {
      ...formik.values,
      [prop]: value,
    };
    if (
      subBrand &&
      packSize1 &&
      packSize2 &&
      containerVolume &&
      containerType &&
      decoType &&
      language &&
      palletization1 &&
      palletization2 &&
      suffix
    ) {
      generateSKUDescription(formik, {
        ...formik.values,
        [prop]: value,
      });
    }
  };

  const handleReset = () => {
    setExistingItem();
    setExistingItemNumber();
    setFgType();
    setLiquid();
    setBrand();
    setSubBrand();
    setPackSize1();
    setPackSize2();
    setContainerType();
    setContainerMaterial();
    setContainerVolume();
    setDecoType();
    setPalletization1();
    setPalletization2();
    setSuffix();
    setAlcohol();
    setGrossWeight();
    setLanguage();
    setImportedFrom();
    setVirtualSku();
    setRealItem();
    setContainerReturnable();
    setLength();
    setTasteSegment();
    setLiquidClass();
    setUpcTbsCodes();
    setWidth();
    setHeight();
    setPriceClass();
    setSKUDescription();
    setPlants([
      {
        index: 0,
        plant: '',
        selling_region: '',
        production_date: null,
        str_date: null,
        stop_ship_date: '',
      },
    ]);
    formik.resetForm({
      existingItem: undefined,
      existingItemNumber: undefined,
      fgType: undefined,
      liquid: undefined,
      brand: undefined,
      subBrand: undefined,
      packSize1: undefined,
      packSize2: undefined,
      containerType: undefined,
      containerMaterial: undefined,
      containerVolume: undefined,
      decoType: undefined,
      palletization1: undefined,
      palletization2: undefined,
      suffix: undefined,
      alcohol: undefined,
      grossWeight: undefined,
      language: undefined,
      importedFrom: undefined,
      virtualSku: undefined,
      realItem: undefined,
      containerReturnable: undefined,
      length: undefined,
      tasteSegment: undefined,
      liquidClass: undefined,
      upcTbsCodes: undefined,
      width: undefined,
      height: undefined,
      priceClass: undefined,
      skuDescription: undefined,
    });
  };

  const handleResetExistingItem = () => {
    setExistingItemNumber();
    setFgType();
    setLiquid();
    setBrand();
    setSubBrand();
    setPackSize1();
    setPackSize2();
    setContainerType();
    setContainerMaterial();
    setContainerVolume();
    setDecoType();
    setPalletization1();
    setPalletization2();
    setSuffix();
    setAlcohol();
    setGrossWeight();
    setLanguage();
    setImportedFrom();
    setVirtualSku();
    setRealItem();
    setContainerReturnable();
    setLength();
    setTasteSegment();
    setLiquidClass();
    setUpcTbsCodes();
    setWidth();
    setHeight();
    setPriceClass();
    setSKUDescription();

    formik.setFieldValue('fgType', undefined);
    formik.setFieldValue('liquid', undefined);
    formik.setFieldValue('brand', undefined);
    formik.setFieldValue('subBrand', undefined);
    formik.setFieldValue('packSize1', undefined);
    formik.setFieldValue('packSize2', undefined);
    formik.setFieldValue('containerType', undefined);
    formik.setFieldValue('containerMaterial', undefined);
    formik.setFieldValue('containerVolume', undefined);
    formik.setFieldValue('decoType', undefined);
    formik.setFieldValue('palletization1', undefined);
    formik.setFieldValue('palletization2', undefined);
    formik.setFieldValue('suffix', undefined);
    formik.setFieldValue('alcohol', undefined);
    formik.setFieldValue('grossWeight', undefined);
    formik.setFieldValue('language', undefined);
    formik.setFieldValue('importedFrom', undefined);
    formik.setFieldValue('virtualSku', undefined);
    formik.setFieldValue('realItem', undefined);
    formik.setFieldValue('containerReturnable', undefined);
    formik.setFieldValue('length', undefined);
    formik.setFieldValue('tasteSegment', undefined);
    formik.setFieldValue('liquidClass', undefined);
    formik.setFieldValue('upcTbsCodes', undefined);
    formik.setFieldValue('width', undefined);
    formik.setFieldValue('height', undefined);
    formik.setFieldValue('priceClass', undefined);
    formik.setFieldValue('skuDescription', undefined);
  };

  const handleCloseCreateNewValueModal = () => {
    setShowCreateNewValueModal(false);
    setCreateNewValue('');
    setCreateNewValueType('');
  };

  const handleResetFgType = () => {
    setLiquid();
    setBrand();
    setSubBrand();
    setPackSize1();
    setPackSize2();
    setContainerType();
    setContainerMaterial();
    setContainerVolume();
    setDecoType();
    setPalletization1();
    setPalletization2();
    setSuffix();
    setAlcohol();
    setGrossWeight();
    setLanguage();
    setImportedFrom();
    setVirtualSku();
    setRealItem();
    setContainerReturnable();
    setLength();
    setTasteSegment();
    setLiquidClass();
    setUpcTbsCodes();
    setWidth();
    setHeight();
    setPriceClass();
    setSKUDescription();

    // formik.values = {
    //   materialType: materialType,
    //   valuationClass: valuationClass,
    //   existingItem: existingItem,
    //   existingItemNumber: existingItemNumber,
    //   fgType: fgType,
    //   liquid: liquid,
    //   brand: brand,
    //   subBrand: subBrand,
    //   packSize1: packSize1,
    //   packSize2: packSize2,
    //   containerType: containerType,
    //   containerMaterial: containerMaterial,
    //   containerVolume: containerVolume,
    //   decoType: decoType,
    //   palletization1: palletization1,
    //   palletization2: palletization2,
    //   suffix: suffix,
    //   alcohol: alcohol,
    //   grossWeight: grossWeight,
    //   language: language,
    //   importedFrom: importedFrom,
    //   virtualSku: virtualSku,
    //   realItem: realItem,
    //   containerReturnable: containerReturnable,
    //   length: length,
    //   tasteSegment: tasteSegment,
    //   liquidClass: liquidClass,
    //   upcTbsCodes: upcTbsCodes,
    //   width: width,
    //   height: height,
    //   priceClass: priceClass,
    //   skuDescription: skuDescription,
    // }
    formik.setFieldValue('liquid', undefined);
    formik.setFieldValue('brand', undefined);
    formik.setFieldValue('subBrand', undefined);
    formik.setFieldValue('packSize1', undefined);
    formik.setFieldValue('packSize2', undefined);
    formik.setFieldValue('containerType', undefined);
    formik.setFieldValue('containerMaterial', undefined);
    formik.setFieldValue('containerVolume', undefined);
    formik.setFieldValue('decoType', undefined);
    formik.setFieldValue('palletization1', undefined);
    formik.setFieldValue('palletization2', undefined);
    formik.setFieldValue('suffix', undefined);
    formik.setFieldValue('alcohol', undefined);
    formik.setFieldValue('grossWeight', undefined);
    formik.setFieldValue('language', undefined);
    formik.setFieldValue('importedFrom', undefined);
    formik.setFieldValue('virtualSku', undefined);
    formik.setFieldValue('realItem', undefined);
    formik.setFieldValue('containerReturnable', undefined);
    formik.setFieldValue('length', undefined);
    formik.setFieldValue('tasteSegment', undefined);
    formik.setFieldValue('liquidClass', undefined);
    formik.setFieldValue('upcTbsCodes', undefined);
    formik.setFieldValue('width', undefined);
    formik.setFieldValue('height', undefined);
    formik.setFieldValue('priceClass', undefined);
    formik.setFieldValue('skuDescription', undefined);
  };

  const handleChangeLiquid = (val, search_val) => {
    var liquid_prodh_element = returnFilter(liquidDropDown, val);

    // console.log(JSON.stringify({"BrandSearchValue":search_val}))
    materialService.getAllBrands(val, liquid_prodh_element, search_val).then((res) => {
      if (res && Array.isArray(res?.data?.brand)) {
        if (createNewBrand != '') {
          setBrandDropDown(res?.data?.brand.concat([createNewBrand]));
        } else {
          setBrandDropDown(res?.data?.brand);
        }
      }
    });
  };

  const handleChangeBrand = async (val, search_val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, val);

    materialService
      .getAllSubBrands(
        formik?.values?.liquid,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        search_val,
      )
      .then((res) => {
        if (res && Array.isArray(res?.data?.subbrand)) {
          if (createNewSubBrand != '') {
            setSubBrandDropDown(res?.data?.subbrand.concat([createNewSubBrand]));
          } else {
            setSubBrandDropDown(res?.data?.subbrand);
          }
        }
      });
  };

  const handleChangeSubBrand = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, val);

    materialService
      .getContainerReturnableList(
        formik?.values?.liquid,
        formik?.values?.brand,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
      )
      .then((res) => {
        if (res && Array.isArray(res?.data?.contret)) {
          setContainerReturnableList(res?.data?.contret);
          return true;
        }
      });
  };

  const returnProdh = (array) => {
    if (array?.length > 0) {
      return array[0].prodh_element;
    } else {
      return 'None';
    }
  };

  const returnFilter = (array, val) => {
    return returnProdh(
      array.filter(function (obj) {
        return obj.value == val;
      }),
    );
  };

  const handleChangeContainerReturnable = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(containerReturnableList, val);

    // await console.log(
    //   'Values:',
    //   await liquid_prodh_element,
    //   await brand_prodh_element,
    //   await sub_brand_prodh_element,
    //   await container_returnable_prodh_element,
    // );

    await materialService
      .getContainerTypeList(
        formik?.values?.liquid,
        formik?.values?.brand,
        formik?.values?.subBrand,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
      )
      .then((res) => {
        if (res && Array.isArray(res?.data?.conttype)) {
          setContainerTypeList(res?.data?.conttype);
          return true;
        }
      });
  };

  const handleChangeContainerType = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(
      containerReturnableList,
      formik?.values?.containerReturnable,
    );

    var container_type_prodh_element = await returnFilter(containerTypeList, val);

    // await console.log(
    //   'Values:',
    //   await liquid_prodh_element,
    //   await brand_prodh_element,
    //   await sub_brand_prodh_element,
    //   await container_returnable_prodh_element,
    //   await container_type_prodh_element,
    // );

    await materialService
      .getContainerMaterialList(
        formik?.values?.liquid,
        formik?.values?.brand,
        formik?.values?.subBrand,
        formik?.values?.containerReturnable,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
        container_type_prodh_element,
      )
      .then((res) => {
        if (res && Array.isArray(res?.data?.contmaterial)) {
          setContainerMaterialList(res?.data?.contmaterial);
          return true;
        }
      });
  };

  const handleChangeContainerMaterial = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(
      containerReturnableList,
      formik?.values?.containerReturnable,
    );

    var container_type_prodh_element = await returnFilter(
      containerTypeList,
      formik?.values?.containerType,
    );

    var container_material_prodh_element = await returnFilter(containerMaterialList, val);

    materialService
      .getPackSize2List(
        formik?.values?.liquid,
        formik?.values?.brand,
        formik?.values?.subBrand,
        formik?.values?.containerReturnable,
        formik?.values?.containerType,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
        container_type_prodh_element,
        container_material_prodh_element,
      )
      .then((res) => {
        // console.log('packsize2', res?.data?.packsize2);
        setPackSize2List(Array.isArray(res?.data?.packsize2) ? res?.data?.packsize2 : []);
        setPackSize2TempList(Array.isArray(res?.data?.packsize2) ? res?.data?.packsize2 : []);
      });
  };

  const handleChangePackSize2 = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(
      containerReturnableList,
      formik?.values?.containerReturnable,
    );

    var container_type_prodh_element = await returnFilter(
      containerTypeList,
      formik?.values?.containerType,
    );

    var container_material_prodh_element = await returnFilter(
      containerMaterialList,
      formik?.values?.containerMaterial,
    );

    var pack_size_2_prodh_element = await returnFilter(packSize2List, val);
    materialService
      .getPackSize1List(
        formik?.values?.liquid,
        formik?.values?.brand,
        formik?.values?.subBrand,
        formik?.values?.containerReturnable,
        formik?.values?.containerType,
        formik?.values?.containerMaterial,
        val,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
        container_type_prodh_element,
        container_material_prodh_element,
        pack_size_2_prodh_element,
      )
      .then((res) => {
        // console.log('packsize1', res?.data?.packsize1);
        setPackSize1List(Array.isArray(res?.data?.packsize1) ? res?.data?.packsize1 : []);
        setPackSize1TempList(Array.isArray(res?.data?.packsize1) ? res?.data?.packsize1 : []);
      });
  };

  const handleChangePackSize1 = async (val, callback) => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(
      containerReturnableList,
      formik?.values?.containerReturnable,
    );

    var container_type_prodh_element = await returnFilter(
      containerTypeList,
      formik?.values?.containerType,
    );

    var container_material_prodh_element = await returnFilter(
      containerMaterialList,
      formik?.values?.containerMaterial,
    );

    var pack_size_2_prodh_element = await returnFilter(packSize2List, formik?.values?.packSize2);
    var pack_size_1_prodh_element = await returnFilter(packSize1List, val);
    materialService
      .getContainerVolumeList(
        formik?.values?.liquid,
        formik?.values?.brand,
        formik?.values?.subBrand,
        formik?.values?.containerReturnable,
        formik?.values?.containerType,
        formik?.values?.containerMaterial,
        formik?.values?.packSize2,
        val,
        containerVolumeSearchValue,
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
        container_type_prodh_element,
        container_material_prodh_element,
        pack_size_2_prodh_element,
        pack_size_1_prodh_element,
      )
      .then((res) => {
        if (res && Array.isArray(res?.data?.containervolume)) {
          setContainerVolumeList(res?.data?.containervolume);
          return true;
        }
      });
  };
  const serchExistingSku = (val) => {
    materialService.getExistingSkuList(val).then((res) => {
      if (res && Array.isArray(res?.data?.sku)) {
        setExistingItemNumberList(
          res?.data?.sku?.sort((a, b) => Number(b?.label) - Number(a?.label)),
        );
        return true;
      }
    });
  };

  const handleSelectExistingSku = (val) => {
    materialService.getExistingSkuDesc(val).then((res) => {
      if (res && res.data) {
        formik.setFieldValue('skuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };
  const handleSelectRealItemSku = (val) => {
    materialService.getExistingSkuList(val).then((res) => {
      if (res && Array.isArray(res?.data?.sku)) {
        setExistingItemNumberList(
          res?.data?.sku?.sort((a, b) => Number(b?.label) - Number(a?.label)),
        );
        return true;
      }
    });
  };
  const handleSelectRealItemSkuDesc = (val) => {
    materialService.getExistingSkuDesc(val).then((res) => {
      if (res && res.data) {
        formik.setFieldValue('virtualSku', res?.data?.skudesc[0]?.sku_description + '_VIRT');
        return true;
      }
    });
  };

  const handleGrossWeight = () => {
    if (
      formik?.values?.containerType &&
      formik?.values?.containerMaterial &&
      formik?.values?.containerVolume &&
      formik?.values?.packSize2 &&
      formik?.values?.packSize1
    ) {
      materialService
        .getGrossWeight(
          formik?.values?.containerType,
          formik?.values?.containerMaterial,
          formik?.values?.containerVolume,
          formik?.values?.packSize2,
          formik?.values?.packSize1,
        )
        .then((res) => {
          if (res && res.data) {
            if (res?.data?.final_weight != '') {
              formik.setFieldValue('grossWeight', res?.data?.final_weight);
            }
            return true;
          }
        });
    }
  };

  useEffect(() => {
    handleGrossWeight();
  }, [
    formik?.values?.containerType,
    formik?.values?.containerMaterial,
    formik?.values?.containerVolume,
    formik?.values?.packSize2,
    formik?.values?.packSize1,
  ]);

  // useEffect(() => {
  //   formik.validateForm();
  // }, [formik.values]);

  const valForm = () => {
    formik.validateForm();
  };

  const getProdhSuggestion = async () => {
    var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid);

    var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand);

    var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand);

    var container_returnable_prodh_element = await returnFilter(
      containerReturnableList,
      formik?.values?.containerReturnable,
    );

    var container_type_prodh_element = await returnFilter(
      containerTypeList,
      formik?.values?.containerType,
    );

    var container_material_prodh_element = await returnFilter(
      containerMaterialList,
      formik?.values?.containerMaterial,
    );

    var pack_size_2_prodh_element = await returnFilter(packSize2List, formik?.values?.packSize2);
    var pack_size_1_prodh_element = await returnFilter(packSize1List, formik?.values?.packSize1);
    var container_volume_prodh_element = await returnFilter(
      containerVolumeList,
      formik?.values?.containerVolume,
    );

    materialService
      .getProdhSuggestion(
        liquid_prodh_element,
        brand_prodh_element,
        sub_brand_prodh_element,
        container_returnable_prodh_element,
        container_type_prodh_element,
        container_material_prodh_element,
        pack_size_2_prodh_element,
        pack_size_1_prodh_element,
        container_volume_prodh_element,
      )
      .then((res) => {
        // console.log(res.data);
        setProdhSuggestion(res.data.prodh);
        formik.setFieldValue('prodhSuggestion', res.data.prodh);
      });
  };

  useEffect(() => {
    // console.log('');
    if (
      formik?.values?.liquid &&
      formik?.values?.brand &&
      formik?.values?.subBrand &&
      formik?.values?.containerReturnable &&
      formik?.values?.containerType &&
      formik?.values?.containerMaterial &&
      formik?.values?.packSize2 &&
      formik?.values?.packSize1 &&
      formik?.values?.containerVolume &&
      formik?.values?.liquid != '' &&
      formik?.values?.brand != '' &&
      formik?.values?.subBrand != '' &&
      formik?.values?.containerReturnable != '' &&
      formik?.values?.containerType != '' &&
      formik?.values?.containerMaterial != '' &&
      formik?.values?.packSize2 != '' &&
      formik?.values?.packSize1 != '' &&
      formik?.values?.containerVolume != ''
    ) {
      getProdhSuggestion();
    }
  }, [
    formik?.values?.liquid,
    formik?.values?.brand,
    formik?.values?.subBrand,
    formik?.values?.containerReturnable,
    formik?.values?.containerType,
    formik?.values?.containerMaterial,
    formik?.values?.packSize2,
    formik?.values?.packSize1,
    formik?.values?.containerVolume,
  ]);

  useEffect(() => {
    packSize1Compare();
    packSize2Compare();
  }, [
    formik.values.packSize1,
    formik?.values?.packSize2,
    formik?.values?.existingItem,
    formik?.values?.fgType,
  ]);

  useEffect(() => {
    if (fgType === 'Import') {
      let tempPlants = plants?.map((currPlant) => {
        currPlant.plant = 'Import';

        return currPlant;
      });
      // console.log('tempPlants', tempPlants);
      setPlants(tempPlants);
      formik.setFieldValue('plants', tempPlants);
      // console.log('tempPlants', tempPlants);
    }
  }, [fgType]);

  useEffect(() => {
    liquidDropDown?.length > 0 && handleChangeLiquid(material?.liquid, '');
  }, [material?.liquid, liquidDropDown?.length]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      handleChangeBrand(material?.brand, '');
  }, [material?.brand, liquidDropDown?.length, brandDropDown?.length]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      handleChangeSubBrand(material?.subBrand);
  }, [material?.subBrand, liquidDropDown?.length, brandDropDown?.length, subBrandDropDown?.length]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      containerReturnableList?.length > 0 &&
      handleChangeContainerReturnable(material?.containerReturnable);
  }, [
    material?.containerReturnable,
    liquidDropDown?.length,
    brandDropDown?.length,
    subBrandDropDown?.length,
    containerReturnableList?.length,
  ]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      containerReturnableList?.length > 0 &&
      containerTypeList?.length > 0 &&
      handleChangeContainerType(material?.containerType);
  }, [
    material?.containerType,
    liquidDropDown?.length,
    brandDropDown?.length,
    subBrandDropDown?.length,
    containerReturnableList?.length,
    containerTypeList?.length,
  ]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      containerReturnableList?.length > 0 &&
      containerTypeList?.length > 0 &&
      containerMaterialList?.length > 0 &&
      handleChangeContainerMaterial(material?.containerMaterial);
  }, [
    material?.containerMaterial,
    liquidDropDown?.length,
    brandDropDown?.length,
    subBrandDropDown?.length,
    containerReturnableList?.length,
    containerTypeList?.length,
    containerMaterialList?.length,
  ]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      containerReturnableList?.length > 0 &&
      containerTypeList?.length > 0 &&
      containerMaterialList?.length > 0 &&
      packSize2List?.length > 0 &&
      handleChangePackSize2(material?.packSize1);
  }, [
    material?.packSize1,
    liquidDropDown?.length,
    brandDropDown?.length,
    subBrandDropDown?.length,
    containerReturnableList?.length,
    containerTypeList?.length,
    containerMaterialList?.length,
    packSize2List?.length,
  ]);

  useEffect(() => {
    liquidDropDown?.length > 0 &&
      brandDropDown?.length > 0 &&
      subBrandDropDown?.length > 0 &&
      containerReturnableList?.length > 0 &&
      containerTypeList?.length > 0 &&
      containerMaterialList?.length > 0 &&
      packSize2List?.length > 0 &&
      handleChangePackSize1(material?.packSize2);
  }, [
    material?.packSize2,
    liquidDropDown?.length,
    brandDropDown?.length,
    subBrandDropDown?.length,
    containerReturnableList?.length,
    containerTypeList?.length,
    containerMaterialList?.length,
    packSize2List?.length,
  ]);

  return (
    <Modal
      radius={'lg'}
      size="85%"
      opened={showCloneMaterialModal}
      centered
      title={<Text weight={700} className="golden-text">{`Clone Material`}</Text>}
      onClose={() => {
        closeCloneMaterilaModal();
      }}
      withCloseButton={true}
      closeOnClickOutside={false}
      className="materialForm"
    >
      <Grid>
        <Grid.Col span={4}>
          <TextInput
            label="Material Type"
            placeholder="Material Type"
            radius={'lg'}
            required
            disabled
            value={'Z001'}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            label="Valuation Class"
            placeholder="Valuation Class"
            radius={'lg'}
            required
            disabled
            value={'Z081'}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            label={
              <Flex gap="xm" justify="right" align="center" required direction="row" wrap="wrap">
                {'Existing Item '}
                <Tooltip
                  multiline
                  width={220}
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Enter Yes if SKU exists and needs to be extended to other production plants, otherwise enter No."
                >
                  <ActionIcon size="sm" variant="transparent">
                    <InfoCircle size={14} color={'#fab005'} />
                  </ActionIcon>
                </Tooltip>
                <span class="text-danger"> *</span>
              </Flex>
            }
            placeholder="Existing Item"
            radius={'lg'}
            rightSection={<IconChevronDown size={14} />}
            rightSectionWidth={30}
            searchable
            styles={{ rightSection: { pointerEvents: 'none' } }}
            data={['Yes', 'No']}
            onChange={(e) => {
              handleResetExistingItem();
              setExistingItem(e);
              formik.setFieldValue('existingItem', e);
            }}
            value={formik.values.existingItem}
            error={formik.errors.existingItem}
          />
        </Grid.Col>
        {/* {formik.values.existingItem && formik.values.existingItem !== 'Yes' ? <></> : */}
        {existingItemNumberList != undefined && (
          <Grid.Col span={4}>
            {Array.isArray(existingItemNumberList) && (
              <Select
                label="Existing Item SKU Number"
                placeholder="Existing Item SKU Number"
                radius={'lg'}
                searchable
                onSearchChange={(e) => {
                  setExistingSkuSerachValue(e);
                  serchExistingSku(e);
                }}
                searchValue={existingSkuSerachValue}
                onChange={(e) => {
                  formik.setFieldValue('existingItemNumber', e);
                  handleSelectExistingSku(e);
                }}
                data={existingItemNumberList?.length > 0 ? existingItemNumberList : []}
                value={formik.values.existingItemNumber}
                error={formik.errors.existingItemNumber}
                disabled={formik.values.existingItem !== 'Yes'}
              />
            )}
          </Grid.Col>
        )}
        {/* //} */}
        {formik.values.existingItem !== 'Yes' && (
          <Grid.Col span={4}>
            {Array.isArray(fgTypeList) && (
              <Select
                label="FG Type"
                placeholder="FG TYPE"
                radius={'lg'}
                required
                searchable
                rightSection={<IconChevronDown size={14} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                data={fgTypeList?.length > 0 ? fgTypeList : []}
                onChange={(e) => {
                  handleResetFgType();
                  setFgType(e);
                  formik.setFieldValue('fgType', e);
                }}
                value={formik.values.fgType}
                error={formik.errors.fgType}
              />
            )}
          </Grid.Col>
        )}
        {formik.values.fgType !== 'Virtual' && formik.values.existingItem !== 'Yes' && (
          <Grid.Col span={4}>
            {Array.isArray(liquidDropDown) && (
              <Select
                label="Liquid Type"
                placeholder="Liquid Type"
                radius={'lg'}
                searchable
                required
                rightSection={<IconChevronDown size={14} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                data={liquidDropDown?.length > 0 ? liquidDropDown : []}
                creatable
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={(query) => {
                  setCreateNewValue(query);
                  setCreateNewValueType('Liquid');
                  setShowCreateNewValueModal(true);
                  const item = { value: query, label: query };
                  return item;
                }}
                onChange={(e) => {
                  formik.setFieldValue('liquid', e);
                  setBrandDropDown([]);

                  formik.setFieldValue('brand', '');
                  setBrand('');

                  formik.setFieldValue('subBrand', '');
                  setSubBrand('');

                  formik.setFieldValue('containerReturnable', '');
                  setContainerReturnable('');

                  formik.setFieldValue('containerType', '');
                  setContainerType('');

                  formik.setFieldValue('containerMaterial', '');
                  setContainerMaterial('');

                  formik.setFieldValue('packSize2', '');
                  setPackSize2('');

                  formik.setFieldValue('packSize1', '');
                  setPackSize1('');

                  formik.setFieldValue('containerVolume', '');
                  setContainerVolume('');

                  handleChangeLiquid(e, '');
                }}
                value={formik.values.liquid}
                error={formik.errors.liquid}
              />
            )}
          </Grid.Col>
        )}
        {formik.values.fgType !== 'Virtual' && formik.values.existingItem !== 'Yes' && (
          <>
            <Grid.Col span={4}>
              {Array.isArray(brandDropDown) && (
                <Select
                  label="Brand"
                  placeholder="Brand"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={brandDropDown?.length > 0 ? brandDropDown : []}
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  // onSearchChange={(e) => {
                  //   if(formik?.values?.liquid != ""){
                  //   handleChangeLiquid(  formik?.values?.liquid, e);
                  //   }
                  //   setBrandSearchValue(e)
                  // }}
                  // searchValue={brandSearchValue}
                  onCreate={(query) => {
                    setCreateNewValue(query);
                    setCreateNewValueType('Brand');
                    setShowCreateNewValueModal(true);
                    const item = { value: query, label: query };
                    return item;
                  }}
                  onChange={(e) => {
                    setBrand(e);
                    formik.setFieldValue('brand', e);

                    formik.setFieldValue('subBrand', '');
                    setSubBrand('');

                    formik.setFieldValue('containerReturnable', '');
                    setContainerReturnable('');

                    formik.setFieldValue('containerType', '');
                    setContainerType('');

                    formik.setFieldValue('containerMaterial', '');
                    setContainerMaterial('');

                    formik.setFieldValue('packSize2', '');
                    setPackSize2('');

                    formik.setFieldValue('packSize1', '');
                    setPackSize1('');

                    formik.setFieldValue('containerVolume', '');
                    setContainerVolume('');
                    handleChangeBrand(e, '');
                  }}
                  value={formik.values.brand}
                  error={formik.errors.brand}
                />
              )}

              <Text fz="xs">Please enter atleast 2 characters to search for Brand</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              {/* {subBrandDropDown && subBrandDropDown !== undefined && subBrandDropDown?.length > 0 && ( */}
              {Array.isArray(subBrandDropDown) && (
                <Select
                  label="Sub Brand"
                  placeholder="Sub Brand"
                  radius={'lg'}
                  required
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={subBrandDropDown?.length > 0 ? subBrandDropDown : []}
                  searchable
                  // onSearchChange={(e) => {
                  //   if(formik?.values?.liquid != "" && formik?.values?.brand != ""){
                  //   handleChangeBrand( formik?.values?.brand,  e);
                  //   }
                  //   setSubBrandSearchValue(e)
                  // }}
                  // searchValue={subBrandSearchValue}
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    // console.log(query)
                    setCreateNewValue(query);
                    setCreateNewValueType('Sub Brand');
                    setShowCreateNewValueModal(true);
                    const item = { value: query, label: query };
                    // setSubBrandDropDown((current) => [...current, item]);
                    return item;
                  }}
                  onChange={(e) => {
                    setSubBrand(e);
                    formik.setFieldValue('subBrand', e);
                    checkSKUDescription('subBrand', e);
                    handleChangeSubBrand(e);

                    formik.setFieldValue('containerReturnable', '');
                    setContainerReturnable('');

                    formik.setFieldValue('containerType', '');
                    setContainerType('');

                    formik.setFieldValue('containerMaterial', '');
                    setContainerMaterial('');

                    formik.setFieldValue('packSize2', '');
                    setPackSize2('');

                    formik.setFieldValue('packSize1', '');
                    setPackSize1('');

                    formik.setFieldValue('containerVolume', '');
                    setContainerVolume('');
                  }}
                  value={formik.values.subBrand}
                  error={formik.errors.subBrand}
                />
              )}

              {/* )} */}
            </Grid.Col>
            {formik.values.fgType !== 'Virtual' && formik.values.existingItem !== 'Yes' && (
              <Grid.Col span={4}>
                {Array.isArray(containerReturnableList) && (
                  <Select
                    label="Container Returnable"
                    placeholder="Container Returnable"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={containerReturnableList?.length > 0 ? containerReturnableList : []}
                    onChange={(e) => {
                      setContainerReturnable(e);
                      formik.setFieldValue('containerReturnable', e);

                      formik.setFieldValue('containerType', '');
                      setContainerType('');

                      formik.setFieldValue('containerMaterial', '');
                      setContainerMaterial('');

                      formik.setFieldValue('packSize2', '');
                      setPackSize2('');

                      formik.setFieldValue('packSize1', '');
                      setPackSize1('');

                      formik.setFieldValue('containerVolume', '');
                      setContainerVolume('');
                      handleChangeContainerReturnable(e);
                    }}
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      setCreateNewValue(query);
                      setCreateNewValueType('Container Returnable');
                      setShowCreateNewValueModal(true);
                      const item = { value: query, label: query };
                      return item;
                    }}
                    value={formik.values.containerReturnable}
                    error={formik.errors.containerReturnable}
                  />
                )}
              </Grid.Col>
            )}
            <Grid.Col span={4}>
              {Array.isArray(containerTypeList) && (
                <Select
                  label="Container Type"
                  placeholder="Container Type"
                  radius={'lg'}
                  required
                  searchable
                  creatable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={containerTypeList?.length > 0 ? containerTypeList : []}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    setCreateNewValue(query);
                    setCreateNewValueType('Container Type');
                    setShowCreateNewValueModal(true);
                    const item = { value: query, label: query };
                    return item;
                  }}
                  onChange={(e) => {
                    setContainerType(e);
                    formik.setFieldValue('containerMaterial', '');
                    setContainerMaterial('');

                    formik.setFieldValue('packSize2', '');
                    setPackSize2('');

                    formik.setFieldValue('packSize1', '');
                    setPackSize1('');

                    formik.setFieldValue('containerVolume', '');
                    setContainerVolume('');

                    formik.setFieldValue('containerType', e);
                    checkSKUDescription('containerType', e);
                    handleChangeContainerType(e);
                  }}
                  value={formik.values.containerType}
                  error={formik.errors.containerType}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              {Array.isArray(containerMaterialList) && (
                <Select
                  label="Container Material"
                  placeholder="Container Material"
                  radius={'lg'}
                  required
                  searchable
                  creatable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={containerMaterialList?.length > 0 ? containerMaterialList : []}
                  onChange={(e) => {
                    setContainerMaterial(e);
                    formik.setFieldValue('containerMaterial', e);

                    formik.setFieldValue('packSize2', '');
                    setPackSize2('');

                    formik.setFieldValue('packSize1', '');
                    setPackSize1('');

                    formik.setFieldValue('containerVolume', '');
                    setContainerVolume('');
                    handleChangeContainerMaterial(e);
                  }}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    setCreateNewValue(query);
                    setCreateNewValueType('Container Material');
                    setShowCreateNewValueModal(true);
                    const item = { value: query, label: query };
                    return item;
                  }}
                  value={formik.values.containerMaterial}
                  error={formik.errors.containerMaterial}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <Flex
                gap="xs"
                justify="center"
                // align="center"
                direction="row"
                wrap="nowrap"
              >
                {Array.isArray(packSize2TempList) && (
                  <Select
                    label="Pack Size"
                    placeholder="Pack Size 1"
                    radius={'lg'}
                    required
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={packSize2TempList?.length > 0 ? packSize2TempList : []}
                    searchable
                    onSearchChange={(e) => {
                      // console.log('Search PS 1', e);
                      if (
                        packSize2List &&
                        packSize2List?.length > 0 &&
                        packSize2List[0]?.label &&
                        packSize2List[0]?.value &&
                        e != undefined &&
                        e != null &&
                        e != '' &&
                        e?.length > 0
                      ) {
                        var pcksize2 = packSize2List.filter(function (list) {
                          return list.value.indexOf(e) != -1;
                        });

                        pcksize2 = pcksize2.sort(function (a, b) {
                          return a.value - b.value;
                        });

                        setPackSize2TempList(pcksize2);
                      }
                    }}
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setPackSize2List((current) => [...current, item]);
                      setPackSize2TempList((current) => [...current, item]);
                      return item;
                    }}
                    onChange={(e) => {
                      setPackSize2(e);
                      formik.setFieldValue('packSize2', e);
                      checkSKUDescription('packSize2', e);

                      formik.setFieldValue('packSize1', '');
                      setPackSize1('');

                      formik.setFieldValue('containerVolume', '');
                      setContainerVolume('');

                      handleChangePackSize2(e);
                    }}
                    value={formik.values.packSize2}
                    error={formik.errors.packSize2}
                  />
                )}

                <Text mt={'6vh'}>X</Text>
                {Array.isArray(packSize1TempList) && (
                  <Select
                    placeholder="Pack Size 2"
                    radius={'lg'}
                    label={<span class="text-danger"> *</span>}
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={packSize1TempList?.length > 0 ? packSize1TempList : []}
                    searchable
                    onSearchChange={(e) => {
                      if (
                        packSize1List &&
                        packSize1List?.length > 0 &&
                        packSize1List[0]?.label &&
                        packSize1List[0]?.value &&
                        e != undefined &&
                        e != null &&
                        e != '' &&
                        e?.length > 0
                      ) {
                        var pcksize1 = packSize1List.filter(function (list) {
                          return list.value.indexOf(e) != -1;
                        });

                        pcksize1 = pcksize1.sort(function (a, b) {
                          return a.value - b.value;
                        });

                        setPackSize1TempList(pcksize1);
                      }
                    }}
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setPackSize1List((current) => [...current, item]);
                      setPackSize1TempList((current) => [...current, item]);
                      return item;
                    }}
                    onChange={(e) => {
                      setPackSize1(e);
                      formik.setFieldValue('packSize1', e);
                      checkSKUDescription('packSize1', e);

                      formik.setFieldValue('containerVolume', '');
                      setContainerVolume('');
                      handleChangePackSize1(e);
                    }}
                    value={formik.values.packSize1}
                    error={formik.errors.packSize1}
                  />
                )}
              </Flex>
            </Grid.Col>
            <Grid.Col span={4}>
              <Flex
                gap="xs"
                justify="center"
                // align="center"
                direction="row"
                wrap="nowrap"
              >
                <TextInput
                  radius={'lg'}
                  required
                  type="number"
                  placeholder="Palletization 1"
                  label="Palletization"
                  onChange={(e) => {
                    formik.setFieldValue('palletization1', e.target.value);
                    checkSKUDescription('palletization1', e.target.value);
                  }}
                  value={formik.values.palletization1}
                  error={formik.errors.palletization1}
                />
                <Text mt={'6vh'}>X</Text>
                <TextInput
                  radius={'lg'}
                  required
                  type="number"
                  placeholder="Palletization 2"
                  label=" "
                  onChange={(e) => {
                    formik.setFieldValue('palletization2', e.target.value);
                    checkSKUDescription('palletization2', e.target.value);
                  }}
                  value={formik.values.palletization2}
                  error={formik.errors.palletization2}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={4}>
              {/* <Select
              label= "Container Volume"
              placeholder="Container Volume"
              radius={'lg'}
              required
              rightSection={<IconChevronDown size={14} />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              data={containerVolumeList}
              searchable
              creatable
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => {
                const item = { value: query, label: query };
                setContainerVolumeList((current) => [...current, item]);
                return item;
              }}
              onChange={(e) => {
                formik.setFieldValue('containerVolume', e);
                checkSKUDescription('containerVolume', e)
              }}
              value={formik.values.containerVolume}
              error={formik.errors.containerVolume}
            /> */}
              {Array.isArray(containerVolumeList) && (
                <Select
                  label="Container Volume"
                  placeholder="Container Volume"
                  radius={'lg'}
                  required
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={containerVolumeList?.length > 0 ? containerVolumeList : []}
                  searchable
                  creatable
                  getCreateLabel={(query) => {
                    var itemfinal = query.replace(/[^0-9.]/g, '');
                    return `+ Create ${itemfinal}`;
                  }}
                  onCreate={(query) => {
                    setCreateNewValue(query);
                    setCreateNewValueType('Container Volume');
                    setShowCreateNewValueModal(true);
                    const item = { value: query, label: query };
                    return item;
                  }}
                  onChange={(e) => {
                    setContainerVolume(e);
                    formik.setFieldValue('containerVolume', e);
                    checkSKUDescription('containerVolume', e);
                  }}
                  value={formik.values.containerVolume}
                  error={formik.errors.containerVolume}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              {Array.isArray(liquidClassDropDown) && (
                <Select
                  label="Liquid Class"
                  placeholder="Liquid Class"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={liquidClassDropDown?.length > 0 ? liquidClassDropDown : []}
                  onChange={(e) => {
                    formik.setFieldValue('liquidClass', e);
                  }}
                  value={formik.values.liquidClass}
                  error={formik.errors.liquidClass}
                />
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              {Array.isArray(suffixList) && (
                <Select
                  label="Suffix/ Other Comments"
                  placeholder="Suffix/ Other Comments"
                  radius={'lg'}
                  required
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={suffixList?.length > 0 ? suffixList : []}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setSuffixList((current) => [...current, item]);
                    return item;
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('suffix', e);
                    checkSKUDescription('suffix', e);
                  }}
                  value={formik.values.suffix}
                  error={formik.errors.suffix}
                />
              )}

              {/* <Select

            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setSuffixList((current) => [...current, item]);
              return item;
            }}
              label={
                <Flex
                  gap="xm"
                  justify="right"
                  align="center"
                  direction="row"
                  wrap="nowrap"
                >
                  {'Suffix/ Other Comments '}
                  <Tooltip
                    multiline
                    width={220}
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                  >
                    <ActionIcon size="sm" variant="transparent">
                      <InfoCircle size={14} color={'#fab005'} />
                      <AsteriskSimple size={6} strokeWidth={3} color={'#fa5252'} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              }
              radius={'lg'}
              placeholder={'Suffix/ Other Comments'}
              data={suffixList}
              onChange={(e) => {
                formik.setFieldValue('suffix', e);
                checkSKUDescription('suffix', e)
              }}
              value={formik.values.suffix}
              error={formik.errors.suffix}
            /> */}
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                label="Alcohol %"
                required
                radius={'lg'}
                placeholder={'Alcohol %'}
                onChange={(e) => {
                  formik.setFieldValue('alcohol', e.target.value);
                }}
                value={formik.values.alcohol}
                error={formik.errors.alcohol}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                label="Gross Weight"
                radius={'lg'}
                placeholder={'Gross Weight'}
                onChange={(e) => {
                  formik.setFieldValue('grossWeight', e.target.value);
                }}
                value={formik.values.grossWeight}
                // error={formik.errors.grossWeight}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              {Array.isArray(languageList) > 0 && (
                <Select
                  label="Language"
                  placeholder="Language"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={languageList?.length > 0 ? languageList : []}
                  onChange={(e) => {
                    formik.setFieldValue('language', e);
                    checkSKUDescription('language', e);
                  }}
                  value={formik?.values?.language}
                  error={formik?.errors?.language}
                />
              )}
            </Grid.Col>
          </>
        )}
        {formik?.values?.fgType !== 'Import' ? (
          <></>
        ) : (
          <Grid.Col span={4}>
            {Array.isArray(importedFromDropDown) && (
              <Select
                label="Imported From"
                placeholder="Imported From"
                radius={'lg'}
                searchable
                required={formik.values.fgType === 'Import'}
                rightSection={<IconChevronDown size={14} />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                data={importedFromDropDown?.length > 0 ? importedFromDropDown : []}
                onChange={(e) => {
                  formik.setFieldValue('importedFrom', e);
                }}
                value={formik.values.importedFrom}
                error={formik.errors.importedFrom}
                // disabled={formik.values.fgType !== 'Import'}
              />
            )}
          </Grid.Col>
        )}
        {formik.values.existingItem === 'Yes' || formik.values.fgType !== 'Virtual' ? (
          <></>
        ) : (
          <Grid.Col span={4}>
            {Array.isArray(existingItemNumberList) && (
              <Select
                label="Real Item SKU ID"
                required={formik.values.fgType !== 'Import'}
                radius={'lg'}
                placeholder={'Real Item SKU ID'}
                value={formik.values.realItem}
                error={formik.errors.realItem}
                searchable
                onSearchChange={(e) => {
                  handleSelectRealItemSku(e);
                  setRealItemSearchValue(e);
                }}
                searchValue={realItemSearchValue}
                onChange={(e) => {
                  formik.setFieldValue('realItem', e);
                  handleSelectRealItemSkuDesc(e);
                }}
                data={existingItemNumberList?.length > 0 ? existingItemNumberList : []}
              />
            )}
          </Grid.Col>
        )}
        {formik.values.existingItem === 'Yes' || formik.values.fgType !== 'Virtual' ? (
          <></>
        ) : (
          <Grid.Col span={4}>
            <TextInput
              label="Virtual SKU Description"
              name="name"
              key="name"
              required={formik.values.fgType !== 'Import'}
              radius={'lg'}
              placeholder={'Virtual SKU Description'}
              onChange={(e) => {
                formik.setFieldValue('skuDescription', e.target.value.toUpperCase());
              }}
              value={formik.values.virtualSku}
              error={formik.errors.virtualSku}
              // disabled={formik.values.fgType === 'Import'}
            />
          </Grid.Col>
        )}
        {formik.values.existingItem !== 'Yes' && (
          <Grid.Col span={4}>
            <TextInput
              label="UPC Codes"
              name="name"
              key="name"
              radius={'lg'}
              placeholder={'UPC Codes'}
              onChange={(e) => {
                formik.setFieldValue('upcTbsCodes', e.target.value);
              }}
              value={formik.values.upcTbsCodes}
            />
          </Grid.Col>
        )}
        {formik.values.fgType !== 'Virtual' && formik.values.existingItem !== 'Yes' && (
          <Grid.Col span={4}>
            <Select
              label="Deco/Non Deco"
              placeholder="Deco/Non Deco"
              radius={'lg'}
              required
              searchable
              rightSection={<IconChevronDown size={14} />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              data={['NonDeco', 'Deco']}
              onChange={(e) => {
                formik.setFieldValue('decoType', e);
                checkSKUDescription('decoType', e);
              }}
              value={formik.values.decoType}
              error={formik.errors.decoType}
            />
          </Grid.Col>
        )}
        {formik.values.fgType !== 'Virtual' && formik.values.existingItem !== 'Yes' && (
          <>
            <Grid.Col span={4}>
              {Array.isArray(tasteSegmentDropDown) && (
                <Select
                  label="Taste Segment"
                  placeholder="Taste Segment"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={tasteSegmentDropDown?.length > 0 ? tasteSegmentDropDown : []}
                  onChange={(e) => {
                    formik.setFieldValue('tasteSegment', e);
                  }}
                  value={formik.values.tasteSegment}
                  error={formik.errors.tasteSegment}
                />
              )}
            </Grid.Col>
            <Grid.Col span={8}>
              <Flex
                gap="sm"
                // justify="center"
                align="flex-end"
                direction="row"
                wrap="nowrap"
              >
                <TextInput
                  radius={'lg'}
                  type="number"
                  placeholder="Length"
                  label="Dimension"
                  onChange={(e) => {
                    formik.setFieldValue('length', e.target.value);
                  }}
                  value={formik.values.length}
                  // error={formik.errors.length}
                />
                <Text mb={'1vh'}>X</Text>
                <TextInput
                  radius={'lg'}
                  type="number"
                  placeholder="Width"
                  label={' '}
                  onChange={(e) => {
                    formik.setFieldValue('width', e.target.value);
                  }}
                  value={formik.values.width}
                  // error={formik.errors.width}
                />
                <Text mb={'1vh'}>X</Text>
                <TextInput
                  radius={'lg'}
                  type="number"
                  placeholder="Height"
                  label={' '}
                  onChange={(e) => {
                    formik.setFieldValue('height', e.target.value);
                  }}
                  value={formik.values.height}
                  // error={formik.errors.height}
                />
              </Flex>
            </Grid.Col>

            <Grid.Col span={4}>
              {Array.isArray(priceClassDropDown) && (
                <Select
                  label="Price Class"
                  placeholder="Price Class"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={priceClassDropDown?.length > 0 ? priceClassDropDown : []}
                  onChange={(e) => {
                    formik.setFieldValue('priceClass', e);
                  }}
                  value={formik.values.priceClass}
                  error={formik.errors.priceClass}
                />
              )}
            </Grid.Col>
          </>
        )}
        {formik.values.fgType !== 'Virtual' && (
          <>
            <Grid.Col span={6}>
              <TextInput
                label="SKU Description"
                name="name"
                key="name"
                radius={'lg'}
                placeholder={'SKU Description'}
                onChange={(e) => {
                  formik.setFieldValue('skuDescription', e.target.value.toUpperCase());
                }}
                value={formik.values.skuDescription}
                // error={formik.errors.skuDescription}
                disabled={
                  !(
                    (formik.values.subBrand &&
                      formik.values.packSize1 &&
                      formik.values.packSize2 &&
                      formik.values.containerVolume &&
                      formik.values.containerType &&
                      formik.values.decoType &&
                      formik.values.language &&
                      formik.values.palletization1 &&
                      formik.values.palletization2 &&
                      formik.values.suffix) ||
                    formik.existingItem == 'Yes'
                  )
                }
              />
            </Grid.Col>
          </>
        )}
      </Grid>
      <Space h="xl" />
      {material?.plants?.some((plant) => plant?.plant === 'Import') ? null : (
        <Group position="right">
          <Button
            color="yellow"
            radius="xl"
            leftIcon={<IconCheck size={24} color="white" />}
            onClick={() => {
              setPlants(
                plants.concat([
                  {
                    index: plants[plants.length - 1].index + 1,
                    plant: '',
                    selling_region: '',
                    production_date: null,
                    str_date: null,
                    stop_ship_date: '',
                  },
                ]),
              );
              formik.setFieldValue(
                'plants',
                plants.concat([
                  {
                    index: plants[plants.length - 1].index + 1,
                    plant: '',
                    selling_region: '',
                    production_date: null,
                    str_date: null,
                    stop_ship_date: '',
                  },
                ]),
              );
            }}
            mr="lg"
          >{`Add Line`}</Button>
        </Group>
      )}
      <Space h="xl" />
      <Group grow>
        <table className="addMaterial-table">
          <thead>
            <tr>
              <th>
                <Input.Label required>Production Plant</Input.Label>
              </th>
              <Space w="sm" />
              <th>
                <Input.Label required>Selling Region</Input.Label>
              </th>
              <Space w="sm" />
              <th>
                <Input.Label required>Production Date</Input.Label>
              </th>
              <Space w="sm" />
              <th>
                <Input.Label required>STR Date</Input.Label>
              </th>
              <Space w="sm" />
              <th>
                <Input.Label>Stop Ship Date</Input.Label>
              </th>
              <Space w="sm" />
              <th style={{ width: '8%' }}>
                <Input.Label>Action</Input.Label>
              </th>
            </tr>
          </thead>
          <tbody>
            {plants?.map((plant, index) => (
              <tr key={index}>
                {material?.plants?.some((plant) => plant?.plant === 'Import') ? (
                  <td>
                    <TextInput radius={'lg'} placeholder={'Import'} disabled />
                  </td>
                ) : (
                  <>
                    <td>
                      {plantDropDown &&
                        plantDropDown !== undefined &&
                        plantDropDown?.length > 0 && (
                          <Select
                            radius={'lg'}
                            data={plantDropDown?.length > 0 ? plantDropDown : []}
                            placeholder={'Plant'}
                            searchable
                            required
                            value={plants[index]?.plant}
                            onChange={(value) => {
                              let tempPlants = plants?.map((currPlant) => {
                                if (currPlant.index == plant.index) {
                                  currPlant.plant = value;
                                }
                                return currPlant;
                              });
                              setPlants(tempPlants);
                              formik.setFieldValue('plants', tempPlants);
                            }}
                            error={
                              formik.errors?.plants
                                ? formik.errors?.plants[index]?.plant
                                  ? formik.errors?.plants[index]?.plant
                                  : ''
                                : ''
                            }
                          />
                        )}
                    </td>
                  </>
                )}
                <Space w="sm" />
                <td>
                  {plantDropDown && plantDropDown !== undefined && plantDropDown?.length > 0 && (
                    <MultiSelect
                      radius={'lg'}
                      data={regionDropDown}
                      placeholder={'Selling Region'}
                      searchable
                      required
                      value={plants[index]?.selling_region?.split(',')}
                      onChange={(value) => {
                        let tempPlants = plants?.map((currPlant) => {
                          if (currPlant.index == plant.index) {
                            currPlant.selling_region = value.join(',');
                          }
                          return currPlant;
                        });
                        setPlants(tempPlants);
                        formik.setFieldValue('plants', tempPlants);
                      }}
                      error={
                        formik.errors?.plants
                          ? formik.errors?.plants[index]?.selling_region
                            ? formik.errors?.plants[index]?.selling_region
                            : ''
                          : ''
                      }
                    />
                  )}
                </td>
                <Space w="sm" />
                <td align="center">
                  <DatePickerInput
                    inputFormat="MM/DD/YYYY"
                    placeholder="Production Date"
                    radius="xl"
                    withAsterisk
                    value={
                      plants[index]?.production_date
                        ? new Date(plants[index]?.production_date)
                        : null
                    }
                    onChange={(value) => {
                      let tempPlants = plants?.map((currPlant) => {
                        if (currPlant.index == plant.index) {
                          currPlant.production_date = value;
                          currPlant.str_date = null;
                        }
                        return currPlant;
                      });
                      setPlants(tempPlants);
                      formik.setFieldValue('plants', tempPlants);
                    }}
                    error={
                      formik.errors?.plants
                        ? formik.errors?.plants[index]?.production_date
                          ? 'Production Date is required'
                          : ''
                        : ''
                    }
                    minDate={dayjs(new Date()).toDate()}
                  />
                </td>
                <Space w="sm" />
                <td align="center">
                  <DatePickerInput
                    inputFormat="MM/DD/YYYY"
                    placeholder="STR Date"
                    radius="xl"
                    withAsterisk
                    minDate={dayjs(plants[index]?.production_date).add(1, 'days').toDate()}
                    value={plants[index]?.str_date ? new Date(plants[index]?.str_date) : null}
                    clearable={true}
                    disabled={!plants[index]?.production_date}
                    onChange={(value) => {
                      let tempPlants = plants?.map((currPlant) => {
                        if (currPlant.index == plant.index) {
                          currPlant.str_date = value;
                        }
                        return currPlant;
                      });
                      setPlants(tempPlants);
                      formik.setFieldValue('plants', tempPlants);
                    }}
                    error={
                      formik.errors?.plants
                        ? formik.errors?.plants[index]?.str_date
                          ? 'STR Date is required'
                          : ''
                        : ''
                    }
                  />
                </td>
                <Space w="sm" />
                <td align="center">
                  <Select
                    placeholder="Stop Ship Date"
                    radius="xl"
                    value={plants[index]?.stop_ship_date ? plants[index]?.stop_ship_date : ''}
                    searchable
                    data={stopShipDateDropDown?.length > 0 ? stopShipDateDropDown : []}
                    onChange={(value) => {
                      let tempPlants = plants?.map((currPlant) => {
                        if (currPlant.index == plant.index) {
                          currPlant.stop_ship_date = value;
                        }
                        return currPlant;
                      });
                      setPlants(tempPlants);
                      formik.setFieldValue('plants', tempPlants);
                    }}
                    error={
                      formik.errors?.plants
                        ? formik.errors?.plants[index]?.stop_ship_date
                          ? formik.errors?.plants[index]?.stop_ship_date
                          : ''
                        : ''
                    }
                  />
                </td>
                <Space w="sm" />
                <td align="center">
                  <ActionIcon variant="light" color={'yellow'} radius="xl">
                    <IconTrash
                      size={24}
                      color="black"
                      onClick={() => {
                        if (plants.length > 1) {
                          setPlants(
                            plants.filter((filterPlant) => filterPlant.index !== plant.index),
                          );
                          formik.setFieldValue(
                            'plants',
                            formik.values.plants.filter(
                              (filterPlant) => filterPlant.index !== plant.index,
                            ),
                          );
                        }
                      }}
                    />
                  </ActionIcon>
                </td>
                <Space w="sm" />
              </tr>
            ))}
          </tbody>
        </table>
      </Group>
      <Space h="xl" />
      <Card.Footer>
        <Group position="right">
          <Button
            color="gray"
            radius="xl"
            onClick={() => {
              // closeAddMaterilaModal();
              handleSaveDraftMaterial(formik.values);
              closeCloneMaterilaModal();
            }}
          >{`Save Draft`}</Button>
          <Button
            color="yellow"
            radius="xl"
            leftIcon={<IconCheck size={24} color="white" />}
            className="btn btn-primary"
            style={{ borderRadius: '40px' }}
            onClick={() => {
              valForm();
              formik.handleSubmit();
            }}
          >{`Submit`}</Button>
        </Group>
      </Card.Footer>
      <SkuDescription
        showSkuDescriptionModal={showSkuDescriptionModal}
        closeSkuDescriptionModal={() => setShowSkuDescriptionModal(false)}
        materialObject={materialObject}
        handleMaterial={() => {
          addMaterial(materialObject);
          handleReset();
        }}
        closeMaterialModal={closeCloneMaterilaModal}
      />
      <Modal
        radius={'lg'}
        size="lg"
        opened={showCreateNewValueModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            Create new {createNewValueType}
          </Text>
        }
        // onClose={onClose}
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        <>
          <Row>
            <Col xs={12}>
              <TextInput
                radius={'lg'}
                required
                type="text"
                placeholder={createNewValueType}
                label={createNewValueType}
                onChange={(e) => {
                  setCreateNewValue(e.target.value);
                }}
                value={createNewValue}
              />
            </Col>
          </Row>
          <Space h="xl" />
          <Group position="right">
            <Button
              color="gray"
              radius="xl"
              leftIcon={<IconX size={16} color="white" />}
              onClick={(e) => {
                handleCloseCreateNewValueModal();
              }}
            >{`Cancel`}</Button>
            {createNewValueType != '' && createNewValue != '' && createNewValue.length > 1 && (
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={(e) => {
                  handleCreateNewValue(createNewValueType, createNewValue);
                  handleCloseCreateNewValueModal();
                }}
              >{`Add`}</Button>
            )}
          </Group>
        </>
      </Modal>
    </Modal>
  );
}
export default CloneMaterial;
