/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Group, Space, Text, Table, Select, Input, TextInput, Button } from '@mantine/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FileUploader } from '../../../../components/FileUploader';
import materialService from '../../../../services/materialService';
import plantService from '../../../../services/plantService';
import projectService from '../../../../services/projectService';
import CustomCardLoader from '../../../../components/CustomCardLoader';
import DisabledTextInput from '../../../../components/DisabledTextInput/DisabledTextInput';
import CostingSkuSelect from '../../../../components/CostingSkuSelect/CostingSkuSelect';
import { IconCheck, IconDeviceFloppy, IconTrash, IconX } from '@tabler/icons';
import bomService from '../../../../services/bomService';
import costService from '../../../../services/costService';
import { toast } from 'react-toastify';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import { FilesTable } from '../../../../components/FilesTable';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmationBox';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';
import mailService from '../../../../services/mailService';

function RmCosting({ setPlantStatus }) {
  const [userState] = useContext(ActiveUserContext);

  // PARAMS
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // STATE
  const [materialData, setMaterialData] = useState({});
  const [plantData, setPlantData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [selectedPlant, setSelectedPlant] = useState();
  const [plantList, setPlantList] = useState([]);
  const [sendBackDialog, setSendBackDialog] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [costingTableData, setCostingTableData] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [existingItemFgType, setExistingItemFgType] = useState('');
  const [plantDataLoading, setPlantDataLoading] = useState(false);
  const [emailReceivers, setEmailReceivers] = useState([]);

  // FORMIK
  const formik = useFormik({
    initialValues: {
      sendNotificationTo: '',
    },
    validationSchema: Yup.object({
      sendNotificationTo: Yup.string().required('Required').nullable(),
    }),
    onSubmit: () => {
      setSubmitDialog(true);
    },
  });

  // FUNCTIONS
  const fetchTableData = (id) => {
    costService
      .getRmCostingTableData({
        plant_id: id,
      })
      .then((res) => {
        //console.log('TABLE DATA', res);
        setCostingTableData(res?.data);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  const getFilesData = () => {
    costService
      .getCostingFilesByPlant(projectData.id, materialData.id, selectedPlant)
      .then((res) => {
        if (res) {
          //console.log('Attachments:', res);
          setFilesData(res);
        }
      });
  };

  const deleteRmCostingTableItem = (id) => {
    costService
      .deleteRMcostingTableData({
        id: id,
      })
      .then((res) => {
        //console.log('Deleted', res);
        fetchTableData(selectedPlant);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  const updateRmCostingTableItem = (body) => {
    costService
      .updateRMCostingTableData({
        comment: body.comment,
        cost_by_100: body.cost_by_100,
        generic_sku_cost: body.generic_sku_cost,
        id: body.id,
        sku_description: body.sku_description,
        sku_id: body.sku_id,
        stage: body.stage,
      })
      .then((res) => {
        //console.log('Updated', res);
        fetchTableData(selectedPlant);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  // CONSTANTS
  const IS_EXISTING = materialData?.existing_item === 'Yes';

  const IS_DOMESTIC_COPACK_REPACK =
    materialData?.existing_item === 'No' &&
    (materialData?.fg_type === 'Copack' ||
      materialData?.fg_type === 'Domestic' ||
      materialData?.fg_type === 'Repack');

  const IS_VIRTUAL = materialData?.existing_item === 'No' && materialData?.fg_type === 'Virtual';

  const IS_IMPORT = materialData?.existing_item === 'No' && materialData?.fg_type === 'Import';

  // EFFECTS
  useEffect(() => {
    if (params?.plant_id) {
      setSelectedPlant(params?.plant_id);
      fetchTableData(params?.plant_id);
    }
  }, [params?.plant_id]);

  useEffect(() => {
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  useEffect(() => {
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          //console.log('Material Data:', res?.data?.material);
          setMaterialData(res?.data?.material);
          setExistingItemFgType(res?.data?.material?.existing_item_fg_type);
          projectService.getProjectById(res?.data?.material?.project_id).then((res) => {
            if (res?.data) {
              //console.log('Project Data:', res?.data);
              setProjectData(res?.data?.project);
            }
          });
        }
      });
    }

    if (params?.id) {
      materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
        if (res?.data) {
          //console.log('PLANT LENGTH', res?.data);
          setPlantList(res?.data?.plants);
          if (!params?.plant_id) {
            setSelectedPlant(res?.data?.plants[0]?.id);
            fetchTableData(res?.data?.plants[0]?.id);
          }
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    setPlantDataLoading(true);
    if (selectedPlant) {
      //console.log('Plant Data:', selectedPlant);
      plantService.getPlantById(selectedPlant).then((res) => {
        //console.log();
        if (res?.data) {
          //console.log('PLANT', res?.data?.data?.status);
          setPlantData(res?.data?.data);
          setPlantStatus(res?.data?.data?.status);
          // setSendNotificationTo(res?.data?.data?.send_sku_notification_to);
          formik.setFieldValue('sendNotificationTo', res?.data?.data?.send_sku_notification_to);
          setPlantDataLoading(false);
        }
      });
    }
  }, [selectedPlant]);

  useEffect(() => {
    //console.log('IDs', selectedPlant, projectData.id, materialData.id);
    if (selectedPlant?.length > 0 && projectData?.id?.length > 0 && materialData?.id?.length > 0)
      getFilesData();
  }, [selectedPlant, projectData.id, materialData.id]);

  useEffect(() => {
    mailService.skuCostingApprovalMail().then((res) => {
      setEmailReceivers(
        res?.data.data?.map((item) => ({
          label: item?.name,
          value: item?.email,
        })),
      );
    });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} sm={2}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              >{`Plant List`}</Text>
              <Space h="sm" />
            </Card.Body>

            {plantList?.length > 0 ? (
              <Card.Body>
                <Group position="center">
                  {plantList?.length > 0 ? (
                    <div className="table-responsive-sm">
                      <Table striped highlightOnHover withBorder>
                        <tbody>
                          {plantList?.map((plant, index) => (
                            <tr
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedPlant(plant?.id);
                                fetchTableData(plant?.id);
                                navigate(`/material/${params?.id}/${plant?.id}`);
                              }}
                            >
                              <td
                                className={
                                  plant?.id === selectedPlant ? 'table-row-selected' : 'table-color'
                                }
                              >
                                {plant?.plant}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <CustomCardLoader />
                  )}
                </Group>
              </Card.Body>
            ) : (
              <CustomCardLoader />
            )}
          </Card>
        </Col>

        <Col xs={12} sm={10}>
          {!plantList?.length > 0 ? (
            <CustomCardLoader />
          ) : (
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {'SKU Costing'}
                  </Text>
                </Group>
              </Card.Body>

              {plantDataLoading ? (
                <CustomCardLoader />
              ) : (
                <>
                  <Row>
                    {IS_EXISTING && (
                      <RenderExisting
                        costingTableData={costingTableData}
                        setCostingTableData={setCostingTableData}
                        materialData={materialData}
                        plantData={plantData}
                        refreshTable={() => fetchTableData(selectedPlant)}
                        setSendBack={() => setSendBackDialog(true)}
                        setSubmit={() => setSubmitDialog(true)}
                        existingItemFgType={existingItemFgType}
                        setExistingItemFgType={setExistingItemFgType}
                        deleteRmCostingTableItem={deleteRmCostingTableItem}
                        updateRmCostingTableItem={updateRmCostingTableItem}
                        // enable={plantData?.p_bom_status == 6}
                      />
                    )}

                    {IS_DOMESTIC_COPACK_REPACK && (
                      <RenderDomestic
                        costingTableData={costingTableData}
                        setCostingTableData={setCostingTableData}
                        materialData={materialData}
                        plantData={plantData}
                        refreshTable={() => fetchTableData(selectedPlant)}
                        setSendBack={() => setSendBackDialog(true)}
                        setSubmit={() => setSubmitDialog(true)}
                        deleteRmCostingTableItem={deleteRmCostingTableItem}
                        updateRmCostingTableItem={updateRmCostingTableItem}

                        // enable={plantData?.p_bom_status == 6}
                      />
                    )}

                    {IS_VIRTUAL && (
                      <RenderVirtual
                        costingTableData={costingTableData}
                        setCostingTableData={setCostingTableData}
                        materialData={materialData}
                        plantData={plantData}
                        refreshTable={() => fetchTableData(selectedPlant)}
                        setSendBack={() => setSendBackDialog(true)}
                        setSubmit={() => setSubmitDialog(true)}
                        deleteRmCostingTableItem={deleteRmCostingTableItem}
                        updateRmCostingTableItem={updateRmCostingTableItem}

                        // enable={plantData?.p_bom_status == 6}
                      />
                    )}

                    {IS_IMPORT && (
                      <RenderRest
                        costingTableData={costingTableData}
                        setCostingTableData={setCostingTableData}
                        materialData={materialData}
                        plantData={plantData}
                        refreshTable={() => fetchTableData(selectedPlant)}
                        setSendBack={() => setSendBackDialog(true)}
                        setSubmit={() => setSubmitDialog(true)}
                        deleteRmCostingTableItem={deleteRmCostingTableItem}
                        updateRmCostingTableItem={updateRmCostingTableItem}

                        // enable={plantData?.p_bom_status == 6}
                      />
                    )}
                  </Row>
                  {filesData?.length > 0 && (
                    <>
                      <Container>
                        <Row>
                          <div>
                            <h5>
                              <Text align="left">Attachments</Text>
                            </h5>
                          </div>
                        </Row>
                      </Container>
                      <Row>
                        <FilesTable
                          project_id={projectData.id}
                          material_id={materialData.id}
                          plant_id={selectedPlant}
                          files_data={filesData}
                          refreshTable={() => {
                            getFilesData();
                          }}
                        />
                      </Row>
                    </>
                  )}
                  {check_roles(userState, ['admin', 'costing']) && (
                    <>
                      <Container>
                        <Row>
                          <div>
                            <Text align="left">
                              Max file size per file is 7 MB. Only limited file types such as
                              documents, images & emails can be attached.
                            </Text>
                          </div>
                        </Row>
                      </Container>
                      {/* {plantData?.p_bom_status == 6 && ( */}
                      {materialData?.material_status == 4 && plantData?.status == 9 && (
                        <Grid
                          style={{
                            margin: '1rem 0',
                          }}
                        >
                          <Grid.Col span={3}>
                            <FileUploader
                              project_id={projectData.id}
                              material_id={materialData.id}
                              plant_id={selectedPlant}
                              refreshTable={() => {
                                getFilesData();
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Select
                              radius={'lg'}
                              placeholder="Send notifications to"
                              value={formik.values.sendNotificationTo}
                              onChange={(e) => formik.setFieldValue('sendNotificationTo', e)}
                              // data={[
                              //   { value: 'Dalbeer Dhaliwal', label: 'Dalbeer Dhaliwal' },
                              //   { value: 'Liam Ellsworth', label: 'Liam Ellsworth' },
                              //   { value: 'Jaime Giannopoulos', label: 'Jaime Giannopoulos' },
                              //   { value: 'Samantha Ramsarup', label: 'Samantha Ramsarup' },
                              //   { value: 'Sherry LI', label: 'Sherry LI' },
                              //   { value: 'Lilian Richards', label: 'Lilian Richards' },
                              //   { value: 'Gabriel Cossette', label: 'Gabriel Cossette' },
                              //   { value: 'Brijesh Rayachoti', label: 'Brijesh Rayachoti' },
                              //   { value: 'Jessica Wallington', label: 'Jessica Wallington' },
                              //   { value: 'Thomas Bray', label: 'Thomas Bray' },
                              // ]}
                              data={emailReceivers}
                              error={
                                costingTableData?.length > 0 && formik.errors.sendNotificationTo
                              }
                            />
                          </Grid.Col>

                          <Grid.Col span={3}>
                            <Button
                              color="gray"
                              radius="xl"
                              leftIcon={<IconX size={16} color="white" />}
                              onClick={() => setSendBackDialog(true)}
                            >
                              Send Back TO MDM
                            </Button>
                          </Grid.Col>

                          <Grid.Col span={3}>
                            <Button
                              color="yellow"
                              radius="xl"
                              className="btn btn-primary"
                              style={{ borderRadius: '40px' }}
                              leftIcon={<IconCheck size={16} color="white" />}
                              onClick={() => {
                                if (costingTableData?.length === 0) {
                                  toast.error('Please add atleast one row in costing table');
                                } else {
                                  formik.handleSubmit();
                                }
                              }}
                            >
                              Submit For Approvals
                            </Button>
                          </Grid.Col>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}

              {/* )} */}
              {/* )} */}
            </Card>
          )}
        </Col>
      </Row>

      <RejectMaterial
        closeRejectMaterialModal={() => setSendBackDialog(false)}
        showRejectMaterialModal={sendBackDialog}
        message={'Provide comment for sending back to MDM.'}
        rejectMaterial={(comment) => {
          costService
            .sendBackToMdmTeam({
              plant_id: selectedPlant,
              comment: comment,
              material_id: params?.id,
            })
            .then((res) => {
              res?.status && toast.success(res?.message);
              navigate('/');
            })
            .catch((err) => {
              toast.error(err || 'Something went wrong');
              navigate('/');
            });
        }}
      />

      <RejectMaterial
        closeRejectMaterialModal={() => setSubmitDialog(false)}
        showRejectMaterialModal={submitDialog}
        message={'Add Comments.'}
        rejectMaterial={(comment) => {
          costService
            .submitForApproval({
              plant_id: selectedPlant,
              comment: comment,
              material_id: params?.id,
              existing_item_fg_type: existingItemFgType,
              sendNotificationTo: formik.values.sendNotificationTo,
            })
            .then((res) => {
              res?.status && toast.success(res?.message);
              navigate('/');
            })
            .catch((err) => {
              toast.error(err || 'Something went wrong');
              navigate('/');
            });
        }}
      />
    </>
  );
}

export default RmCosting;

function RenderExisting({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  existingItemFgType,
  setExistingItemFgType,
  enable = true,
  deleteRmCostingTableItem,
  updateRmCostingTableItem,
}) {
  let params = useParams();
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const [stageList, setStageList] = useState([]);
  const [value, setValue] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [temp, setTemp] = useState();

  useEffect(() => {
    if (
      existingItemFgType === 'Domestic' ||
      existingItemFgType === 'Copack' ||
      existingItemFgType === 'Repack'
    ) {
      setStageList([
        {
          value: 'FG',
          label: 'FG',
        },
        {
          value: 'Brand',
          label: 'Brand',
        },
        {
          value: 'Age',
          label: 'Age',
        },
        {
          value: 'Ferment',
          label: 'Ferment',
        },
        {
          value: 'Wort',
          label: 'Wort',
        },
        {
          value: 'Phantom',
          label: 'Phantom',
        },
        {
          value: 'Blend',
          label: 'Blend',
        },
        {
          value: 'Tanker',
          label: 'Tanker',
        },
        {
          value: 'Phantom Case',
          label: 'Phantom Case',
        },
        {
          value: 'Phantom Co-Pack',
          label: 'Phantom Co-Pack',
        },
      ]);
    }
    // else if (existingItemFgType === 'Repack') {
    //   setStageList([
    //     {
    //       value: 'FG',
    //       label: 'FG',
    //     },
    //   ]);
    // }
    else {
      setStageList([]);
    }

    tableFormik.setFieldValue('existingItemFgType', existingItemFgType);
    tableFormik.setFieldValue('stage', '');
  }, [existingItemFgType]);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
      existingItemFgType: '',
      comment: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
      // cost_by_100: Yup.string().required('Required')?.nullable(),
      cost_by_100: Yup.string().when('existingItemFgType', {
        is: (val) => val === 'Domestic' || val === 'Copack' || val === 'Repack',
        then: Yup.string().required('Required')?.nullable(),
      }),
    }),
    onSubmit: (values) => {
      const IS_EXISTING = costingTableData.map((item) => item.sku_id).includes(values.sku_id);
      if (IS_EXISTING) {
        tableFormik.resetForm();
        toast.error('SKU ID already exist');
        return;
      }
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      // setCostingTableData([
      //   ...costingTableData,
      //   {
      //     values,
      //   },
      // ]);
      tableFormik.setFieldValue('sku_id', '');
      tableFormik.setFieldValue('sku_description', '');
      tableFormik.setFieldValue('stage', '');
      tableFormik.setFieldValue('cost_by_100', '');
      tableFormik.setFieldValue('existingItemFgType', tableFormik.values.existingItemFgType);
      tableFormik.setFieldValue('comment', '');
    },
  });

  const deleteEntireRmCostingTable = (type) => {
    costService
      .deleteCostingDataByPlantId({
        plant_id: plantData?.id,
        type,
        material_id: params?.id,
      })
      .then((res) => {
        //console.log('res', res);
      })
      .catch((err) => {
        //console.log('err', err);
      });
  };

  useEffect(() => {
    if (costingTableData.length === 0) {
      tableFormik.setFieldValue(
        'sku_id',
        materialData?.existing_item === 'Yes'
          ? materialData?.existing_item_number
          : materialData?.sku_id,
      );
      tableFormik.setFieldValue('sku_description', materialData?.sku_description);
    } else {
      tableFormik.setFieldValue('sku_id', '');
      tableFormik.setFieldValue('sku_description', '');
    }
  }, [costingTableData.length]);
  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput
            label="SKU ID"
            placeholder="SKU ID"
            value={
              materialData?.existing_item === 'Yes'
                ? materialData?.existing_item_number
                : materialData?.sku_id
            }
          />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          {/* <DisabledTextInput
            label="Existing Item FG Type"
            placeholder="FG Type"
            value={materialData?.existing_item_fg_type}
          /> */}
          <Select
            label="Existing Item FG Type"
            placeholder="Exiting Item FG Type"
            radius={'xl'}
            value={existingItemFgType}
            onChange={(e) => {
              if (e !== existingItemFgType) {
                setTemp(e);
                setConfirmDialog(true);
              }
            }}
            data={[
              {
                value: 'Domestic',
                label: 'Domestic',
              },
              {
                value: 'Copack',
                label: 'Copack',
              },
              {
                value: 'Repack',
                label: 'Repack',
              },
            ]}
            disabled={!(materialData?.material_status == 4 && plantData?.status == 9)}
          />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          {existingItemFgType && (
            <Table striped highlightOnHover withBorder>
              <thead>
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {[
                    // 'Plant',
                    check_roles(userState, ['admin', 'costing']) && 'Action',
                    'SKU ID',
                    'Description',
                    'Stage',
                    'Cost/100',
                    'Approved Status',
                    'Comments',
                  ].map((item, index) => (item ? <th key={index}>{item}</th> : null))}
                </tr>
              </thead>
              <tbody>
                {costingTableData?.map((item, index) => (
                  <tr
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {/* <td>{plantData?.plant}</td> */}
                    {check_roles(userState, ['admin', 'costing']) && (
                      <>
                        <td>
                          {materialData?.material_status == 4 && plantData?.status == 9 && (
                            <>
                              <Button
                                variant="light"
                                color={'yellow'}
                                radius="xl"
                                onClick={() => {
                                  updateRmCostingTableItem(item);
                                }}
                              >
                                <IconDeviceFloppy size={24} color="black" />
                              </Button>
                              <Button
                                onClick={() => deleteRmCostingTableItem(item?.id)}
                                variant="light"
                                color={'yellow'}
                                radius="xl"
                              >
                                <IconTrash size={24} color="black" />
                              </Button>
                            </>
                          )}
                        </td>
                      </>
                    )}
                    <td>
                      <CostingSkuSelect
                        value={item?.sku_id}
                        setId={(e) =>
                          setCostingTableData((prev) => {
                            prev[index].sku_id = e;
                            return [...prev];
                          })
                        }
                        setDescription={(e) =>
                          setCostingTableData((prev) => {
                            prev[index].sku_description = e;
                            return [...prev];
                          })
                        }
                        // error={tableFormik?.errors?.sku_id}
                      />
                    </td>
                    <td>{item?.sku_description}</td>
                    <td>
                      <Select
                        radius={'lg'}
                        data={stageList}
                        placeholder="Select items"
                        nothingFound="Nothing found"
                        searchable
                        creatable
                        getCreateLabel={(query) => `+ Create ${query}`}
                        onCreate={(query) => {
                          const item = { value: query, label: query };
                          setStageList((current) => [...current, item]);
                          return item;
                        }}
                        onChange={(e) => {
                          setCostingTableData((prev) => {
                            prev[index].stage = e;
                            return [...prev];
                          });
                        }}
                        value={item.stage}
                      />
                    </td>
                    <td>
                      <TextInput
                        type={'number'}
                        radius={'lg'}
                        placeholder="Cost"
                        onChange={(e) => {
                          setCostingTableData((prev) => {
                            prev[index].cost_by_100 = e.target.value;
                            return [...prev];
                          });
                        }}
                        value={item?.cost_by_100}
                        required
                      />
                    </td>
                    <td>{item?.status}</td>
                    <td>
                      {/* {existingItemFgType === 'Domestic' && ( */}
                      <Input
                        placeholder="Comments"
                        style={{
                          width: '150px',
                        }}
                        value={item?.comment}
                        onChange={(e) => {
                          setCostingTableData(
                            costingTableData?.map((curr, index) =>
                              curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                            ),
                          );
                        }}
                        // error={!item?.comment}
                        // disabled={item?.status !== 'No'}
                      />
                      {/* )} */}
                    </td>
                  </tr>
                ))}
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* <td>{plantData?.plant}</td> */}
                  {check_roles(userState, ['admin', 'costing']) && (
                    <>
                      <td>
                        {materialData?.material_status == 4 && plantData?.status == 9 && (
                          <Button
                            variant="light"
                            color={'yellow'}
                            radius="xl"
                            onClick={() => {
                              tableFormik.handleSubmit();
                            }}
                          >
                            <IconDeviceFloppy size={24} color="black" />
                          </Button>
                        )}
                      </td>
                    </>
                  )}
                  <td
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    <CostingSkuSelect
                      value={tableFormik?.values?.sku_id}
                      setId={(e) => tableFormik.setFieldValue('sku_id', e)}
                      setDescription={(e) => tableFormik.setFieldValue('sku_description', e)}
                      error={tableFormik?.errors?.sku_id}
                    />
                  </td>
                  <td>{tableFormik?.values?.sku_description}</td>
                  <td
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    <Select
                      radius={'lg'}
                      data={stageList}
                      placeholder="Select items"
                      nothingFound="Nothing found"
                      searchable
                      creatable
                      getCreateLabel={(query) => `+ Create ${query}`}
                      onCreate={(query) => {
                        const item = { value: query, label: query };
                        setStageList((current) => [...current, item]);
                        return item;
                      }}
                      onChange={(e) => {
                        tableFormik.setFieldValue('stage', e);
                      }}
                      value={tableFormik?.values?.stage}
                      error={tableFormik?.errors?.stage}
                    />
                  </td>
                  <td
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    <TextInput
                      type={'number'}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        tableFormik.setFieldValue('cost_by_100', e.target.value);
                      }}
                      value={tableFormik?.values?.cost_by_100}
                      error={tableFormik?.errors?.cost_by_100}
                    />
                  </td>
                  <td></td>
                  <td>
                    {existingItemFgType === 'Domestic' && (
                      <TextInput
                        type={'text'}
                        radius={'lg'}
                        placeholder="Comments"
                        onChange={(e) => {
                          tableFormik.setFieldValue('comment', e.target.value);
                        }}
                        value={tableFormik?.values?.comment}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Grid.Col>
      </Grid>

      <ConfirmationBox
        onDiscard={() => {
          setConfirmDialog(false);
          //console.log('existingItemFgType', existingItemFgType);
          tableFormik.setFieldValue('existingItemFgType', existingItemFgType);
        }}
        open={confirmDialog}
        message={'All Table data will be deleted, Are you sure you want to save this FG Type?'}
        onSave={() => {
          setConfirmDialog(false);
          setExistingItemFgType(temp);
          tableFormik.setFieldValue('existingItemFgType', temp);

          deleteEntireRmCostingTable(temp);
          setCostingTableData([]);
        }}
      />
    </Card.Body>
  );
}

function RenderDomestic({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
  deleteRmCostingTableItem,
  updateRmCostingTableItem,
}) {
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const [stageList, setStageList] = useState([
    {
      value: 'FG',
      label: 'FG',
    },
    {
      value: 'Brand',
      label: 'Brand',
    },
    {
      value: 'Age',
      label: 'Age',
    },
    {
      value: 'Ferment',
      label: 'Ferment',
    },
    {
      value: 'Wort',
      label: 'Wort',
    },
    {
      value: 'Phantom',
      label: 'Phantom',
    },
    {
      value: 'Blend',
      label: 'Blend',
    },
    {
      value: 'Tanker',
      label: 'Tanker',
    },
    {
      value: 'Phantom Case',
      label: 'Phantom Case',
    },
    {
      value: 'Phantom Co-Pack',
      label: 'Phantom Co-Pack',
    },
  ]);
  const [value, setValue] = useState([]);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
      comment: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
      cost_by_100: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      const IS_EXISTING = costingTableData.map((item) => item.sku_id).includes(values.sku_id);
      if (IS_EXISTING) {
        tableFormik.resetForm();
        toast.error('SKU ID already exist');
        return;
      }
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  useEffect(() => {
    if (costingTableData.length === 0) {
      tableFormik.setFieldValue(
        'sku_id',
        materialData?.existing_item === 'Yes'
          ? materialData?.existing_item_number
          : materialData?.sku_id,
      );
      tableFormik.setFieldValue('sku_description', materialData?.sku_description);
    } else {
      tableFormik.setFieldValue('sku_id', '');
      tableFormik.setFieldValue('sku_description', '');
    }
  }, [costingTableData.length]);

  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput
            label="SKU ID"
            placeholder="SKU ID"
            value={
              materialData?.existing_item === 'Yes'
                ? materialData?.existing_item_number
                : materialData?.sku_id
            }
          />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  check_roles(userState, ['admin', 'costing']) && 'Action',
                  'SKU ID',
                  'Description',
                  'Stage',
                  'Cost/100',
                  'Approved Status',
                  'Comments',
                ].map((item, index) => (item ? <th key={index}>{item}</th> : null))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* <td>{plantData?.plant}</td> */}
                  {/* <td>{item?.sku_id}</td> */}
                  {check_roles(userState, ['admin', 'mdm', 'costing']) && (
                    <td>
                      {materialData?.material_status == 4 && plantData?.status == 9 && (
                        <>
                          <Button
                            variant="light"
                            color={'yellow'}
                            radius="xl"
                            onClick={() => {
                              updateRmCostingTableItem(item);
                            }}
                          >
                            <IconDeviceFloppy size={24} color="black" />
                          </Button>
                          <Button
                            onClick={() => deleteRmCostingTableItem(item?.id)}
                            variant="light"
                            color={'yellow'}
                            radius="xl"
                          >
                            <IconTrash size={24} color="black" />
                          </Button>
                        </>
                      )}
                    </td>
                  )}
                  <td>
                    <CostingSkuSelect
                      value={item?.sku_id}
                      setId={(e) =>
                        setCostingTableData((prev) => {
                          prev[index].sku_id = e;
                          return [...prev];
                        })
                      }
                      setDescription={(e) =>
                        setCostingTableData((prev) => {
                          prev[index].sku_description = e;
                          return [...prev];
                        })
                      }
                      // error={tableFormik?.errors?.sku_id}
                    />
                  </td>
                  <td>{item?.sku_description}</td>
                  <td>
                    <Select
                      radius={'lg'}
                      data={stageList}
                      placeholder="Select items"
                      nothingFound="Nothing found"
                      searchable
                      creatable
                      getCreateLabel={(query) => `+ Create ${query}`}
                      onCreate={(query) => {
                        const item = { value: query, label: query };
                        setStageList((current) => [...current, item]);
                        return item;
                      }}
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].stage = e;
                          return [...prev];
                        });
                      }}
                      value={item.stage}
                    />
                  </td>
                  <td>
                    <TextInput
                      type={'number'}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                  </td>
                  <td>{item?.status}</td>
                  <td>
                    <Input
                      style={{
                        width: '150px',
                      }}
                      placeholder="Comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // error={!item?.comment}
                      // disabled={item?.status !== 'No'}
                    />
                  </td>
                </tr>
              ))}
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {/* <td>{plantData?.plant}</td> */}
                {check_roles(userState, ['admin', 'costing']) && (
                  <>
                    <td>
                      {materialData?.material_status == 4 && plantData?.status == 9 && (
                        <Button
                          variant="light"
                          color={'yellow'}
                          radius="xl"
                          onClick={() => {
                            tableFormik.handleSubmit();
                          }}
                        >
                          <IconDeviceFloppy size={24} color="black" />
                        </Button>
                      )}
                    </td>
                  </>
                )}
                <td
                  style={{
                    minWidth: '120px',
                  }}
                >
                  <CostingSkuSelect
                    value={tableFormik?.values?.sku_id}
                    setId={(e) => tableFormik.setFieldValue('sku_id', e)}
                    setDescription={(e) => tableFormik.setFieldValue('sku_description', e)}
                    error={tableFormik?.errors?.sku_id}
                  />
                </td>
                <td>{tableFormik?.values?.sku_description}</td>
                <td
                  style={{
                    minWidth: '120px',
                  }}
                >
                  <Select
                    radius={'lg'}
                    data={stageList}
                    placeholder="Select items"
                    nothingFound="Nothing found"
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setStageList((current) => [...current, item]);
                      return item;
                    }}
                    onChange={(e) => {
                      tableFormik.setFieldValue('stage', e);
                    }}
                    value={tableFormik?.values?.stage}
                    error={tableFormik?.errors?.stage}
                  />
                </td>
                <td
                  style={{
                    minWidth: '120px',
                  }}
                >
                  <TextInput
                    type={'number'}
                    radius={'lg'}
                    placeholder="Cost"
                    onChange={(e) => {
                      tableFormik.setFieldValue('cost_by_100', e.target.value);
                    }}
                    value={tableFormik?.values?.cost_by_100}
                    error={tableFormik?.errors?.cost_by_100}
                    required
                  />
                </td>
                <td></td>
                <td>
                  <TextInput
                    style={{
                      width: '150px',
                    }}
                    type={'text'}
                    placeholder="Comments"
                    onChange={(e) => {
                      tableFormik.setFieldValue('comment', e.target.value);
                    }}
                    value={tableFormik?.values?.comment}
                    // error={tableFormik?.errors?.comment}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}

function RenderVirtual({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
  deleteRmCostingTableItem,
  updateRmCostingTableItem,
  componentsListData = [],
}) {
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const [value, setValue] = useState([]);
  const [genericData, setGenericData] = useState({
    description: '',
    cost: '',
  });

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      cost_by_100: '',
      generic_sku_cost: genericData?.cost,
      comment: '',
    },

    validationSchema: Yup.object({
      cost_by_100: Yup.string().required('Required')?.nullable(),
      generic_sku_cost: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  const fetchGenericData = async () => {
    try {
      const response = await bomService.getAllComponentsByPlant(
        plantData?.plant,
        materialData?.real_item,
      );

      //console.log('FETCHING GENERIC DATA', response);
      setGenericData({
        description: response[0]?.component_description,
        cost: response[0]?.standard_cost,
      });
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    if (materialData?.real_item && plantData?.plant) {
      fetchGenericData();
    }
  }, [materialData?.real_item, plantData?.plant]);

  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput label="SKU ID" placeholder="SKU ID" value={materialData?.sku_id} />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  check_roles(userState, ['admin', 'costing']) && 'Action',
                  'Virtual SKU ID',
                  'Virtual SKU Description',
                  'Virtual SKU Cost',
                  'Generic SKU ID',
                  'Generic SKU Description',
                  'Generic SKU Cost',
                  'Production Location',
                  'Approval Status',
                  'Comments',
                ].map((item, index) => (item ? <th key={index}>{item}</th> : null))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {check_roles(userState, ['admin', 'costing']) && (
                    <>
                      <td>
                        {materialData?.material_status == 4 && plantData?.status == 9 && (
                          <>
                            <Button
                              variant="light"
                              color={'yellow'}
                              radius="xl"
                              onClick={() => {
                                updateRmCostingTableItem(item);
                              }}
                            >
                              <IconDeviceFloppy size={24} color="black" />
                            </Button>
                            <Button
                              onClick={() => deleteRmCostingTableItem(item?.id)}
                              variant="light"
                              color={'yellow'}
                              radius="xl"
                            >
                              <IconTrash size={24} color="black" />
                            </Button>
                          </>
                        )}
                      </td>
                    </>
                  )}
                  <td>{materialData?.sku_id}</td>
                  <td>{materialData?.sku_description}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                  </td>
                  <td>{materialData?.real_item}</td>
                  <td>{materialData?.sku_description?.replace(/_VIRT([^_VIRT]*)$/, '')}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].generic_sku_cost = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.generic_sku_cost}
                      required
                    />
                  </td>
                  <td>{plantData?.plant}</td>
                  <td>{item?.status}</td>
                  <td>
                    <Input
                      style={{
                        width: '150px',
                      }}
                      placeholder="Comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // error={!item?.comment}
                      // disabled={item?.status !== 'No'}
                    />
                  </td>
                </tr>
              ))}
              {costingTableData?.length >= 12 ? null : (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {check_roles(userState, ['admin', 'costing']) && (
                    <>
                      <td>
                        {materialData?.material_status == 4 && plantData?.status == 9 && (
                          <Button
                            variant="light"
                            color={'yellow'}
                            radius="xl"
                            onClick={() => {
                              tableFormik.handleSubmit();
                            }}
                          >
                            <IconDeviceFloppy size={24} color="black" />
                          </Button>
                        )}
                      </td>
                    </>
                  )}
                  <td>{materialData?.sku_id}</td>
                  <td>{materialData?.sku_description}</td>
                  <td>
                    <TextInput
                      placeholder="Cost"
                      radius="xl"
                      color="yellow"
                      type="number"
                      name="cost"
                      value={tableFormik.values.cost_by_100}
                      onChange={(e) => tableFormik.setFieldValue('cost_by_100', e.target.value)}
                      error={tableFormik.errors.cost_by_100}
                    />
                  </td>
                  <td>{materialData?.real_item}</td>
                  <td>{materialData?.sku_description?.replace(/_VIRT([^_VIRT]*)$/, '')}</td>
                  <td>
                    <TextInput
                      placeholder={genericData?.cost}
                      radius="xl"
                      color="yellow"
                      type="number"
                      name="cost"
                      value={tableFormik.values.generic_sku_cost}
                      onChange={(e) =>
                        tableFormik.setFieldValue('generic_sku_cost', e.target.value)
                      }
                      error={tableFormik.errors.generic_sku_cost}
                    />
                  </td>
                  <td>{plantData?.plant}</td>
                  <td></td>
                  <td>
                    <TextInput
                      style={{
                        width: '150px',
                      }}
                      placeholder="Comments"
                      value={tableFormik.values.comment}
                      onChange={(e) => tableFormik.setFieldValue('comment', e.target.value)}
                      // error={tableFormik.errors.comment}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}

function RenderRest({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
  deleteRmCostingTableItem,
  updateRmCostingTableItem,
  componentsListData = [],
}) {
  const [stageList, setStageList] = useState([
    {
      value: 'FG',
      label: 'FG',
    },
  ]);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      const IS_EXISTING = costingTableData.map((item) => item.sku_id).includes(values.sku_id);
      if (IS_EXISTING) {
        tableFormik.resetForm();
        toast.error('SKU ID already exist');
        return;
      }
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  useEffect(() => {
    if (costingTableData.length === 0) {
      tableFormik.setFieldValue(
        'sku_id',
        materialData?.existing_item === 'Yes'
          ? materialData?.existing_item_number
          : materialData?.sku_id,
      );
      tableFormik.setFieldValue('sku_description', materialData?.sku_description);
    } else {
      tableFormik.setFieldValue('sku_id', '');
      tableFormik.setFieldValue('sku_description', '');
    }
  }, [costingTableData.length]);
  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput label="SKU ID" placeholder="SKU ID" value={materialData?.sku_id} />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  check_roles(userState, ['admin', 'costing']) && 'Action',
                  'SKU ID',
                  'Description',
                  'Stage',
                  'Production Date',
                  'Cost/100',
                  // 'Approved Status',
                  // 'Comments',
                ].map((item, index) => (item ? <th key={index}>{item}</th> : null))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  key={item.id}
                >
                  {check_roles(userState, ['admin', 'costing']) && (
                    <>
                      <td>
                        {materialData?.material_status == 4 && plantData?.status == 9 && (
                          <>
                            <Button
                              variant="light"
                              color={'yellow'}
                              radius="xl"
                              onClick={() => {
                                updateRmCostingTableItem(item);
                              }}
                            >
                              <IconDeviceFloppy size={24} color="black" />
                            </Button>
                            <Button
                              variant="light"
                              color={'yellow'}
                              radius="xl"
                              onClick={() => {
                                deleteRmCostingTableItem(item.id);
                              }}
                            >
                              <IconTrash size={24} color="black" />
                            </Button>
                          </>
                        )}
                      </td>
                    </>
                  )}
                  <td
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    <CostingSkuSelect
                      value={item?.sku_id}
                      setId={(e) =>
                        setCostingTableData((prev) => {
                          prev[index].sku_id = e;
                          return [...prev];
                        })
                      }
                      setDescription={(e) =>
                        setCostingTableData((prev) => {
                          prev[index].sku_description = e;
                          return [...prev];
                        })
                      }
                      // error={tableFormik?.errors?.sku_id}
                    />
                  </td>
                  <td>{item?.sku_description}</td>
                  <td>
                    {' '}
                    <Select
                      radius={'lg'}
                      data={stageList}
                      placeholder="Select items"
                      nothingFound="Nothing found"
                      searchable
                      creatable
                      getCreateLabel={(query) => `+ Create ${query}`}
                      onCreate={(query) => {
                        const item = { value: query, label: query };
                        setStageList((current) => [...current, item]);
                        return item;
                      }}
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].stage = e;
                          return [...prev];
                        });
                      }}
                      value={item.stage}
                    />
                  </td>
                  <td>{new Date(plantData?.production_date).toDateString()}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                  </td>
                  {/* <td>{item?.status}</td> */}
                  {/* <td>
                    <Input
                      style={{
                        width: '150px',
                      }}
                      placeholder="Comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // error={!item?.comment}
                      // disabled={item?.status !== 'No'}
                    />
                  </td> */}
                </tr>
              ))}
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {check_roles(userState, ['admin', 'costing']) && (
                  <>
                    <td>
                      {materialData?.material_status == 4 && plantData?.status == 9 && (
                        <Button
                          variant="light"
                          color={'yellow'}
                          radius="xl"
                          onClick={() => {
                            tableFormik.handleSubmit();
                          }}
                        >
                          <IconDeviceFloppy size={24} color="black" />
                        </Button>
                      )}
                    </td>
                  </>
                )}
                <td
                  style={{
                    minWidth: '120px',
                  }}
                >
                  <CostingSkuSelect
                    value={tableFormik?.values?.sku_id}
                    setId={(e) => tableFormik.setFieldValue('sku_id', e)}
                    setDescription={(e) => tableFormik.setFieldValue('sku_description', e)}
                    error={tableFormik?.errors?.sku_id}
                  />
                </td>
                <td>{tableFormik?.values?.sku_description}</td>
                {/* <td>{plantData?.plant}</td> */}
                <td>
                  <Select
                    radius={'lg'}
                    data={stageList}
                    placeholder="Select items"
                    nothingFound="Nothing found"
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setStageList((current) => [...current, item]);
                      return item;
                    }}
                    onChange={(e) => {
                      tableFormik.setFieldValue('stage', e);
                    }}
                    value={tableFormik?.values?.stage}
                    error={tableFormik?.errors?.stage}
                    style={{
                      minWidth: '120px',
                    }}
                  />
                </td>
                <td>{new Date(plantData?.production_date).toDateString()}</td>

                <td>
                  <TextInput
                    type={'number'}
                    radius={'lg'}
                    placeholder="Cost"
                    onChange={(e) => {
                      tableFormik.setFieldValue('cost_by_100', e.target.value);
                    }}
                    value={tableFormik?.values?.cost_by_100}
                    error={tableFormik?.errors?.cost_by_100}
                    required
                    style={{
                      minWidth: '120px',
                    }}
                  />
                </td>
                {/* <td></td> */}
                {/* <td></td> */}
              </tr>
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}
