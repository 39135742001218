import { urls } from '../utils/apiConfigs';
import axios from '../utils/axios';

/* eslint-disable import/no-anonymous-default-export */
export default {
  getHomeProjectRequest: async (body) => {
    try {
      const { data } = await axios.post(urls.getProjectRequestDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getSkuDescriptionData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSkuDescriptionDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getFullGoodsFormData: async (body) => {
    try {
      const { data } = await axios.post(urls.getFullGoodsFormDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getSkuIdCreationData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSkuIdCreationDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getBomValidationData: async (body) => {
    try {
      const { data } = await axios.post(urls.getBomValidationDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getSapBomCreationData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSapBomCreationDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getPackagingBOMData: async (body) => {
    try {
      const { data } = await axios.post(urls.getPackagingBomDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getLiquidBOMData: async (body) => {
    try {
      const { data } = await axios.post(urls.getLiquidBomDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },
  getSkuCostingData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSkuCostingDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getCostingApprovalData: async (body) => {
    try {
      const { data } = await axios.post(urls.getCostingApprovalDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getSapCostUpdateData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSapCostUpdateDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },
  getSummaryData: async (body) => {
    try {
      const { data } = await axios.post(urls.getSummaryDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getCostingNotificationsData: async (body) => {
    try {
      const { data } = await axios.post(urls.getCostingNotificationDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  getRmCostingData: async (body) => {
    try {
      const { data } = await axios.post(urls.getRmCostingDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  saveRmCostingData: async (body) => {
    try {
      const { data } = await axios.post(urls.saveRmCostingDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  checkRmCostingData: async (body) => {
    try {
      const { data } = await axios.post(urls.checkRmCostingDataURL, body);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },

  snowflakeRefreshedAt: async () => {
    try {
      const { data } = await axios.get(urls.getSnowflakeRefreshedAtURL);
      return data;
    } catch (error) {
      //console.log(error);
    }
  },
};
