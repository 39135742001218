import {
  Center,
  Group,
  Table,
  Text,
  Button,
  Select,
  Box,
  NumberInput,
  Space,
  Modal,
} from '@mantine/core';
import { IconCheck, IconClipboardText, IconX } from '@tabler/icons';
import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import CustomCardLoader from '../../components/CustomCardLoader';
import { useCounter } from '@mantine/hooks';
import userService from '../../services/userService';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
function ManageSLA() {
  const [projectType, setProjectType] = useState();
  const [count, handlers] = useCounter(0, { min: 0, max: 10 });
  const [newSLA, setNewSLA] = useState([]);
  const [slaTypes, setSLATypes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [slaValues, setSlaValues] = useState({});
  const [changedSlaValues, setChangedSlaValues] = useState({});

  const [userState, dispatch] = useContext(ActiveUserContext);

  // modal states
  const [updateSLAActivePage, setUpdateSLAActivePage] = useState('first');
  const [updateSLAModalMessage, setUpdateSLAModalMessage] = useState();
  const [updateSLAModalLoader, setUpdateSLAModalLoader] = useState(false);
  const [showUpdateSLAModal, setShowUpdateSLAModal] = useState(false);

  const getProjectSLAApi = (id) => {
    setSLATypes();
    setSlaValues();
    userService.getProjectSLA(id).then((res) => {
      if (res?.data?.Success) {
        setSLATypes(res?.data);
        //console.log(res?.data)
        setSlaValues(
          res?.data?.SLA.reduce(
            (acc, { id, sla, sla_step_manager, sla_senior_manager }) => ({
              ...acc,
              [id]: { sla, sla_step_manager, sla_senior_manager },
            }),
            {},
          ),
        );
      }
    });
  };

  const updateSLAApi = (data) => {
    userService.updateSLA(data).then((res) => {
      if (res?.data?.Success) {
        // setIsLoading(false)
        //console.log(res?.data)
        getProjectSLAApi(projectType);
        setProjectType(projectType);
        setUpdateSLAModalLoader(false);
        setUpdateSLAActivePage('response');
        setUpdateSLAModalMessage(res?.data?.message);
      }
    });
  };

  useEffect(() => {
    getProjectSLAApi(projectType);
  }, []);

  const project_types = [
    { value: '1', label: 'NPPA' },
    { value: '2', label: 'Innovation' },
    { value: '3', label: 'Craft' },
    { value: '4', label: 'Others' },
  ];

  function updateSLAModalCleanup() {
    setNewSLA([]);
    setUpdateSLAActivePage('first');
    setUpdateSLAModalMessage();
    setUpdateSLAModalLoader(false);
    setShowUpdateSLAModal(false);
    getProjectSLAApi(projectType);
  }

  const handleDropdown = (e) => {
    setProjectType(e);
    getProjectSLAApi(e);
    //console.log(e)
  };

  const handleSlaChange = (id, slaValue, slaStepManagerValue, slaSeniorManagerValue) => {
    const updatedSlaValues = {
      ...slaValues,
      [id]: {
        sla: parseInt(slaValue),
        sla_step_manager: parseInt(slaStepManagerValue),
        sla_senior_manager: parseInt(slaSeniorManagerValue),
      },
    };
    //console.log(updatedSlaValues)
    setSlaValues(updatedSlaValues);

    const index = newSLA.findIndex((item) => item.id === id);

    if (index === -1) {
      // If the ID doesn't exist, add it to the array
      setNewSLA([
        ...newSLA,
        {
          id: id,
          sla: parseInt(slaValue),
          sla_step_manager: parseInt(slaStepManagerValue),
          sla_senior_manager: parseInt(slaSeniorManagerValue),
        },
      ]);
    } else {
      // If the ID already exists, update its value
      const updatedSLA = [...newSLA];
      updatedSLA[index].sla = parseInt(slaValue);
      updatedSLA[index].sla_step_manager = parseInt(slaStepManagerValue);
      updatedSLA[index].sla_senior_manager = parseInt(slaSeniorManagerValue);
      setNewSLA(updatedSLA);
    }

    // Log the new SLA values and the updated newSLA array
    //console.log(updatedSlaValues);
    //console.log(newSLA);
  };

  return (
    <>
      <Modal
        radius={'lg'}
        size="lg"
        opened={showUpdateSLAModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {`Update SLA`}
          </Text>
        }
        onClose={() => {
          updateSLAModalCleanup();
        }}
        withCloseButton={false}
      >
        {updateSLAActivePage === 'first' && (
          <>
            <Row>
              <Col xs={12}>
                <Text>{`Are you sure you want update the SLA?`}</Text>
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  updateSLAModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowUpdateSLAModal(true);
                  updateSLAApi(newSLA);
                }}
              >{`Update`}</Button>
            </Group>
          </>
        )}

        {updateSLAActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {updateSLAModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  updateSLAModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {updateSLAModalLoader ? (
          <>
            <CustomCardLoader />
          </>
        ) : null}
      </Modal>
      <Card>
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left">{`SLA Hub`}</Text>
          </Group>
        </Card.Body>

        <Card.Body>
          <Group position="apart">
            <Group position="left">
              <Select
                // label="Project Type"
                icon={<IconClipboardText size={14} />}
                placeholder="Select Project Type"
                data={project_types}
                radius="xl"
                value={projectType}
                ta="left"
                style={{ maxWidth: 250 }}
                onChange={(e) => {
                  handleDropdown(e);
                  // setIsLoading(true)
                  //console.log(e)
                }}
              />
            </Group>
            {check_roles(userState, ['admin']) && (
              <Group position="right">
                <Button
                  color="yellow"
                  radius="xl"
                  className="btn btn-primary"
                  style={{ borderRadius: '40px' }}
                  leftIcon={<IconCheck size={16} color="white" />}
                  onClick={() => {
                    setShowUpdateSLAModal(true);
                    //console.log(newSLA)
                  }}
                >{`Save`}</Button>
              </Group>
            )}
          </Group>
        </Card.Body>
        {!slaTypes && !projectType && (
          <Card.Body>
            <Group position="center">
              <Text>{`Select a Project Type.`}</Text>
            </Group>
          </Card.Body>
        )}
        {!slaTypes && projectType && (
          <Card.Body>
            <Group position="center">
              <CustomCardLoader />
            </Group>
          </Card.Body>
        )}
        {slaTypes && slaTypes?.SLA && slaTypes?.SLA.length === 0 && (
          <Card.Body>
            <Group position="center">
              <Text>{`No SLA found.`}</Text>
            </Group>
          </Card.Body>
        )}
        {slaTypes && slaTypes?.SLA && slaTypes?.SLA.length > 0 && (
          <div className="table-responsive">
            <Box sx={{ height: 420 }}>
              <Table
                className="table card-table table-outline text-nowrap table-vcenter card-table"
                striped
                highlightOnHover
                withBorder
                withColumnBorders
              >
                <thead>
                  <tr>
                    <th>
                      <Center>Step</Center>
                    </th>
                    <th>
                      <Center>SLA (In Days)</Center>
                    </th>
                    <th>
                      <Center>SLA (Level 1 Manager)</Center>
                    </th>
                    <th>
                      <Center>SLA (Level 2 Manager)</Center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {slaTypes.SLA.map(
                    ({ id, form_type, sla, sla_step_manager, sla_senior_manager }) => (
                      <tr key={id}>
                        <td>{form_type}</td>
                        <td>
                          <Center>
                            {check_roles(userState, ['admin']) ? (
                              <div>
                                <NumberInput
                                  value={parseInt(slaValues[id]?.sla)}
                                  onChange={(val) => {
                                    handleSlaChange(
                                      id,
                                      val,
                                      slaValues[id].sla_step_manager,
                                      slaValues[id].sla_senior_manager,
                                    );
                                  }}
                                  max={10}
                                  min={0}
                                  step={1}
                                  style={{ maxWidth: 100 }}
                                />
                              </div>
                            ) : (
                              slaValues[id]?.sla
                            )}
                          </Center>
                        </td>
                        <td>
                          <Center>
                            {check_roles(userState, ['admin']) ? (
                              <div>
                                <NumberInput
                                  value={parseInt(slaValues[id].sla_step_manager)}
                                  onChange={(val) => {
                                    handleSlaChange(
                                      id,
                                      slaValues[id]?.sla,
                                      val,
                                      slaValues[id].sla_senior_manager,
                                    );
                                  }}
                                  max={10}
                                  min={-10}
                                  step={1}
                                  style={{ maxWidth: 100 }}
                                />
                              </div>
                            ) : (
                              slaValues[id]?.sla_step_manager
                            )}
                          </Center>
                        </td>
                        <td>
                          <Center>
                            {check_roles(userState, ['admin']) ? (
                              <div>
                                <NumberInput
                                  value={parseInt(slaValues[id]?.sla_senior_manager)}
                                  onChange={(val) => {
                                    handleSlaChange(
                                      id,
                                      slaValues[id].sla,
                                      slaValues[id].sla_step_manager,
                                      val,
                                    );
                                  }}
                                  max={30}
                                  min={-10}
                                  step={1}
                                  style={{ maxWidth: 100 }}
                                />
                              </div>
                            ) : (
                              slaValues[id]?.sla_senior_manager
                            )}
                          </Center>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </Box>
          </div>
        )}
      </Card>
    </>
  );
}

export default ManageSLA;
