import React, { useState, useContext } from 'react';
import {
  Button,
  Group,
  TextInput,
  Space,
  Text,
  Modal,
  Select,
  MultiSelect
} from '@mantine/core';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { Card, Col, Row } from 'react-bootstrap';
import { UserContext } from "../../context/userContext";


function DeleteMaterial({ showDeleteMaterialModal, closeDeleteMaterialModal, deleteMaterial }) {
  const [tableData, setTableDate] = useState([0]);
  const [showSkuDescriptionModal, setShowSkuDescriptionModal] = useState(false);
  const { loading,
    selectedMaterial
  } = useContext(UserContext);
  return (
    <Modal
      radius={'lg'}
      size="lg"
      opened={showDeleteMaterialModal}
      centered
      title={<Text weight={700} className="golden-text">{`Delete Material`}</Text>}
      onClose={() => {
        closeDeleteMaterialModal();
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <>
        <Row>
          <Col xs={12}>
            <Text>{`Are you sure you want to delete the Material - ${selectedMaterial?.sku_description ? selectedMaterial?.sku_description : ''}?`}</Text>
          </Col>
        </Row>
        <Space h="xl" />
        <Group position="right">
          <Button
            color="gray"
            radius="xl"
            leftIcon={<IconX size={16} color="white" />}
            onClick={() => {
              closeDeleteMaterialModal();
            }}
          >{`Cancel`}</Button>
          <Button
            color="yellow"
            radius="xl"
            leftIcon={<IconCheck size={16} color="white" />}
            onClick={() => {
              deleteMaterial()
              closeDeleteMaterialModal(true);
            }}
          >{`Delete`}</Button>
        </Group>
      </>
    </Modal>
  );
}
export default DeleteMaterial;
