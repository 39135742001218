import React, { useReducer, createContext, useState } from 'react';
import projectService from '../services/projectService';
import materialService from '../services/materialService';
import { useNavigate } from 'react-router-dom';
import bomService from '../services/bomService';
import userService from '../services/userService';
import { toast } from 'react-toastify';

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [draftProjects, setDraftProjects] = useState([]);
  const [project, setProject] = useState({});
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedProjectMaterials, setSelectedProjectMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState();
  const [roles, setRoles] = useState(['stakeholder']);
  const [projectList, setProjectList] = useState([]);

  const [skuDescription, setSkuDescription] = useState();
  const [materialApprovalList, setMaterialApprovalList] = useState([]);
  const [productionLineList, setProductionLineList] = useState([]);

  const loadProductionLineList = (plant) => {
    bomService
      .getProductionLines({
        plant,
      })
      .then((res) => {
        //console.log('PDL', [...new Set(res?.production_line_list.map((item) => item.value))]);
        setProductionLineList(
          [...new Set(res?.production_line_list.map((item) => item.value))].map((item) => ({
            value: item,
            label: item,
          })),
        );
      });
  };

  const loadProjects = () => {
    setLoading(true);
    projectService
      .getAllProjects()
      .then((res) => {
        //console.log('LOAD PROJECTS', res);
        if (res.data) {
          setProjectList(res.data?.projects);
          let tempProject = res.data?.projects;
          let tempMaterials = res.data?.materials;
          const tempPlants = res.data?.plants;
          tempMaterials = tempMaterials?.map((material) => {
            material = {
              ...material,
              plants: tempPlants.filter((plant) => plant.material_id === material.id),
            };
            return material;
          });
          tempProject = tempProject?.map((project) => {
            project = {
              ...project,
              materials: tempMaterials.filter((material) => material.project_id === project.id),
            };
            return project;
          });

          //console.log('LOAD PROJECTS', tempProject);
          setProjects(tempProject);
          // setSelectedProject(tempProject[0]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    // projectService
    //   .getAllDraftProjects()
    //   .then((res) => {
    //     if (res.data) {
    //       let tempProject = res.data?.projects;
    //       setDraftProjects(tempProject);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  };

  const addMaterialInProject = (material) => {
    setLoading(true);
    material = {
      ...material,
      project_id: selectedProject.id,
    };
    materialService
      .createMaterial(material)
      .then((res) => {
        if (res.data) {
          //console.log(res.data);
          onProjectSelect(selectedProject.id);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const createProject = (project) => {
    setLoading(true);
    projectService
      .createProject(project)
      .then((res) => {
        //console.log(res);
        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setLoading(false);
        } else {
          navigate('/');
          setLoading(false);
        }
        // if (res.data) {
        // navigate('/');
        // setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateProject = (project) => {
    setLoading(true);
    projectService
      .updateProject(project)
      .then((res) => {
        if (res.data) {
          navigate('/');
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getMaterialById = (id) => {
    materialService
      .getMaterialById(id)
      .then((res) => {
        if (res.data) {
          setSelectedMaterial(res.data?.material);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateMaterial = (materialObject) => {
    materialService
      .updateMaterial({
        materialObject,
      })
      .then((res) => {
        if (res.data) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const generateSKUDescription = (formik, materialObject) => {
    materialService
      .getSKUDescription({
        sub_brand: materialObject?.subBrand ? materialObject?.subBrand : '',
        pack_size_1: materialObject?.packSize1 ? materialObject?.packSize1 : '',
        pack_size_2: materialObject?.packSize2 ? materialObject?.packSize2 : '',
        container_size: materialObject?.containerVolume ? materialObject?.containerVolume : '',
        container: materialObject?.containerType ? materialObject?.containerType : '',
        deco_nondeco: materialObject?.decoType ? materialObject?.decoType : '',
        language: materialObject?.language ? materialObject?.language : '',
        palletization_1: materialObject?.palletization1 ? materialObject?.palletization1 : '',
        palletization_2: materialObject?.palletization2 ? materialObject?.palletization2 : '',
        suffix: materialObject?.suffix ? materialObject?.suffix : '',
      })
      .then((res) => {
        if (res?.data) {
          setSkuDescription(res.data ? res.data : '');
          // materialObject['skuDescription'] =  res.data ? res.data : ''
          formik.setFieldValue('skuDescription', res.data ? res.data : '');
          //console.log('skuDescription', res.data ? res.data : '');
        }
      });
  };

  const onProjectSelect = (id) => {
    setLoading(true);
    materialService
      .getAllMaterialsByProjectId(id)
      .then((res) => {
        if (res.data) {
          //console.log(res.data?.filter((item) => item?.deleted === false));
          setSelectedProjectMaterials(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getDraftProjectById = (id) => {
    setLoading(true);
    if (id) {
      projectService
        .getDraftProjectById(id)
        .then((res) => {
          if (res.data) {
            setProject(res.data.projects ? res.data.projects[0] : {});
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setProject({});
    }
  };

  const loadMaterialApprovalList = () => {
    setLoading(true);

    materialService
      .getAllMaterialApproval()
      .then((res) => {
        if (res.data) {
          setMaterialApprovalList(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        project,
        projects,
        draftProjects,
        selectedProject,
        selectedMaterial,
        selectedMaterialIndex,
        skuDescription,
        materialApprovalList,
        selectedProjectMaterials,
        projectList,
        setSelectedMaterialIndex,
        setSelectedProjectMaterials,
        setSelectedMaterial,
        setSelectedProject,
        addMaterialInProject,
        updateProject,
        createProject,
        setProject,
        loadProjects,
        getMaterialById,
        updateMaterial,
        generateSKUDescription,
        onProjectSelect,
        getDraftProjectById,
        loadMaterialApprovalList,
        loadProductionLineList,
        productionLineList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
