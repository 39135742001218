/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import { Text, Stack, Group, Space, Center, Button, Tabs, Switch } from '@mantine/core';
import { Pagination, Badge } from '@mantine/core';
import { TextInput } from '@mantine/core';
import {
  IconSearch,
  IconCopy,
  IconEdit,
  IconTrash,
  IconPhoto,
  IconMessageCircle,
  IconRefresh,
  IconSortDescending,
  IconArrowsSort,
  IconSortAscending,
} from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import AddMaterial from '../ProjectRequest/AddMaterial';
import { UserContext } from '../../context/userContext';
import CustomCardLoader from '../../components/CustomCardLoader';
import moment from 'moment';
import { pageContext } from '../../context/pageContext';
import { DateDifference, RowColors, formatChange, mapStatusToValue } from '../../utils/Utils';
import homeServices from '../../services/homeServices';
import { convertDateTimeStringToDateTimeEST } from '../../utils/Utils';

function PackagingBOM() {
  const navigate = useNavigate();

  const {
    searchPackagingBom,
    setSearchPackagingBom,
    packagingBomPagination,
    setPackagingBomPagination,
    totalPackagingBom,
    setTotalPackagingBom,
  } = useContext(pageContext);

  // const [projectList, setProjectList] = useState([]);

  // useEffect(() => {
  //   let temp = [];
  //   projects?.map((project) => {
  //     project?.materials?.map((material) => {
  //       material.plants?.map((plant) => {
  //         temp.push({
  //           ...plant,
  //           plant: plant?.plant,
  //           project_type: project?.project_type,
  //           project_name: project?.project_name,
  //           project_year: project?.project_year,
  //           project_owner: project?.project_owner,
  //           material_status: material?.material_status,
  //           material_id: material?.id,
  //           sku_description: material?.sku_description,
  //           sku_id: material?.sku_id,
  //           fg_type: material?.fg_type,
  //           liquid: material?.liquid,
  //           m_deleted: material?.deleted,
  //           deleted: material?.deleted,
  //           pl_deleted: plant?.deleted,
  //           pr_deleted: project?.deleted,
  //           plant_status: plant?.status,
  //           plant_id: plant?.id,
  //           existing_item: material?.existing_item,
  //           existing_item_number: material?.existing_item_number,
  //           virtual_sku_description: material?.virtual_sku_description,
  //           // rejected_comment: plant?.rejected_comment,
  //         });
  //       });
  //     });
  //   });

  //   console.log(
  //     'P BOM',
  //     temp
  //       ?.filter((item) => item?.material_status == 4 && item?.plant_status == null)
  //       ?.filter((item) => item?.deleted === false)
  //       ?.filter((item) => item?.p_bom_status == 5 || item?.p_bom_status == 1),
  //   );
  //   setProjectList(
  //     temp
  //       ?.filter((item) => item?.material_status == 4 && item?.plant_status == null)
  //       ?.filter((item) => item?.deleted === false)
  //       ?.filter((item) => item?.p_bom_status == 5 || item?.p_bom_status == 1),
  //   );
  // }, [projects]);

  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);
    setLoadingData(true);
    homeServices
      .getPackagingBOMData({
        search: search,
        offset: (offset - 1) * 10,
        sort_column: sort_column,
        sort_type: sort_type,
      })
      .then((res) => {
        // console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(res?.data);
        setPages(res?.total);
        setLoadingData(false);
        setTotalPackagingBom(res?.rows);
      })
      .catch((err) => {
        // console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(1, searchPackagingBom);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchPackagingBom]);

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Card radius="xl">
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
              {}
            </Text>
          </Group>
        </Card.Body>
        <Card.Body>
          <Group position="apart">
            <Text
              size="xl"
              weight={700}
              color="yellow"
              style={{ fontSize: '18px' }}
            >{`Packaging BOM`}</Text>
          </Group>
          <Space h="xl" />
          <TextInput
            radius={'xl'}
            placeholder={`Plant`}
            sx={{ width: '16vw' }}
            rightSection={
              <ActionIcon
                variant="filled"
                color={'yellow'}
                radius="xl"
                size="md"
                onClick={() => {
                  setSearchPackagingBom('');
                  setPackagingBomPagination(1);
                }}
              >
                <IconRefresh size={14} color="black" />
              </ActionIcon>
            }
            value={searchPackagingBom}
            onChange={(e) => {
              setLoadingData(true);
              setSearchPackagingBom(e.target.value);
              setPackagingBomPagination(1);
            }}
          />
        </Card.Body>
        <div className="table-responsive-sm">
          {loadingData ? (
            <CustomCardLoader />
          ) : (
            <Table hover>
              <thead>
                <tr>
                  <th>
                    <Center fw={500}>
                      SKU ID
                      <>
                        {sortedColumnData !== 'SKU ID' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU ID', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU ID' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU ID', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU ID' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>
                  <th>
                    <Center fw={500}>
                      SKU Description
                      <>
                        {sortedColumnData !== 'SKU Description' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU Description', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU Description' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU Description', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU Description' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>
                  <th>
                    <Center fw={500}>
                      Hold
                      <>
                        {sortedColumnData !== 'SKU Description' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU Description', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU Description' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'SKU Description', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'SKU Description' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>
                  <th>
                    <Center fw={500}>
                      Plant
                      <>
                        {sortedColumnData !== 'Plant' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Plant', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Plant' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Plant', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Plant' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>

                  <th>
                    <Center fw={500}>
                      Project Name
                      <>
                        {sortedColumnData !== 'Project Name' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Project Name', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Project Name' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Project Name', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Project Name' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>

                  <th>
                    <Center fw={500}>
                      Project Requestor
                      <>
                        {sortedColumnData !== 'Project Requestor' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Project Requestor', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Project Requestor' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Project Requestor', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Project Requestor' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>

                  <th>
                    <Center fw={500}>
                      Status
                      <>
                        {sortedColumnData !== 'Status' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Status', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Status' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Status', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Status' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>

                  <th>
                    <Center fw={500}>
                      Production Date
                      <>
                        {sortedColumnData !== 'Production Date' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Production Date', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Production Date' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Production Date', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Production Date' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>
                  <th>
                    <Center fw={500}>
                      Due Date
                      <>
                        {sortedColumnData !== 'Due Date' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Due Date', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Due Date' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Due Date', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Due Date' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>

                  <th>
                    <Center fw={500}>Comments</Center>
                  </th>
                  <th>
                    <Center fw={500}>
                      Days To Production
                      <>
                        {sortedColumnData !== 'Days To Production' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Days To Production', 'ascending');
                            }}
                          >
                            <IconArrowsSort />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Days To Production' && sortType == 'ascending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, 'Days To Production', 'descending');
                            }}
                          >
                            <IconSortAscending />
                          </ActionIcon>
                        )}
                        {sortedColumnData == 'Days To Production' && sortType == 'descending' && (
                          <ActionIcon
                            onClick={() => {
                              fetchData(1, searchPackagingBom, null, null);
                            }}
                          >
                            <IconSortDescending />
                          </ActionIcon>
                        )}
                      </>
                    </Center>
                  </th>
                </tr>
              </thead>
              <tbody>
                {projectList?.map((project, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      navigate(
                        `/material/${project?.material_id}/${project?.plant_id}`,
                        //   {
                        //   state: {
                        //     plant_id: project?.plant_id,
                        //     plant: project?.plant,
                        //     status: project?.plant_status,
                        //   },
                        // }
                      );
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>
                      {project?.existing_item === 'Yes'
                        ? project?.existing_item_number
                        : project?.sku_id}
                    </td>
                    <td>
                      {
                        // project?.fg_type === 'Virtual'
                        // ? project?.virtual_sku_description
                        // :
                        project?.sku_description
                      }
                    </td>
                    <td>
                      <Switch label="Hold" size="xs" color="yellow" checked={project?.on_hold} />
                    </td>
                    <td>{project.plant}</td>
                    <td>{project.project_name}</td>

                    <td>{project?.material_created_by_name}</td>
                    <td>{mapStatusToValue(project?.p_bom_status)}</td>
                    <td>
                      {project?.production_date
                        ? moment(project?.production_date).format('MM-DD-YYYY')
                        : ''}
                    </td>
                    <td>
                      {project?.due_date_by
                        ? formatChange(project?.due_date_by)
                        : ''}
                    </td>
                    <td>{project?.p_bom_rejected_comment}</td>
                    <td>{RowColors(DateDifference(project?.production_date))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        <Card.Footer>
          <Container>
            <Center>
              <Pagination
                total={pages + 1}
                color="yellow"
                radius="xl"
                withEdges
                page={packagingBomPagination}
                boundaries={1}
                initialPage={packagingBomPagination}
                value={packagingBomPagination}
                onChange={(e) => {
                  setPackagingBomPagination(e);
                  fetchData(e, searchPackagingBom, sortedColumnData, sortType);
                }}
              />
            </Center>
          </Container>
        </Card.Footer>
      </Card>
    </div>
  );
}
export default PackagingBOM;
