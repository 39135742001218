/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import {
  Button,
  Grid,
  Group,
  MultiSelect,
  Select,
  Space,
  Tabs,
  Text,
  TextInput,
  Table,
} from '@mantine/core';
import { IconInfoCircle, IconX } from '@tabler/icons';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { UserContext } from '../../../../context/userContext';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import bomService from '../../../../services/bomService';
import materialService from '../../../../services/materialService';
import plantService from '../../../../services/plantService';
import projectService from '../../../../services/projectService';
import BOMtable from '../../../../components/BOMtable/BOMtable';
import CustomCardLoader from '../../../../components/CustomCardLoader';
import ReferenceHelpdesk from '../../../../components/ReferenceHelpdesk/ReferenceHelpdesk';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';
import SearchableSelectLiquid from '../../../../components/SearchableSelect/SearchableSelectLiquid';
import DescriptionForm from '../../../../components/DescriptionForm/DescriptionForm';

function LiquidBOMView({ setPlantStatus }) {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // STATE DATAS
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [plantList, setPlantList] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState();
  const [materialData, setMaterialData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [plantData, setPlantData] = useState({});
  const [copiedItem, setCopiedItem] = useState({});
  const [copyEnabled, setCopyEnabled] = useState(false);

  // BOM SUB TABLES DATA
  const [brandBOMList, setBrandBOMList] = useState([]);
  const [wortBOMList, setWortBOMList] = useState([]);
  const [fermentBOMList, setFermentBOMList] = useState([]);
  const [ageBOMList, setAgeBOMList] = useState([]);
  const [blendBOMList, setBlendBOMList] = useState([]);
  const [tankerBOMList, setTankerBOMList] = useState([]);
  const [plantDataLoading, setPlantDataLoading] = useState(false);

  const { loadProductionLineList, productionLineList } = useContext(UserContext);

  // FORMIK
  const formik = useFormik({
    initialValues: {
      bomExits: '',
      bomType: '',
      brandId: '',
      brandDescription: '',
      wortId: '',
      wortDescription: '',
      fermentId: '',
      fermentDescription: '',
      ageId: '',
      ageDescription: '',
      blendId: '',
      blendDescription: '',
      tankerId: '',
      tankerDescription: '',
      production_line: [],
      similarSku: '',
      similarSkuDescription: '',
      skuNotRequired: false,
      skuNotExist: false,
      // BRAND
      similarBrand: '',
      similarBrandDescription: '',
      brandNotRequired: false,
      brandNotExist: false,
      // AGE
      similarAge: '',
      similarAgeDescription: '',
      ageNotRequired: false,
      ageNotExist: false,
      // FERMENT
      similarFerment: '',
      similarFermentDescription: '',
      fermentNotRequired: false,
      fermentNotExist: false,
      // WORT
      similarWort: '',
      similarWortDescription: '',
      wortNotRequired: false,
      wortNotExist: false,
      // BLEND
      similarBlend: '',
      similarBlendDescription: '',
      blendNotRequired: false,
      blendNotExist: false,
      // TANKER
      similarTanker: '',
      similarTankerDescription: '',
      tankerNotRequired: false,
      tankerNotExist: false,
    },

    validationSchema: Yup.object({
      bomExits: Yup.string().required('BOM Exists is required').nullable(),
      bomType: Yup.string().required('BOM Type is required').nullable(),
      brandId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Brand is required').nullable(),
      }),
      brandDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Brand Description is required').nullable(),
      }),
      wortId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Wort is required').nullable(),
      }),
      wortDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Wort Description is required').nullable(),
      }),
      fermentId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Ferment is required').nullable(),
      }),
      fermentDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Ferment Description is required').nullable(),
      }),
      ageId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Age is required').nullable(),
      }),
      ageDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Liquid',
        then: Yup.string().required('Age Description is required').nullable(),
      }),
      blendId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Blend',
        then: Yup.string().required('Blend is required').nullable(),
      }),
      blendDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Blend',
        then: Yup.string().required('Blend Description is required').nullable(),
      }),
      tankerId: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Tanker',
        then: Yup.string().required('Tanker is required').nullable(),
      }),
      tankerDescription: Yup.string().when(['bomExits', 'bomType'], {
        is: (v1, v2) => v1 === 'Yes' && v2 === 'Tanker',
        then: Yup.string().required('Tanker Description is required').nullable(),
      }),
      // similarSku: Yup.string().when(['bomExits', 'skuNotRequired', 'skuNotExist'], {
      //   is: (bomExits, skuNotRequired, skuNotExist) =>
      //     bomExits === 'No' && skuNotRequired === false && skuNotExist === false,
      //   then: Yup.string().required('Similar SKU is required').nullable(),
      //   otherwise: Yup.string().notRequired(),
      // }),
      // similarSkuDescription: Yup.string().when(['bomExits', 'skuNotRequired', 'skuNotExist'], {
      //   is: (bomExits, skuNotRequired, skuNotExist) =>
      //     bomExits === 'No' && skuNotRequired === false && skuNotExist === false,
      //   then: Yup.string().required('Similar SKU Description is required').nullable(),
      //   otherwise: Yup.string().notRequired(),
      // }),
      // skuNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarSku', {
      //     is: (similarSku) => similarSku === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // skuNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarSku', {
      //     is: (similarSku) => similarSku === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // BRAND
      // similarBrand: Yup.string().when(
      //   ['bomExits', 'bomType', 'brandNotRequired', 'brandNotExist'],
      //   {
      //     is: (bomExits, bomType, brandNotRequired, brandNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       brandNotRequired === false &&
      //       brandNotExist === false,
      //     then: Yup.string().required('Similar Brand is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // similarBrandDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'brandNotRequired', 'brandNotExist'],
      //   {
      //     is: (bomExits, bomType, brandNotRequired, brandNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       brandNotRequired === false &&
      //       brandNotExist === false,
      //     then: Yup.string().required('Similar Brand Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // brandNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarBrand', {
      //     is: (similarBrand) => similarBrand === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // brandNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarBrand', {
      //     is: (similarBrand) => similarBrand === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),

      // // AGE
      // similarAge: Yup.string().when(['bomExits', 'bomType', 'ageNotRequired', 'ageNotExist'], {
      //   is: (bomExits, bomType, ageNotRequired, ageNotExist) =>
      //     bomExits === 'No' &&
      //     bomType === 'Liquid' &&
      //     ageNotRequired === false &&
      //     ageNotExist === false,
      //   then: Yup.string().required('Similar Age is required').nullable(),
      //   otherwise: Yup.string().notRequired(),
      // }),
      // similarAgeDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'ageNotRequired', 'ageNotExist'],
      //   {
      //     is: (bomExits, bomType, ageNotRequired, ageNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       ageNotRequired === false &&
      //       ageNotExist === false,
      //     then: Yup.string().required('Similar Age Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // ageNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarAge', {
      //     is: (similarAge) => similarAge === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // ageNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarAge', {
      //     is: (similarAge) => similarAge === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),

      // // FERMENT
      // similarFerment: Yup.string().when(
      //   ['bomExits', 'bomType', 'fermentNotRequired', 'fermentNotExist'],
      //   {
      //     is: (bomExits, bomType, fermentNotRequired, fermentNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       fermentNotRequired === false &&
      //       fermentNotExist === false,
      //     then: Yup.string().required('Similar Ferment is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // similarFermentDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'fermentNotRequired', 'fermentNotExist'],
      //   {
      //     is: (bomExits, bomType, fermentNotRequired, fermentNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       fermentNotRequired === false &&
      //       fermentNotExist === false,
      //     then: Yup.string().required('Similar Ferment Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // fermentNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarFerment', {
      //     is: (similarFerment) => similarFerment === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // fermentNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarFerment', {
      //     is: (similarFerment) => similarFerment === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),

      // // WORT
      // similarWort: Yup.string().when(['bomExits', 'bomType', 'wortNotRequired', 'wortNotExist'], {
      //   is: (bomExits, bomType, wortNotRequired, wortNotExist) =>
      //     bomExits === 'No' &&
      //     bomType === 'Liquid' &&
      //     wortNotRequired === false &&
      //     wortNotExist === false,
      //   then: Yup.string().required('Similar Wort is required').nullable(),
      //   otherwise: Yup.string().notRequired(),
      // }),
      // similarWortDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'wortNotRequired', 'wortNotExist'],
      //   {
      //     is: (bomExits, bomType, wortNotRequired, wortNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Liquid' &&
      //       wortNotRequired === false &&
      //       wortNotExist === false,
      //     then: Yup.string().required('Similar Wort Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // wortNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarWort', {
      //     is: (similarWort) => similarWort === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // wortNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarWort', {
      //     is: (similarWort) => similarWort === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),

      // // BLEND
      // similarBlend: Yup.string().when(
      //   ['bomExits', 'bomType', 'blendNotRequired', 'blendNotExist'],
      //   {
      //     is: (bomExits, bomType, blendNotRequired, blendNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Blend' &&
      //       blendNotRequired === false &&
      //       blendNotExist === false,
      //     then: Yup.string().required('Similar Blend is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // similarBlendDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'blendNotRequired', 'blendNotExist'],
      //   {
      //     is: (bomExits, bomType, blendNotRequired, blendNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Blend' &&
      //       blendNotRequired === false &&
      //       blendNotExist === false,
      //     then: Yup.string().required('Similar Blend Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // blendNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarBlend', {
      //     is: (similarBlend) => similarBlend === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // blendNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarBlend', {
      //     is: (similarBlend) => similarBlend === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),

      // // TANKER
      // similarTanker: Yup.string().when(
      //   ['bomExits', 'bomType', 'tankerNotRequired', 'tankerNotExist'],
      //   {
      //     is: (bomExits, bomType, tankerNotRequired, tankerNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Tanker' &&
      //       tankerNotRequired === false &&
      //       tankerNotExist === false,
      //     then: Yup.string().required('Similar Tanker is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // similarTankerDescription: Yup.string().when(
      //   ['bomExits', 'bomType', 'tankerNotRequired', 'tankerNotExist'],
      //   {
      //     is: (bomExits, bomType, tankerNotRequired, tankerNotExist) =>
      //       bomExits === 'No' &&
      //       bomType === 'Tanker' &&
      //       tankerNotRequired === false &&
      //       tankerNotExist === false,
      //     then: Yup.string().required('Similar Tanker Description is required').nullable(),
      //     otherwise: Yup.string().notRequired(),
      //   },
      // ),
      // tankerNotRequired: Yup.lazy(() =>
      //   Yup.boolean().when('similarTanker', {
      //     is: (similarTanker) => similarTanker === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
      // tankerNotExist: Yup.lazy(() =>
      //   Yup.boolean().when('similarTanker', {
      //     is: (similarTanker) => similarTanker === '',
      //     then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
      //     otherwise: Yup.boolean().notRequired(),
      //   }),
      // ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      setSubmitLoading(true);
      const body = {
        plant_id: selectedPlant,
        existing_liquid_bom: values?.bomExits === 'Yes' ? true : false,
        liquid_bom_type: values?.bomType ? values?.bomType : null,
        brand_id: values?.brandId ? values?.brandId : null,
        brand_description: values?.brandDescription ? values?.brandDescription : null,
        age_id: values?.ageId ? values?.ageId : null,
        age_description: values?.ageDescription ? values?.ageDescription : null,
        wort_id: values?.wortId ? values?.wortId : null,
        wort_description: values?.wortDescription ? values?.wortDescription : null,
        ferment_id: values?.fermentId ? values?.fermentId : null,
        ferment_description: values?.fermentDescription ? values?.fermentDescription : null,
        blend_id: values?.blendId ? values?.blendId : null,
        blend_description: values?.blendDescription ? values?.blendDescription : null,
        tanker_id: values?.tankerId ? values?.tankerId : null,
        tanker_description: values?.tankerDescription ? values?.tankerDescription : null,
        productionLineList: values?.production_line,

        // BRAND
        similarBrand: values.similarBrand,
        similarBrandDescription: values.similarBrandDescription,
        brandNotRequired: values.brandNotRequired,
        brandNotExist: values.brandNotExist,

        // AGE
        similarAge: values.similarAge,
        similarAgeDescription: values.similarAgeDescription,
        ageNotRequired: values.ageNotRequired,
        ageNotExist: values.ageNotExist,
        // FERMENT
        similarFerment: values.similarFerment,
        similarFermentDescription: values.similarFermentDescription,
        fermentNotRequired: values.fermentNotRequired,
        fermentNotExist: values.fermentNotExist,

        // WORT
        similarWort: values.similarWort,
        similarWortDescription: values.similarWortDescription,
        wortNotRequired: values.wortNotRequired,
        wortNotExist: values.wortNotExist,

        // BLEND
        similarBlend: values.similarBlend,
        similarBlendDescription: values.similarBlendDescription,
        blendNotRequired: values.blendNotRequired,
        blendNotExist: values.blendNotExist,

        // TANKER
        similarTanker: values.similarTanker,
        similarTankerDescription: values.similarTankerDescription,
        tankerNotRequired: values.tankerNotRequired,
        tankerNotExist: values.tankerNotExist,

        // similar_sku_id: values?.similarSku,
        // similar_sku_description: values?.similarSkuDescription,
        // no_similar_sku_required: values?.skuNotRequired,
        // no_similar_sku_exists: values?.skuNotExist,
      };

      //console.log(body);

      bomService
        .saveLiquidBom(body)
        .then((res) => {
          //console.log(res);
          setSubmitLoading(false);
          if (res?.status) {
            toast.success(res?.message);
            navigate('/');
          } else {
            toast.error(res?.message || 'Something went wrong');
          }
        })
        .catch((err) => {
          toast.error(err?.message || 'Something went wrong');
          setSubmitLoading(false);
        });
    },
  });

  // FORMIK VALIDATION SCHEMA FOR SUB TABLES

  // BRAND BOM FORMIK & FUNCTIONS
  const brandBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveLiquidSubtype({
          type: 1,
          plant_id: selectedPlant,
          component: brandBOM_formik.values.component,
          quantity: brandBOM_formik.values.quantity,
          scrap: brandBOM_formik.values.scrap,
          uom: brandBOM_formik.values.uom,
          component_description: brandBOM_formik.values.component_description,
          standard_cost: brandBOM_formik.values.standard_cost,
        })
        .then((res) => {
          toast.success('Brand BOM saved successfully');
          fetchTabData();

          brandBOM_formik.resetForm();
        });
    },
  });
  const updateBrandBom = (data) => {
    bomService
      .updateLiquidSubtype(data)
      .then((res) => {
        toast.success('Brand BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteBrandBom = (data) => {
    bomService
      .deleteLiquidSubtype(data)
      .then((res) => {
        toast.success('Brand BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // AFE BOM FORMIK & FUNCTIONS
  const ageBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveLiquidSubtype({
          type: 2,
          plant_id: selectedPlant,
          component: ageBOM_formik.values.component,
          quantity: ageBOM_formik.values.quantity,
          scrap: ageBOM_formik.values.scrap,
          uom: ageBOM_formik.values.uom,
          component_description: ageBOM_formik.values.component_description,
          standard_cost: ageBOM_formik.values.standard_cost,
        })
        .then((res) => {
          toast.success('Age BOM saved successfully');
          fetchTabData();

          ageBOM_formik.resetForm();
        });
    },
  });
  const updateAgeBom = (data) => {
    bomService
      .updateLiquidSubtype(data)
      .then((res) => {
        toast.success('Age BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteAgeBom = (data) => {
    bomService
      .deleteLiquidSubtype(data)
      .then((res) => {
        toast.success('Age BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // FERMENTATION BOM FORMIK & FUNCTIONS
  const fermentBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveLiquidSubtype({
          type: 4,
          plant_id: selectedPlant,
          component: fermentBOM_formik.values.component,
          quantity: fermentBOM_formik.values.quantity,
          scrap: fermentBOM_formik.values.scrap,
          uom: fermentBOM_formik.values.uom,
          component_description: fermentBOM_formik.values.component_description,
          standard_cost: fermentBOM_formik.values.standard_cost,
        })
        .then((res) => {
          toast.success('Ferment BOM saved successfully');
          fetchTabData();

          fermentBOM_formik.resetForm();
        });
    },
  });
  const updateFermentBom = (data) => {
    bomService
      .updateLiquidSubtype(data)
      .then((res) => {
        toast.success('Ferment BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteFermentBom = (data) => {
    bomService
      .deleteLiquidSubtype(data)
      .then((res) => {
        toast.success('Ferment BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // WORT BOM FORMIK & FUNCTIONS
  const wortBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveLiquidSubtype({
          type: 3,
          plant_id: selectedPlant,
          component: wortBOM_formik.values.component,
          quantity: wortBOM_formik.values.quantity,
          scrap: wortBOM_formik.values.scrap,
          uom: wortBOM_formik.values.uom,
          component_description: wortBOM_formik.values.component_description,
          standard_cost: wortBOM_formik.values.standard_cost,
        })
        .then((res) => {
          toast.success('Wort BOM saved successfully');
          fetchTabData();

          wortBOM_formik.resetForm();
        });
    },
  });
  const updateWortBom = (data) => {
    bomService
      .updateLiquidSubtype(data)
      .then((res) => {
        toast.success('Wort BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteWortBom = (data) => {
    bomService
      .deleteLiquidSubtype(data)
      .then((res) => {
        toast.success('Wort BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // BLEND BOM FORMIK & FUNCTIONS
  const blendBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveBlendBom({
          plant_id: selectedPlant,
          component: blendBOM_formik.values.component,
          quantity: blendBOM_formik.values.quantity,
          scrap: blendBOM_formik.values.scrap,
          uom: blendBOM_formik.values.uom,
          component_description: blendBOM_formik.values.component_description,
          standard_cost: blendBOM_formik.values.standard_cost,
        })
        .then((res) => {
          //console.log('Blend BOM', res.data);
          toast.success('Blend BOM saved successfully');
          fetchTabData();
          blendBOM_formik.resetForm();
        });
    },
  });
  const updateBlendBom = (data) => {
    bomService
      .updateBlendBom(data)
      .then((res) => {
        toast.success('Blend BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteBlendBom = (data) => {
    bomService
      .deleteBlendBom(data)
      .then((res) => {
        toast.success('Blend BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // TANKER BOM FORMIK & FUNCTIONS
  const tankerBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .saveTankerBom({
          plant_id: selectedPlant,
          component: tankerBOM_formik.values.component,
          quantity: tankerBOM_formik.values.quantity,
          scrap: tankerBOM_formik.values.scrap,
          uom: tankerBOM_formik.values.uom,
          component_description: tankerBOM_formik.values.component_description,
          standard_cost: tankerBOM_formik.values.standard_cost,
        })
        .then((res) => {
          toast.success('Tanker BOM saved successfully');
          fetchTabData();
          tankerBOM_formik.resetForm();
        });
    },
  });
  const updateTankerBom = (data) => {
    bomService
      .updateTankerBom(data)
      .then((res) => {
        toast.success('Tanker BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };
  const deleteTankerBom = (data) => {
    bomService
      .deleteTankerBom(data)
      .then((res) => {
        toast.success('Tanker BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  // FUNCTIONS
  const fetchInitialData = () => {
    const getLiquidBomType = (type) => {
      switch (type) {
        case 1:
          return 'Liquid';
        case 2:
          return 'Blend';
        case 3:
          return 'Tanker';
        default:
          return '';
      }
    };
    const getLiquidExist = (type) => {
      switch (type) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return '';
      }
    };
    bomService
      .getLiquidBOM({
        plant_id: selectedPlant,
      })
      .then((res) => {
        setBlendBOMList(res?.blendBomList);
        setTankerBOMList(res?.tankerBomList);
        setBrandBOMList(res?.brandBomList);
        setAgeBOMList(res?.ageBomList);
        setWortBOMList(res?.wortBomList);
        setFermentBOMList(res?.fermentBomList);

        formik.setFieldValue('bomExits', getLiquidExist(res?.liquidBom?.existing_liquid_bom));
        formik.setFieldValue('bomType', getLiquidBomType(res?.liquidBom?.liquid_bom_type));
        formik.setFieldValue('brandId', res?.liquidBom?.brand_id ? res?.liquidBom?.brand_id : '');
        formik.setFieldValue(
          'brandDescription',
          res?.liquidBom?.brand_description ? res?.liquidBom?.brand_description : '',
        );
        formik.setFieldValue('wortId', res?.liquidBom?.wort_id ? res?.liquidBom?.wort_id : '');
        formik.setFieldValue(
          'wortDescription',
          res?.liquidBom?.wort_description ? res?.liquidBom?.wort_description : '',
        );
        formik.setFieldValue(
          'fermentId',
          res?.liquidBom?.ferment_id ? res?.liquidBom?.ferment_id : '',
        );
        formik.setFieldValue(
          'fermentDescription',
          res?.liquidBom?.ferment_description ? res?.liquidBom?.ferment_description : '',
        );
        formik.setFieldValue('ageId', res?.liquidBom?.age_id ? res?.liquidBom?.age_id : '');
        formik.setFieldValue(
          'ageDescription',
          res?.liquidBom?.age_description ? res?.liquidBom?.age_description : '',
        );
        formik.setFieldValue('blendId', res?.liquidBom?.blend_id ? res?.liquidBom?.blend_id : '');
        formik.setFieldValue(
          'blendDescription',
          res?.liquidBom?.blend_description ? res?.liquidBom?.blend_description : '',
        );
        formik.setFieldValue(
          'tankerId',
          res?.liquidBom?.tanker_id ? res?.liquidBom?.tanker_id : '',
        );
        formik.setFieldValue(
          'tankerDescription',
          res?.liquidBom?.tanker_description ? res?.liquidBom?.tanker_description : '',
        );
        formik.setFieldValue(
          'production_line',
          res?.production_line?.length > 0
            ? res?.production_line?.map((item) => item?.production_line)
            : [],
        );

        formik.setFieldValue(
          'similarSku',
          Boolean(res?.liquidBom?.similar_sku_id) ? res?.liquidBom?.similar_sku_id : '',
        );
        formik.setFieldValue(
          'similarSkuDescription',
          Boolean(res?.liquidBom?.similar_sku_description)
            ? res?.liquidBom?.similar_sku_description
            : '',
        );
        formik.setFieldValue(
          'skuNotExist',
          Boolean(res?.liquidBom?.no_similar_sku_exists) ? true : false,
        );
        formik.setFieldValue(
          'skuNotRequired',
          Boolean(res?.liquidBom?.no_similar_sku_required) ? true : false,
        );

        // BRAND
        formik.setFieldValue(
          'similarBrand',
          Boolean(res?.liquidBom?.similar_brand_id) ? res?.liquidBom?.similar_brand_id : '',
        );
        formik.setFieldValue(
          'similarBrandDescription',
          Boolean(res?.liquidBom?.similar_brand_description)
            ? res?.liquidBom?.similar_brand_description
            : '',
        );
        formik.setFieldValue(
          'brandNotExist',
          Boolean(res?.liquidBom?.no_similar_brand_exist) ? true : false,
        );
        formik.setFieldValue(
          'brandNotRequired',
          Boolean(res?.liquidBom?.no_similar_brand_required) ? true : false,
        );

        // AGE
        formik.setFieldValue(
          'similarAge',
          Boolean(res?.liquidBom?.similar_age_id) ? res?.liquidBom?.similar_age_id : '',
        );
        formik.setFieldValue(
          'similarAgeDescription',
          Boolean(res?.liquidBom?.similar_age_description)
            ? res?.liquidBom?.similar_age_description
            : '',
        );
        formik.setFieldValue(
          'ageNotExist',
          Boolean(res?.liquidBom?.no_similar_age_exist) ? true : false,
        );
        formik.setFieldValue(
          'ageNotRequired',
          Boolean(res?.liquidBom?.no_similar_age_required) ? true : false,
        );

        // FERMENT
        formik.setFieldValue(
          'similarFerment',
          Boolean(res?.liquidBom?.similar_ferment_id) ? res?.liquidBom?.similar_ferment_id : '',
        );
        formik.setFieldValue(
          'similarFermentDescription',
          Boolean(res?.liquidBom?.similar_ferment_description)
            ? res?.liquidBom?.similar_ferment_description
            : '',
        );
        formik.setFieldValue(
          'fermentNotExist',
          Boolean(res?.liquidBom?.no_similar_ferment_exist) ? true : false,
        );
        formik.setFieldValue(
          'fermentNotRequired',
          Boolean(res?.liquidBom?.no_similar_ferment_required) ? true : false,
        );

        // WORT
        formik.setFieldValue(
          'similarWort',
          Boolean(res?.liquidBom?.similar_wort_id) ? res?.liquidBom?.similar_wort_id : '',
        );
        formik.setFieldValue(
          'similarWortDescription',
          Boolean(res?.liquidBom?.similar_wort_description)
            ? res?.liquidBom?.similar_wort_description
            : '',
        );
        formik.setFieldValue(
          'wortNotExist',
          Boolean(res?.liquidBom?.no_similar_wort_exist) ? true : false,
        );
        formik.setFieldValue(
          'wortNotRequired',
          Boolean(res?.liquidBom?.no_similar_wort_required) ? true : false,
        );

        // BLEND
        formik.setFieldValue(
          'similarBlend',
          Boolean(res?.liquidBom?.similar_blend_id) ? res?.liquidBom?.similar_blend_id : '',
        );
        formik.setFieldValue(
          'similarBlendDescription',
          Boolean(res?.liquidBom?.similar_blend_description)
            ? res?.liquidBom?.similar_blend_description
            : '',
        );
        formik.setFieldValue(
          'blendNotExist',
          Boolean(res?.liquidBom?.no_similar_blend_exist) ? true : false,
        );
        formik.setFieldValue(
          'blendNotRequired',
          Boolean(res?.liquidBom?.no_similar_blend_required) ? true : false,
        );

        // TANKER
        formik.setFieldValue(
          'similarTanker',
          Boolean(res?.liquidBom?.similar_tanker_id) ? res?.liquidBom?.similar_tanker_id : '',
        );
        formik.setFieldValue(
          'similarTankerDescription',
          Boolean(res?.liquidBom?.similar_tanker_description)
            ? res?.liquidBom?.similar_tanker_description
            : '',
        );
        formik.setFieldValue(
          'tankerNotExist',
          Boolean(res?.liquidBom?.no_similar_tanker_exist) ? true : false,
        );
        formik.setFieldValue(
          'tankerNotRequired',
          Boolean(res?.liquidBom?.no_similar_tanker_required) ? true : false,
        );

        setPageLoading(false);

        // formik.setFieldValue({
        //   bomExits: res?.liquidBom?.existing_liquid_bom ? 'Yes' : 'No',
        //   bomType: getLiquidBomType(res?.liquidBom?.liquid_bom_type),
        //   brandId: res?.liquidBom?.brand_id,
        //   brandDescription: res?.liquidBom?.brand_description,
        //   wortId: res?.liquidBom?.wort_id,
        //   wortDescription: res?.liquidBom?.wort_description,
        //   fermentId: res?.liquidBom?.ferment_id,
        //   fermentDescription: res?.liquidBom?.ferment_description,
        //   ageId: res?.liquidBom?.age_id,
        //   ageDescription: res?.liquidBom?.age_description,
        //   blendId: res?.liquidBom?.blend_id,
        //   blendDescription: res?.liquidBom?.blend_description,
        //   tankerId: res?.liquidBom?.tanker_id,
        //   tankerDescription: res?.liquidBom?.tanker_description,
        // });
      });
  };

  const fetchTabData = () => {
    bomService
      .getLiquidBOM({
        plant_id: selectedPlant,
      })
      .then((res) => {
        setBlendBOMList(res?.blendBomList);
        setTankerBOMList(res?.tankerBomList);
        setBrandBOMList(res?.brandBomList);
        setAgeBOMList(res?.ageBomList);
        setWortBOMList(res?.wortBomList);
        setFermentBOMList(res?.fermentBomList);
      });
  };

  // USE EFFECTS

  useEffect(() => {
    if (params?.plant_id) {
      setSelectedPlant(params?.plant_id);
    }
    if (location?.state?.plant) {
      loadProductionLineList(location?.state?.plant);
      fetchTabData();
    }
  }, [params?.plant_id]);

  useEffect(() => {
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          setMaterialData(res?.data?.material);
          projectService.getProjectById(res?.data?.material?.project_id).then((res) => {
            if (res?.data) {
              setProjectData(res?.data?.project);
            }
          });
        }
      });
    }

    if (params?.id) {
      materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
        if (res?.data) {
          //console.log('PLANT LENGTH', res?.data);
          setPlantList(res?.data?.plants);
          if (!params?.plant_id) {
            setSelectedPlant(res?.data?.plants[0]?.id);
          }
          if (!location?.state?.plant) {
            loadProductionLineList(res?.data?.plants[0]?.plant);
          }
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    setPageLoading(true);
    setPlantDataLoading(true);
    if (selectedPlant) {
      plantService.getPlantById(selectedPlant).then((res) => {
        if (res?.data) {
          //console.log('PLANT', res?.data?.data);
          setPlantData(res?.data?.data);
          setPageLoading(false);
          setPlantStatus(res?.data?.data?.status);
          setPlantDataLoading(false);
        }
      });

      fetchInitialData();
    }
  }, [selectedPlant]);

  // USE EFFECTS
  useEffect(() => {
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  // refresh formik

  const refreshFormikData = () => {
    // BRAND
    formik.setFieldValue('similarBrand', '');
    formik.setFieldValue('similarBrandDescription', '');
    formik.setFieldValue('brandNotExist', false);
    formik.setFieldValue('brandNotRequired', false);

    // AGE
    formik.setFieldValue('similarAge', '');
    formik.setFieldValue('similarAgeDescription', '');
    formik.setFieldValue('ageNotExist', false);
    formik.setFieldValue('ageNotRequired', false);

    // FERMENT
    formik.setFieldValue('similarFerment', '');
    formik.setFieldValue('similarFermentDescription', '');
    formik.setFieldValue('fermentNotExist', false);
    formik.setFieldValue('fermentNotRequired', false);

    // WORT
    formik.setFieldValue('similarWort', '');
    formik.setFieldValue('similarWortDescription', '');
    formik.setFieldValue('wortNotExist', false);
    formik.setFieldValue('wortNotRequired', false);

    // BLEND
    formik.setFieldValue('similarBlend', '');
    formik.setFieldValue('similarBlendDescription', '');
    formik.setFieldValue('blendNotExist', false);
    formik.setFieldValue('blendNotRequired', false);

    // TANKER
    formik.setFieldValue('similarTanker', '');
    formik.setFieldValue('similarTankerDescription', '');
    formik.setFieldValue('tankerNotExist', false);
    formik.setFieldValue('tankerNotRequired', false);
  };

  return (
    <>
      <Row>
        {/* LEFT CARD SHOWING PLANT LISTS AND PLANT SELECTION */}
        <Col xs={12} sm={2}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              >{`Plant List`}</Text>
              <Space h="sm" />
            </Card.Body>

            <Card.Body>
              <Group position="center">
                {plantList?.length > 0 ? (
                  <div className="table-responsive-sm">
                    <Table hover striped highlightOnHover withBorder>
                      <tbody>
                        {plantList?.map((plant, index) => (
                          <tr
                            style={{
                              cursor: 'pointer',
                              // backgroundColor: plant?.id === selectedPlant ? '#fab005' : '',
                              // color: plant?.id === selectedPlant ? '#fff' : '',
                            }}
                            onClick={() => {
                              loadProductionLineList(plant?.plant);
                              setSelectedPlant(plant?.id);
                              navigate(`/material/${params?.id}/${plant?.id}`);
                            }}
                          >
                            <td
                              className={
                                plant?.id === selectedPlant ? 'table-row-selected' : 'table-color'
                              }
                            >
                              {plant?.plant}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <CustomCardLoader />
                )}
              </Group>
            </Card.Body>
          </Card>
        </Col>

        {/* RIGHT CARD PLANT DETAILS AND LIQUID BOM FORMS */}

        {plantData?.l_bom_status == 3 ? (
          <Col xs={12} sm={10}>
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {'Liquid BOM'}
                  </Text>
                </Group>

                {plantDataLoading ? (
                  <CustomCardLoader />
                ) : (
                  <Card.Body>
                    <Grid>
                      <Grid.Col span={4}>
                        <Select
                          label="Is Liquid BOM Required?"
                          placeholder="Select"
                          radius={'lg'}
                          data={[
                            {
                              label: 'Yes',
                              value: 'Yes',
                            },
                            {
                              label: 'Not Required',
                              value: 'Not Required',
                            },
                          ]}
                          value={'Not Required'}
                          disabled
                        />
                      </Grid.Col>
                    </Grid>
                  </Card.Body>
                )}
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col xs={12} sm={10}>
            {!plantList?.length > 0 ? (
              <CustomCardLoader />
            ) : (
              <Card radius="xl">
                <Card.Body>
                  <Group position="apart">
                    <Text
                      size="xl"
                      weight={700}
                      color="yellow"
                      align="left"
                      style={{ fontSize: '18px' }}
                    >
                      Liquid BOM
                    </Text>
                  </Group>
                </Card.Body>

                {/* FORM FIELDS */}
                {plantDataLoading ? (
                  <CustomCardLoader />
                ) : (
                  <>
                    <Card.Body align="left">
                      <Grid
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="Project Name"
                            label="Project Name"
                            radius={'lg'}
                            value={projectData.project_name}
                            disabled
                          />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="Project Type"
                            label="Project Type"
                            radius={'lg'}
                            value={projectData.project_type}
                            disabled
                          />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="SKU ID"
                            label="SKU ID"
                            radius={'lg'}
                            value={materialData.sku_id}
                            disabled
                          />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="SKU Description"
                            label="SKU Description"
                            radius={'lg'}
                            value={materialData.sku_description}
                            disabled
                          />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="Plant"
                            label="Plant"
                            radius={'lg'}
                            value={plantData.plant}
                            disabled
                          />
                        </Grid.Col>

                        {materialData?.existing_item === 'No' && (
                          <Grid.Col span={4}>
                            <TextInput
                              placeholder="FG Type"
                              label="FG Type"
                              radius={'lg'}
                              value={materialData.fg_type}
                              disabled
                            />
                          </Grid.Col>
                        )}

                        <Grid.Col span={4}>
                          <TextInput
                            placeholder="Material Type"
                            label="Material Type"
                            radius={'lg'}
                            value={materialData.material_type}
                            disabled
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid
                        style={{
                          alignItems: 'end',
                        }}
                      >
                        <Grid.Col span={4}>
                          <Select
                            label="Does the BOM already exist?"
                            placeholder="Select"
                            radius={'lg'}
                            data={[
                              {
                                label: 'Yes',
                                value: 'Yes',
                              },
                              {
                                label: 'No',
                                value: 'No',
                              },
                            ]}
                            value={formik.values.bomExits}
                            onChange={(value) => {
                              refreshFormikData();
                              formik.setFieldValue('bomExits', value);
                            }}
                            // disabled={
                            //   materialData?.material_status != 4 || plantData?.l_bom_status == 6
                            // }
                          />
                        </Grid.Col>
                      </Grid>

                      {/* IF BOM EXISTS */}
                      <Space h="sm" />
                      {formik.values.bomExits === 'Yes' && (
                        <BOMexists
                          formik={formik}
                          loading={submitLoading}
                          plantData={plantData}
                          selectedPlant={selectedPlant}
                          materialData={materialData}
                          refreshFormikData={refreshFormikData}
                        />
                      )}

                      {/* IF BOM DOES NOT EXISTS */}
                      <Space h="sm" />
                      {formik.values.bomExits === 'No' && (
                        <BOMnotexists
                          formik={formik}
                          projectData={projectData}
                          materialData={materialData}
                          plantData={plantData}
                          copiedItem={copiedItem}
                          setCopiedItem={setCopiedItem}
                          copyEnabled={copyEnabled}
                          setCopyEnabled={setCopyEnabled}
                          loading={submitLoading}
                          selectedPlant={selectedPlant}
                          // BRAND BOM
                          brandBOMList={brandBOMList}
                          setBrandBOMList={setBrandBOMList}
                          brandBOM_formik={brandBOM_formik}
                          updateBrandBom={updateBrandBom}
                          deleteBrandBom={deleteBrandBom}
                          // AGE BOM
                          ageBOMList={ageBOMList}
                          setAgeBOMList={setAgeBOMList}
                          ageBOM_formik={ageBOM_formik}
                          updateAgeBom={updateAgeBom}
                          deleteAgeBom={deleteAgeBom}
                          // FERMENT BOM
                          fermentBOMList={fermentBOMList}
                          setFermentBOMList={setFermentBOMList}
                          fermentBOM_formik={fermentBOM_formik}
                          updateFermentBom={updateFermentBom}
                          deleteFermentBom={deleteFermentBom}
                          // WORT BOM
                          wortBOMList={wortBOMList}
                          setWortBOMList={setWortBOMList}
                          wortBOM_formik={wortBOM_formik}
                          updateWortBom={updateWortBom}
                          deleteWortBom={deleteWortBom}
                          // BLEND BOM
                          blendBOMList={blendBOMList}
                          setBlendBOMList={setBlendBOMList}
                          blendBOM_formik={blendBOM_formik}
                          updateBlendBom={updateBlendBom}
                          deleteBlendBom={deleteBlendBom}
                          // TANKER BOM
                          tankerBOMList={tankerBOMList}
                          setTankerBOMList={setTankerBOMList}
                          tankerBOM_formik={tankerBOM_formik}
                          updateTankerBom={updateTankerBom}
                          deleteTankerBom={deleteTankerBom}
                          // REFRESH
                          refreshFormikData={refreshFormikData}
                        />
                      )}
                    </Card.Body>
                  </>
                )}
              </Card>
            )}
          </Col>
        )}
      </Row>
    </>
  );
}

export default LiquidBOMView;

function BOMexists({ formik, loading, plantData, selectedPlant, materialData, refreshFormikData }) {
  let params = useParams();
  const navigate = useNavigate();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const rejectMaterial = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        toast.success('Material Deleted ');

        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const rejectLiquidBOM = (comment) => {
    bomService
      ?.rejectLiquidBOM({
        id: selectedPlant,
        comment,
        status: 6,
        l_bom_status: 8,
        material_id: params?.id,
      })
      .then((res) => {
        toast.success('Liquid BOM Rejected ');
        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const [userState, userDispatch] = useContext(ActiveUserContext);

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <Select
            label="BOM Type"
            placeholder="Select BOM Type"
            radius={'lg'}
            data={[
              {
                label: 'Liquid',
                value: 'Liquid',
              },
              {
                label: 'Blend',
                value: 'Blend',
              },
              {
                label: 'Tanker',
                value: 'Tanker',
              },
            ]}
            value={formik.values.bomType}
            error={formik.errors.bomType}
            onChange={(value) => {
              refreshFormikData();
              formik.setFieldValue('bomType', value);
            }}
            // disabled={plantData?.l_bom_status == 6}
          />
        </Grid.Col>

        {/* RENDERING FOR LIQUID */}

        {formik.values.bomType === 'Liquid' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Brand Id'}
                  value={formik.values.brandId}
                  error={formik.errors.brandId}
                  setId={(e) => formik.setFieldValue('brandId', e)}
                  setDescription={(e) => formik.setFieldValue('brandDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Brand Description"
                  placeholder="Brand Description"
                  radius={'lg'}
                  value={formik.values.brandDescription}
                  disabled
                  error={formik.errors.brandDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Age Id'}
                  value={formik.values.ageId}
                  error={formik.errors.ageId}
                  setId={(e) => formik.setFieldValue('ageId', e)}
                  setDescription={(e) => formik.setFieldValue('ageDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Age Description"
                  placeholder="Age Description"
                  radius={'lg'}
                  value={formik.values.ageDescription}
                  disabled
                  error={formik.errors.ageDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Ferment Id'}
                  value={formik.values.fermentId}
                  error={formik.errors.fermentId}
                  setId={(e) => formik.setFieldValue('fermentId', e)}
                  setDescription={(e) => formik.setFieldValue('fermentDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Ferment Description"
                  placeholder="Ferment Description"
                  radius={'lg'}
                  value={formik.values.fermentDescription}
                  disabled
                  error={formik.errors.fermentDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Wort Id'}
                  value={formik.values.wortId}
                  error={formik.errors.wortId}
                  setId={(e) => formik.setFieldValue('wortId', e)}
                  setDescription={(e) => formik.setFieldValue('wortDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Wort Description"
                  placeholder="Wort Description"
                  radius={'lg'}
                  value={formik.values.wortDescription}
                  disabled
                  error={formik.errors.wortDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}

        {/* RENDERING FOR BLEND */}

        {formik.values.bomType === 'Blend' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Blend Id'}
                  value={formik.values.blendId}
                  error={formik.errors.blendId}
                  setId={(e) => formik.setFieldValue('blendId', e)}
                  setDescription={(e) => formik.setFieldValue('blendDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Blend Description"
                  placeholder="Blend Description"
                  radius={'lg'}
                  value={formik.values.blendDescription}
                  disabled
                  error={formik.errors.blendDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}

        {/* RENDERING FOR TANKER */}

        {formik.values.bomType === 'Tanker' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelectLiquid
                  label={'Tanker Id'}
                  value={formik.values.tankerId}
                  error={formik.errors.tankerId}
                  setId={(e) => formik.setFieldValue('tankerId', e)}
                  setDescription={(e) => formik.setFieldValue('tankerDescription', e)}
                  disabled={plantData?.l_bom_status == 2}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Tanker Description"
                  placeholder="Tanker Description"
                  radius={'lg'}
                  value={formik.values.tankerDescription}
                  disabled
                  error={formik.errors.tankerDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}
      </Grid>

      <Card.Footer
        style={{
          width: '100%',
        }}
      >
        {/* {plantData?.p_bom_status == 4 && ( */}
        {check_roles(userState, ['admin', 'brewmaster']) && (
          <>
            {materialData?.material_status == 4 && plantData?.status == 7 && (
              <Col>
                <Group position="right">
                  <Button
                    color="gray"
                    radius="xl"
                    leftIcon={<IconX size={16} color="white" />}
                    onClick={setShowDeletePopup}
                  >{`Delete Request`}</Button>
                  {/* {plantData?.l_bom_status == 2 && ( */}
                  <Button
                    color="gray"
                    radius="xl"
                    leftIcon={<IconX size={16} color="white" />}
                    // onClick={() => setShowDeletePopup(true)}
                    // onClick={() => setShowRejectMaterialModal(true)}
                    onClick={setShowRejectPopup}
                  >{`Reject Request`}</Button>
                  <Button
                    color="yellow"
                    radius="xl"
                    className="btn btn-primary"
                    style={{ borderRadius: '40px' }}
                    // disabled={submitLoader}
                    // leftIcon={
                    //   submitLoader ? (
                    //     <IconLoader size={16} color="white" />
                    //   ) : (
                    //     <IconCheck size={16} color="white" />
                    //   )
                    // }
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    disabled={loading}
                  >
                    {/* {!submitLoader && 'Submit'} */}
                    {loading ? <CustomCardLoader /> : 'Submit'}
                  </Button>
                </Group>
              </Col>
            )}
          </>
        )}
        {/* )} */}
        <Space h="xl"></Space>
      </Card.Footer>

      <RejectMaterial
        showRejectMaterialModal={showDeletePopup}
        closeRejectMaterialModal={() => setShowDeletePopup(false)}
        rejectMaterial={(comment) => {
          rejectMaterial(comment);
        }}
        message="Please provide your comments for Deleting this Request"
      />

      <RejectMaterial
        showRejectMaterialModal={showRejectPopup}
        closeRejectMaterialModal={() => setShowRejectPopup(false)}
        rejectMaterial={(comment) => {
          rejectLiquidBOM(comment);
        }}
        message="Please provide your comments for Rejecting this Request"
      />
    </>
  );
}

function BOMnotexists({
  formik,
  projectData,
  materialData,
  plantData,
  copiedItem,
  setCopiedItem,
  copyEnabled,
  setCopyEnabled,
  loading,
  selectedPlant,
  // FORMIK AND BOM SUB TABLES
  // BRAND BOM
  brandBOMList,
  setBrandBOMList,
  brandBOM_formik,
  updateBrandBom,
  deleteBrandBom,

  // AGE BOM
  ageBOMList,
  setAgeBOMList,
  ageBOM_formik,
  updateAgeBom,
  deleteAgeBom,

  // FERMENT BOM
  fermentBOMList,
  setFermentBOMList,
  fermentBOM_formik,
  updateFermentBom,
  deleteFermentBom,

  // WORT BOM
  wortBOMList,
  setWortBOMList,
  wortBOM_formik,
  updateWortBom,
  deleteWortBom,

  // BLEND BOM
  blendBOMList,
  setBlendBOMList,
  blendBOM_formik,
  updateBlendBom,
  deleteBlendBom,

  // TANKER BOM
  tankerBOMList,
  setTankerBOMList,
  tankerBOM_formik,
  updateTankerBom,
  deleteTankerBom,

  // REFRESH
  refreshFormikData,
}) {
  let params = useParams();
  const navigate = useNavigate();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  const rejectMaterial = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        toast.success('Material Deleted ');

        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const rejectLiquidBOM = (comment) => {
    bomService
      ?.rejectLiquidBOM({
        id: selectedPlant,
        comment,
        status: 6,
        l_bom_status: 8,
        material_id: params?.id,
      })
      .then((res) => {
        toast.success('Liquid BOM Rejected ');
        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  return (
    <Grid>
      <Grid.Col span={4}>
        <Select
          label="BOM Type"
          placeholder="Select BOM Type"
          radius={'lg'}
          data={[
            {
              label: 'Liquid',
              value: 'Liquid',
            },
            {
              label: 'Blend',
              value: 'Blend',
            },
            {
              label: 'Tanker',
              value: 'Tanker',
            },
          ]}
          value={formik.values.bomType}
          onChange={(value) => {
            refreshFormikData();
            formik.setFieldValue('bomType', value);
          }}
          // disabled={plantData?.l_bom_status == 6}
        />
      </Grid.Col>

      {/* RENDERING FOR LIQUID (BEER) */}
      {formik.values.bomType === 'Liquid' && (
        <BOMnotexists_Liquid
          projectData={projectData}
          materialData={materialData}
          plantData={plantData}
          formik={formik}
          loading={loading}
          setShowDeletePopup={() => setShowDeletePopup(true)}
          setShowRejectPopup={() => setShowRejectPopup(true)}
          // FORMIK AND BOM SUB TABLES
          // BRAND BOM
          brandBOMList={brandBOMList}
          setBrandBOMList={setBrandBOMList}
          brandBOM_formik={brandBOM_formik}
          updateBrandBom={updateBrandBom}
          deleteBrandBom={deleteBrandBom}
          // AGE BOM
          ageBOMList={ageBOMList}
          setAgeBOMList={setAgeBOMList}
          ageBOM_formik={ageBOM_formik}
          updateAgeBom={updateAgeBom}
          deleteAgeBom={deleteAgeBom}
          // FERMENT BOM
          fermentBOMList={fermentBOMList}
          setFermentBOMList={setFermentBOMList}
          fermentBOM_formik={fermentBOM_formik}
          updateFermentBom={updateFermentBom}
          deleteFermentBom={deleteFermentBom}
          // WORT BOM
          wortBOMList={wortBOMList}
          setWortBOMList={setWortBOMList}
          wortBOM_formik={wortBOM_formik}
          updateWortBom={updateWortBom}
          deleteWortBom={deleteWortBom}
          // COPY FUNCTIONS
          copyEnabled={copyEnabled}
          copiedItem={copiedItem}
          setCopyEnabled={setCopyEnabled}
          setCopiedItem={setCopiedItem}
        />
      )}

      {
        // RENDERING FOR BLEND
        formik.values.bomType === 'Blend' && (
          <BOMnotexists_Blend
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            formik={formik}
            loading={loading}
            setShowDeletePopup={() => setShowDeletePopup(true)}
            setShowRejectPopup={() => setShowRejectPopup(true)}
            // BRAND BOM
            blendBOMList={blendBOMList}
            setBlendBOMList={setBlendBOMList}
            blendBOM_formik={blendBOM_formik}
            updateBlendBom={updateBlendBom}
            deleteBlendBom={deleteBlendBom}
            // COPY FUNCTIONS
            copyEnabled={copyEnabled}
            copiedItem={copiedItem}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
          />
        )
      }

      {
        // RENDERING FOR BLEND
        formik.values.bomType === 'Tanker' && (
          <BOMnotexists_Tanker
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            formik={formik}
            loading={loading}
            setShowDeletePopup={() => setShowDeletePopup(true)}
            setShowRejectPopup={() => setShowRejectPopup(true)}
            // BRAND BOM
            tankerBOMList={tankerBOMList}
            setTankerBOMList={setTankerBOMList}
            tankerBOM_formik={tankerBOM_formik}
            updateTankerBom={updateTankerBom}
            deleteTankerBom={deleteTankerBom}
            // COPY FUNCTIONS
            copyEnabled={copyEnabled}
            copiedItem={copiedItem}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
          />
        )
      }

      {/* {
        // RENDERING FOR TANKER
        formik.values.bomType === 'Tanker' && (
          <BOMnotexists_Blend_Tanker
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
            formik={formik}
            
          />
        )
      } */}
      <RejectMaterial
        showRejectMaterialModal={showDeletePopup}
        closeRejectMaterialModal={() => setShowDeletePopup(false)}
        rejectMaterial={(comment) => {
          rejectMaterial(comment);
        }}
        message="Please provide your comments for Deleting this Request"
      />

      <RejectMaterial
        showRejectMaterialModal={showRejectPopup}
        closeRejectMaterialModal={() => setShowRejectPopup(false)}
        rejectMaterial={(comment) => {
          rejectLiquidBOM(comment);
        }}
        message="Please provide your comments for Rejecting this Request"
      />
    </Grid>
  );
}

function BOMnotexists_Liquid({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  setShowRejectPopup,
  // BOM SUB TABLES
  brandBOMList,
  setBrandBOMList,
  brandBOM_formik,
  updateBrandBom,
  deleteBrandBom,
  // AGE BOM
  ageBOMList,
  setAgeBOMList,
  ageBOM_formik,
  updateAgeBom,
  deleteAgeBom,
  // FERMENT BOM
  fermentBOMList,
  setFermentBOMList,
  fermentBOM_formik,
  updateFermentBom,
  deleteFermentBom,
  // WORT BOM
  wortBOMList,
  setWortBOMList,
  wortBOM_formik,
  updateWortBom,
  deleteWortBom,

  // COPY FUNCTIONS
  copyEnabled,
  copiedItem,
  setCopyEnabled,
  setCopiedItem,
}) {
  const { loadProductionLineList, productionLineList } = useContext(UserContext);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  // REFERENCE FORMIK
  const referenceFormik = useFormik({
    initialValues: {
      sku: '',
      skuDescription: '',
      plant: '',
    },
  });

  return (
    <>
      {/* FORM FIELDS */}
      <Grid
        style={{
          alignItems: 'center',
        }}
      >
        {/* <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>

        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}

        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={plantData?.l_bom_status == 2}
          />
        </Grid.Col>

        {/* BRAND */}
        {/* <Grid.Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid.Col span={2}>
            <Checkbox
              label="No similar Brand Required"
              color="yellow"
              checked={formik.values.brandNotRequired}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarBrand', '');
                  formik.setFieldValue('similarBrandDescription', '');
                }
                formik.setFieldValue('brandNotRequired', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.brandNotExist}
              error={formik.errors.brandNotRequired}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Checkbox
              label="No similar Brand Exist"
              color="yellow"
              checked={formik.values.brandNotExist}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarBrand', '');
                  formik.setFieldValue('similarBrandDescription', '');
                }
                formik.setFieldValue('brandNotExist', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.brandNotRequired}
              error={formik.errors.brandNotExist}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <SearchableSelect
              setId={(e) => {
                formik.setFieldValue('similarBrand', e);
                referenceFormik.setFieldValue('sku', e);
              }}
              setDescription={(e) => {
                formik.setFieldValue('similarBrandDescription', e);
                referenceFormik.setFieldValue('skuDescription', e);
              }}
              error={formik.errors.similarBrand}
              value={formik.values.similarBrand}
              label="Similar Brand ID"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              disabled
              label="Similar Brand Description"
              placeholder="Similar Brand Description"
              radius={'lg'}
              value={formik.values.similarBrandDescription}
              error={formik.errors.similarBrandDescription}
            />
          </Grid.Col>
        </Grid.Col> */}

        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Brand'}
          similarId={'similarBrand'}
          similarDescription={'similarBrandDescription'}
          idNotRequired={'brandNotRequired'}
          idNotExist={'brandNotExist'}
        />

        {/* AGE */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Age'}
          similarId={'similarAge'}
          similarDescription={'similarAgeDescription'}
          idNotRequired={'ageNotRequired'}
          idNotExist={'ageNotExist'}
        />

        {/* FERMENT */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Ferment'}
          similarId={'similarFerment'}
          similarDescription={'similarFermentDescription'}
          idNotRequired={'fermentNotRequired'}
          idNotExist={'fermentNotExist'}
        />

        {/* WORT */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Wort'}
          similarId={'similarWort'}
          similarDescription={'similarWortDescription'}
          idNotRequired={'wortNotRequired'}
          idNotExist={'wortNotExist'}
        />
      </Grid>

      {/* BOM TABLES TABS*/}
      <Card.Body>
        <Container
          style={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Brand</Tabs.Tab>
              <Tabs.Tab value="age">Age</Tabs.Tab>
              <Tabs.Tab value="ferment">Ferment</Tabs.Tab>
              <Tabs.Tab value="wort">Wort</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              <BOMtable
                list={brandBOMList}
                plant={plantData.plant}
                setList={setBrandBOMList}
                formik={brandBOM_formik}
                updateData={updateBrandBom}
                deleteData={deleteBrandBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
            <Tabs.Panel value="age">
              <BOMtable
                list={ageBOMList}
                plant={plantData.plant}
                setList={setAgeBOMList}
                formik={ageBOM_formik}
                updateData={updateAgeBom}
                deleteData={deleteAgeBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
            <Tabs.Panel value="ferment">
              <BOMtable
                list={fermentBOMList}
                plant={plantData.plant}
                setList={setFermentBOMList}
                formik={fermentBOM_formik}
                updateData={updateFermentBom}
                deleteData={deleteFermentBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
            <Tabs.Panel value="wort">
              <BOMtable
                list={wortBOMList}
                plant={plantData.plant}
                setList={setWortBOMList}
                formik={wortBOM_formik}
                updateData={updateWortBom}
                deleteData={deleteWortBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
          </Tabs>
          <Text>
            <IconInfoCircle size={16} color="black" style={{ marginRight: '5px' }} />
            Note : <b>Please click save after every component is added or any change is made.</b>
          </Text>
        </Container>
      </Card.Body>

      {/* REFERENCE HELPDESK */}
      <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
        formik={referenceFormik}
      />

      {/* FOOTER */}
      <Card.Footer
        style={{
          width: '100%',
        }}
      >
        {check_roles(userState, ['admin', 'brewmaster']) && (
          <>
            {materialData?.material_status == 4 && plantData?.status == 7 && (
              <Col>
                <Group position="right">
                  <Button
                    color="gray"
                    radius="xl"
                    leftIcon={<IconX size={16} color="white" />}
                    onClick={setShowDeletePopup}
                  >{`Delete Request`}</Button>

                  {/* {plantData?.l_bom_status == 2 && ( */}
                  <Button
                    color="gray"
                    radius="xl"
                    leftIcon={<IconX size={16} color="white" />}
                    // onClick={() => setShowDeletePopup(true)}
                    // onClick={() => setShowRejectMaterialModal(true)}
                    onClick={setShowRejectPopup}
                  >{`Reject Request`}</Button>
                  {/* )} */}
                  <Button
                    color="yellow"
                    radius="xl"
                    className="btn btn-primary"
                    style={{ borderRadius: '40px' }}
                    // disabled={submitLoader}
                    // leftIcon={
                    //   submitLoader ? (
                    //     <IconLoader size={16} color="white" />
                    //   ) : (
                    //     <IconCheck size={16} color="white" />
                    //   )
                    // }
                    onClick={() => {
                      if (brandBOMList.length === 0) {
                        toast.error('Please add atleast one item in the Brand BOM');
                        return;
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                    disabled={loading}
                  >
                    {/* {!submitLoader && 'Submit'} */}
                    {loading ? <CustomCardLoader /> : 'Submit'}
                  </Button>
                </Group>
              </Col>
            )}
          </>
        )}
        <Space h="xl"></Space>
      </Card.Footer>
    </>
  );
}

function BOMnotexists_Blend({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  setShowRejectPopup,
  // BLEND BOM
  blendBOMList,
  setBlendBOMList,
  blendBOM_formik,
  updateBlendBom,
  deleteBlendBom,

  // COPY FUNCTIONS
  setCopyEnabled,
  setCopiedItem,
  copyEnabled,
  copiedItem,
}) {
  const { productionLineList } = useContext(UserContext);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  // REFERENCE FORMIK
  const referenceFormik = useFormik({
    initialValues: {
      sku: '',
      skuDescription: '',
      plant: '',
    },
  });

  return (
    <>
      {/* FORM FIELDS */}
      <Grid
        style={{
          alignItems: 'center',
        }}
      >
        {/* <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>
        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={plantData?.l_bom_status == 2}
          />
        </Grid.Col>

        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Blend'}
          similarId={'similarBlend'}
          similarDescription={'similarBlendDescription'}
          idNotExist={'blendNotExist'}
          idNotRequired={'blendNotRequired'}
        />

        {/* <Grid.Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Required"
              color="yellow"
              checked={formik.values.skuNotRequired}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotRequired', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.skuNotExist}
              error={formik.errors.skuNotRequired}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Exist"
              color="yellow"
              checked={formik.values.skuNotExist}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotExist', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.skuNotRequired}
              error={formik.errors.skuNotExist}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <SearchableSelect
              setId={(e) => {
                formik.setFieldValue('similarSku', e);
                referenceFormik.setFieldValue('sku', e);
              }}
              setDescription={(e) => {
                formik.setFieldValue('similarSkuDescription', e);
                referenceFormik.setFieldValue('skuDescription', e);
              }}
              error={formik.errors.similarSku}
              value={formik.values.similarSku}
              label="Similar SKU ID"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              disabled
              label="Similar SKU Description"
              placeholder="Similar SKU Description"
              radius={'lg'}
              value={formik.values.similarSkuDescription}
              error={formik.errors.similarSkuDescription}
            />
          </Grid.Col>
        </Grid.Col> */}
      </Grid>

      {/* SUB TABLE TABS */}
      <Card.Body>
        <Container
          style={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Blend</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              <BOMtable
                list={blendBOMList}
                plant={plantData?.plant}
                setList={setBlendBOMList}
                formik={blendBOM_formik}
                updateData={updateBlendBom}
                deleteData={deleteBlendBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
          </Tabs>
          <Text>
            <IconInfoCircle size={16} color="black" style={{ marginRight: '5px' }} />
            Note : <b>Please click save after every component is added or any change is made.</b>
          </Text>
        </Container>
      </Card.Body>

      {/* REF. HELPDESK */}
      <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
        formik={referenceFormik}
      />

      {/* SUBMIT BUTTONS */}
      {check_roles(userState, ['admin', 'brewmaster']) && (
        <>
          {materialData?.material_status == 4 && plantData?.status == 7 && (
            <Card.Footer
              style={{
                width: '100%',
              }}
            >
              <Group position="right">
                <Button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                  onClick={setShowDeletePopup}
                >{`Delete Request`}</Button>
                {/* {plantData?.l_bom_status == 2 && ( */}
                <Button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                  // onClick={() => setShowDeletePopup(true)}
                  // onClick={() => setShowRejectMaterialModal(true)}
                  onClick={setShowRejectPopup}
                >{`Reject Request`}</Button>
                {/* )} */}
                <Button
                  color="yellow"
                  radius="xl"
                  className="btn btn-primary"
                  style={{ borderRadius: '40px' }}
                  // disabled={submitLoader}
                  // leftIcon={
                  //   submitLoader ? (
                  //     <IconLoader size={16} color="white" />
                  //   ) : (
                  //     <IconCheck size={16} color="white" />
                  //   )
                  // }
                  onClick={() => {
                    if (blendBOMList.length === 0) {
                      toast.error('Please add atleast one item in the Blend BOM');
                      return;
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                  disabled={loading}
                >
                  {/* {!submitLoader && 'Submit'} */}
                  {loading ? <CustomCardLoader /> : 'Submit'}
                </Button>
              </Group>

              <Space h="xl"></Space>
            </Card.Footer>
          )}
        </>
      )}
    </>
  );
}

function BOMnotexists_Tanker({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  setShowRejectPopup,
  // BRAND BOM
  tankerBOMList,
  setTankerBOMList,
  tankerBOM_formik,
  updateTankerBom,
  deleteTankerBom,

  // COPY FUNCTIONS
  setCopyEnabled,
  setCopiedItem,
  copyEnabled,
  copiedItem,
}) {
  const { productionLineList } = useContext(UserContext);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  // REFERENCE FORMIK
  const referenceFormik = useFormik({
    initialValues: {
      sku: '',
      skuDescription: '',
      plant: '',
    },
  });
  return (
    <>
      {/* FORM FIELDS */}
      <Grid
        style={{
          alignItems: 'center',
        }}
      >
        {/* <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>
        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={plantData?.l_bom_status == 2}
          />
        </Grid.Col>

        {/* <Grid.Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Required"
              color="yellow"
              checked={formik.values.skuNotRequired}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotRequired', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.skuNotExist}
              error={formik.errors.skuNotRequired}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Exist"
              color="yellow"
              checked={formik.values.skuNotExist}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotExist', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled={formik.values.skuNotRequired}
              error={formik.errors.skuNotExist}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <SearchableSelect
              setId={(e) => {
                formik.setFieldValue('similarSku', e);
                referenceFormik.setFieldValue('sku', e);
              }}
              setDescription={(e) => {
                formik.setFieldValue('similarSkuDescription', e);
                referenceFormik.setFieldValue('skuDescription', e);
              }}
              error={formik.errors.similarSku}
              value={formik.values.similarSku}
              label="Similar SKU ID"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              disabled
              label="Similar SKU Description"
              placeholder="Similar SKU Description"
              radius={'lg'}
              value={formik.values.similarSkuDescription}
              error={formik.errors.similarSkuDescription}
            />
          </Grid.Col>
        </Grid.Col> */}

        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Tanker'}
          similarId={'similarTanker'}
          similarDescription={'similarTankerDescription'}
          idNotExist={'tankerNotExist'}
          idNotRequired={'tankerNotRequired'}
        />
      </Grid>

      {/* SUB TABLE TABS */}
      <Card.Body>
        <Container
          style={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Tanker</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              <BOMtable
                list={tankerBOMList}
                plant={plantData?.plant}
                setList={setTankerBOMList}
                formik={tankerBOM_formik}
                updateData={updateTankerBom}
                deleteData={deleteTankerBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                enabled={materialData?.material_status == 4 && plantData?.status == 7}
                // disabled={
                //   plantData?.l_bom_status == 2 ||
                //   plantData?.l_bom_status == 6 ||
                //   plantData?.l_bom_status == 4 ||
                //   plantData?.p_bom_status != 4
                // }
                disabled={false}
              />
            </Tabs.Panel>
          </Tabs>
          <Text>
            <IconInfoCircle size={16} color="black" style={{ marginRight: '5px' }} />
            Note : <b>Please click save after every component is added or any change is made.</b>
          </Text>
        </Container>
      </Card.Body>

      {/* REF. HELPDESK */}
      <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
        formik={referenceFormik}
      />

      {/* SUBMIT BUTTONS */}
      {check_roles(userState, ['admin', 'brewmaster']) && (
        <>
          {materialData?.material_status == 4 && plantData?.status == 7 && (
            <Card.Footer
              style={{
                width: '100%',
              }}
            >
              <Group position="right">
                <Button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                  onClick={setShowDeletePopup}
                >{`Delete Request`}</Button>
                {/* {plantData?.l_bom_status == 2 && ( */}
                <Button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                  // onClick={() => setShowDeletePopup(true)}
                  // onClick={() => setShowRejectMaterialModal(true)}
                  onClick={setShowRejectPopup}
                >{`Reject Request`}</Button>
                {/* )} */}
                <Button
                  color="yellow"
                  radius="xl"
                  className="btn btn-primary"
                  style={{ borderRadius: '40px' }}
                  // disabled={submitLoader}
                  // leftIcon={
                  //   submitLoader ? (
                  //     <IconLoader size={16} color="white" />
                  //   ) : (
                  //     <IconCheck size={16} color="white" />
                  //   )
                  // }
                  onClick={() => {
                    if (tankerBOMList.length === 0) {
                      toast.error('Please add atleast one item in the tanker BOM');
                      return;
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                  disabled={loading}
                >
                  {/* {!submitLoader && 'Submit'} */}
                  {loading ? <CustomCardLoader /> : 'Submit'}
                </Button>
              </Group>

              <Space h="xl"></Space>
            </Card.Footer>
          )}
        </>
      )}
    </>
  );
}
