import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Group,
  TextInput,
  Space,
  Text,
  Modal,
  Select,
  Grid,
  Flex,
  ScrollArea,
} from '@mantine/core';
import { Input } from '@mantine/core';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useFormik } from 'formik';
import { Row, Col, Card } from 'react-bootstrap';
import { NumberInput, Tooltip } from '@mantine/core';
import { InfoCircle, AsteriskSimple, DropCircle } from 'tabler-icons-react';
import materialService from '../services/materialService';
import SimilarSkuTable from './SimilarSkuTable';
function SimilarSkuHelpdesk({
  type,
  plant,
  showSimilarSkuModal,
  closeSimilarSkuModal,
  setSimilarSkuAndDescription,
  skuId = '',
  skuDescription = '',
}) {
  const [liquid, setLiquid] = useState('');
  const [brand, setBrand] = useState('');
  const [subBrand, setSubBrand] = useState('');
  const [containerReturnable, setContainerReturnable] = useState('');
  const [containerMaterial, setContainerMaterial] = useState('');
  const [packSize1, setPackSize1] = useState('');
  const [packSize2, setPackSize2] = useState('');
  const [containerType, setContainerType] = useState('');
  const [containerVolume, setContainerVolume] = useState('');
  const [liquidDropDown, setLiquidDropDown] = useState([]);
  const [packSize2List, setPackSize2List] = useState([]);
  const [packSize1List, setPackSize1List] = useState([]);
  const [containerTypeList, setContainerTypeList] = useState([]);
  const [containerVolumeList, setContainerVolumeList] = useState([]);
  const [packSize2TempList, setPackSize2TempList] = useState([]);
  const [packSize1TempList, setPackSize1TempList] = useState([]);
  const [existingSkuList, setExistingSkuList] = useState([]);
  const [brandDropDown, setBrandDropDown] = useState([]);
  const [subBrandDropDown, setSubBrandDropDown] = useState([]);
  const [containerReturnableList, setContainerReturnableList] = useState([]);
  const [containerMaterialList, setContainerMaterialList] = useState([]);
  const [plantDropDown, setPlantDropDown] = useState([]);
  const [plantInput, setPlantInput] = useState();
  const [similarSkuNumber, setSimilarSkuNumber] = useState();
  const [similarSkuDescription, setSimilarSkuDescription] = useState();
  const [noSimilarSkuFound, setNoSimilarSkuFound] = useState(false);

  const getAllLiquids = async (val, callback) => {
    materialService.getAllLiquids().then((res) => {
      if (res && res.data) {
        setLiquidDropDown(res?.data?.liquid ? res?.data?.liquid : []);
        // console.log(res);
      }
    });
  };

  const handleChangeLiquid = () => {
    // console.log(JSON.stringify({"BrandSearchValue":search_val}))
    materialService
      .getAllBrandsForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setBrandDropDown(res?.data?.brand?.sort((a, b) => (a.label < b.label ? -1 : 1)));
        }
      });
  };

  const handleChangeBrand = async () => {
    materialService
      .getAllSubBrandsForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setSubBrandDropDown(res?.data?.subbrand?.sort((a, b) => (a.label < b.label ? -1 : 1)));
        }
      });
  };

  const handleChangeSubBrand = async () => {
    materialService
      .getContainerReturnableListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setContainerReturnableList(
            res?.data?.contret?.sort((a, b) => (a.label < b.label ? -1 : 1)),
          );
          return true;
        }
      });
  };

  const handleChangeContainerReturnable = async () => {
    await materialService
      .getContainerTypeListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setContainerTypeList(res?.data?.conttype?.sort((a, b) => (a.label < b.label ? -1 : 1)));
          return true;
        }
      });
  };

  const handleChangeContainerMaterial = async () => {
    materialService
      .getPackSize2ListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        // console.log('PACK SIZE 2 LIST', res);
        if (res && res.data) {
          setPackSize2List(
            res?.data?.packsize2?.sort((a, b) => Number(a?.value) - Number(b?.value)),
          );
          setPackSize2TempList(
            res?.data?.packsize2?.sort((a, b) => Number(a?.value) - Number(b?.value)),
          );
          return true;
        }
      });
  };

  const handleChangePackSize2 = async () => {
    materialService
      .getPackSize1ListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setPackSize1List(
            res?.data?.packsize1?.sort((a, b) => Number(a?.value) - Number(b?.value)),
          );
          setPackSize1TempList(
            res?.data?.packsize1?.sort((a, b) => Number(a?.value) - Number(b?.value)),
          );
          return true;
        }
      });
  };

  const handleChangePackSize1 = async () => {
    materialService
      .getContainerVolumeListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setContainerVolumeList(
            res?.data?.containervolume?.sort((a, b) => (a.label < b.label ? -1 : 1)),
          );
          return true;
        }
      });
  };

  const handleChangeContainerType = async () => {
    await materialService
      .getContainerMaterialListForSimilarSku(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
      )
      .then((res) => {
        if (res && res.data) {
          setContainerMaterialList(
            res?.data?.contmaterial?.sort((a, b) => (a.label < b.label ? -1 : 1)),
          );
          return true;
        }
      });
  };

  const returnProdh = (array) => {
    if (array?.length > 0) {
      return array[0].prodh_element;
    } else {
      return 'None';
    }
  };

  const returnFilter = (array, val) => {
    return returnProdh(
      array.filter(function (obj) {
        return obj.value == val;
      }),
    );
  };

  const getExistingSkuList = async (type) => {
    // console.log("Test", materialService.getSimilarSkuHelpdeskDropDown)
    //     var liquid_prodh_element = await returnFilter(liquidDropDown, formik?.values?.liquid)

    // var brand_prodh_element = await returnFilter(brandDropDown, formik?.values?.brand)

    // var sub_brand_prodh_element = await returnFilter(subBrandDropDown, formik?.values?.subBrand)

    // var container_returnable_prodh_element = await returnFilter(containerReturnableList, formik?.values?.containerReturnable)

    // var container_type_prodh_element = await returnFilter(containerTypeList, formik?.values?.containerType)

    // var container_material_prodh_element = await returnFilter(containerMaterialList, formik?.values?.containerMaterial)

    // var pack_size_2_prodh_element = await returnFilter(packSize2List, formik?.values?.packSize2)
    // var pack_size_1_prodh_element = await returnFilter(packSize1List, formik?.values?.packSize1)
    // var container_volume_prodh_element = await returnFilter(containerVolumeList, formik?.values?.containerVolume)

    materialService
      .getSimilarSkuHelpDeskDropDown(
        type,
        liquid,
        brand,
        subBrand,
        containerReturnable,
        containerType,
        containerMaterial,
        packSize2,
        packSize1,
        containerVolume,
        plantInput,
      )
      .then((res) => {
        if (res?.data) {
          if (res?.data?.length == 0) {
            setNoSimilarSkuFound(true);
          } else {
            setNoSimilarSkuFound(false);
          }

          // console.log('Res', res);
          setExistingSkuList(
            res?.data?.sort((a, b) => (Number(a?.sku_number) > Number(b?.sku_number) ? -1 : 1)),
          );
          return true;
        }
      });
  };

  const handleSelectExistingSku = async (val) => {
    materialService.getSimilarSkuDesc(val).then((res) => {
      if (res && res?.data) {
        setSimilarSkuDescription(res?.data?.skudesc[0]?.sku_description);
        formik.setFieldValue('similarSkuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      liquid: liquid,
      brand: brand,
      subBrand,
      subBrand,
      containerReturnable: containerReturnable,
      containerType: containerType,
      containerMaterial: containerMaterial,
      packSize2: packSize2,
      packSize1: packSize1,
      containerVolume: containerVolume,
    },
    validate: (values) => {
      const errors = {};
      // console.log(!values?.liquid);
      // if (!values?.liquid) errors.liquid = 'Liquid is required';

      // if (!values?.containerVolume)
      // errors.containerVolume = 'Container Volume is required';

      // if (!values?.containerType)
      // errors.containerType = 'Container Type is required';

      // if (!values?.packSize2)
      // errors.packSize2 = 'Pack Size 2 is required';

      // if (!values?.packSize1)
      // errors.packSize1 = 'Pack Size 1 is required';

      // if ( values?.packSize1 <=1 && values?.packSize2 > 1)
      // errors.packSize1 = 'Pack Size 2 should be more than Pack Size 1';

      // return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      // console.log(values);
      getExistingSkuList(type);
    },
  });

  const getAllPlants = async () => {
    materialService.getAllPlants().then((res) => {
      if (res && res.data) {
        setPlantDropDown(
          res?.data?.plant ? res?.data?.plant?.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
        );
      }
    });
  };

  useEffect(() => {
    if (plant?.length > 0) {
      setPlantInput(plant);
    }
  }, [plant]);

  useEffect(() => {
    getAllLiquids();
    getAllPlants();
  }, []);

  useEffect(() => {
    if (packSize2 != '' && packSize2List?.length > 0) {
      if (!packSize2List.some((item) => item.value == packSize2)) {
        // console.log('Exists:', packSize2);
        setPackSize2('');
      }
    }
    if (packSize1 != '' && packSize1List?.length > 0) {
      if (!packSize1List.some((item) => item.value == packSize1)) {
        // console.log('Exists:', packSize1);
        setPackSize1('');
      }
    }
    if (containerType != '' && containerTypeList?.length > 0) {
      if (!containerTypeList.some((item) => item.value == containerType)) {
        // console.log('Exists:', containerType);
        setContainerType('');
      }
    }
    if (containerVolume != '' && containerVolumeList?.length > 0) {
      if (!containerVolumeList.some((item) => item.value == containerVolume)) {
        // console.log('Exists:', containerVolume);
        setContainerVolume('');
      }
    }
    if (brand != '' && brandDropDown?.length > 0) {
      if (!brandDropDown.some((item) => item.value == brand)) {
        // console.log('Exists:', brand);
        setBrand('');
      }
    }
    if (subBrand != '' && subBrandDropDown?.length > 0) {
      if (!subBrandDropDown.some((item) => item.value == subBrand)) {
        // console.log('Exists:', subBrand);
        setSubBrand('');
      }
    }
    if (containerMaterial != '' && containerMaterialList?.length > 0) {
      if (!containerMaterialList.some((item) => item.value == containerMaterial)) {
        // console.log('Exists:', containerMaterial);
        setContainerMaterial('');
      }
    }
    if (containerReturnable != '' && containerReturnableList?.length > 0) {
      if (!containerReturnableList.some((item) => item.value == containerReturnable)) {
        // console.log('Exists:', containerReturnable);
        setContainerReturnable('');
      }
    }
  }, [
    packSize2List,
    packSize1List,
    containerMaterialList,
    containerReturnableList,
    containerTypeList,
    containerVolumeList,
    brandDropDown,
    subBrandDropDown,
  ]);

  useEffect(() => {
    if (formik?.values?.liquid?.length > 0) {
      handleChangeLiquid();
    }
  }, [formik?.values?.liquid, plantInput]);

  return (
    <Modal
      centered
      radius={'lg'}
      size={'85%'}
      opened={showSimilarSkuModal}
      title={
        <Text
          weight={700}
          className="golden-text"
        >{`Similar SKU Helpdesk - ${skuId} : ${skuDescription}`}</Text>
      }
      onClose={() => closeSimilarSkuModal()}
      //dummychange
    >
      <>
        <div>
          <Text weight={700} className="golden-text">{`Mandatory Filters`}</Text>
        </div>
        <Grid>
          {plantDropDown &&
            plantDropDown !== undefined &&
            plantDropDown?.length > 0 &&
            type != 'FGForm' && (
              <Grid.Col span={4}>
                <Select
                  radius={'lg'}
                  dropdownPosition="bottom"
                  data={plantDropDown?.length > 0 ? plantDropDown : []}
                  placeholder={'Plant'}
                  label={'Plant'}
                  searchable
                  required
                  value={plantInput}
                  withinPortal
                  onChange={(e) => {
                    setPlantInput(e);
                    // plantInputFormik.setFieldValue('plant', e);
                  }}
                  // error={plantInputFormik.errors?.plant}
                />
              </Grid.Col>
            )}

          <Grid.Col span={4}>
            <Select
              label="Liquid Type"
              placeholder="Liquid Type"
              radius={'lg'}
              searchable
              dropdownPosition="bottom"
              required
              data={liquidDropDown?.length > 0 ? liquidDropDown : []}
              withinPortal
              onChange={(e) => {
                formik.setFieldValue('liquid', e);
                setLiquid(e);
                // handleChangeLiquid(e,'');
              }}
              value={formik.values.liquid}
              error={formik.errors.liquid}
            />
          </Grid.Col>
        </Grid>

        {liquidDropDown &&
          formik?.values?.liquid?.length > 0 &&
          liquidDropDown !== undefined &&
          liquidDropDown?.length > 0 && (
            <>
              <br />
              <div>
                <Text weight={700} className="golden-text">{`Recommended Filters`}</Text>
              </div>
              <Grid>
                <Grid.Col span={4}>
                  <Flex
                    gap="xs"
                    justify="center"
                    // align="center"
                    direction="row"
                    wrap="nowrap"
                  >
                    <Select
                      label="Pack Size"
                      placeholder="Pack Size 1"
                      radius={'lg'}
                      clearable
                      data={packSize2TempList?.length > 0 ? packSize2TempList : []}
                      searchable
                      withinPortal
                      dropdownPosition="bottom"
                      onSearchChange={(e) => {
                        if (
                          packSize2List &&
                          packSize2List?.length > 0 &&
                          e != undefined &&
                          e != null &&
                          e != '' &&
                          e?.length > 0
                        ) {
                          var pcksize2 = packSize2List.filter(function (list) {
                            return list.value.indexOf(e) != -1;
                          });

                          pcksize2 = pcksize2.sort(function (a, b) {
                            return a.value - b.value;
                          });

                          // console.log('INTERNAL PACK SIZE 2', pcksize2);

                          setPackSize2TempList(pcksize2);
                        }
                      }}
                      onClick={() => {
                        handleChangeContainerMaterial();
                      }}
                      onChange={(e) => {
                        setPackSize2(e);
                        formik.setFieldValue('packSize2', e);
                      }}
                      value={formik.values.packSize2}
                      error={formik.errors.packSize2}
                    />
                    <Text mt={'6vh'}>X</Text>
                    <Select
                      placeholder="Pack Size 2"
                      radius={'lg'}
                      label={<></>}
                      clearable
                      withinPortal
                      data={packSize1TempList?.length > 0 ? packSize1TempList : []}
                      searchable
                      onSearchChange={(e) => {
                        if (
                          packSize1List &&
                          packSize1List?.length > 0 &&
                          e != undefined &&
                          e != null &&
                          e != '' &&
                          e?.length > 0
                        ) {
                          var pcksize1 = packSize1List.filter(function (list) {
                            return list.value.indexOf(e) != -1;
                          });

                          pcksize1 = pcksize1.sort(function (a, b) {
                            return a.value - b.value;
                          });

                          // console.log('INTERNAL PACK SIZE 1 ', pcksize1);
                          setPackSize1TempList(pcksize1);
                        }
                      }}
                      onClick={() => {
                        handleChangePackSize2();
                      }}
                      onChange={(e) => {
                        setPackSize1(e);
                        formik.setFieldValue('packSize1', e);
                      }}
                      value={formik.values.packSize1}
                      error={formik.errors.packSize1}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Select
                    label="Container Volume"
                    placeholder="Container Volume"
                    radius={'lg'}
                    withinPortal
                    dropdownPosition="bottom"
                    onClick={() => {
                      handleChangePackSize1();
                    }}
                    clearable
                    data={containerVolumeList}
                    searchable
                    onChange={(e) => {
                      // console.log(e);
                      setContainerVolume(e);
                      formik.setFieldValue('containerVolume', e);
                    }}
                    value={formik.values.containerVolume}
                    error={formik.errors.containerVolume}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Select
                    label="Container Type"
                    placeholder="Container Type"
                    radius={'lg'}
                    searchable
                    clearable
                    withinPortal
                    dropdownPosition="bottom"
                    data={containerTypeList}
                    onClick={() => {
                      handleChangeContainerReturnable();
                    }}
                    onChange={(e) => {
                      setContainerType(e);
                      formik.setFieldValue('containerType', e);
                      // handleChangeContainerType();
                    }}
                    value={formik.values.containerType}
                    error={formik.errors.containerType}
                  />
                </Grid.Col>
              </Grid>
              <br />
              <div>
                <Text weight={700} className="golden-text">{`Other Filters`}</Text>
              </div>
              <Grid>
                <Grid.Col span={4}>
                  <Select
                    label="Brand"
                    placeholder="Brand"
                    radius={'lg'}
                    searchable
                    clearable
                    withinPortal
                    dropdownPosition="bottom"
                    data={brandDropDown}
                    onChange={(e) => {
                      setBrand(e);
                      formik.setFieldValue('brand', e);
                    }}
                    value={formik.values.brand}
                    error={formik.errors.brand}
                  />
                  <Text fz="xs">Please enter atleast 2 characters to search for Brand</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  {/* {subBrandDropDown && subBrandDropDown !== undefined && subBrandDropDown?.length > 0 && ( */}
                  <Select
                    label="Sub Brand"
                    placeholder="Sub Brand"
                    radius={'lg'}
                    dropdownPosition="bottom"
                    data={subBrandDropDown}
                    searchable
                    clearable
                    withinPortal
                    onClick={() => {
                      handleChangeBrand();
                    }}
                    onChange={(e) => {
                      setSubBrand(e);
                      formik.setFieldValue('subBrand', e);
                      // handleChangeSubBrand(e);
                    }}
                    value={formik.values.subBrand}
                    error={formik.errors.subBrand}
                  />
                  {/* )} */}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={4}>
                  <Select
                    label="Container Returnable"
                    placeholder="Container Returnable"
                    radius={'lg'}
                    searchable
                    clearable
                    data={containerReturnableList}
                    withinPortal
                    dropdownPosition="bottom"
                    onClick={() => {
                      handleChangeSubBrand();
                    }}
                    onChange={(e) => {
                      setContainerReturnable(e);
                      formik.setFieldValue('containerReturnable', e);
                      // handleChangeContainerReturnable(e);
                    }}
                    value={formik.values.containerReturnable}
                    error={formik.errors.containerReturnable}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Select
                    label="Container Material"
                    placeholder="Container Material"
                    radius={'lg'}
                    searchable
                    clearable
                    withinPortal
                    dropdownPosition="bottom"
                    data={containerMaterialList}
                    onClick={() => {
                      handleChangeContainerType();
                    }}
                    onChange={(e) => {
                      setContainerMaterial(e);
                      formik.setFieldValue('containerMaterial', e);
                      // handleChangeContainerMaterial();
                    }}
                    value={formik.values.containerMaterial}
                    error={formik.errors.containerMaterial}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button
                    color="yellow"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    radius="xl"
                  >
                    Search
                  </Button>
                </Grid.Col>
              </Grid>
            </>
          )}
        {noSimilarSkuFound == true && (
          <div>
            <hr />
            <Text>No Similar SKU's found with this Product Hierarchy & Plant Combination.</Text>
          </div>
        )}
        {existingSkuList?.length > 0 && (
          <Row>
            <SimilarSkuTable
              data={existingSkuList}
              setSimilarSkuData={(val1, val2) => {
                // console.log(val1, val2);
                setSimilarSkuNumber(val1);
                setSimilarSkuDescription(val2);
              }}
            />
          </Row>
        )}
      </>

      {similarSkuDescription?.length > 0 && (
        <Card.Footer>
          <Group position="right">
            <Button
              color="gray"
              radius="xl"
              leftIcon={<IconX size={16} color="white" />}
              onClick={() => {
                closeSimilarSkuModal();
              }}
            >{`Cancel`}</Button>
            <Button
              color="yellow"
              radius="xl"
              leftIcon={<IconCheck size={24} color="white" />}
              onClick={() => {
                setSimilarSkuAndDescription(similarSkuNumber, similarSkuDescription, plantInput);
              }}
            >{`Select Sku`}</Button>
          </Group>
        </Card.Footer>
      )}
    </Modal>
  );
}
export default SimilarSkuHelpdesk;
