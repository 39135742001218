import { useState, useEffect, useRef } from 'react';
import axios from '../utils/axios';
import costService from '../services/costService';
import { AlertCircle } from 'tabler-icons-react';
import {
  Group,
  Button,
  Text,
  Table,
  Center,
  Space,
  ActionIcon,
  Input,
  Modal,
  FileButton,
  Collapse,
  SimpleGrid,
  UnstyledButton,
} from '@mantine/core';
import { IconDownload, IconTrash, IconEye, IconX, IconCheck } from '@tabler/icons';
import CustomLoader from './CustomLoader';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { urls } from '../utils/apiConfigs';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { ImageLoader } from './ImageLoader';
import { toast } from 'react-toastify';
export const FilesTable = ({ project_id, material_id, plant_id, files_data, refreshTable }) => {
  const [imageLoaderModal, setImageLoaderModal] = useState(false);
  const [base64Text, setBase64Text] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDownload = (file_name) => {
    costService.downloadCostingFile(project_id, material_id, plant_id, file_name).then((res) => {
      if (res) {
        // console.log("Response:",res?.data?.file_data)
        const buffer = Buffer.from(res?.data?.file_data);
        const blob = new Blob([buffer]);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  };

  const handleDelete = (file_name) => {
    costService.deleteCostingFile(project_id, material_id, plant_id, file_name).then((res) => {
      if (res) {
        // console.log("Response:",res)
        setShowDeleteModal(false);
        toast.success(`${file_name} has been deleted successfuly.`);
        new Promise((resolve) => {
          setTimeout(() => {
            refreshTable();
          }, 1000);
        });
      }
    });
  };

  const getBase64File = (file_name) => {
    costService.downloadCostingFile(project_id, material_id, plant_id, file_name).then((res) => {
      if (res) {
        // console.log("Response:",res?.data?.file_data)
        const buffer = Buffer.from(res?.data?.file_data);
        const mimetype = res?.data?.mime_type;
        const base64String = buffer.toString('base64');
        // console.log("Base64:",base64String)
        // console.log(mimetype)
        setBase64Text(`data:${mimetype};base64,${base64String}`);
        setFileName(file_name);
        setImageLoaderModal(true);
      }
    });
  };

  return (
    <>
      <ImageLoader
        fileName={fileName}
        base64path={base64Text}
        showModal={imageLoaderModal}
        closeModal={() => setImageLoaderModal(false)}
      />
      <div className="table-responsive" style={{ maxHeight: '40vh' }}>
        <Table
          className="table card-table table-hover table-outline text-nowrap table-vcenter card-table"
          striped
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead>
            <tr>
              <th>
                <Center>File Name</Center>
              </th>
              <th>
                <Center>Uploaded On</Center>
              </th>
              <th style={{ width: '8%' }}>
                <Center>Download</Center>
              </th>
              <th style={{ width: '8%' }}>
                <Center>Delete</Center>
              </th>
            </tr>
          </thead>

          <tbody>
            {files_data?.map((item, index) => (
              <tr>
                <td align="center">
                  {item.file_type == 'image' && (
                    <Link
                      onClick={() => {
                        getBase64File(item.file_name);
                      }}
                    >
                      <>
                        {`${item.file_name} `} <IconEye />
                      </>
                    </Link>
                  )}
                  {item.file_type != 'image' && <Text>{`${item.file_name}`}</Text>}
                </td>
                <td align="center">{item.created_on}</td>
                <td align="center">
                  <ActionIcon variant="light" color={'yellow'} radius="xl">
                    <IconDownload
                      size={16}
                      color="black"
                      onClick={() => {
                        handleDownload(item.file_name);
                      }}
                    />
                  </ActionIcon>
                </td>
                <td align="center">
                  <ActionIcon variant="light" color={'yellow'} radius="xl">
                    <IconTrash
                      size={16}
                      color="black"
                      onClick={() => {
                        setDeleteFileName(item.file_name);
                        setShowDeleteModal(true);
                      }}
                    />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        radius={'lg'}
        size={'lg'}
        opened={showDeleteModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {`Delete ${deleteFileName}`}
          </Text>
        }
        onClose={() => {
          setShowDeleteModal(false);
        }}
        withCloseButton={true}
      >
        <>
          <Row>
            <Text>{`Are you sure, you want to delete ${deleteFileName} permanently?`}</Text>
          </Row>
          <Space h="xl" />
          <Group position="right">
            <Button
              color="gray"
              radius="xl"
              leftIcon={<IconX size={16} color="white" />}
              onClick={() => {
                setShowDeleteModal(false);
                setDeleteFileName('');
              }}
            >{`Cancel`}</Button>
            <Button
              color="yellow"
              radius="xl"
              leftIcon={<IconCheck size={16} color="white" />}
              onClick={() => {
                handleDelete(deleteFileName);
              }}
            >{`Delete`}</Button>
          </Group>
        </>
      </Modal>
    </>
  );
};
