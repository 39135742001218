import { Button, Center, Grid, Select, Space, Table, Text, TextInput } from '@mantine/core';
// import { SelectItems } from '@mantine/core/lib/Select/SelectItems/SelectItems';
import { IconChevronDown, IconChevronUp, IconCopy, IconInfoCircle } from '@tabler/icons';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Card, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import bomService from '../../services/bomService';
import materialService from '../../services/materialService';
import CustomCardLoader from '../CustomCardLoader';

function ReferenceHelpdesk({ plantData, setCopyEnabled, setCopiedItem, formik }) {
  // state
  const [SkuList, setSkuList] = useState([]);
  const [SkuId, setSkuId] = useState('');
  const [SkuSerachValue, setSkuSerachValue] = useState('');
  // const [plant, setPlant] = useState('');
  const [refHelpdeskLoading, setRefHelpdeskLoading] = useState(false);
  const [refHelpdeskLoadingStart, setRefHelpdeskLoadingStart] = useState(false);
  const [referenceTableVisible, setReferenceTableVisible] = useState(false);
  const [referenceTableData, setReferenceTableData] = useState([]);
  const [bomUsageLevel, setBomUsageLevel] = useState('6');
  const [plantDropDown, setPlantDropDown] = useState([]);
  const [bomUsageLevelList, setBomUsageLevelList] = useState([
    { label: '3', value: '3' },
    { label: '6', value: '6' },
  ]);

  // SEARCHING EXISTING
  const searchSku = (val) => {
    if (val.length > 3) {
      bomService.getReferenceSkuDetails(val).then((res) => {
        //console.log('Data:', res);
        if (res.length > 0) {
          setSkuList(res?.sort((a, b) => Number(b?.label) - Number(a?.label)));
          return true;
        } else {
          setSkuList([]);
        }
      });
    }
  };

  // HANDLE SEARCHING EXISTING
  const handleSelectSku = (val) => {
    //console.log(val);
    bomService.getReferenceSkuDesc(val).then((res) => {
      setRefHelpdeskLoadingStart(true);
      if (res && res.data) {
        setSkuId(res?.data?.skudesc[0]?.sku_description);
        formik.setFieldValue('skuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  const getAllPlants = async () => {
    materialService.getAllPlants().then((res) => {
      if (res && res.data) {
        setPlantDropDown(
          res?.data?.plant ? res?.data?.plant?.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
        );
      }
    });
  };

  useEffect(() => {
    // console.log(
    //   'Loading:',
    //   refHelpdeskLoadingStart,
    //   referenceTableData?.length,
    //   refHelpdeskLoading,
    //   referenceTableData,
    // );
  }, [refHelpdeskLoadingStart, referenceTableData, refHelpdeskLoading]);

  useEffect(() => {
    getAllPlants();
  }, []);

  useEffect(() => {
    formik.setFieldValue('plant', plantData?.plant);
  }, [plantData?.plant]);

  useEffect(() => {
    formik.values.sku &&
      bomService.getReferenceSkuDetails(formik.values.sku).then((res) => {
        //console.log('Data:', res);
        if (res.length > 0) {
          setSkuList(res?.sort((a, b) => Number(b?.label) - Number(a?.label)));
          return true;
        } else {
          setSkuList([]);
        }
      });
  }, [formik.values.sku]);

  return (
    <Col xs={12} sm={12} align="left">
      <Card.Body align="left">
        <Text
          size="xl"
          weight={700}
          color="yellow"
          align="left"
          style={{ fontSize: '18px' }}
        >{`Reference Helpdesk`}</Text>
        <Space h="sm" />
      </Card.Body>
      <Grid
        style={{
          alignItems: 'end',
        }}
      >
        <Grid.Col span={2}>
          <Select
            label="SKU ID"
            placeholder="SKU ID"
            radius={'lg'}
            searchable
            onSearchChange={(e) => {
              setSkuSerachValue(e);
              searchSku(e);
            }}
            searchValue={SkuSerachValue}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('sku', e);
              setRefHelpdeskLoading(true);
              setReferenceTableData([]);
              bomService
                .getReferenceHelpdesk({
                  sku_id: e,
                  plant: formik?.values?.plant,
                  bom_usage_level: bomUsageLevel,
                })
                .then((res) => {
                  //console.log('HELPDESK', res);
                  setReferenceTableData(res);
                  setRefHelpdeskLoading(false);
                });

              handleSelectSku(e);
            }}
            data={SkuList}
            value={formik.values.sku}
            type="number"
          />
        </Grid.Col>

        <Grid.Col span={2}>
          <Select
            label="Bom Usage Level"
            placeholder="SKU ID"
            radius={'lg'}
            searchable
            onChange={(e) => {
              setBomUsageLevel(e);
              setReferenceTableData([]);
              if (formik?.values?.sku) {
                setRefHelpdeskLoading(true);
                bomService
                  .getReferenceHelpdesk({
                    sku_id: formik.values.sku,
                    plant: formik?.values?.plant,
                    bom_usage_level: e,
                  })
                  .then((res) => {
                    //console.log('HELPDESK', res);
                    setReferenceTableData(res);
                    setRefHelpdeskLoading(false);
                  });
              }
            }}
            value={bomUsageLevel}
            data={bomUsageLevelList}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label="SKU Description"
            disabled
            placeholder="SKU Description"
            radius={'lg'}
            value={formik.values.skuDescription}
          />
        </Grid.Col>

        <Grid.Col span={2}>
          <Select
            radius={'lg'}
            label="Plant"
            placeholder="Plant"
            data={plantDropDown}
            value={formik.values.plant}
            searchable
            onChange={(e) => {
              formik.setFieldValue('plant', e);
              setRefHelpdeskLoading(true);
              if (formik?.values?.sku) {
                bomService
                  .getReferenceHelpdesk({
                    sku_id: formik.values.sku,
                    plant: e,
                    bom_usage_level: bomUsageLevel,
                  })
                  .then((res) => {
                    //console.log('HELPDESK', res);
                    setReferenceTableData(res);
                    setRefHelpdeskLoading(false);
                  });
              }
            }}
          />
        </Grid.Col>

        <Grid.Col span={1}>
          <Button
            color="yellow"
            onClick={() => {
              setReferenceTableVisible(!referenceTableVisible);
            }}
          >
            {referenceTableVisible ? <IconChevronUp /> : <IconChevronDown />}
          </Button>
        </Grid.Col>
        <Grid.Col span={11}>
          <Text>
            <IconInfoCircle size={16} color="black" style={{ marginRight: '5px' }} />
            Note : <b>Please click on the downwards arrow button to expand the Reference BOM.</b>
          </Text>
        </Grid.Col>

        {/* <Grid> */}
        <Grid.Col span={12}>
          {(referenceTableData?.length == 0 || referenceTableData == undefined) &&
            refHelpdeskLoading == false &&
            refHelpdeskLoadingStart == true && (
              <div>
                <Text size="sm" align="left">
                  {'No associated SKUs found with this specific Plant & BOM Usage Combination.'}
                </Text>
              </div>
            )}
          {refHelpdeskLoading && <CustomCardLoader />}
          <Table
            striped
            highlightOnHover
            withBorder
            style={{
              width: '100%',
            }}
          >
            <Space h="md" />
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Components
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Scrap %
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  UoM
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Valid From
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Valid To
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Change Number
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Standard Cost
                </th>
                <th></th>
                <Space w="sm" />
              </tr>
            </thead>

            <tbody>
              {referenceTableData?.length > 0 && refHelpdeskLoadingStart == true && (
                <tr>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.bom_component}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.bom_component_description}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.component_quantity}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.component_scrap_in_percent}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.component_unit_of_measure}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.valid_from_date}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.valid_to_date}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{referenceTableData[0]?.change_number}</Center>
                  </td>
                  <td>
                    <Center fw={500}>{'N/A'}</Center>
                  </td>
                  <td>
                    <Button
                      color="yellow"
                      onClick={() => {
                        toast.success('Copied');
                        setCopyEnabled(true);
                        setCopiedItem({
                          component: referenceTableData[0]?.bom_component,
                          component_description: referenceTableData[0]?.bom_component_description,
                          quantity: Number(
                            referenceTableData[0]?.component_quantity?.replace(',', ''),
                          ),
                          uom: referenceTableData[0]?.component_unit_of_measure,
                          standard_cost: 'N/A',
                          scrap: Number(
                            referenceTableData[0]?.component_scrap_in_percent?.replace(',', '.'),
                          ),
                        });
                      }}
                    >
                      <IconCopy />
                    </Button>
                  </td>
                </tr>
              )}
              {referenceTableVisible && refHelpdeskLoadingStart == true && (
                <>
                  {referenceTableData?.slice(1, referenceTableData?.length)?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Center fw={500}>{item?.bom_component}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.bom_component_description}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.component_quantity}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.component_scrap_in_percent}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.component_unit_of_measure}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.valid_from_date}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.valid_to_date}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{item?.change_number}</Center>
                      </td>
                      <td>
                        <Center fw={500}>{'N/A'}</Center>
                      </td>
                      <td>
                        <Button
                          color="yellow"
                          onClick={() => {
                            toast.success('Copied');
                            setCopyEnabled(true);
                            setCopiedItem({
                              component: item?.bom_component,
                              component_description: item?.bom_component_description,
                              quantity: Number(item?.component_quantity.replace(',', '')),
                              uom: item?.component_unit_of_measure,
                              standard_cost: 'N/A',
                              scrap: Number(item?.component_scrap_in_percent?.replace(',', '.')),
                            });
                          }}
                        >
                          <IconCopy />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
              {/* <tr>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Center fw={500}>Test 1</Center>
                          </td>
                          <td>
                            <Button
                              color="yellow"
                              onClick={() => {
                                setCopyEnabled(true);
                                setCopiedItem({
                                  component: 'Test 1',
                                  quantity: 'Test 1',
                                  scrap: 'Test 1',
                                });
                              }}
                            >
                              <IconCopy />
                            </Button>
                          </td>
                        </tr>
                        {referenceTableVisible && (
                          <>
                            <tr>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 2</Center>
                              </td>
                              <td>
                                <Button
                                  color="yellow"
                                  onClick={() => {
                                    setCopyEnabled(true);
                                    setCopiedItem({
                                      component: 'Test 2',
                                      quantity: 'Test 2',
                                      scrap: 'Test 2',
                                    });
                                  }}
                                >
                                  <IconCopy />
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Center fw={500}>Test 3</Center>
                              </td>
                              <td>
                                <Button
                                  color="yellow"
                                  onClick={() => {
                                    setCopyEnabled(true);
                                    setCopiedItem({
                                      component: 'Test 3',
                                      quantity: 'Test 3',
                                      scrap: 'Test 3',
                                    });
                                  }}
                                >
                                  <IconCopy />
                                </Button>
                              </td>
                            </tr>
                          </>
                        )} */}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
      {/* </Grid> */}
    </Col>
  );
}

export default ReferenceHelpdesk;
