import { Text } from '@mantine/core';
import React from 'react';
import { Card } from 'react-bootstrap';
import { act } from 'react-dom/test-utils';

function Completed({ active }) {
  return (
    <Card radius="xl">
      <Card.Body>
        <Card.Title>
          <Text
            size="xl"
            weight={700}
            color={active ? 'yellow' : 'red'}
            style={{ fontSize: '18px' }}
          >
            {active
              ? 'The set-up for this SKU has been completed in the platform!'
              : 'The set-up for this SKU has not completed in the platform! Please complete previous steps.'}
          </Text>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default Completed;
