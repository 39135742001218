import {
  Center,
  Group,
  Pagination,
  Space,
  Table,
  Text,
  Button,
  Modal,
  TextInput,
  Select,
  Avatar,
  ActionIcon,
  Loader,
  SimpleGrid,
} from '@mantine/core';
import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import {
  IconAt,
  IconCheck,
  IconEdit,
  IconInfoCircle,
  IconPlus,
  IconSearch,
  IconTrash,
  IconUser,
  IconX,
} from '@tabler/icons';
import { useFormik } from 'formik';
import dataService from '../../services/dataService';
// import teamService from '../../services/teamService';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import userService from '../../services/userService';
import { pageContext } from '../../context/pageContext';
import { UserContext } from '../../context/userContext';
// import CustomLoader from '../../components/CustomLoader';
import CustomCardLoader from '../../components/CustomCardLoader';

import validator from 'validator';
import DataTable from '../../components/DataTable';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
function ManageBSClog() {
  const [bsclog, setBscLog] = useState();
  const [userDropdown, setUsersDropdown] = useState();

  const [pages, setPages] = React.useState(0);

  // States for Search Bar
  const [searchName, setSearchName] = useState();
  const [searchEmail, setSearchEmail] = useState();
  const [userState, dispatch] = useContext(ActiveUserContext);

  // States for Saving User in Modal
  const [showSaveUserModal, setShowSaveUserModal] = useState(false);
  const [saveUserModalActivePage, setSaveUserModalActivePage] = useState('first');
  const [showSaveUserModalLoader, setShowSaveUserModalLoader] = useState(false);
  const [saveUserModalMessage, setSaveUserModalMessage] = useState();

  // States for Deleting User in Modal
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteUserModalActivePage, setDeleteUserModalActivePage] = useState('first');
  const [showDeleteUserModalLoader, setShowDeleteUserModalLoader] = useState(false);
  const [deleteUserModalMessage, setDeleteUserModalMessage] = useState();

  //State Management for Users
  const [activeUserForSave, setActiveUserForSave] = useState();
  const [activeUserForDelete, setActiveUserForDelete] = useState();

  const { searchLogistics, setSearchLogistics, logisticsPagination, setLogisticsPagination } =
    useContext(pageContext);

  const getUsersApi = (page, name = null, email = null) => {
    userService.getAllUsers(true, page, searchName, searchEmail).then((res) => {
      if (res?.data?.Success) {
        setUsersDropdown(
          res?.data?.Users.map((val) => ({
            label: val?.name,
            value: val?.id,
            description: val?.email,
          })),
        );
      }
      //nothing
    });
  };

  const getBscLog = (offset) => {
    setBscLog();
    userService.getAllLogistics((offset - 1) * 10).then((res) => {
      if (res?.data?.Success) {
        setBscLog(res?.data);
        setPages(res?.data?.total);
      }
    });
  };

  const getSearchedParamsApi = () => {
    setBscLog();
    // //console.log("getting hit",searchName)

    userService.getSearchedLogistics(searchName, searchEmail).then((res) => {
      //console.log(res?.data);
      if (res?.data?.Success) {
        setBscLog(res?.data);
        setPages(0);
        // //console.log(res?.data)
      }
    });
  };

  const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ));

  useEffect(() => {
    getBscLog(1);
    getUsersApi();
  }, []);

  const saveUserFormik = useFormik({
    initialValues: {
      userID: activeUserForSave?.userID,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      if (!values.userID) errors.userID = 'Name is required';
      // if (!values.email) errors.email = 'Email is required';
      // if (values.email && !validator.isEmail(values.email)) errors.email = 'Enter a correct email';

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setShowSaveUserModalLoader(true);
      //console.log(values);
      userService.saveLogistics(values).then((res) => {
        setSubmitting(false);
        setShowSaveUserModalLoader(false);
        setSaveUserModalActivePage('response');
        setSaveUserModalMessage(res?.data?.message);
      });
    },
  });

  const deleteBscLog = (data) => {
    //console.log(data);
    userService.deleteLogistics(data).then((res) => {
      setShowDeleteUserModalLoader(false);
      setDeleteUserModalActivePage('response');
      setDeleteUserModalMessage(res?.data?.message);
    });
  };

  function saveUserModalCleanup() {
    setActiveUserForSave();
    setSaveUserModalActivePage('first');
    setShowSaveUserModalLoader(false);
    saveUserFormik.resetForm();
    setShowSaveUserModal(false);
    getBscLog(1);
  }

  function deleteUserModalCleanup() {
    setActiveUserForDelete();
    setDeleteUserModalActivePage('first');
    setDeleteUserModalMessage();
    setShowDeleteUserModalLoader(false);
    setShowDeleteUserModal(false);
    getBscLog(1);
  }

  const table_columns = useMemo(
    () => [
      {
        header: '',
        accessor: 'id',
      },
      {
        header: 'Name',
        accessor: 'name',
      },
      {
        header: 'Email',
        accessor: 'email',
      },
      {
        header: 'Level 1 Manager',
        accessor: 'step_manager_name',
      },
      {
        header: 'Level 2 Manager',
        accessor: 'senior_manager_name',
      },

      {
        header: '',
        accessor: 'delete',
        Cell: (props) => {
          const isAdmin = check_roles(userState, ['admin']);

          if (!isAdmin) {
            return null;
          }

          return (
            <ActionIcon
              variant="light"
              color={'yellow'}
              radius="xl"
              onClick={() => {
                setActiveUserForDelete(props.row?.original);
                setShowDeleteUserModal(true);
              }}
            >
              <IconTrash size={16} color="black" />
            </ActionIcon>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Modal
        radius={'lg'}
        size="sm"
        opened={showSaveUserModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {activeUserForSave ? `Modify User` : `Add User`}
          </Text>
        }
        onClose={() => {
          saveUserModalCleanup();
        }}
        withCloseButton={false}
      >
        {saveUserModalActivePage === 'first' && (
          <>
            <Row>
              <Col>
                <Select
                  label="User"
                  name="userID"
                  itemComponent={SelectItem}
                  placeholder="Please select user "
                  data={userDropdown}
                  searchable
                  withinPortal
                  dropdownPosition={'bottom'}
                  radius={'lg'}
                  maxDropdownHeight={400}
                  onChange={(e) => {
                    //console.log(e);
                    saveUserFormik.setFieldValue('userID', e);
                  }}
                  value={saveUserFormik.values.userID}
                  error={saveUserFormik.errors.userID}
                  disabled={saveUserFormik.isSubmitting}
                  clearable
                />
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  saveUserModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                className="btn btn-primary"
                style={{ borderRadius: '40px' }}
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={saveUserFormik.handleSubmit}
              >{`Save`}</Button>
            </Group>
          </>
        )}
        {saveUserModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {saveUserModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  saveUserModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {showSaveUserModalLoader ? (
          <>
            <CustomCardLoader />
          </>
        ) : null}
      </Modal>

      <Modal
        radius={'lg'}
        size="lg"
        opened={showDeleteUserModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {`Delete User`}
          </Text>
        }
        onClose={() => {
          deleteUserModalCleanup();
        }}
        withCloseButton={false}
      >
        {deleteUserModalActivePage === 'first' && (
          <>
            <Row>
              <Col xs={12}>
                <Text>{`Are you sure you want to delete the user ${activeUserForDelete?.name}?`}</Text>
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  deleteUserModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowDeleteUserModalLoader(true);
                  deleteBscLog(activeUserForDelete);
                }}
              >{`Delete`}</Button>
            </Group>
          </>
        )}

        {deleteUserModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {deleteUserModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  deleteUserModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {showDeleteUserModalLoader ? (
          <>
            <CustomCardLoader />
          </>
        ) : null}
      </Modal>

      <Card>
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left">{`BSC Logistics Hub`}</Text>
            {check_roles(userState, ['admin']) && (
              <Button
                radius="xl"
                color={'yellow'}
                leftIcon={<IconPlus size={14} color="white" />}
                loading={!userDropdown}
                onClick={() => {
                  setShowSaveUserModal(true);
                }}
              >{`Add`}</Button>
            )}
          </Group>
        </Card.Body>
        <Card.Body>
          <Container>
            <Row>
              <Group>
                <Group>
                  <TextInput
                    radius={'lg'}
                    placeholder={`User's name`}
                    value={searchName}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                    icon={<IconUser size={14} />}
                  />
                  <ActionIcon
                    variant="filled"
                    color={'yellow'}
                    radius="xl"
                    size="lg"
                    // disabled={!searchName}
                    onClick={() => {
                      if (searchName) getSearchedParamsApi();
                      else getBscLog(1);
                    }}
                  >
                    <IconSearch size={16} color="black" />
                  </ActionIcon>
                </Group>
                <Group>
                  <TextInput
                    radius={'lg'}
                    placeholder={`User's Email`}
                    value={searchEmail}
                    onChange={(e) => {
                      setSearchEmail(e.target.value);
                    }}
                    icon={<IconAt size={14} />}
                  />
                  <ActionIcon
                    variant="filled"
                    color={'yellow'}
                    radius="xl"
                    size="lg"
                    // disabled={!searchEmail}
                    onClick={() => {
                      if (searchEmail) getSearchedParamsApi();
                      else getBscLog(1);
                    }}
                  >
                    <IconSearch size={16} color="black" />
                  </ActionIcon>
                </Group>
              </Group>
            </Row>
          </Container>
        </Card.Body>
        {!bsclog && <CustomCardLoader />}
        {bsclog && bsclog?.Logistics && bsclog?.Logistics.length === 0 && (
          <Card.Body>
            <Group position="center">
              <Text>{`No Logistics found.`}</Text>
            </Group>
          </Card.Body>
        )}
        {bsclog && bsclog?.Logistics && bsclog?.Logistics.length > 0 && (
          <DataTable
            columns={table_columns}
            data={bsclog}
            records={bsclog?.Logistics}
            // page={tablePage}
            hiddenColumns={['id']}
          />
        )}
        <Card.Footer>
          <Center>
            <Pagination
              total={pages + 1}
              color="yellow"
              radius="xl"
              withEdges
              page={logisticsPagination}
              boundaries={1}
              initialPage={logisticsPagination}
              value={logisticsPagination}
              onChange={(e) => {
                setLogisticsPagination(e);
                getBscLog(e);
              }}
            />
          </Center>
        </Card.Footer>
      </Card>
    </>
  );
}

export default ManageBSClog;
