/* eslint-disable import/no-anonymous-default-export */
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';

export default {
  getFgAndPhantomBom: async function (data) {
    try {
      // console.log('data', data);
      const response = await axios.post(urls.getFGandPhantomBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  // FG BOM
  saveFgBom: async function (data) {
    try {
      const response = await axios.post(urls.saveFGbomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  deleteFgBom: async function (data) {
    try {
      const response = await axios.post(urls.deleteFGbomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  updateFgBom: async function (data) {
    try {
      const response = await axios.post(urls.updateFGbomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  //   PHANTOM BOM
  savePhantomBom: async function (data) {
    try {
      const response = await axios.post(urls.savePhantomBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  deletePhantomBom: async function (data) {
    try {
      const response = await axios.post(urls.deletePhantomBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  updatePhantomBom: async function (data) {
    try {
      const response = await axios.post(urls.updatePhantomBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  getReferenceHelpdesk: async function (data) {
    try {
      const response = await axios.post(urls.referenceSkuHelpdeskURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  createPackagingBOM: async function (data) {
    try {
      const response = await axios.post(urls.createPackagingBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  rejectPackagingBOM: async function (data) {
    try {
      const response = await axios.post(urls.rejectPackagingBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  acceptPackagingBOM: async function (data) {
    try {
      const response = await axios.post(urls.acceptPackagingBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  uploadTOSapPackageBOM: async function (data) {
    try {
      const response = await axios.post(urls.packageSAPBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  getProductionLines: async function (plant) {
    try {
      const response = await axios.post(urls.getProductionLineURL, plant);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  getProductionLinesByPackagingBOM: async function (data) {
    try {
      const resp = await axios.post(urls.getProductionLinesForPackagingBomURL, data);
      return resp.data;
    } catch (error) {
      return error.responses;
    }
  },

  getAllComponentsByPlant: async function (plant, search_val) {
    try {
      const response = await axios.post(urls.getAllComponentsByPlantURL, {
        plant: plant,
        search_val: search_val,
      });
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  getReferenceSkuDetails: async function (val) {
    try {
      const response = await axios.post(urls.getReferenceSkuDetailsURL, { search_val: val });
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  getReferenceSkuDesc: async function (sku) {
    try {
      const response = await axios.post(urls.getReferenceSkuDescURL, {
        sku: sku,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  //   LIQUID BOM
  getLiquidBOM: async function (data) {
    try {
      const response = await axios.post(urls.getLiquidBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  saveLiquidBom: async function (data) {
    try {
      const response = await axios.post(urls.saveLiquidBomURL, data);
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  rejectLiquidBOM: async function (data) {
    try {
      const response = await axios.post(urls.rejectLiquidBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  uploadTOSapLiquidBOM: async function (data) {
    try {
      const response = await axios.post(urls.liquidSAPBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  // BLEND BOM CRUD
  saveBlendBom: async function (data) {
    try {
      const response = await axios.post(urls.saveBlendBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  deleteBlendBom: async function (data) {
    try {
      const response = await axios.post(urls.deleteBlendBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  updateBlendBom: async function (data) {
    try {
      const response = await axios.post(urls.updateBlendBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  // TANKER BOM CRUD
  saveTankerBom: async function (data) {
    try {
      const response = await axios.post(urls.saveTankerBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  deleteTankerBom: async function (data) {
    try {
      const response = await axios.post(urls.deleteTankerBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  updateTankerBom: async function (data) {
    try {
      const response = await axios.post(urls.updateTankerBomURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  // BRAND BOM CRUD
  saveLiquidSubtype: async function (data) {
    try {
      const response = await axios.post(urls.saveLiquidSubTypeURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  deleteLiquidSubtype: async function (data) {
    try {
      const response = await axios.post(urls.deleteLiquidSubTypeURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  updateLiquidSubtype: async function (data) {
    try {
      const response = await axios.post(urls.updateLiquidSubTypeURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  getComponentDetails: async function (val, plant) {
    try {
      const response = await axios.post(urls.getComponentDetailsURL, {
        plant: plant,
        component: val,
      });
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  getAllComponents: async function () {
    try {
      const response = await axios.get(urls.getAllComponentsURL);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
};
