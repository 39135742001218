/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Text, Group, Space, Button, Select } from '@mantine/core';
import { TextInput } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import { Grid, Modal, Checkbox } from '@mantine/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import materialService from '../../services/materialService';
import plantService from '../../services/plantService';
import ApproveMaterial from '../MaterialApproval/ApproveMaterial';
import PlantTable from '../../components/PlantTable/PlantTable';
import SimilarSkuHelpdesk from '../../components/SimilarSkuHelpdesk';
import { toast } from 'react-toastify';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';

function FgFormInternal({ nextStep }) {
  // PARAMS
  let params = useParams();
  const navigate = useNavigate();

  // STATES
  const [skuDescription, setSkuDescription] = useState();
  const [showSimilarSkuModal, setShowSimilarSkuModal] = useState(false);
  const [similarSkuDescription, setSimilarSkuDescription] = useState();
  const [materialStatus, setMaterialStatus] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [userState, userDispatch] = useContext(ActiveUserContext);
  const [plants, setPlants] = useState([]);
  const [plantInput, setPlantInput] = useState({
    index: plants[plants?.length - 1]?.index + 1,
    id: Math.random() * 10001,
    plant: '',
    selling_region: '',
    production_date: null,
    str_date: null,
    stop_ship_date: null,
    fg_type: '',
    liquid_type: '',
  });
  const [similarSku, setSimilarSku] = useState();
  const [helpdeskPopup, setHelpdeskPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [similarItemNumberList, setSimilarItemNumberList] = useState([]);
  const [similarSkuSerachValue, setSimilarSkuSerachValue] = useState('');
  const [similarSkuSerachValueDescription, setSimilarSkuSerachValueDescription] = useState('');
  const [notInTheList, setNotInTheList] = useState(false);

  // USE EFFECTS
  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          //console.log(res?.data?.material?.sku_description);
          setSelectedMaterial(res.data?.material);
          setSkuDescription(res.data.material?.sku_description);
          setMaterialStatus(res.data.material?.material_status);
          setSimilarSkuSerachValue(res.data.material?.similar_sku_id || '');
          setSimilarSkuSerachValueDescription(res.data.material?.similar_sku_description);
          setPlantInput({
            ...plantInput,
            fg_type: res.data.material?.fg_type,
            liquid_type: res.data.material?.liquid,
          });
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      getPlants();
    }
  }, [params?.id]);

  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      materialService.getPlantsDetails(params?.id).then((res) => {
        if (res?.data) {
          setPlants(res?.data?.plants);
        }
      });
    }
  }, [params?.id]);

  // GETTING ALL THE PLANTS
  const getPlants = () => {
    materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
      if (res?.data) {
        setPlants(res?.data?.plants);
      }
    });

    // materialService.getAllPlants().then((res) => {
    //   if (res && res.data) {
    //     //console.log(res);
    //     setPlantDropDown(res?.data?.plant ? res?.data?.plant : '');
    //   }
    // });
  };

  // FORMIK
  const formik = useFormik({
    initialValues: {
      similarSku: similarSku,
      similarSkuDescription: similarSkuDescription,
      skuDescription: skuDescription,
      plants: plants,
    },
    validationSchema: Yup.object({
      // skuDescription: Yup.string().required('SKU Description is required'),
      similarSku: Yup.string().required('Similar SKU is required'),
      similarSkuDescription: Yup.string().required('Similar SKU Description is required'),
      plants: Yup.array().of(
        Yup.object().shape({
          index: Yup.number(),
          plant: Yup.string().required('Plant is required'),
          selling_region: Yup.string().required('Selling Region is required'),
          production_date: Yup.date().required('Production Date is required'),
          str_date: Yup.date().required('STR Date is required'),
          stop_ship_date: Yup.string(),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      //console.log('Form data', formik.errors);
    },
  });

  // SEARCHING EXISTING
  const serchSimilarSku = (val) => {
    materialService.getSimilarSkuList(val).then((res) => {
      if (res && res.data) {
        setSimilarItemNumberList(
          res?.data?.sku?.sort((a, b) => Number(b?.label) - Number(a?.label)),
        );
        return true;
      }
    });
  };

  // HANDLE SEARCHING EXISTING
  const handleSelectSimilarSku = (val) => {
    materialService.getSimilarSkuDesc(val).then((res) => {
      if (res && res.data) {
        setSimilarSkuSerachValueDescription(res?.data?.skudesc[0]?.sku_description);
        formik.setFieldValue('similarSkuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  // SUBMIT
  const submit = () => {
    const body = {
      similar_sku_id: notInTheList ? '' : similarSkuSerachValue,
      similar_sku_description: notInTheList ? '' : similarSkuSerachValueDescription,
    };
    // //console.log(body);
    plantService.submitFGform(params?.id, body).then((res) => {
      toast.success('Material Submitted Successfully');
      navigate('/');
    });
  };

  const handleSetSimilarSkuDropDown = (skuval) => {
    var tempfinal = [];
    var tempfinallist = [];
    if (similarItemNumberList.length > 0) {
      tempfinal = tempfinal.concat(similarItemNumberList);
    } else {
      tempfinal = tempfinal;
    }
    tempfinal.push({ label: skuval, value: skuval });
    //console.log('Final2:', tempfinal);
    if (tempfinal.length > 0) {
      tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
    }

    if (tempfinallist.length > 0) {
      setSimilarItemNumberList(tempfinallist);
    }
  };

  return (
    <>
      <Card radius="xl">
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
              {'Full Goods Form'}
            </Text>
          </Group>
        </Card.Body>
        <Card.Body>
          <Grid
            style={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Grid.Col span={4} align="left">
              <Grid.Col span={12}>
                <TextInput
                  label="SKU Description"
                  value={skuDescription}
                  // error={formik.errors.skuDescription}
                  placeholder="SKU Description"
                  radius={'lg'}
                  disabled
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={2} align="left">
              <Grid.Col span={12}>
                <Select
                  type="number"
                  label="Similar SKU ID"
                  placeholder="Similar SKU ID"
                  radius={'lg'}
                  searchable
                  onSearchChange={(e) => {
                    setSimilarSkuSerachValue(e);
                    if (e.length >= 2) {
                      serchSimilarSku(e);
                    }
                  }}
                  searchValue={similarSkuSerachValue}
                  onChange={(e) => {
                    formik.setFieldValue('similarSku', e);
                    handleSelectSimilarSku(e);
                  }}
                  data={similarItemNumberList}
                  value={formik.values.similarSku}
                  error={formik.errors.similarSku}
                  disabled={notInTheList}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Checkbox
                  label="No similar SKU available"
                  color="yellow"
                  checked={notInTheList}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setSimilarSkuSerachValue('');
                      setSimilarSkuSerachValueDescription('');
                    }
                    setNotInTheList(e.target.checked);
                    // formik.setFieldValue('notInTheList', e);
                  }}
                  radius="xl"
                  size="xs"
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4} align="left">
              <Grid.Col span={12}>
                <TextInput
                  disabled
                  label="Similar SKU Description"
                  placeholder="Similar SKU Description"
                  radius={'lg'}
                  value={similarSkuSerachValueDescription}
                  error={formik.errors.similarSkuDescription}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={2} align="left">
              <Grid.Col span={12}>
                <Button
                  color="yellow"
                  radius="xl"
                  onClick={() => {
                    setShowSimilarSkuModal(true);
                  }}
                >
                  Helpdesk
                </Button>
              </Grid.Col>
            </Grid.Col>
          </Grid>
        </Card.Body>
        <Space h="xl" />

        {/* PLANT COMPONENT FOR PLANTS CRUD OPERATION */}
        <PlantTable disabled={materialStatus != 2} />

        <Card.Footer>
          {check_roles(userState, ['admin', 'supply_planner']) && (
            <Group position="right">
              {materialStatus == 2 && (
                <>
                  <Button
                    color="yellow"
                    radius="xl"
                    className="btn btn-primary"
                    style={{ borderRadius: '40px' }}
                    leftIcon={<IconCheck size={24} color="white" />}
                    // onClick={submit}
                    onClick={() => {
                      setSubmitPopup(true);
                    }}
                  >{`Submit`}</Button>
                </>
              )}
            </Group>
          )}
        </Card.Footer>
      </Card>
      <ApproveMaterial
        onClose={() => setSubmitPopup(false)}
        message={'Are you sure you want to submit this material?'}
        showApproveMaterialModal={submitPopup}
        onSubmit={submit}
      />
      <SimilarSkuHelpdesk
        type="FGForm"
        plant="None"
        showSimilarSkuModal={showSimilarSkuModal}
        closeSimilarSkuModal={() => {
          //console.log('Similar Sku Modal Close:', false);
          setShowSimilarSkuModal(false);
        }}
        setSimilarSkuAndDescription={(val1, val2, val3 = '') => {
          //console.log(val1, val2);
          formik.setFieldValue('similarSku', val1);
          setSimilarSkuSerachValueDescription(val2);
          handleSetSimilarSkuDropDown(val1);
          setShowSimilarSkuModal(false);
        }}
        skuDescription={skuDescription}
      />
    </>
  );
}
export default FgFormInternal;
