/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import { Container, Card } from 'react-bootstrap';
import { Text, Group } from '@mantine/core';

import { Tabs } from '@mantine/core';

import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/userContext';
import FgForm from '../FgForm/FgForm';
import MaterialApproval from '../MaterialApproval';
import MDMView1 from './MDMview1';
import PackagingBOM from './PackagingBOM';
import MDMView2 from './MDMview2';
import LiquidBOM from './LiquidBOM';
import MDMView3 from './MDMview3';
import CostingApproval from './CostingApproval';
import CostNotificationsTable from './CostNotificationsTable';
import ProjectRequest from './ProjectRequest';
import SummaryView from './SummaryView';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
import CustomCardLoader from '../../components/CustomCardLoader';
import { Link } from 'react-router-dom';
import SkuCosting from './SkuCosting';
import SapCostUpdate from './SapCostUpdate';
import RmCosting from './RmCosting';
import { pageContext } from '../../context/pageContext';

function Home() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('submitted');

  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const { loadProjects, setSelectedProjectMaterials } = useContext(UserContext);

  const [userState, userDispatch] = useContext(ActiveUserContext);

  const {
    totalSubmitted,
    totalDrafts,
    totalApproval,
    totalFgForm,
    totalMdmView1,
    totalMdmView2,
    totalMdmView3,
    totalCostingNotifications,
    totalPackagingBom,
    totalLiquidBom,
    totalRmCosting1,
    totalRmCosting2,
    totalController,
    totalRMCost,
    totalSummary,
  } = useContext(pageContext);

  useEffect(() => {
    loadProjects();
    // setSelectedProject({});
    setSelectedProjectMaterials([]);
  }, [navigate]);

  if (!userState.roles) {
    return <CustomCardLoader />;
  }

  return (
    <>
      {/* {localStorage.getItem('access_token_backend')} */}
      {check_roles(userState, ['user']) ? (
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          <Tabs color="yellow" radius="md" defaultValue="summary">
            <Tabs.List>
              {[
                {
                  label: `Summary 
                  (${Boolean(totalSummary) ? totalSummary : 0})
                  `,
                  value: 'summary',
                },
                {
                  label: `Project Request (${
                    Boolean(totalSubmitted + totalDrafts) ? totalSubmitted + totalDrafts : 0
                  })`,
                  value: 'project_request',
                  roles: ['admin', 'requestor'],
                },
                {
                  label: `SKU Description Approval (${Boolean(totalApproval) ? totalApproval : 0})`,
                  value: 'material_approval',
                  roles: ['admin', 'demand_planner'],
                },
                {
                  label: `Full Goods Form
                  (${Boolean(totalFgForm) ? totalFgForm : 0})
                  `,
                  value: 'fg_form',
                  roles: ['admin', 'supply_planner'],
                },
                {
                  label: `MDM
                  (${
                    Boolean(
                      totalMdmView1 + totalMdmView2 + totalMdmView3 + totalCostingNotifications,
                    )
                      ? totalMdmView1 + totalMdmView2 + totalMdmView3 + totalCostingNotifications
                      : 0
                  })
                  `,
                  value: 'mdm_view',
                  roles: ['admin', 'mdm'],
                },
                {
                  label: `Packaging Bom
                  (${Boolean(totalPackagingBom) ? totalPackagingBom : 0})
                `,
                  value: 'packaging_bom',
                  roles: ['admin', 'logistics'],
                },
                {
                  label: `Liquid BOM
                  (${Boolean(totalLiquidBom) ? totalLiquidBom : 0})
                  `,
                  value: 'liquid_bom',
                  roles: ['admin', 'brewmaster'],
                },
                // { label: 'SKU Costing', value: 'rm_costing', roles: ['admin', 'costing'] },
                {
                  label: `GCC Costing
                  (${
                    Boolean(totalRmCosting1 + totalRmCosting2)
                      ? totalRmCosting1 + totalRmCosting2
                      : 0
                  })
                  `,
                  value: 'gcc_costing',
                  roles: ['admin', 'costing'],
                },
                {
                  label: `Costing Approval
                  (${Boolean(totalController) ? totalController : 0})
                  `,
                  value: 'costing_approval',
                  roles: ['admin', 'controller'],
                },
                {
                  label: `RM costing
                    (${Boolean(totalRMCost) ? totalRMCost : 0})
                    `,
                  value: 'costing',
                  roles: ['admin', 'rm_cost', 'costing', 'mdm'],
                },
                // { label: 'SAP Cost Update', value: 'sap_cost_update', roles: ['admin', 'costing'] },
              ].map((item, index) => {
                if (item.roles && !check_roles(userState, item.roles)) {
                  return null; // skip this item if the user doesn't have the required roles
                }
                return (
                  <Tabs.Tab key={index} value={item.value}>
                    {item.label}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>

            <Tabs.Panel value="summary" pt="xs">
              <SummaryView refresh={loadProjects} />
            </Tabs.Panel>

            <Tabs.Panel value="project_request" pt="xs">
              <ProjectRequest
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                showAddMaterialModal={showAddMaterialModal}
                setShowAddMaterialModal={setShowAddMaterialModal}
              />
            </Tabs.Panel>

            <Tabs.Panel value="material_approval" pt="xs">
              <MaterialApproval />
            </Tabs.Panel>

            <Tabs.Panel value="fg_form" pt="xs">
              <FgForm />
            </Tabs.Panel>

            <Tabs.Panel value="mdm_view" pt="xs">
              <Tabs color="yellow" variant="pills" radius="xl" defaultValue="mdm1">
                <Tabs.List>
                  {[
                    {
                      label: `SKU ID Creation
                      (${Boolean(totalMdmView1) ? totalMdmView1 : 0})
                      `,
                      value: 'mdm1',
                    },
                    {
                      label: `BOMs Validation
                      (${Boolean(totalMdmView2) ? totalMdmView2 : 0})
                      `,
                      value: 'mdm2',
                    },
                    {
                      label: `SAP BOMs Creation
                      (${Boolean(totalMdmView3) ? totalMdmView3 : 0})
                      `,
                      value: 'mdm3',
                    },
                    {
                      label: `Cost Notifications Table
                      (${Boolean(totalCostingNotifications) ? totalCostingNotifications : 0})
                      `,
                      value: 'mdm4',
                    },
                  ].map((item, index) => (
                    <Tabs.Tab key={index} value={item.value}>
                      {item.label}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
                <Tabs.Panel value="mdm1" pt="xs">
                  <MDMView1 />
                </Tabs.Panel>
                <Tabs.Panel value="mdm2" pt="xs">
                  <MDMView2 />
                </Tabs.Panel>
                <Tabs.Panel value="mdm3" pt="xs">
                  <MDMView3 />
                </Tabs.Panel>
                <Tabs.Panel value="mdm4" pt="xs">
                  <CostNotificationsTable refreshTable={() => loadProjects()} />
                </Tabs.Panel>
              </Tabs>
            </Tabs.Panel>
            <Tabs.Panel value="packaging_bom" pt="xs">
              <PackagingBOM />
            </Tabs.Panel>
            <Tabs.Panel value="liquid_bom" pt="xs">
              <LiquidBOM />
            </Tabs.Panel>
            {/* <Tabs.Panel value="rm_costing" pt="xs">
              <RmCosting1 />
            </Tabs.Panel> */}
            <Tabs.Panel value="gcc_costing" pt="xs">
              <Tabs color="yellow" variant="pills" radius="xl" defaultValue="sku_costing">
                <Tabs.List>
                  {[
                    {
                      label: `SKU Costing
                        (${Boolean(totalRmCosting1) ? totalRmCosting1 : 0})
                      `,
                      value: 'sku_costing',
                    },
                    {
                      label: `SAP Cost Update
                      (${Boolean(totalRmCosting2) ? totalRmCosting2 : 0})
                      `,
                      value: 'sap_cost_update',
                    },
                  ].map((item, index) => (
                    <Tabs.Tab key={index} value={item.value}>
                      {item.label}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
                <Tabs.Panel value="sku_costing" pt="xs">
                  <SkuCosting />
                </Tabs.Panel>
                <Tabs.Panel value="sap_cost_update" pt="xs">
                  <SapCostUpdate />
                </Tabs.Panel>
              </Tabs>
            </Tabs.Panel>
            <Tabs.Panel value="costing_approval" pt="xs">
              <CostingApproval />
            </Tabs.Panel>
            <Tabs.Panel value="costing" pt="xs">
              <RmCosting />
            </Tabs.Panel>
            {/* <Tabs.Panel value="sap_cost_update" pt="xs">
              <RmCosting2 />
            </Tabs.Panel> */}
          </Tabs>
          {/* {roles.includes('stakeholder') && <FgForm />} */}
        </div>
      ) : (
        (() => {
          return (
            <Card>
              <Card.Body>
                <Group position="apart">
                  <Text size="xl" weight={700} color="yellow" align="left">{`Home Hub`}</Text>
                </Group>
              </Card.Body>
              <Card.Body>
                <Group position="apart">
                  <Text align="left">
                    {`Sorry, but it seems that you do not have the required permissions to access or view this page.`}
                    <br />
                    {`Please contact the administrator to request access to this page.`}
                    <br />
                    {`Thank you.`}
                    <p>
                      <Link to="/role-request">Click Here</Link> to request the administrator for
                      access.
                    </p>
                  </Text>
                </Group>
              </Card.Body>
            </Card>
          );
        })()
      )}
    </>
  );
}
export default Home;
