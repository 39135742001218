import { TextInput } from '@mantine/core';
import React from 'react';

function DisabledTextInput({ label = '', placeholder = '', value = '' }, props) {
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      radius={'lg'}
      required
      disabled
      value={value}
      {...props.style}
    />
  );
}

export default DisabledTextInput;
