import pureAxios from 'axios';

const axios = pureAxios.create({
  //   baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Authorization: `${localStorage.getItem('access_token_backend')}`,
      // 'Content-Security-Policy': 'use default',
      // 'X-Frame-Options': 'DENY',
      // 'X-XSS-Protection': '1; mode=block',
      // 'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
      // 'X-Content-Type-Options': 'nosniff',
      // 'Referrer-Policy': 'same-origin',
      // 'Permissions-Policy':
      //   'geolocation=(self), microphone=(), camera=(), fullscreen=(self), payment=(), sync-xhr=(self)',
    },
  },
});

// axios.interceptors.response.use(
//   function (response) {
//     // if (response?.data?.message) showAlert(response.data);
//     return response;
//   },
//   function (error) {
//     // if (error?.response?.data?.message) showAlert(error.response.data, 'error');
//     // Do something with response error
//     if (error.response?.status === 401) {
//       localStorage.clear();
//       window.location.href = '/';
//     }
//     return Promise.reject(error);
//   },
// );

axios.interceptors.request.use((config) => {
  // Add security headers to the request headers
  // use default
  config.headers['Content-Security-Policy'] = 'use default';
  config.headers['X-Frame-Options'] = 'DENY';
  config.headers['X-XSS-Protection'] = '1; mode=block';
  config.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains';
  config.headers['X-Content-Type-Options'] = 'nosniff';
  config.headers['Referrer-Policy'] = 'same-origin';
  config.headers['Permissions-Policy'] =
    'geolocation=(self), microphone=(), camera=(), fullscreen=(self), payment=(), sync-xhr=(self)';

  return config;
});

axios.interceptors.response.use(
  function (config) {
    console.log(config);
    config.headers['Content-Security-Policy'] = 'use default';
    config.headers['X-Frame-Options'] = 'DENY';
    config.headers['X-XSS-Protection'] = '1; mode=block';
    config.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains';
    config.headers['X-Content-Type-Options'] = 'nosniff';
    config.headers['Referrer-Policy'] = 'same-origin';
    config.headers['Permissions-Policy'] =
      'geolocation=(self), microphone=(), camera=(), fullscreen=(self), payment=(), sync-xhr=(self)';

    return config;
  },
  function axiosLogoutInterceptor(err) {
    const originalRequest = err.config;
    // console.log('ORIGINAL REQUEST', originalRequest);

    // console.log(err.response?.config?.url);

    if (
      err.response?.config?.url.includes('/api') &&
      err.response?.status === 401 &&
      !originalRequest._retry
    ) {
      // authFlow();
      originalRequest._retry = true;
      return axios.request(originalRequest);
    } else if (originalRequest?._retry) {
      window.location.href = '/';
    }
    return Promise.reject(err);
  },
);

export default axios;
