/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';
import { graphConfig } from '../utils/authConfig';

export default {
  getAllUsers: async function () {
    try {
      const response = await axios.get(urls.getAllUsers);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getUsersView: async function (offset) {
    try {
      const response = await axios.post(urls.getUsersViewUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveUser: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveUserUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteUser: async function (data) {
    try {
      const response = axios.patch(urls.deleteUserUrl, data);

      return response;
    } catch (error) {
      return error.data;
    }
  },
  getSearchedUsers: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedUsersUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  getUserRoles: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.getUserRolesUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  sendUserRequests: async function (roles, description) {
    try {
      const response = axios.post(urls.sendUserRequestsUrl, {
        roles: roles,
        description: description,
      });
      return response;
    } catch (error) {
      return error.data;
    }
  },
  saveAdmin: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveAdminUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteAdmin: async function (data) {
    try {
      const response = axios.patch(urls.deleteAdminUrl, data);

      return response;
    } catch (error) {
      return error.data;
    }
  },
  getAllAdmins: async function (offset) {
    try {
      const response = await axios.post(urls.getAllAdminsUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getSearchedAdmin: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedAdminUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveRequestor: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveProjectRequestorUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteRequestor: async function (data) {
    try {
      const response = axios.patch(urls.deleteProjectRequestorUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  getAllRequestors: async function (offset) {
    try {
      const response = await axios.post(urls.getAllProjectRequestorUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  getSearchedRequestors: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedRequestorsUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllDemandPlanners: async function (offset) {
    try {
      const response = await axios.post(urls.getAllDemandPlannersUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savePlanner: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveDemandPlannersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deletePlanner: async function (data) {
    try {
      const response = axios.patch(urls.deleteDemandPlannersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedPlanners: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedPlannersUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllSupplyPlanners: async function (offset) {
    try {
      const response = await axios.post(urls.getAllSupplyPlannersUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveSupplyPlanner: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveSupplyPlannersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteSupplyPlanner: async function (data) {
    try {
      const response = axios.patch(urls.deleteSupplyPlannersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedSupplyPlanners: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedSupplyPlannersUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllLogistics: async function (offset) {
    try {
      const response = await axios.post(urls.getAllLogisticsUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveLogistics: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveLogisticsUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteLogistics: async function (data) {
    try {
      const response = axios.patch(urls.deleteLogisticsUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedLogistics: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedLogisticsUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllMDM: async function (offset) {
    try {
      const response = await axios.post(urls.getAllMDMUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveMDM: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveMDMUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteMDM: async function (data) {
    try {
      const response = axios.patch(urls.deleteMDMUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedMDM: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedMDMUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllBrewMasters: async function (offset) {
    try {
      const response = await axios.post(urls.getAllBrewMastersUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveBrewMaster: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveBrewMastersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteBrewMaster: async function (data) {
    try {
      const response = axios.patch(urls.deleteBrewMastersUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedBrewMasters: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedBrewMastersUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getSearchedGCC_Costing: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedGCC_CostingUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  getAllGCC_Costing: async function (offset) {
    try {
      const response = await axios.post(urls.getAllGCC_CostingUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveGCC_Costing: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveGCC_CostingUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteGCC_Costing: async function (data) {
    try {
      const response = axios.patch(urls.deleteGCC_CostingUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  getAllControllers: async function (offset) {
    try {
      const response = await axios.post(urls.getAllControllersUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveController: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveControllerUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteController: async function (data) {
    try {
      const response = axios.patch(urls.deleteControllerUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },

  getSearchedControllers: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedControllersUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSearchedRMCost: async function (searchName, searchEmail) {
    try {
      const response = await axios.post(urls.getSearchedRMCostUrl, {
        name: searchName,
        email: searchEmail,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllRMCost: async function (offset) {
    try {
      const response = await axios.post(urls.getAllRMCostUrl, { offset: offset });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  saveRMCost: async function (data) {
    try {
      //console.log(data);
      const response = axios.post(urls.saveRMCostUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  deleteRMCost: async function (data) {
    try {
      const response = axios.patch(urls.deleteRMCostUrl, data);
      return response;
    } catch (error) {
      return error.data;
    }
  },
  getProjectSLA: async function (projectID) {
    try {
      const response = await axios.post(urls.getProjectSLAUrl, {
        projectID: projectID,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  updateSLA: async function (data) {
    try {
      const response = await axios.post(urls.updateSLAUrl, {
        sla: data,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  getRolesForSummary: async function (plant, roles) {
    try {
      const response = axios.post(urls.getRolesForSummaryUrl, {
        plant: plant,
        roles: roles,
      });
      return response;
    } catch (error) {
      return error.data;
    }
  },
};
