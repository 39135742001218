import { useState, useRef, useEffect, useContext } from 'react';
import { Center, Space, Table, TextInput } from '@mantine/core';
import {
  IconX,
  IconClipboard,
  IconClearAll,
  IconDeviceFloppy,
  IconPageBreak,
  IconTrash,
} from '@tabler/icons';
import React from 'react';
import { Button } from 'react-bootstrap';
import bomService from '../../services/bomService';
import { Select, Loader } from '@mantine/core';
import { toast } from 'react-toastify';
import materialService from '../../services/materialService';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';

function BOMtable({
  list,
  plant,
  setList,
  formik,
  updateData,
  deleteData,
  copyEnabled,
  copiedItem,
  setCopyEnabled,
  enabled = true,
}) {
  const [componentList, setComponentList] = useState([]);
  const [allComponentList, setAllComponentList] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [userState, userDispatch] = useContext(ActiveUserContext);
  const [loading, setLoading] = useState(false);

  const handleSearch = (search_val) => {
    if (search_val?.length >= 3 && search_val != '') {
      setLoading(true);
      bomService.getAllComponentsByPlant(plant, search_val).then((res) => {
        // console.log('Components:', res);
        if (res) {
          var list = [];
          // console.log('FG BOM:', list);

          for (var i = 0; i < list?.length; i++) {
            list.push({ label: list[i].component, value: list[i].component });
          }
          var tempfinal = [];
          var tempfinallist = [];
          if (list?.length > 0) {
            tempfinal = res.concat(list);
          } else {
            tempfinal = res;
          }
          // console.log('Final2:', tempfinal);
          if (tempfinal?.length > 0) {
            tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
          }
          setLoading(false);
          setComponentList(tempfinallist?.sort((a, b) => Number(b?.label) - Number(a?.label)));
          // console.log('Final List:', tempfinallist);
        }
      });
    }
  };

  useEffect(() => {
    if (allComponentList?.length == 0) loadComponents();
  }, [list]);

  const getAllComponents = () => {
    bomService.getAllComponents().then((res) => {
      // console.log('Components:', res);
      if (res) {
        setAllComponentList(res);
        var list = [];
        // console.log('FG BOM:', list);

        for (var i = 0; i < list?.length; i++) {
          list.push({ label: list[i].component, value: list[i].component });
        }
        var tempfinal = [];
        var tempfinallist = [];
        if (list?.length > 0) {
          tempfinal = res.concat(list);
        } else {
          tempfinal = res;
        }
        // console.log('Final2:', tempfinal);
        if (tempfinal?.length > 0) {
          tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
        }
        setLoading(false);
        setComponentList(tempfinallist);
        // console.log('Final List:', tempfinallist);
      }
    });
  };

  const loadComponents = () => {
    var list = [];
    // console.log('FG BOM:', list);

    for (var i = 0; i < list?.length; i++) {
      list.push({ label: list[i].component, value: list[i].component });
    }
    var tempfinal = [];
    var tempfinallist = [];
    if (list?.length > 0) {
      tempfinal = list;
    }
    // console.log('Final2:', tempfinal);
    if (tempfinal?.length > 0) {
      tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
    }
    setLoading(false);
    setComponentList(tempfinallist);
  };

  useEffect(() => {
    // console.log('Selected Plant:', plant);
    if (plant && plant != '') {
      if (list?.length > 0) {
        var lists = [];
        // console.log('FG BOM:', lists);

        for (var i = 0; i < list?.length; i++) {
          lists.push({
            label: list[i].component,
            value: list[i].component,
            uom: '',
            quantity: '',
            standard_cost: '',
            scrap: '',
          });
        }
        var tempfinal = [];
        var tempfinallist = [];
        tempfinal = lists;

        // console.log('Final2:', tempfinal);
        if (tempfinal?.length > 0) {
          tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
        }
        // console.log('Final List:', tempfinallist);
        if (allComponentList?.length > 0) {
          setComponentList(tempfinallist.concat(allComponentList));
        } else {
          setComponentList(tempfinallist);
        }
      }
    }
  }, [plant, list]);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  // useEffect(() => {
  //   formik?.validateForm();
  // }, [formik?.values]);

  const includeOptionsAfterPaste = (item) => {
    // console.log('Item:', item);
    if (plant && plant != '') {
      // console.log('BOM List:', list);
      var lists = [];
      if (list?.length > 0) {
        for (var i = 0; i < list?.length; i++) {
          lists.push({
            label: list[i].component,
            value: list[i].component,
            uom: '',
            quantity: '',
            standard_cost: '',
            scrap: '',
          });
        }
      }
      lists.push({ label: item, value: item, uom: '', quantity: '', standard_cost: '', scrap: '' });
      var tempfinal = [];
      var tempfinallist = [];
      tempfinal = lists;

      // console.log('Final2:', tempfinal);
      if (tempfinal?.length > 0) {
        tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
      }
      // console.log('Final List:', tempfinallist);
      setComponentList(tempfinallist);
    }
  };

  const handleChangeSku = (e) => {
    materialService.skuDescValidate(e).then((res) => {
      if (res && res.data) {
        // console.log('Data:', res.data);
        return true;
      }
    });
  };

  const handleSelectComponent = (val) => {
    const componentExist = list?.map((i) => i?.component)?.includes(val);
    if (componentExist) {
      formik.setFieldValue('component', '');
      formik.setFieldValue('component_description', '');
      return toast.error('Component already exist in the list');
    }
    // console.log(val);
    formik.setFieldValue('component', val);
    bomService.getComponentDetails(val, plant).then((res) => {
      // console.log(res);
      formik.setFieldValue('component_description', res[0].component_description);
      formik.setFieldValue('uom', res[0].uom);
      // formik.setFieldValue('standard_cost', res[0].standard_cost);
      formik.setFieldValue('standard_cost', 'N/A');
      formik.setFieldValue('new_component', 'false');
    });
  };

  const handleSelectComponent2 = (val, item_id) => {
    const componentExist = list?.map((i) => i?.component)?.includes(val);
    if (componentExist) {
      return toast.error('Component already exist in the list');
    }
    // console.log(val);
    bomService.getComponentDetails(val, plant).then((res) => {
      var templist = list;
      var pos = templist.findIndex((obj) => obj.id == item_id);
      // console.log('Test:', templist, pos);
      templist[pos].component = val;
      templist[pos].component_description = res[0].component_description;
      templist[pos].uom = res[0].uom;
      // templist[pos].standard_cost = res[0].standard_cost;
      templist[pos].standard_cost = 'N/A';
      templist[pos].component_description = res[0].component_description;

      setList(templist);
    });
  };

  return (
    <Table className="addMaterial-table" highlightOnHover withBorder>
      <Space h="md" />
      <thead>
        <tr>
          {check_roles(userState, ['admin', 'mdm', 'logistics', 'brewmaster']) && (
            <th
              style={{
                width: '120px',
              }}
            >
              Action
            </th>
          )}

          <th
            style={{
              width: '160px',
            }}
          >
            <Center fw={500}>Components</Center>
          </th>
          <th
            style={{
              width: '300px',
            }}
          >
            <Center fw={500}>Description</Center>
          </th>
          <th
            style={{
              width: '150px',
            }}
          >
            <Center fw={500}>Quantity</Center>
          </th>
          <th
            style={{
              width: '150px',
            }}
          >
            <Center fw={500}>Scrap %</Center>
          </th>
          <th
            style={{
              width: '150px',
            }}
          >
            <Center fw={500}>UoM</Center>
          </th>
          <th
            style={{
              width: '150px',
            }}
          >
            <Center fw={500}>Std. Cost</Center>
          </th>
        </tr>
      </thead>

      <tbody>
        {list?.map((item, index) => (
          <tr key={index}>
            {check_roles(userState, ['admin', 'logistics', 'brewmaster', 'mdm']) && (
              <>
                <td
                  style={{
                    display: 'flex',
                    width: '120px',
                  }}
                >
                  {enabled && (
                    <>
                      <Button
                        style={{
                          marginRight: '12px',
                        }}
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          updateData({
                            id: item.id,
                            component: item.component,
                            component_description: item.component_description,
                            quantity: item.quantity,
                            scrap: item.scrap,
                            uom: item.uom,
                            standard_cost: item.standard_cost,
                          });
                          // bomService
                          //   .updateFgBom({
                          //     id: item.id,
                          //     component: item.component,
                          //     component_description: item.component_description,
                          //     quantity: item.quantity,
                          //     scrap: item.scrap,
                          //     uom: item.uom,
                          //     standard_cost: item.standard_cost,
                          //   })
                          //   .then((res) => {
                          //     toast.success('Fg BOM updated successfully');
                          //   })
                          //   .catch((err) => {
                          //     toast.error('Something went wrong, please try again later');
                          //   });
                        }}
                      >
                        <IconDeviceFloppy size={20} color="black" />
                      </Button>
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          setList((prev) => prev.filter((item, i) => i !== index));
                          deleteData({ id: item.id });
                          // bomService
                          //   .deleteFgBom({ id: item.id })
                          //   .then((res) => {
                          //     toast.success('Fg BOM deleted successfully');
                          //   })
                          //   .catch((err) => {
                          //     toast.error('Something went wrong, please try again later');
                          //   });
                        }}
                      >
                        <IconTrash size={20} color="black" />
                      </Button>
                    </>
                  )}
                </td>
              </>
            )}
            <td
              style={{
                width: '160px',
              }}
            >
              <Select
                type="number"
                placeholder="Components"
                searchable
                radius={'lg'}
                data={componentList}
                value={item.component}
                disabled={item?.component == 'NA' || item?.component?.length == 0 || !enabled}
                // searchValue={searchValue}
                onSearchChange={(e) => {
                  setSearchValue(e);
                  // if(e?.length >= 3 && e != ""){
                  // handleSearch(e);
                  // }
                }}
                onChange={(e) => {
                  var componentdesc = componentList.find(
                    (t) => t.value === e,
                  ).component_description;
                  var qty = componentList.find((t) => t.value === e).quantity;
                  var scrap = componentList.find((t) => t.value === e).scrap;
                  var uom = componentList.find((t) => t.value === e).uom;
                  var standardcost = componentList.find((t) => t.value === e).standard_cost;
                  setList((prev) =>
                    prev.map((item, i) =>
                      i === index
                        ? {
                            ...item,
                            component: e,
                          }
                        : item,
                    ),
                  );
                }}
              />
            </td>
            <td
              style={{
                width: '300px',
              }}
            >
              <TextInput
                placeholder="Description"
                value={item.component_description}
                radius={'lg'}
                disabled={(item.component != 'NA' && item?.component?.length != 0) || !enabled}
                onChange={(e) => {
                  setList((prev) =>
                    prev.map((item, i) =>
                      i === index ? { ...item, component_description: e.target.value } : item,
                    ),
                  );
                }}
              />
            </td>
            <td
              style={{
                width: '80px',
              }}
            >
              <TextInput
                disabled={!enabled}
                placeholder="Quantity"
                radius={'lg'}
                value={item.quantity}
                onChange={(e) => {
                  setList((prev) =>
                    prev.map((item, i) =>
                      i === index ? { ...item, quantity: e.target.value } : item,
                    ),
                  );
                }}
                type="number"
              />
            </td>

            <td
              style={{
                width: '80px',
              }}
            >
              <TextInput
                disabled={!enabled}
                placeholder="Scrap %"
                radius={'lg'}
                value={item.scrap}
                onChange={(e) => {
                  setList((prev) =>
                    prev.map((item, i) =>
                      i === index ? { ...item, scrap: e.target.value } : item,
                    ),
                  );
                }}
                type="number"
              />
            </td>

            <td
              style={{
                width: '80px',
              }}
            >
              <TextInput
                disabled={(item.component != 'NA' && item?.component?.length != 0) || !enabled}
                placeholder="UoM"
                radius={'lg'}
                value={item.uom}
                onChange={(e) => {
                  setList((prev) =>
                    prev.map((item, i) => (i === index ? { ...item, uom: e.target.value } : item)),
                  );
                }}
              />
            </td>

            <td
              style={{
                width: '80px',
              }}
            >
              <TextInput
                disabled={(item.component != 'NA' && item?.component?.length != 0) || !enabled}
                placeholder="Standard Cost"
                radius={'lg'}
                value={item.standard_cost}
                onChange={(e) => {
                  setList((prev) =>
                    prev.map((item, i) =>
                      i === index ? { ...item, standard_cost: e.target.value } : item,
                    ),
                  );
                }}
              />
            </td>
          </tr>
        ))}
        {enabled && (
          <tr>
            {check_roles(userState, ['admin', 'mdm', 'logistics', 'brewmaster']) && (
              <>
                <td
                  style={{
                    display: 'flex',
                    width: '120px',
                  }}
                >
                  <Button
                    style={{
                      marginRight: '12px',
                    }}
                    variant="light"
                    color={'yellow'}
                    radius="xl"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    <IconDeviceFloppy size={20} color="black" />
                  </Button>
                  <Button
                    variant="light"
                    color={'yellow'}
                    radius="xl"
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    <IconX size={20} color="black" />
                  </Button>
                  {copyEnabled && (
                    <Button
                      variant="light"
                      color={'yellow'}
                      radius="xl"
                      onClick={() => {
                        // console.log('copiedItem', copiedItem);
                        const componentExist = list
                          ?.map((i) => i?.component)
                          ?.includes(copiedItem.component);
                        if (componentExist) {
                          return toast.error('Component already exist in the list');
                        }
                        includeOptionsAfterPaste(copiedItem.component);
                        formik.setValues({
                          component: copiedItem.component,
                          quantity: copiedItem.quantity,
                          scrap: copiedItem.scrap,
                          uom: copiedItem.uom,
                          component_description: copiedItem.component_description,
                          standard_cost: copiedItem.standard_cost,
                          new_component: 'false',
                        });
                        setCopyEnabled(false);
                      }}
                    >
                      <IconClipboard size={20} color="black" />
                    </Button>
                  )}
                </td>
              </>
            )}
            <td
              style={{
                width: '160px',
              }}
            >
              <Select
                type="number"
                placeholder="Component"
                radius={'lg'}
                disabled={formik.values.new_component == 'true'}
                data={componentList?.length > 0 ? componentList : []}
                value={formik.values.component}
                searchable
                rightSection={loading ? <Loader size="xs" /> : null}
                // searchValue={searchValue}
                // onSearchChange={(e) => {
                //   // setSearchValue(e)
                //   if(e?.length >= 3)
                //   handleSearch(e);
                // }}
                onSearchChange={(e) => {
                  setSearchValue(e);
                  // if(e?.length >= 3)
                  // handleSearch(e);
                }}
                onChange={(e) => {
                  handleSelectComponent(e);
                  // formik.setFieldValue('new_component', 'false');
                  // var componentdesc = componentList.find(
                  //   (t) => t.value === e,
                  // ).component_description;
                  // var qty = componentList.find((t) => t.value === e).quantity;
                  // var scrap = componentList.find((t) => t.value === e).scrap;
                  // var uom = componentList.find((t) => t.value === e).uom;
                  // var standardcost = componentList.find((t) => t.value === e).standard_cost;
                  // formik.setFieldValue('component_description', componentdesc);
                  // // formik.setFieldValue('quantity', qty);
                  // // formik.setFieldValue('scrap', scrap);
                  // formik.setFieldValue('uom', uom);
                  // formik.setFieldValue('standard_cost', standardcost);
                }}
                error={formik.errors.component}
              />
            </td>
            <td
              style={{
                width: '300px',
              }}
            >
              <TextInput
                placeholder="Description"
                // disabled
                radius={'lg'}
                disabled={formik.values.new_component == 'false'}
                value={formik.values.component_description}
                onChange={(e) => {
                  formik.setFieldValue('new_component', 'true');
                  formik.setFieldValue('component_description', e.target.value);
                  formik.setFieldValue('component', 'N/A');
                  formik.setFieldValue('standard_cost', 'N/A');
                }}
                error={formik.errors.component_description}
              />
            </td>
            <td
              style={{
                width: '150px',
              }}
            >
              <TextInput
                placeholder="Quantity"
                radius={'lg'}
                value={formik.values.quantity}
                onChange={(e) => {
                  formik.setFieldValue('quantity', e.target.value);
                }}
                error={formik.errors.quantity}
                type="number"
              />
            </td>
            <td
              style={{
                width: '150px',
              }}
            >
              <TextInput
                placeholder="Scrap %"
                radius={'lg'}
                value={formik.values.scrap}
                onChange={(e) => {
                  formik.setFieldValue('scrap', e.target.value);
                }}
                error={formik.errors.scrap}
                type="number"
              />
            </td>
            <td
              style={{
                width: '100px',
              }}
            >
              <TextInput
                placeholder="UoM"
                // disabled
                radius={'lg'}
                disabled={formik.values.new_component == 'false'}
                value={formik.values.uom}
                onChange={(e) => {
                  formik.setFieldValue('uom', e.target.value);
                }}
                error={formik.errors.uom}
              />
            </td>

            <td
              style={{
                width: '150px',
              }}
            >
              <TextInput
                placeholder="Std. Cost"
                radius={'lg'}
                value={formik.values.standard_cost}
                disabled={true}
                onChange={(e) => {
                  formik.setFieldValue('standard_cost', e.target.value);
                }}
                error={formik.errors.standard_cost}
                type="number"
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default BOMtable;
