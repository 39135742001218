/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import { Text, Stack, Group, Space, Center, Button, Tabs } from '@mantine/core';
import { Pagination } from '@mantine/core';
import { TextInput } from '@mantine/core';
import {
  IconPhoto,
  IconMessageCircle,
  IconRefresh,
  IconPlus,
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { pageContext } from '../../context/pageContext';
import { UserContext } from '../../context/userContext';
import CustomCardLoader from '../../components/CustomCardLoader';
import moment from 'moment';
import AddMaterial from '../ProjectRequest/AddMaterial';
import homeServices from '../../services/homeServices';
import projectService from '../../services/projectService';
import { plantStatusToOwner, materialStatusToOwner } from '../../utils/Utils';

function ProjectRequest({
  activeTab,
  setActiveTab,
  showAddMaterialModal,
  setShowAddMaterialModal,
}) {
  const navigate = useNavigate();
  const {
    searchProject,
    setSearchProject,
    projectPagination,
    setProjectPagination,
    searchMaterial,
    setSearchMaterial,
    materialPagination,
    setMaterialPagination,
    draftPagination,
    setDraftPagination,
    setTotalSubmitted,
    totalSubmitted,
    totalDrafts,
    setTotalDrafts,
  } = useContext(pageContext);

  const {
    loading,
    draftProjects,
    selectedProject,
    selectedProjectMaterials,
    setSelectedProject,
    addMaterialInProject,
    onProjectSelect,
    setSelectedProjectMaterials,
  } = useContext(UserContext);

  const [projectList, setProjectList] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const [draftList, setDraftList] = React.useState([]);
  const [draftPages, setDraftPages] = React.useState(0);
  const [draftLoadingData, setDraftLoadingData] = useState(false);
  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);

  const fetchData = async (offset, search) => {
    setLoadingData(true);
    homeServices
      .getHomeProjectRequest({
        search: search,
        offset: (offset - 1) * 10,
      })
      .then((res) => {
        //console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(res?.projects);
        setPages(res?.total);
        setLoadingData(false);
        setTotalSubmitted(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  const fetchDraftData = async (offset) => {
    setDraftLoadingData(true);
    projectService
      .getAllDraftProjects({
        offset: (offset - 1) * 10,
      })
      .then((res) => {
        setDraftList(res?.projects);
        setDraftPages(res?.total);
        setDraftLoadingData(false);
        setTotalDrafts(res?.rows);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchDraftData(1);
  }, []);

  useEffect(() => {
    // fetchData(0, searchProject);

    const timer = setTimeout(() => {
      fetchData(1, searchProject);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchProject]);

  function determineOwners(obj) {
    const { plant_status, send_sku_notification_to, ownerNames, material_status, send_request_to } =
      obj;

    let owners = '';
    if (plant_status) {
      owners = plantStatusToOwner(plant_status, send_sku_notification_to, ownerNames);
    } else if (material_status) {
      owners = materialStatusToOwner(material_status, send_request_to, ownerNames);
    }

    return owners;
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={3}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="center"
                style={{ fontSize: '18px' }}
              >{`All Projects`}</Text>
              <Space h="sm" />
              <Button
                radius="xl"
                color={'yellow'}
                rightIcon={<IconPlus size={14} color="white" />}
                onClick={() => navigate('/project-request')}
              >{`Add Project`}</Button>
            </Card.Body>
            <Card.Body>
              <Stack align="flex-start">
                <TextInput
                  radius={'xl'}
                  placeholder={`Project Name`}
                  rightSection={
                    <ActionIcon
                      variant="filled"
                      color={'yellow'}
                      radius="xl"
                      size="md"
                      onClick={() => {
                        setLoadingData(true);
                        setSearchProject('');
                        setProjectPagination(1);
                      }}
                    >
                      <IconRefresh size={14} color="black" />
                    </ActionIcon>
                  }
                  value={searchProject}
                  onChange={(e) => {
                    setSearchProject(e.target.value);
                    setProjectPagination(1);

                    setSelectedProjectMaterials([]);
                  }}
                />
              </Stack>
            </Card.Body>
            <Card.Body>
              <Tabs radius="xs" value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                  <Tabs.Tab value="submitted" color="yellow" icon={<IconPhoto size={14} />}>
                    Submitted {totalSubmitted && `(${totalSubmitted})`}
                  </Tabs.Tab>
                  <Tabs.Tab value="drafts" color="gray" icon={<IconMessageCircle size={14} />}>
                    Drafts {totalDrafts && `(${totalDrafts})`}
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="submitted">
                  <Space h="xl" />
                  {loading && <CustomCardLoader />}
                  {loadingData && <CustomCardLoader />}
                  {projectList && (
                    <Table hover>
                      <tbody>
                        {projectList?.map((project, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedProject(project);
                              onProjectSelect(project.id);
                              setSearchMaterial('');
                              setMaterialPagination(1);
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <td
                              className={
                                project.id === selectedProject.id
                                  ? 'table-row-selected'
                                  : 'table-color'
                              }
                            >
                              {project.project_name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  <Card.Footer>
                    <Center>
                      <Pagination
                        total={pages + 1}
                        color="yellow"
                        radius="xl"
                        withEdges
                        size="xs"
                        page={projectPagination}
                        boundaries={1}
                        initialPage={projectPagination}
                        value={projectPagination}
                        onChange={(e) => {
                          fetchData(e, searchProject);
                          //console.log(e);
                          setProjectPagination(e);
                          setSelectedProjectMaterials([]);
                        }}
                      />
                    </Center>
                  </Card.Footer>
                </Tabs.Panel>
                <Tabs.Panel value="drafts">
                  <Space h="xl" />
                  {draftLoadingData ? (
                    <CustomCardLoader />
                  ) : (
                    <Table hover>
                      <tbody>
                        {draftList?.map((project, index) => (
                          <tr
                            key={index}
                            onClick={() => {
                              // setSelectedDraftProject(project);
                              navigate('/project-request/' + project.id);
                            }}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <td className={'table-color'}>{project.project_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  <Card.Footer>
                    <Center>
                      <Pagination
                        total={draftPages + 1}
                        color="yellow"
                        radius="xl"
                        withEdges
                        size="xs"
                        page={draftPagination}
                        boundaries={1}
                        initialPage={draftPagination}
                        value={draftPagination}
                        onChange={(e) => {
                          setDraftPagination(e);
                          setSelectedProjectMaterials([]);
                          fetchDraftData(e);
                        }}
                      />
                    </Center>
                  </Card.Footer>
                </Tabs.Panel>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={9}>
          {activeTab === 'drafts' && (
            <Card radius="xl">
              <Card.Body>
                <Group position="center">
                  <Text
                    size="lg"
                    weight={500}
                    color="yellow"
                    align="center"
                    style={{ fontSize: '18px' }}
                  >
                    {' Please select Project'}
                  </Text>
                </Group>
              </Card.Body>
            </Card>
          )}
          {activeTab === 'submitted' && selectedProjectMaterials?.length !== 0 && (
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {selectedProject?.project_name}
                  </Text>
                </Group>
              </Card.Body>
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    style={{ fontSize: '18px' }}
                  >{`Materials`}</Text>
                  <Button
                    radius="xl"
                    color={'yellow'}
                    rightIcon={<IconPlus size={14} color="white" />}
                    onClick={() => setShowAddMaterialModal(true)}
                  >{`Add Material`}</Button>
                </Group>
                <Space h="xl" />
                <TextInput
                  radius={'xl'}
                  placeholder={`SKU Description`}
                  sx={{ width: '16vw' }}
                  rightSection={
                    <ActionIcon
                      variant="filled"
                      color={'yellow'}
                      radius="xl"
                      size="md"
                      onClick={() => {
                        setSearchMaterial('');
                        setMaterialPagination(1);
                      }}
                    >
                      <IconRefresh size={14} color="black" />
                    </ActionIcon>
                  }
                  value={searchMaterial}
                  onChange={(e) => {
                    setSearchMaterial(e.target.value);
                    setMaterialPagination(1);
                    // setSelectedProjectMaterials([]);
                  }}
                />
              </Card.Body>
              <div className="table-responsive-sm">
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        <Center fw={500}>
                          SKU ID
                          <>
                            {sortedColumnData !== 'Sku Id' && (
                              <ActionIcon
                                onClick={() => {
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      if (a.sku_id !== null && b.sku_id !== null) {
                                        return Number(a.sku_id) - Number(b.sku_id);
                                      } else if (a.sku_id !== null) {
                                        return -1;
                                      } else if (b.sku_id !== null) {
                                        return 1;
                                      } else {
                                        return (
                                          Number(a.existing_item_number) -
                                          Number(b.existing_item_number)
                                        );
                                      }
                                    });

                                    return sortedArr;
                                  }

                                  var temparray = selectedProjectMaterials;
                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Sku Id');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Sku Id' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      if (a.sku_id !== null && b.sku_id !== null) {
                                        return Number(b.sku_id) - Number(a.sku_id);
                                      } else if (a.sku_id !== null) {
                                        return -1;
                                      } else if (b.sku_id !== null) {
                                        return 1;
                                      } else {
                                        return (
                                          Number(b.existing_item_number) -
                                          Number(a.existing_item_number)
                                        );
                                      }
                                    });

                                    return sortedArr;
                                  }
                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Sku Id');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Sku Id' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th>
                        <Center fw={500}>
                          SKU Description
                          <>
                            {sortedColumnData !== 'Sku Description' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      if (
                                        a.sku_description !== null &&
                                        b.sku_description !== null
                                      ) {
                                        return a.sku_description.localeCompare(b.sku_description);
                                      } else if (a.sku_description !== null) {
                                        return -1;
                                      } else if (b.sku_description !== null) {
                                        return 1;
                                      } else {
                                        return a.virtual_sku_description.localeCompare(
                                          b.virtual_sku_description,
                                        );
                                      }
                                    });

                                    return sortedArr;
                                  }
                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Sku Description');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Sku Description' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      if (
                                        a.sku_description !== null &&
                                        b.sku_description !== null
                                      ) {
                                        return b.sku_description.localeCompare(a.sku_description);
                                      } else if (a.sku_description !== null) {
                                        return -1;
                                      } else if (b.sku_description !== null) {
                                        return 1;
                                      } else {
                                        return b.virtual_sku_description.localeCompare(
                                          a.virtual_sku_description,
                                        );
                                      }
                                    });

                                    return sortedArr;
                                  }
                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Sku Description');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Sku Description' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th>
                        <Center fw={500}>
                          Plant
                          <>
                            {sortedColumnData !== 'Plant' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      return a.plant.localeCompare(b.plant);
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Plant');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Plant' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      return b.plant.localeCompare(a.plant);
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Plant');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Plant' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th>
                        <Center fw={500}>
                          Production Date
                          <>
                            {sortedColumnData !== 'Production Date' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const dateA = new Date(a.production_date);
                                      const dateB = new Date(b.production_date);
                                      return dateA - dateB;
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Production Date');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Production Date' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const dateA = new Date(a.production_date);
                                      const dateB = new Date(b.production_date);
                                      return dateB - dateA;
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Production Date');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Production Date' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th>
                        <Center fw={500}>
                          Status
                          <>
                            {sortedColumnData !== 'Status' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      // Sort by plant_status if both plant_status are not null
                                      if (a.plant_status !== null && b.plant_status !== null) {
                                        return a.plant_status - b.plant_status;
                                      }

                                      // Sort by material_status if plant_status is null
                                      if (a.plant_status === null && b.plant_status === null) {
                                        return a.material_status - b.material_status;
                                      }

                                      // Sort by plant_status if only one of them is null
                                      if (a.plant_status !== null && b.plant_status === null) {
                                        return -1;
                                      }

                                      if (a.plant_status === null && b.plant_status !== null) {
                                        return 1;
                                      }
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Status');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Status' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      // Sort by plant_status if both plant_status are not null
                                      if (a.plant_status !== null && b.plant_status !== null) {
                                        return b.plant_status - a.plant_status;
                                      }

                                      // Sort by material_status if plant_status is null
                                      if (a.plant_status === null && b.plant_status === null) {
                                        return b.material_status - a.material_status;
                                      }

                                      // Sort by plant_status if only one of them is null
                                      if (a.plant_status !== null && b.plant_status === null) {
                                        return -1;
                                      }

                                      if (a.plant_status === null && b.plant_status !== null) {
                                        return 1;
                                      }
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData(sorted);
                                  setSortedColumnData('Status');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Status' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th
                        style={{
                          maxWidth: '210px',
                        }}
                      >
                        <Center fw={500}>
                          Comment
                          <>
                            {sortedColumnData !== 'Comment' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const commentA = a.comment || ''; // Handle null values by considering an empty string
                                      const commentB = b.comment || '';

                                      return commentA.localeCompare(commentB); // Sort based on string comparison
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Comment');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Comment' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  var sorted = temparray.sort((a, b) => b?.comment - a?.comment);
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const commentA = a.comment || ''; // Handle null values by considering an empty string
                                      const commentB = b.comment || '';

                                      return commentB.localeCompare(commentA); // Sort based on string comparison
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Comment');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Comment' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                      <th>
                        <Center fw={500}>
                          Owner
                          <>
                            {sortedColumnData !== 'Owner' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const ownersA = String(determineOwners(a));
                                      const ownersB = String(determineOwners(b));

                                      return ownersA.localeCompare(ownersB); // Sort based on string comparison
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Owner');
                                  setSortType('ascending');
                                }}
                              >
                                <IconArrowsSort />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Owner' && sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  var temparray = selectedProjectMaterials;
                                  var sorted = temparray.sort((a, b) => b?.comment - a?.comment);
                                  function sortObjects(arr) {
                                    const sortedArr = arr.sort((a, b) => {
                                      const ownersA = String(determineOwners(a));
                                      const ownersB = String(determineOwners(b));

                                      return ownersB.localeCompare(ownersA); // Sort based on string comparison
                                    });

                                    return sortedArr;
                                  }

                                  var sorted = sortObjects(temparray);
                                  setSortedColumnData(sorted);
                                  //console.log('Sorted:', sorted);
                                  setSortedColumnData('Owner');
                                  setSortType('descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                            {sortedColumnData == 'Owner' && sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  onProjectSelect(selectedProject.id);
                                  setSortedColumnData(null);
                                  setSortType(null);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                          </>
                        </Center>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProjectMaterials
                      ?.filter((material) => {
                        if (searchMaterial === '') {
                          return material;
                        } else if (
                          material?.virtual_sku_description
                            ?.toLowerCase()
                            .includes(searchMaterial.toLowerCase()) ||
                          material?.sku_description
                            ?.toLowerCase()
                            .includes(searchMaterial.toLowerCase())
                        ) {
                          return material;
                        }
                      })
                      ?.slice((materialPagination - 1) * 5, (materialPagination - 1) * 5 + 5)

                      ?.map((material, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            !material?.plant_deleted &&
                              !material?.deleted &&
                              navigate(`/material/${material.id}/${material?.plant_id}`, {
                                state: {
                                  status: material?.plant_status,
                                  plant_id: material?.plant_id,
                                },
                              });
                          }}
                          style={{
                            cursor:
                              material?.plant_deleted || material?.deleted
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                        >
                          <td>
                            {material?.existing_item === 'Yes'
                              ? material?.existing_item_number
                              : material?.sku_id}
                          </td>

                          <td style={{ color: '#fab005' }}>
                            {!material?.sku_description
                              ? material?.virtual_sku_description
                              : material?.sku_description}
                          </td>

                          <td>{material.plant}</td>
                          <td>
                            {material.production_date
                              ? moment(material.production_date).format('MM-DD-YYYY')
                              : ''}
                          </td>
                          <td>
                            {material?.plant_deleted || material?.deleted
                              ? 'Deleted'
                              : material?.material_status == 1 && material?.plant_status == null
                              ? 'SKU Approval'
                              : material?.material_status == 2 && material?.plant_status == null
                              ? 'FG Form'
                              : material?.material_status == 3 && material?.plant_status == null
                              ? 'SKU ID Creation'
                              : material?.material_status == 4 && material?.plant_status == null
                              ? 'Packaging BOM'
                              : material?.plant_status == 5
                              ? 'Packaging BOM'
                              : material?.plant_status == 6
                              ? 'BOM Validation'
                              : material?.plant_status == 7
                              ? 'Liquid BOM'
                              : material?.plant_status == 8
                              ? 'SAP Bom Creation'
                              : material?.plant_status == 9
                              ? 'SKU Costing'
                              : material?.plant_status == 10
                              ? 'Costing Approval'
                              : material?.plant_status == 11
                              ? 'SAP Cost Update'
                              : material?.plant_status == 12
                              ? 'Cost Notification'
                              : material?.plant_status == 13
                              ? 'Completed'
                              : ''}
                          </td>
                          <td
                            style={{
                              maxWidth: material?.comment?.length > 70 && '270px',
                              overflow: 'hidden',
                              textAlign: 'justify',
                              height: material?.comment?.length > 70 && '120px',
                              maxHeight: material?.comment?.length > 70 && '120px',
                              overflowY: material?.comment?.length > 70 && 'scroll',
                              display: material?.comment?.length > 70 && 'block',
                            }}
                          >
                            {material?.comment}
                          </td>
                          <td style={{ overflow: 'auto', maxWidth: '200px', whiteSpace: 'nowrap' }}>
                            {material?.step_owner
                              ? material.step_owner.split('|').map((element, index) => (
                                  <React.Fragment key={index}>
                                    {element}
                                    <br />
                                  </React.Fragment>
                                ))
                              : 'Completed'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <Card.Footer>
                <Container>
                  <Center>
                    <Pagination
                      total={
                        Math.floor(
                          selectedProjectMaterials?.filter((item) => {
                            if (searchMaterial === '') {
                              return item;
                            } else if (
                              item?.virtual_sku_description
                                ?.toLowerCase()
                                .includes(searchMaterial.toLowerCase()) ||
                              item?.sku_description
                                ?.toLowerCase()
                                .includes(searchMaterial.toLowerCase())
                            ) {
                              return item;
                            }
                          })?.length / 5,
                        ) + 1
                      }
                      color="yellow"
                      radius="xl"
                      withEdges
                      page={materialPagination}
                      initialPage={1}
                      onChange={(page) => setMaterialPagination(page)}
                      boundaries={1}
                    />
                  </Center>
                </Container>
              </Card.Footer>
            </Card>
          )}
          {activeTab === 'submitted' && selectedProjectMaterials?.length === 0 && (
            <Card radius="xl">
              <Card.Body>
                <Group position="center">
                  <Text
                    size="lg"
                    weight={500}
                    color="yellow"
                    align="center"
                    style={{ fontSize: '18px' }}
                  >
                    {' Please go to Project Request and Add Materials'}
                  </Text>
                </Group>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <AddMaterial
        showAddMaterialModal={showAddMaterialModal}
        closeAddMaterialModal={() => {
          setShowAddMaterialModal(false);
        }}
        addMaterial={addMaterialInProject}
      />
    </>
  );
}

export default ProjectRequest;
