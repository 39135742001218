import React, { useEffect } from 'react';
import { useHistory, useNavigate } from 'react-router-dom';

import '../../assets/styles/Login.css';
import { Button, Card } from 'react-bootstrap';
import { loginRequest } from '../../utils/authConfig';
import ABILogo from '../../assets/images/abi_logo_black.png';
import DSCOELogo from '../../assets/images/dscoe_logo.png';
import FIGOLogo from '../../assets/images/figo_black_logo.png';
// import BtLogo from '../../assets/images/bt_logo.png';
// import KriaLogoBlack from '../../assets/images/kria_logo_black.png';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

const Login = () => {
  const navigate = useNavigate();

  const { instance, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  //console.log('I AM IN LOGIN');

  useEffect(() => {
    //console.log(`AUTH LOG = ${isAuthenticated}`);
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      //console.log('You are authenticated!');
      //console.log(`Refer: ${document.referrer}`);
      // history.goBack();
      navigate('/');
    }
  }, [inProgress, isAuthenticated]);

  return (
    !isAuthenticated &&
    inProgress === InteractionStatus.None && (
      <div className="login-page">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <Card>
                <Card.Body className="p-6">
                  <div className="text-center mb-6">
                    <img src={FIGOLogo} className="h-9" alt="KRIA Logo" />
                  </div>

                  <div className="card-title text-center">
                    <p>
                      {`Please use your `}
                      <strong className="golden-text">{`AB InBev ID`}</strong>
                      {` to login`}
                    </p>
                  </div>

                  <div className="form-footer">
                    <Button
                      className="btn btn-primary btn-block"
                      style={{ borderRadius: '40px' }}
                      onClick={() => instance.loginRedirect(loginRequest)}
                    >
                      {`Login`}
                    </Button>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="text-center">
                    <img
                      src={ABILogo}
                      className="h-5"
                      alt="AB InBev Logo"
                      style={{ paddingRight: '25px' }}
                    />
                    <img
                      src={DSCOELogo}
                      className="h-9"
                      alt="DT Logo"
                      style={{
                        borderLeft: '1px solid #c9c9c9',
                        paddingLeft: '2.7rem',
                      }}
                    />
                  </div>
                </Card.Footer>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Login;
