import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Container, Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/userContext';
import {
  Group,
  SimpleGrid,
  Grid,
  MultiSelect,
  Text,
  Button,
  Tooltip,
  Table,
  Modal,
  Input,
  Select,
  Space,
  TextInput,
  ActionIcon,
  FileButton,
  Loader,
  Pagination,
  List,
  UnstyledButton,
  Avatar,
  Collapse,
  Center,
  Checkbox,
  Textarea,
} from '@mantine/core';
import {
  IconCheck,
  IconExclamationMark,
  IconRefresh,
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons';
import { isMobile } from 'react-device-detect';
import { actionLogStatusBadge } from '../../utils/Utils';
import { IconDeviceFloppy } from '@tabler/icons';
import CustomLoader from '../../components/CustomLoader';
import CustomCardLoader from '../../components/CustomCardLoader';
import { pageContext } from '../../context/pageContext';
import materialService from '../../services/materialService';
import costService from '../../services/costService';
import homeServices from '../../services/homeServices';
import { notification } from 'antd';
function CostNotificationsTable({ refreshTable }) {
  const navigate = useNavigate();

  const {
    searchCostingNotifications,
    setSearchCostingNotifications,
    costingNotificationPagination,
    setCostingNotificationPagination,
    totalCostingNotifications,
    setTotalCostingNotifications,
  } = useContext(pageContext);

  const { loading, loadMaterialApprovalList, materialApprovalList, projects } =
    useContext(UserContext);

  useEffect(() => {
    loadMaterialApprovalList();
  }, [navigate]);

  // const [projectList, setProjectList] = useState([]);
  const [selectedNotificattions, setSelectedNotificattions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [selectAllProjects, setSelectAllProjects] = useState(false);
  const [costingNotificationValid, setCostingNotificationValid] = useState(false);
  const [archiveFilter, setArchiveFilter] = useState([]);
  const [archiveTypes, setArchiveTypes] = useState([
    { label: 'Archived', value: '13' },
    { label: 'New', value: '12' },
  ]);
  const [plantList, setPlantList] = useState([]);
  const [plantFilter, setPlantFilter] = useState([]);
  const [crossReferenceList, setCrossReferenceList] = useState([
    {
      value: 'ON - TBS X-Ref to be maintained by business',
      label: 'ON - TBS X-Ref to be maintained by business',
    },
    {
      value: 'QC - Data feeds from SAP and not from NAZ',
      label: 'QC - Data feeds from SAP and not from NAZ',
    },
  ]);

  const [upcSccCodeList, setUpcSccCodeList] = useState([
    {
      value: 'N/A',
      label: 'N/A',
    },
  ]);

  // useEffect(() => {
  //   let temp = [];
  //   projects?.map((project) => {
  //     project?.materials?.map((material) => {
  //       material.plants?.map((plant) => {
  //         //console.log("Project:",project, material, plant)
  //         var first = material?.sku_description?.substring( material?.sku_description?.indexOf('_') + 1);
  //         var second = first?.substring( 0, first?.indexOf('_') );
  //         temp.push({
  //           ...plant,
  //           plant: plant?.plant,
  //           project_type: project?.project_type,
  //           project_name: project?.project_name,
  //           project_year: project?.project_year,
  //           project_owner: project?.project_owner,
  //           material_status: material?.material_status,
  //           material_id: material?.id,
  //           sku_description: material?.fg_type === "Virtual" ? material?.virtual_sku_description : material?.sku_description,
  //           sku_id: material?.existing_item == 'Yes' ? material?.existing_item_number : material?.sku_id,
  //           fg_type: material?.fg_type,
  //           liquid: material?.liquid,
  //           m_deleted: material?.deleted,
  //           deleted: material?.deleted,
  //           pl_deleted: plant?.deleted,
  //           pr_deleted: project?.deleted,
  //           plant_status: plant?.status,
  //           plant_id: plant?.id,
  //           existing_item: material?.existing_item,
  //           existing_item_number: material?.existing_item_number,
  //           ph: material?.ph,
  //           pack_size: second,
  //           pack_size_2: material?.pack_size_2,
  //           pack_size_1: material?.pack_size_1,
  //           notification_region: plant?.notification_region,
  //           notification_cross_reference: plant?.notification_cross_reference,
  //           notification_upc_scc_code: plant?.notification_upc_scc_code,
  //           archive_status: plant?.archive_status,
  //           archive_status_final: plant?.archive_status == true ? "Archived" : "New",
  //           selected: false
  //           // rejected_comment: plant?.rejected_comment,
  //         });
  //       });
  //     });
  //   });

  //   //console.log(
  //     'Costing Controller',
  //     temp
  //       ?.filter((item) => item?.material_status == 4)
  //       ?.filter((item) => item?.deleted === false)
  //       ?.filter(
  //         (item) => item?.plant_status == 12,
  //       ),
  //   );
  //   setProjectList(
  //     temp
  //       ?.filter((item) => item?.material_status == 4)
  //       ?.filter((item) => item?.deleted === false)
  //       ?.filter(
  //         (item) => item?.plant_status == 12,
  //       ),
  //   );
  // }, [projects]);

  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [filterType, setFilterType] = useState('');

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);
    setLoadingData(true);
    homeServices
      .getCostingNotificationsData({
        offset: (offset - 1) * 10,
        sort_column: sort_column,
        sort_type: sort_type,
        filterType: filterType,
        archiveFilter: archiveFilter,
        plantFilter: plantFilter,
      })
      .then((res) => {
        //console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(
          res?.data?.map((item) => {
            var first = item?.sku_description?.substring(item?.sku_description?.indexOf('_') + 1);
            var second = first?.substring(0, first?.indexOf('_'));
            return {
              ...item,
              pack_size_2: item?.pack_size_2,
              pack_size_1: item?.pack_size_1,
              pack_size: second,
              sku_description: item?.sku_description,
              sku_id: item?.existing_item == 'Yes' ? item?.existing_item_number : item?.sku_id,
            };
          }),
        );
        setPages(res?.total);
        setLoadingData(false);
        setTotalCostingNotifications(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(1);
    }, 1500);
    return () => clearTimeout(timer);
  }, [archiveFilter?.length, plantFilter?.length]);

  useEffect(() => {
    selectedNotificationsValidation();
  }, [selectedNotificattions]);

  useEffect(() => {
    materialService.getAllPlants().then((res) => {
      if (res && res.data) {
        setPlantList(
          res?.data?.plant ? res?.data?.plant.sort((a, b) => (a.label < b.label ? -1 : 1)) : [],
        );
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      plants: projectList,
    },

    validationSchema: Yup.object({
      plants: Yup.array().of(
        Yup.object().shape({
          ph: Yup.string().required('Plant is required'),
          notification_cross_reference: Yup.string().required('Selling Region is required'),
          notification_region: Yup.string().required('Production Date is required'),
          notification_upc_scc_code: Yup.string().required('STR Date is required'),
        }),
      ),
    }),
    onSubmit: (values) => {
      //console.log(values);
      //console.log(formik.errors);
      // costService
      //   .saveRMCostingTable({
      //     ...values,
      //     plant_id: plantData?.id,
      //   })
      //   .then((res) => {
      //     refreshTable();
      //     toast.success('Saved Successfully');
      //   })
      //   .catch((err) => {
      //     toast.error(err || 'Something went wrong');
      //   });
      // formik.resetForm();
    },
  });

  const handleSaveCostingNotifications = (item) => {
    //console.log(item);
    costService
      .saveCostingNotification(item)
      .then((res) => {
        fetchData(1);
        setLoadingStatus(false);
        setSelectAllProjects(false);
        toast.success('Saved Successfully');
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
    // formik.resetForm();
  };

  const selectedNotificationsValidation = async () => {
    let jsonValid = true;
    if (selectedNotificattions.length > 0) {
      await selectedNotificattions.forEach(function (obj) {
        if (
          obj['ph'] == '' ||
          obj['ph'] == null ||
          obj['notification_region'] == '' ||
          obj['notification_upc_scc_code'] == '' ||
          obj['notification_cross_reference'] == '' ||
          obj['notification_region'] == null ||
          obj['notification_upc_scc_code'] == null ||
          obj['notification_cross_reference'] == null
        ) {
          jsonValid = false;
          //console.log('False:', selectedNotificattions);
        }
      });
      if (jsonValid == true) {
        //console.log('True:', selectedNotificattions);
      }
      //console.log(jsonValid);
      await setCostingNotificationValid(jsonValid);
    } else {
      setCostingNotificationValid(false);
    }
  };

  const handleSaveAndSendCostingNotification = () => {
    costService
      .saveAndSendCostingNotification(selectedNotificattions)
      .then((res) => {
        fetchData(1);
        setSelectedNotificattions([]);
        setLoadingStatus(false);
        setSelectAllProjects(false);
        toast.success('Saved Successfully');
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
    // formik.resetForm();
  };

  useEffect(() => {
    //console.log('Costing Notification Valid:', costingNotificationValid);
  }, [costingNotificationValid]);

  return (
    <>
      <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
        <Card radius="xl">
          <div style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem' }}>
            <div>
              <Group position="apart">
                <Text
                  size="xl"
                  weight={700}
                  color="yellow"
                  align="left"
                  style={{ fontSize: '18px' }}
                >
                  {'Cost Notification'}
                </Text>
                <Button
                  disabled={costingNotificationValid == false}
                  color="yellow"
                  radius="xl"
                  className="btn btn-primary"
                  style={{ borderRadius: '40px' }}
                  onClick={() => {
                    // //console.log(formik.errors)
                    // formik.handleSubmit();
                    handleSaveAndSendCostingNotification();
                    // //console.log(selectedNotificattions);
                    // if (blendBOMList.length === 0) {
                    //   toast.error('Please add atleast one item in the Blend BOM');
                    //   return;
                    // } else {
                    //   formik.handleSubmit();
                    // }
                  }}
                  // disabled={loading}
                >
                  {/* {!submitLoader && 'Submit'} */}
                  Save & Send Notification
                </Button>
              </Group>
              <div>
                <Text size="sm" align="left">
                  {
                    'To be able to send the cost notification, please check wether the mandatory items, which are: Region, Cross Reference & UPC/SCC Code of the selected items have been filled.'
                  }
                </Text>
              </div>
            </div>
          </div>

          <Card.Body>
            {/* {projectList && projectList?.length > 0 && ( */}
            <>
              <div>
                <Grid>
                  <Grid.Col span={4}>
                    <Select
                      placeholder="Select Filter"
                      radius={'lg'}
                      value={filterType}
                      onChange={(e) => {
                        setArchiveFilter([]);
                        setPlantFilter([]);
                        setFilterType(e);
                        setCostingNotificationPagination(1);
                      }}
                      data={[
                        { label: 'Archive', value: 'Archive' },
                        { label: 'Plant', value: 'Plant' },
                      ]}
                      rightSection={
                        <ActionIcon
                          variant="filled"
                          color={'yellow'}
                          radius="xl"
                          size="md"
                          onClick={() => {
                            setFilterType('');
                            setArchiveFilter([]);
                            setPlantFilter([]);
                            setSearchCostingNotifications('');
                            setCostingNotificationPagination(1);
                          }}
                        >
                          <IconRefresh size={14} color="black" />
                        </ActionIcon>
                      }
                      // error={formik.errors.production_line}
                    />
                  </Grid.Col>
                  {filterType === 'Archive' && (
                    <Grid.Col span={4}>
                      <MultiSelect
                        placeholder="Archive Filter"
                        radius={'lg'}
                        value={archiveFilter}
                        onChange={(e) => {
                          setArchiveFilter(e);
                        }}
                        data={archiveTypes}
                        searchable
                        nothingFound="Nothing found"
                        rightSection={
                          <ActionIcon
                            variant="filled"
                            color={'yellow'}
                            radius="xl"
                            size="md"
                            onClick={() => {
                              setArchiveFilter([]);
                              setFilterType('');
                            }}
                          >
                            <IconRefresh size={14} color="black" />
                          </ActionIcon>
                        }
                        // error={formik.errors.production_line}
                      />
                    </Grid.Col>
                  )}

                  {filterType === 'Plant' && (
                    <Grid.Col span={4}>
                      <MultiSelect
                        placeholder="Plant List"
                        radius={'lg'}
                        value={plantFilter}
                        onChange={(e) => {
                          //console.log(plantFilter);
                          setPlantFilter(e);
                        }}
                        data={plantList?.length > 0 ? plantList : []}
                        searchable
                        nothingFound="Nothing found"
                        rightSection={
                          <ActionIcon
                            variant="filled"
                            color={'yellow'}
                            radius="xl"
                            size="md"
                            onClick={() => {
                              // setSearchCostingNotifications('');
                              // setCostingNotificationPagination(1);
                              setPlantFilter([]);
                              setFilterType('');
                            }}
                          >
                            <IconRefresh size={14} color="black" />
                          </ActionIcon>
                        }
                        // error={formik.errors.production_line}
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </div>
              {loadingData ? (
                <CustomCardLoader />
              ) : (
                <div className="table-responsive">
                  <Table className="table card-table table-outline text-nowrap table-vcenter card-table">
                    <thead>
                      <tr>
                        <th>
                          {/* <Checkbox
                      size="xs"
                      color="yellow"
                      // indeterminate={
                      //   selectedEmps?.length < pilotEmployees?.length &&
                      //   selectedEmps?.length !== 0
                      // }
                      checked={
                        selectAllProjects
                      }
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          // setProjectList(
                          //   projectList?.map((project) => project?.stakeholder_id),
                          // );

                          // let temp = []
                          // selectedNotificattions.push.apply(
                          //   selectedNotificattions,
                          //   projectList?.map((project) => project?.stakeholder_id),
                          // ),
                          var temparray = projectList.map(e => ({...e, selected: true}));
                          setProjectList(temparray);
                          setSelectedNotificattions(temparray);
                          setSelectAllProjects(true)
                        } else{
                          var temparray = projectList.map(e => ({...e, selected: false}));
                          setProjectList(temparray);
                          setSelectedNotificattions([]);
                          setSelectAllProjects(false)
                            }
                      }}
                    /> */}
                        </th>
                        <th>
                          <Center fw={500}>
                            SKU ID
                            <>
                              {sortedColumnData !== 'SKU ID' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'SKU ID',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'SKU ID' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'SKU ID',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'SKU ID' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            SKU Description
                            <>
                              {sortedColumnData !== 'SKU Description' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'SKU Description',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'SKU Description' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'SKU Description',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'SKU Description' &&
                                sortType == 'descending' && (
                                  <ActionIcon
                                    onClick={() => {
                                      fetchData(1, 'costingNotificationsTable');
                                    }}
                                  >
                                    <IconSortDescending />
                                  </ActionIcon>
                                )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            Archive Status
                            <>
                              {sortedColumnData !== 'Archive Status' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Archive Status',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Archive Status' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Archive Status',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Archive Status' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            PH Basic Data
                            <>
                              {sortedColumnData !== 'PH Basic Data' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'PH Basic Data',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'PH Basic Data' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'PH Basic Data',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'PH Basic Data' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            PH Sales Data
                            <>
                              {sortedColumnData !== 'PH Sales Data' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'PH Sales Data',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'PH Sales Data' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'PH Basic Data',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'PH Sales Data' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            Plant
                            <>
                              {sortedColumnData !== 'Plant' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable', 'Plant', 'ascending');
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Plant' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Plant',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Plant' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            Pack Size
                            <>
                              {sortedColumnData !== 'Pack Size' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Pack Size',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Pack Size' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Pack Size',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Pack Size' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            Region
                            <>
                              {sortedColumnData !== 'Region' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Region',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Region' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Region',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Region' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>

                        <th>
                          <Center fw={500}>
                            Cross Reference
                            <>
                              {sortedColumnData !== 'Cross Reference' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Cross Reference',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Cross Reference' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'Cross Reference',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'Cross Reference' &&
                                sortType == 'descending' && (
                                  <ActionIcon
                                    onClick={() => {
                                      fetchData(1, 'costingNotificationsTable');
                                    }}
                                  >
                                    <IconSortDescending />
                                  </ActionIcon>
                                )}
                            </>
                          </Center>
                        </th>
                        <th>
                          <Center fw={500}>
                            UPC/SCC Code
                            <>
                              {sortedColumnData !== 'UPC/SCC Code' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'UPC/SCC Code',
                                      'ascending',
                                    );
                                  }}
                                >
                                  <IconArrowsSort />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'UPC/SCC Code' && sortType == 'ascending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(
                                      1,
                                      'costingNotificationsTable',
                                      'UPC/SCC Code',
                                      'descending',
                                    );
                                  }}
                                >
                                  <IconSortAscending />
                                </ActionIcon>
                              )}
                              {sortedColumnData == 'UPC/SCC Code' && sortType == 'descending' && (
                                <ActionIcon
                                  onClick={() => {
                                    fetchData(1, 'costingNotificationsTable');
                                  }}
                                >
                                  <IconSortDescending />
                                </ActionIcon>
                              )}
                            </>
                          </Center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectList
                        // ?.filter((item) => {
                        //   if (plantFilter.length <= 0 && archiveFilter.length <= 0) {
                        //     return item;
                        //   } else if (
                        //     plantFilter.includes(item.plant) ||
                        //     archiveFilter.includes(item.plant_status)
                        //   ) {
                        //     return item;
                        //   }
                        // })
                        ?.map((project, index) => (
                          <tr
                            key={index}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <td>
                              <Checkbox
                                size="xs"
                                color="yellow"
                                checked={selectedNotificattions
                                  .map((item) => item.plant_id)
                                  .includes(project.plant_id)}
                                onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                    setProjectList((prev) =>
                                      prev.map((item) =>
                                        item.plant_id === project.plant_id
                                          ? {
                                              ...item,
                                              selected: true,
                                            }
                                          : item,
                                      ),
                                    );
                                    setSelectedNotificattions([...selectedNotificattions, project]);
                                  } else {
                                    var temparray = selectedNotificattions.filter(
                                      (item) => item.plant_id !== project.plant_id,
                                    );
                                    setSelectedNotificattions(temparray);
                                    setProjectList((prev) =>
                                      prev.map((item) =>
                                        item.plant_id === project.plant_id
                                          ? {
                                              ...item,
                                              selected: false,
                                            }
                                          : item,
                                      ),
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td>
                              {project?.existing_item === 'Yes'
                                ? project?.existing_item_number
                                : project?.sku_id}
                            </td>
                            <td>{project?.sku_description}</td>
                            <td>
                              {' '}
                              <Center>
                                {project?.archive_status == true ? (
                                  <IconCheck color="green" />
                                ) : (
                                  <IconExclamationMark color="red" />
                                )}
                              </Center>
                            </td>
                            <td
                              style={{
                                minWidth: '200px',
                              }}
                            >
                              <TextInput
                                placeholder="PH Basic Data"
                                radius={'xl'}
                                value={project?.ph}
                                onChange={(e) => {
                                  setProjectList((prev) =>
                                    prev.map((item) =>
                                      item.plant_id === project.plant_id
                                        ? {
                                            ...item,
                                            ph: e.target.value,
                                          }
                                        : item,
                                    ),
                                  );
                                }}
                                error={formik.errors.ph}
                              />
                            </td>
                            <td>{project?.ph}</td>
                            <td>{project?.plant}</td>
                            <td>{project?.pack_size}</td>
                            <td
                              style={{
                                minWidth: '280px',
                              }}
                            >
                              <MultiSelect
                                placeholder="Region"
                                radius={'xl'}
                                value={project?.notification_region?.split(',')}
                                onChange={(e) => {
                                  //console.log(e.join(','));
                                  setProjectList((prev) =>
                                    prev.map((item) =>
                                      item.plant_id === project.plant_id
                                        ? {
                                            ...item,
                                            notification_region: e.join(','),
                                          }
                                        : item,
                                    ),
                                  );
                                }}
                                data={[
                                  {
                                    value: 'Onatrio',
                                    label: 'Ontario',
                                  },
                                  {
                                    value: 'Quebec',
                                    label: 'Quebec',
                                  },
                                  {
                                    value: 'West',
                                    label: 'West',
                                  },
                                  {
                                    value: 'Atlantic',
                                    label: 'Atlantic',
                                  },
                                ]}
                                error={formik.errors.notification_region}
                              />
                            </td>
                            <td
                              style={{
                                minWidth: '360px',
                              }}
                            >
                              {!project?.notification_cross_reference ? (
                                <Select
                                  placeholder="Cross Reference"
                                  radius={'xl'}
                                  creatable
                                  searchable
                                  value={project?.notification_cross_reference}
                                  onChange={(e) => {
                                    setProjectList((prev) =>
                                      prev.map((item) =>
                                        item.plant_id === project.plant_id
                                          ? {
                                              ...item,
                                              notification_cross_reference: e,
                                            }
                                          : item,
                                      ),
                                    );
                                  }}
                                  getCreateLabel={(query) => `+ Create ${query}`}
                                  onCreate={(val) => {
                                    // setCrossReferenceList((crossReferenceList) => [...crossReferenceList, val]);
                                    const item = { value: val, label: val };
                                    return item;
                                  }}
                                  data={
                                    crossReferenceList.some(
                                      (e) =>
                                        e.value === project?.notification_cross_reference &&
                                        project?.notification_cross_reference != null &&
                                        project?.notification_cross_reference &&
                                        project?.notification_cross_reference != '',
                                    )
                                      ? crossReferenceList.concat([
                                          project?.notification_cross_reference,
                                        ])
                                      : crossReferenceList
                                  }
                                  error={formik.errors.notification_cross_reference}
                                />
                              ) : (
                                <Textarea
                                  radius={'xl'}
                                  size="xs"
                                  minRows={2}
                                  maxRows={4}
                                  value={project?.notification_cross_reference}
                                  onChange={(e) => {
                                    setProjectList((prev) =>
                                      prev.map((item) =>
                                        item.plant_id === project.plant_id
                                          ? {
                                              ...item,
                                              notification_cross_reference: e.target.value,
                                            }
                                          : item,
                                      ),
                                    );
                                  }}
                                  error={formik.errors.notification_cross_reference}
                                />
                              )}
                            </td>
                            <td
                              style={{
                                minWidth: '160px',
                              }}
                            >
                              {project?.notification_upc_scc_code ? (
                                <>
                                  <TextInput
                                    placeholder="UPC/SCC Code"
                                    radius={'xl'}
                                    value={project?.notification_upc_scc_code}
                                    onChange={(e) => {
                                      setProjectList((prev) =>
                                        prev.map((item) =>
                                          item.plant_id === project.plant_id
                                            ? {
                                                ...item,
                                                notification_upc_scc_code: e.target.value,
                                              }
                                            : item,
                                        ),
                                      );
                                    }}
                                    error={formik.errors.notification_upc_scc_code}
                                  />
                                </>
                              ) : (
                                <Select
                                  placeholder={'UPC/SCC Code'}
                                  radius={'xl'}
                                  value={project?.notification_upc_scc_code}
                                  onChange={(e) => {
                                    setProjectList((prev) =>
                                      prev.map((item) =>
                                        item.plant_id === project.plant_id
                                          ? {
                                              ...item,
                                              notification_upc_scc_code: e,
                                            }
                                          : item,
                                      ),
                                    );
                                  }}
                                  searchable
                                  creatable
                                  getCreateLabel={(query) => `+ Create ${query}`}
                                  onCreate={(val) => {
                                    // setCrossReferenceList((crossReferenceList) => [...crossReferenceList, val]);
                                    const item = { value: val, label: val };
                                    return item;
                                  }}
                                  data={
                                    upcSccCodeList.some(
                                      (e) =>
                                        e.value === project?.notification_upc_scc_code &&
                                        project?.notification_upc_scc_code != null &&
                                        project?.notification_upc_scc_code &&
                                        project?.notification_upc_scc_code != '',
                                    )
                                      ? upcSccCodeList.concat([project?.notification_upc_scc_code])
                                      : upcSccCodeList
                                  }
                                  error={formik.errors.notification_upc_scc_code}
                                />
                              )}
                            </td>

                            <td>
                              {loadingStatus == false && (
                                <ActionIcon variant="light" color={'yellow'} radius="xl">
                                  <IconDeviceFloppy
                                    size={16}
                                    color="black"
                                    onClick={() => {
                                      setLoadingStatus(true);
                                      handleSaveCostingNotifications(projectList[index]);
                                    }}
                                  />
                                </ActionIcon>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
              <Card.Footer>
                <Container>
                  <Center>
                    <Pagination
                      total={pages + 1}
                      color="yellow"
                      radius="xl"
                      withEdges
                      page={costingNotificationPagination}
                      boundaries={1}
                      initialPage={costingNotificationPagination}
                      value={costingNotificationPagination}
                      onChange={(e) => {
                        // //console.log(e);
                        setCostingNotificationPagination(e);
                        fetchData(e, '', sortedColumnData, sortType);
                      }}
                    />
                  </Center>
                </Container>
              </Card.Footer>
            </>
            {/* ) */}
            {/* } */}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default CostNotificationsTable;
