import React, { useEffect, useState, useContext, useMemo } from 'react';

import {
    Button,
    Col,
    Container,
    Modal,
    Row,
    Spinner,
    Toast,
    ToastContainer,
    Card,
} from 'react-bootstrap';
import { Text, Group } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import ManageUsers from './ManageUsers';
import ManagePlants from './ManagePlants';
import ManageMDM from './ManageMDM';
import ManageProjectRequestors from './ManageProjectRequestors';
import ManageDemandPlanners from './ManageDemandPlanners';
import ManageBSClog from './ManageBSClog';
import ManageBrewMasters from './ManageBrewMasters';
import ManageCosting from './ManageCosting';
import ManageAdmin from './ManageAdmin';
import ManageControllers from './ManageControllers';
import ManageSLA from './ManageSLA';
import { ActiveUserContext } from '../../context/activerUserContext';
import ManageSupplyPlanners from './ManageSupplyPlanners';
import ManageRMCosting from './ManageRMCosting';
import CustomCardLoader from '../../components/CustomCardLoader';
import { Link } from 'react-router-dom';


function ManageHub() {
    const location = useLocation();
    const [userState, dispatch] = useContext(ActiveUserContext);
    const [activeTab, setActiveTab] = useState(location?.state?.clickedTab);

    function check_roles(allowed_roles) {

        if (userState?.roles) {
            const contains = userState.roles.some((element) => {
                return allowed_roles.includes(element);
            });

            return contains;
        }

        return false;
    }



    const tabData = useMemo(
        () => [
            {
                id: 'users',
                name: 'Users',
                page: <ManageUsers />,
                access: ['user'],
            },
            {
                id: 'Project_Requestors',
                name: 'Project Requestors',
                page: <ManageProjectRequestors />,
                access: ['user'],
            },
            {
                id: 'Demand_Planners',
                name: 'Demand Planners',
                page: <ManageDemandPlanners />,
                access: ['user'],
            },
            {
                id: 'Supply Planners',
                name: 'Supply Planners',
                page: <ManageSupplyPlanners />,
                access: ['user'],
            },
            {
                id: 'MDM',
                name: 'MDM',
                page: <ManageMDM />,
                access: ['user'],
            },
            {
                id: 'BSC Logistics',
                name: 'BSC Logistics',
                page: <ManageBSClog />,
                access: ['user'],
            },
            {
                id: 'Brewmasters',
                name: 'Brewmasters',
                page: <ManageBrewMasters />,
                access: ['user'],
            },
            {
                id: 'GCC Costing',
                name: 'GCC Costing',
                page: <ManageCosting />,
                access: ['user'],
            },
            {
                id: 'Brewery Controllers',
                name: 'Brewery Controllers',
                page: <ManageControllers />,
                access: ['user'],
            },
            {
                id: 'RM Cost',
                name: 'RM Cost',
                page: <ManageRMCosting/>,
                access: ['user'],
            },
            {
                id: 'Plants',
                name: 'Production Plants',
                page: <ManagePlants />,
                access: ['user'],
            },
            {
                id: 'SLA',
                name: 'Manage SLA',
                page: <ManageSLA />,
                access: ['user'],
            },
            {
                id: 'Admin',
                name: 'Admin',
                page: <ManageAdmin />,
                access: ['user'],
            },
        ],
        [],
    );

    const getTabById = (tab_id) =>
        tabData.find((object) => {
            return object.id === tab_id;
        });

    if (!userState.roles) {
        return <CustomCardLoader />;
    }
    return (
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            {check_roles(['user'])
                ? (
                    <Row>
                        <Col xs={12} sm={3}>
                            <Card style={{ padding: '16px' }}>
                                <div className="list-group list-group-transparent mb-0">
                                    {tabData.map((tab, index) => (
                                        <a
                                            onClick={() => {
                                                setActiveTab(tab?.id);
                                            }}
                                            className={`list-group-item list-group-item-action d-flex align-items-center ${activeTab == tab?.id ? ' active' : null}`}
                                            key={tab?.id}
                                        >
                                            {tab?.name}
                                        </a>
                                    ))}
                                </div>
                            </Card>
                        </Col>

                        <Col xs={12} sm={9}>
                            {/* {tabData.map((tab, index) => (getTabById('stakeholders') ? tab?.page : null))} */}
                            {getTabById(activeTab)?.page}
                            {!activeTab && (
                                <Card>
                                    <Card.Body>
                                        <Group position="apart">
                                            <Text size="xl" weight={700} color="yellow" align="left">{`Manage Hub`}</Text>
                                        </Group>
                                    </Card.Body>
                                    <Card.Body>
                                        <Group position="apart">
                                            <Text align="left">{`Click on one of the navigation tabs to get started.`}</Text>
                                        </Group>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>
                )
                : (
                    (() => {
                        return (<Card>
                            <Card.Body>
                                <Group position="apart">
                                    <Text size="xl" weight={700} color="yellow" align="left">{`Manage Hub`}</Text>
                                </Group>
                            </Card.Body>
                            <Card.Body>
                                <Group position="apart">
                                    <Text align="left">
                                        {`Sorry, but it seems that you do not have the required permissions to access or view this page.`}<br />
                                        {`Please contact the administrator to request access to this page.`}<br />
                                        {`Thank you.`}
                                        <p>
                                            <Link to="/role-request">Click Here</Link> to request the administrator for access.
                                        </p>
                                    </Text>
                                </Group>
                            </Card.Body>
                        </Card>);
                    })()
                )
            }
        </div>
    )
}

export default ManageHub;

