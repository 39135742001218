import React from 'react';
import moment from 'moment-timezone';
import { Loader, Text } from '@mantine/core';

function LastRefreshedAt({ lastRefreshedAt, screen }) {
  return (
    <Text size="xl" weight={500} color="yellow" align="left" style={{ fontSize: '14px' }}>
      {screen === 'Summary' && (
        <span>
          Snowflake Data Last Refresh:{' '}
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            {lastRefreshedAt?.snowflake_table_refresh_timing ? (
              moment
                ?.tz(lastRefreshedAt?.snowflake_table_refresh_timing, 'America/Toronto')
                .format('MM-DD-YYYY')
            ) : (
              <Loader variant="dots" color="yellow" />
            )}
            ,{' '}
            {lastRefreshedAt?.snowflake_table_refresh_timing &&
              moment
                ?.tz(lastRefreshedAt?.snowflake_table_refresh_timing, 'America/Toronto')
                .format('hh:mm:ss A')}{' '}
            (EDT/EST)
          </span>
        </span>
      )}

      {screen === 'Costing' && (
        <span>
          Costing Data Last Refresh:{' '}
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            {lastRefreshedAt?.costing_table_refresh_timing ? (
              moment
                ?.tz(lastRefreshedAt?.costing_table_refresh_timing, 'America/Toronto')
                .format('MM-DD-YYYY')
            ) : (
              <Loader variant="dots" color="yellow" />
            )}
            ,{' '}
            {lastRefreshedAt?.costing_table_refresh_timing &&
              moment
                ?.tz(lastRefreshedAt?.costing_table_refresh_timing, 'America/Toronto')
                .format('hh:mm:ss A')}{' '}
            (EDT/EST)
          </span>
        </span>
      )}
    </Text>
  );
}

export default LastRefreshedAt;
