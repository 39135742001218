import { useState, useEffect, useRef } from 'react';
import axios from '../utils/axios';
import costService from '../services/costService';
import { AlertCircle } from 'tabler-icons-react';
import { Group, Button, Text, Table, Center, Space, ActionIcon, Input, Modal, FileButton, Collapse, SimpleGrid, UnstyledButton } from '@mantine/core';
import { IconDownload, IconTrash, IconEye } from '@tabler/icons';
import CustomLoader from './CustomLoader';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { urls } from '../utils/apiConfigs';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
export const ImageLoader = ({fileName, base64path, showModal, closeModal}) => {
    
  return (
    <>
        <Modal
    radius={'lg'}
    size="sm%"
    opened={showModal}
    centered
    title={
        <Text weight={700} className="golden-text">
            {`${fileName}`}
        </Text>
    }
    onClose={() => {
        closeModal();
    }}
    withCloseButton={true}
>
<img src={`${base64path}`} alt=""/>
    </Modal>
    </>
  );
};