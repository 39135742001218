/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import { Text, Stack, Group, Space, Center, Button, Tabs, Switch } from '@mantine/core';
import { Pagination, Badge, ActionIcon } from '@mantine/core';
import { TextInput, Select } from '@mantine/core';
import {
  IconSearch,
  IconRefresh,
  IconSortDescending,
  IconSortAscending,
  IconArrowsSort,
} from '@tabler/icons';
import materialService from '../../services/materialService';
import { UserContext } from '../../context/userContext';
import { pageContext } from '../../context/pageContext';
import homeServices from '../../services/homeServices';
import CustomCardLoader from '../../components/CustomCardLoader';
import userService from '../../services/userService';
import mailService from '../../services/mailService';
import moment from 'moment';
import { DateDifference, RowColors } from '../../utils/Utils';

function FgForm() {
  const [plants, setPlants] = useState([
    {
      index: 0,
      plant: '',
      selling_region: '',
      production_date: '',
      str_date: '',
      stop_ship_date: '',
    },
  ]);

  const [plantDropDown, setPlantDropDown] = useState([]);

  const {
    projects,

    loadProjects,
  } = useContext(UserContext);

  const {
    searchFgForm,
    setSearchFgForm,
    fgFormPagination,
    setFgFormPagination,
    totalFgForm,
    setTotalFgForm,
  } = useContext(pageContext);

  const handleReset = () => {
    setPlants([
      {
        index: 0,
        plant: '',
        selling_region: '',
        production_date: '',
        str_date: '',
        stop_ship_date: '',
      },
    ]);
  };

  const data = projects?.map((project) => ({ value: project.id, label: project.project_name }));

  const navigate = useNavigate();

  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);

  useEffect(() => {
    loadProjects();
  }, [navigate]);

  // const [projectList, setProjectList] = useState([]);

  // useEffect(() => {
  //   let temp = [];
  //   projects?.map((project) => {
  //     project?.materials?.map((material) => {
  //       material.plants?.map((plant) => {
  //         temp.push({
  //           ...plant,
  //           plant: plant?.plant,
  //           project_type: project?.project_type,
  //           project_name: project?.project_name,
  //           project_year: project?.project_year,
  //           project_owner: project?.project_owner,
  //           sku_description: material?.sku_description,
  //           material_status: material?.material_status,
  //           material_id: material?.id,
  //           fg_type: material?.fg_type,
  //           liquid: material?.liquid,
  //           m_deleted: material?.deleted,
  //           deleted: material?.deleted,
  //           pl_deleted: plant?.deleted,
  //           pr_deleted: project?.deleted,
  //           send_request_to: material?.send_request_to,
  //           plant_status: plant?.plant_status,
  //           plant_id: plant?.id,
  //           virtual_sku_description: material?.virtual_sku_description,
  //         });
  //       });
  //     });
  //   });

  //   //console.log(
  //     'temp FILTERED 2',
  //     temp?.filter((item) => item?.material_status == 2),
  //   );
  //   setProjectList(
  //     temp?.filter((item) => item?.material_status == 2)?.filter((item) => item?.deleted === false),
  //   );
  // }, [projects]);

  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);
    setLoadingData(true);
    homeServices
      .getFullGoodsFormData({
        search: search,
        offset: (offset - 1) * 10,
        sort_column: sort_column,
        sort_type: sort_type,
      })
      .then((res) => {
        mailService.skuDescriptionApprovalMail().then((res2) => {
          var USERS = res2?.data.data?.map((item) => ({
            label: item?.name,
            value: item?.email,
          }));
          //console.log('USERS: ', USERS);
          let modifiedData = res?.data?.map((item) => {
            let found = USERS?.find(
              (user) => user?.value?.toLowerCase() == item?.send_request_to?.toLowerCase(),
            );
            //console.log('FOUND: ', found);
            return {
              ...item,
              send_request_to: found,
            };
          });
          //console.log('MODIFIED DATA: ', modifiedData);
          setProjectList(modifiedData);
        });
        setPages(res?.total);
        setLoadingData(false);
        setTotalFgForm(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(1, searchFgForm);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchFgForm]);

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Col xs={12} sm={12}>
        <Card radius="xl">
          <Card.Body>
            <Group position="apart">
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              ></Text>
            </Group>
          </Card.Body>
          <Card.Body>
            <Group position="apart">
              <Text
                size="xl"
                weight={700}
                color="yellow"
                style={{ fontSize: '18px' }}
              >{`Full Goods Form`}</Text>
            </Group>
            <Space h="xl" />
            <TextInput
              radius={'xl'}
              placeholder={`Plant`}
              sx={{ width: '16vw' }}
              rightSection={
                <ActionIcon
                  variant="filled"
                  color={'yellow'}
                  radius="xl"
                  size="md"
                  onClick={() => {
                    setLoadingData(true);
                    setSearchFgForm('');
                    setFgFormPagination(1);
                  }}
                >
                  <IconRefresh size={14} color="black" />
                </ActionIcon>
              }
              value={searchFgForm}
              onChange={(e) => {
                setSearchFgForm(e.target.value);
                setFgFormPagination(1);
              }}
            />
          </Card.Body>
          {loadingData ? (
            <CustomCardLoader />
          ) : (
            <div className="table-responsive-sm">
              <Table hover>
                <thead>
                  <tr>
                    <th>
                      <Center fw={500}>
                        Production Plant
                        <>
                          {sortedColumnData !== 'Production Plant' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Production Plant', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Production Plant' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Production Plant', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Production Plant' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>

                    <th>
                      <Center fw={500}>
                        FG Type
                        <>
                          {sortedColumnData !== 'Fg Type' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Fg Type', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Fg Type' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Fg Type', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Fg Type' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Liquid Type
                        <>
                          {sortedColumnData !== 'Liquid Type' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Liquid Type', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Liquid Type' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Liquid Type', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Liquid Type' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        SKU Description
                        <>
                          {sortedColumnData !== 'Sku Description' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Sku Description', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Sku Description' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Sku Description', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Sku Description' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Hold
                        <>
                          {sortedColumnData !== 'Hold' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Hold', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Hold' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Hold', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Hold' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Request Submitted to
                        <>
                          {sortedColumnData !== 'Request Submitted To' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Request Submitted To', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Request Submitted To' &&
                            sortType == 'ascending' && (
                              <ActionIcon
                                onClick={() => {
                                  fetchData(1, searchFgForm, 'Request Submitted To', 'descending');
                                }}
                              >
                                <IconSortAscending />
                              </ActionIcon>
                            )}
                          {sortedColumnData == 'Request Submitted To' &&
                            sortType == 'descending' && (
                              <ActionIcon
                                onClick={() => {
                                  fetchData(1, searchFgForm);
                                }}
                              >
                                <IconSortDescending />
                              </ActionIcon>
                            )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Status
                        {/* <>
                          {sortedColumnData !== 'Status' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Status', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Status' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Status', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Status' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </> */}
                      </Center>
                    </th>

                    <th>
                      <Center fw={500}>
                        Production Date
                        <>
                          {sortedColumnData !== 'Production Date' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Production Date', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Production Date' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Production Date', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Production Date' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Due Date
                        <>
                          {sortedColumnData !== 'Due Date' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Due Date', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Due Date' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Due Date', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Due Date' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>

                    <th>
                      <Center fw={500}>
                        Comments
                        {/* <>
                          {sortedColumnData !== 'Comments' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Comments', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Comments' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Comments', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Comments' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </> */}
                      </Center>
                    </th>
                    <th>
                      <Center fw={500}>
                        Days To Production
                        <>
                          {sortedColumnData !== 'Days To Production' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Days To Production', 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Days To Production' && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm, 'Days To Production', 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == 'Days To Production' && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchFgForm);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectList?.map((project, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(
                          `/material/${project?.material_id}/${project?.plant_id}`,
                          //   {
                          //   state: {
                          //     plant_id: project?.plant_id,
                          //     plant: project?.plant,
                          //     status: project?.plant_status,
                          //   },
                          // }
                        );
                        // setSelectedProject(plant);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <td>{project.plant}</td>
                      <td>{project.fg_type}</td>
                      <td>{project.liquid}</td>
                      <td>{project?.sku_description}</td>
                      <td>
                        <Switch label="Hold" size="xs" color="yellow" checked={project?.on_hold} />
                      </td>
                      <td>{project?.send_request_to?.label}</td>
                      <td>{project?.comment?.trim() ? 'Rejected' : 'Action Needed'}</td>
                      <td>
                        {project?.production_date
                          ? moment(project?.production_date).format('MM-DD-YYYY')
                          : ''}
                      </td>
                      <td>
                        {project?.due_date_by
                          ? moment(project?.due_date_by).format('MM-DD-YYYY')
                          : ''}
                      </td>
                      <td>{project?.comment}</td>
                      <td>{RowColors(DateDifference(project?.production_date))}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          <Card.Footer>
            <Container>
              <Center>
                <Pagination
                  total={pages + 1}
                  color="yellow"
                  radius="xl"
                  withEdges
                  page={fgFormPagination}
                  boundaries={1}
                  initialPage={fgFormPagination}
                  value={fgFormPagination}
                  onChange={(e) => {
                    setFgFormPagination(e);
                    fetchData(e, searchFgForm, sortedColumnData, sortType);
                  }}
                />
              </Center>
            </Container>
          </Card.Footer>
        </Card>
      </Col>
    </div>
  );
}
export default FgForm;
