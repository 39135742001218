import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { Pagination, Badge } from '@mantine/core';
import userService from '../services/userService';

export function getNumberWithOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export function formatTime(val) {
  // console.log(val);
  var time = new Date(`12-31-9999, ${val}`);
  return moment(val, 'HH:mm:ss').format('hh:mm A');
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  //remember the last callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  //set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay != null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

export function getGreeting() {
  let currentTime = moment();

  let greeting = null;

  if (currentTime.hour() < 12) greeting = 'Good Morning';
  else if (currentTime.hour() < 18) greeting = 'Good Afternoon';
  else if (currentTime.hour() < 21) greeting = 'Good Evening';
  else greeting = 'Good Night';

  return greeting;
}

export const isEmployeeContractor = (global_id) => {
  if (global_id) return global_id.includes('C');
  else return false;
};

export function materialStatusBadge(material_status) {
  if (material_status === 'FG-Planning')
    return (
      <Badge color="blue" variant="light">
        {material_status}
      </Badge>
    );
  else if (material_status === 'Approval Required')
    return (
      <Badge color="red" variant="light">
        {material_status}
      </Badge>
    );
}

export const mapStatusToValue = (status) => {
  // console.log('STATUS', status);
  switch (status) {
    case '1':
      return 'Action Needed';
    case '2':
      return 'Pending';
    case '3':
      return 'Not Required';
    case '4':
      return 'Accepted';
    case '5':
      return 'Rejected';
    case '6':
      return 'Uploaded To SAP';
    case '7':
      return 'SKU Costing Completed';
    default:
      return '';
  }
};

export function check_roles(userState, allowed_roles) {
  // console.log('All Roles', userState?.roles);

  if (userState?.roles) {
    const contains = userState.roles.some((element) => {
      return allowed_roles.includes(element);
    });

    return contains;
  }

  return false;
}

export const plantStatusToValue = (status) => {
  switch (status) {
    case '5':
      return 'Packaging BOM';
    case '6':
      return 'BOM Validation';
    case '7':
      return 'Liquid BOM';
    case '8':
      return 'SAP BOM Creation';
    case '9':
      return 'SKU Costing';
    case '10':
      return 'Costing Approval';
    case '11':
      return 'SAP Cost Update';
    case '12':
      return 'Cost Notification';
    case '13':
      return 'Completed';
    default:
      return '';
  }
};

export const materialStatusToValue = (status) => {
  switch (status) {
    case '1':
      return 'SKU Approval';
    case '2':
      return 'FG Form';
    case '3':
      return 'SKU ID Creation';
    case '4':
      return 'Packaging BOM';
    default:
      return status;
  }
};
export const plantStatusToOwner = (status, notified, ownerNames) => {
  // console.log(status, " ", plant)
  switch (status) {
    case '5':
      return ownerNames.map((owner) => <div>{owner}</div>);

    case '6':
      return 'MDM Team';

    case '7':
      return ownerNames.map((owner) => <div>{owner}</div>);

    case '8':
      return 'MDM Team';
    case '9':
      return 'GCC Costing';
    case '10':
      return notified;
    case '11':
      return 'GCC Costing';
    case '12':
      return 'MDM Team';
    case '13':
      return 'Completed';
    default:
      return '';
  }
};

export const materialStatusToOwner = (status, approver, ownerNames) => {
  switch (status) {
    case '1':
      return 'Demand Planners';
    case '2':
      return approver;

    case '3':
      return 'MDM Team';

    case '4':
      return ownerNames.map((owner) => <div>{owner}</div>);

    default:
      return status;
  }
};

export const convertDateTimeStringToDateTimeEST = (datetimestring) => {
  var date = new Date(datetimestring);
  var final_date = date.toLocaleString('en-US', { timeZone: 'Canada/Eastern' }) + ' (EDT/EST)';
  return final_date;
};

export const DateDifference = (date) => {
  const date1 = moment(date);
  const date2 = moment(new Date());

  const diff = date1.diff(date2, 'days');
  return diff > 0 ? diff + 1 : diff;
};

export const RowColors = (days) => {
  if (days < 15) {
    return (
      <p
        style={{
          backgroundColor: '#ffd9cf',
          color: '#ff3600',
          width: 'min-content',
          margin: 'auto',
          padding: '2px 32px',
          maxWidth: '120px',
          minWidth: '60px',
          borderRadius: '4px',
          fontWeight: '700',
        }}
      >
        {days}
      </p>
    );
  } else if (days > 15 && days < 30) {
    return (
      <p
        style={{
          backgroundColor: '#fffec4',
          color: '#8a8700',
          width: 'min-content',
          margin: 'auto',
          padding: '2px 32px',
          maxWidth: '120px',
          minWidth: '60px',
          borderRadius: '4px',
          fontWeight: '700',
        }}
      >
        {days}
      </p>
    );
  } else if (days > 30) {
    return (
      <p
        style={{
          backgroundColor: '#d0ffcc',
          color: '#0a7500',
          width: 'min-content',
          margin: 'auto',
          padding: '2px 32px',
          maxWidth: '120px',
          minWidth: '60px',
          borderRadius: '4px',
          fontWeight: '700',
        }}
      >
        {days}
      </p>
    );
  } else {
    return (
      <p
        style={{
          backgroundColor: '#ffffff',
          color: '#000000',
          width: 'min-content',
          margin: 'auto',
          padding: '2px 32px',
          maxWidth: '120px',
          minWidth: '60px',
          borderRadius: '4px',
          fontWeight: '700',
        }}
      >
        {days}
      </p>
    );
  }
};

export const DateAndDayFormatter = (date, sla = 0) => {
  let business_day = '';
  let day = moment(new Date(date).toISOString()).add(+sla, 'days').format('dddd');
  let ISO = moment(new Date(date).toISOString())
    .add(+sla, 'days')
    .format('MM-DD-YYYYTHH:mm:ss.SSS[Z]');

  if (day === 'Saturday') {
    day = moment(date)
      .add(+sla + 2, 'days')
      .format('MM-DD-YYYY');
    business_day = moment(date)
      .add(+sla + 2, 'days')
      .format('dddd');
    ISO = moment(date)
      .add(+sla + 2, 'days')
      .format('MM-DD-YYYYTHH:mm:ss.SSS[Z]');
  } else if (day === 'Sunday') {
    day = moment(date)
      .add(+sla + 1, 'days')
      .format('MM-DD-YYYY');
    business_day = moment(date)
      .add(+sla + 1, 'days')
      .format('dddd');
    ISO = moment(date)
      .add(+sla + 1, 'days')
      .format('MM-DD-YYYYTHH:mm:ss.SSS[Z]');
  } else {
    day = moment(date).add(+sla, 'days').format('MM-DD-YYYY');
    business_day = moment(date).add(+sla, 'days').format('dddd');
    ISO = moment(date).add(+sla, 'days').format('MM-DD-YYYYTHH:mm:ss.SSS[Z]');
  }

  return { day, business_day, ISO };
};

export const formatChange = (date) => {
  const newDate = moment?.utc(date)?.format('MM-DD-YYYY')
return newDate
}
