/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Button,
  Center,
  Checkbox,
  Grid,
  Group,
  Modal,
  MultiSelect,
  Pagination,
  Select,
  Space,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import materialService from '../../../../services/materialService';
import plantService from '../../../../services/plantService';
import projectService from '../../../../services/projectService';
import DisabledTextInput from '../../../../components/DisabledTextInput/DisabledTextInput';
import * as Yup from 'yup';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconCopy,
  IconDeviceFloppy,
  IconInfoCircle,
  IconLoader,
  IconPageBreak,
  IconSearch,
  IconTrash,
  IconX,
} from '@tabler/icons';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import bomService from '../../../../services/bomService';
import FgBomTable from '../../../../components/FgBomTable/FgBomTable';
import PhantomBomTable from '../../../../components/PhantomBomTable/PhantomBomTable';
import SimilarSkuHelpdesk from '../../../../components/SimilarSkuHelpdesk';
import CustomCardLoader from '../../../../components/CustomCardLoader';
import { UserContext } from '../../../../context/userContext';
import ReferenceHelpdesk from '../../../../components/ReferenceHelpdesk/ReferenceHelpdesk';
import { toast } from 'react-toastify';
import BOMtable from '../../../../components/BOMtable/BOMtable';
import { mapStatusToValue } from '../../../../utils/Utils';
import reverseMailService from '../../../../services/reverseMailService';

function PackagingBOMView({ setPlantStatus }) {
  const [userState, userDispatch] = useContext(ActiveUserContext);
  // PARAMS
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // CONTEXT
  const { loadProductionLineList, productionLineList } = useContext(UserContext);

  // STATE
  const [materialData, setMaterialData] = useState({});
  const [plantData, setPlantData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [similarSkuSerachValue, setSimilarSkuSerachValue] = useState('');
  const [ExistingSimilarSkuId, setExistingSimilarSkuId] = useState('');
  const [similarSkuList, setSimilarSkuList] = useState([]);
  const [helpdeskPopup, setHelpdeskPopup] = useState(false);
  const [plantList, setPlantList] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState();
  const [FgBOMList, setFgBOMList] = useState([]);
  const [copyEnabled, setCopyEnabled] = useState(false);
  const [copiedItem, setCopiedItem] = useState({});
  const [phantomList, setPhantomList] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showSimilarSkuModal, setShowSimilarSkuModal] = useState(false);
  const [plantDataLoading, setPlantDataLoading] = useState(false);

  // SEARCHING EXISTING
  const serchSimilarSku = (val) => {
    materialService.getSimilarSkuList(val).then((res) => {
      if (res && res.data) {
        setSimilarSkuList(res?.data?.sku?.sort((a, b) => Number(b?.label) - Number(a?.label)));
        return true;
      }
    });
  };

  // HANDLE SEARCHING EXISTING
  const handleSelectSimilarSku = (val) => {
    materialService.getSimilarSkuDesc(val).then((res) => {
      if (res && res.data) {
        setExistingSimilarSkuId(res?.data?.skudesc[0]?.sku_description);
        formik.setFieldValue('similarSkuDescription', res?.data?.skudesc[0]?.sku_description);
        referenceFormik.setFieldValue('skuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  // FORMIK GLOBAL
  const formik = useFormik({
    initialValues: {
      similarSku: '',
      similarSkuDescription: '',
      skuNotRequired: false,
      skuNotExist: false,
      production_line: [],
      sku: '',
      skuDescription: '',
    },
    validationSchema: Yup.object({
      similarSku: Yup.string().when(['skuNotRequired', 'skuNotExist'], {
        is: (skuNotRequired, skuNotExist) => skuNotRequired === false && skuNotExist === false,
        then: Yup.string().required('Similar SKU is required').nullable(),
        otherwise: Yup.string().notRequired(),
      }),
      similarSkuDescription: Yup.string().when(['skuNotRequired', 'skuNotExist'], {
        is: (skuNotRequired, skuNotExist) => skuNotRequired === false && skuNotExist === false,
        then: Yup.string().required('Similar SKU Description is required').nullable(),
        otherwise: Yup.string().notRequired(),
      }),
      skuNotRequired: Yup.lazy(() =>
        Yup.boolean().when('similarSku', {
          is: (similarSku) => similarSku === '',
          then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
          otherwise: Yup.boolean().notRequired(),
        }),
      ),
      skuNotExist: Yup.lazy(() =>
        Yup.boolean().when('similarSku', {
          is: (similarSku) => similarSku === '',
          then: Yup.boolean().oneOf([true], 'Field must be checked').nullable(),
          otherwise: Yup.boolean().notRequired(),
        }),
      ),
      // production_line: Yup.array().required().min(1),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (FgBOMList?.length === 0) {
        toast.error('Please add atleast one item in the FG BOM');
        return;
      }
      setSubmitLoader(true);
      let body = {
        plant_id: selectedPlant,
        fgBomList: FgBOMList.map((item) => item.id),
        phantomBomList: phantomList.map((item) => item.id),
        similar_sku_id: formik.values.similarSku,
        similar_sku_description: formik.values.similarSkuDescription,
        no_similar_sku_required: formik.values.skuNotRequired,
        no_similar_sku_exists: formik.values.skuNotExist,
        productionLineList: formik.values.production_line,
      };
      bomService
        .createPackagingBOM(body)
        .then((res) => {
          toast.success('Packaging BOM created successfully');
          setSubmitLoader(false);
          navigate('/');
        })
        .catch((err) => {
          setSubmitLoader(false);
          toast.error('Something went wrong, please try again later');
        });
    },
  });

  // FORMIK FG BOM TABLE
  const fgBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      //console.log('Form data', fgBOM_formik.errors, fgBOM_formik.values);

      bomService
        .saveFgBom({
          plant_id: selectedPlant,
          component: fgBOM_formik.values.component,
          quantity: fgBOM_formik.values.quantity,
          scrap: fgBOM_formik.values.scrap,
          uom: fgBOM_formik.values.uom,
          component_description: fgBOM_formik.values.component_description,
          standard_cost: fgBOM_formik.values.standard_cost,
        })
        .then((res) => {
          bomService.getFgAndPhantomBom({ plant_id: selectedPlant }).then((res) => {
            toast.success('Fg BOM created successfully');

            // //console.log('LISTS', res);
            setFgBOMList(res?.fgBomList);
            setPhantomList(res?.phantomBomList);
          });
        });
      fgBOM_formik.resetForm();
    },
  });

  // FORMIK PHANTOM BOM TABLE
  const phantomBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      bomService
        .savePhantomBom({
          plant_id: selectedPlant,
          component: phantomBOM_formik.values.component,
          quantity: phantomBOM_formik.values.quantity,
          scrap: phantomBOM_formik.values.scrap,
          uom: phantomBOM_formik.values.uom,
          component_description: phantomBOM_formik.values.component_description,
          standard_cost: phantomBOM_formik.values.standard_cost,
        })
        .then((res) => {
          bomService.getFgAndPhantomBom({ plant_id: selectedPlant }).then((res) => {
            // //console.log('LISTS', res);
            toast.success('Phantom BOM created successfully');

            setFgBOMList(res?.fgBomList);
            setPhantomList(res?.phantomBomList);
          });
        });
      phantomBOM_formik.resetForm();
    },
  });

  // REFERENCE FORMIK
  const referenceFormik = useFormik({
    initialValues: {
      sku: '',
      skuDescription: '',
      plant: '',
    },
  });

  // REJECT HANDLER
  const rejectMaterial = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        //console.log('REJECT', res);
        toast.success('Plant Deleted & Rejected successfully');
        reverseMailService.sendSkuIdRejectMail({
          material_id: params?.id,
          comment,
        });
        navigate('/');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const updateFgBOM = (data) => {
    bomService
      .updateFgBom(data)
      .then((res) => {
        toast.success('Fg BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const deleteFgBOM = (data) => {
    bomService
      .deleteFgBom(data)
      .then((res) => {
        toast.success('Fg BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const updatePhantomBOM = (data) => {
    bomService
      .updatePhantomBom(data)
      .then((res) => {
        toast.success('Phantom BOM updated successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const deletePhantomBOM = (data) => {
    bomService
      .deletePhantomBom(data)
      .then((res) => {
        toast.success('Phantom BOM deleted successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const handleSetSimilarSkuDropDown = (skuval) => {
    var tempfinal = [];
    var tempfinallist = [];
    if (similarSkuList.length > 0) {
      tempfinal = tempfinal.concat(similarSkuList);
    } else {
      tempfinal = tempfinal;
    }
    tempfinal.push({ label: skuval, value: skuval });
    //console.log('Final2:', tempfinal);
    if (tempfinal.length > 0) {
      tempfinallist = [...new Map(tempfinal.map((item) => [item['label'], item])).values()];
    }

    if (tempfinallist.length > 0) {
      setSimilarSkuList(tempfinallist);
    }
  };

  // USE EFFECTS
  useEffect(() => {
    if (params?.plant_id) {
      setSelectedPlant(params?.plant_id);
      loadProductionLineList(location?.state?.plant);
    }
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [params?.plant_id]);

  // USE EFFECTS
  useEffect(() => {
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  useEffect(() => {
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          setMaterialData(res?.data?.material);
          projectService.getProjectById(res?.data?.material?.project_id).then((res) => {
            if (res?.data) {
              setProjectData(res?.data?.project);
            }
          });
        }
      });
    }

    if (params?.id) {
      materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
        if (res?.data) {
          //console.log('PLANT LENGTH', res?.data);
          setPlantList(res?.data?.plants);
          if (!params?.plant_id) {
            setSelectedPlant(res?.data?.plants[0]?.id);
          }
          if (!location?.state?.plant) {
            loadProductionLineList(res?.data?.plants[0]?.plant);
          }
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    setPlantDataLoading(true);
    if (selectedPlant) {
      plantService.getPlantById(selectedPlant).then((res) => {
        // //console.log();
        if (res?.data) {
          //console.log('PLANT', res?.data?.data?.status);
          setPlantData(res?.data?.data);
          setPlantStatus(res?.data?.data?.status);
          setPlantDataLoading(false);
        }
      });

      bomService.getFgAndPhantomBom({ plant_id: selectedPlant }).then((res) => {
        setFgBOMList(res?.fgBomList);
        setPhantomList(res?.phantomBomList);
        formik.setFieldValue('similarSku', res?.packagingBOM?.similar_sku_id);
        formik.setFieldValue('similarSkuDescription', res?.packagingBOM?.similar_sku_description);
        formik.setFieldValue(
          'skuNotExist',
          Boolean(res?.packagingBOM?.no_similar_sku_exists) ? true : false,
        );
        formik.setFieldValue(
          'skuNotRequired',
          Boolean(res?.packagingBOM?.no_similar_sku_required) ? true : false,
        );
        res?.packagingBOM?.id &&
          bomService
            .getProductionLinesByPackagingBOM({
              plant_id: selectedPlant,
            })
            .then((res) => {
              formik.setFieldValue(
                'production_line',
                res?.data?.map((item) => item?.production_line),
              );
            });
      });
    }
  }, [selectedPlant]);

  return (
    <>
      {/* <Container> */}
      <Row>
        <Col xs={12} sm={2}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              >{`Plant List`}</Text>
              <Space h="sm" />
            </Card.Body>

            <Card.Body>
              <Group position="center">
                {plantList?.length > 0 ? (
                  <div className="table-responsive-sm">
                    <Table hover striped highlightOnHover withBorder>
                      <tbody>
                        {plantList?.map((plant, index) => (
                          <tr
                            style={{
                              cursor: 'pointer',
                              // backgroundColor: plant?.id === selectedPlant ? '#fab005' : '',
                              // color: plant?.id === selectedPlant ? '#fff' : '',
                            }}
                            onClick={() => {
                              loadProductionLineList(plant?.plant);
                              setSelectedPlant(plant?.id);
                              navigate(`/material/${params?.id}/${plant?.id}`);
                            }}
                          >
                            <td
                              className={
                                plant?.id === selectedPlant ? 'table-row-selected' : 'table-color'
                              }
                            >
                              {plant?.plant}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <CustomCardLoader />
                )}
              </Group>
            </Card.Body>
            {/* <Card.Footer>
                <Center>
                  <Pagination total={3} color="yellow" radius="xl" withEdges size="xs" />
                </Center>
              </Card.Footer> */}
          </Card>
        </Col>

        <Col xs={12} sm={10}>
          {!plantList?.length > 0 ? (
            <CustomCardLoader />
          ) : (
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {'Packaging BOM'}
                  </Text>
                </Group>
              </Card.Body>

              {plantDataLoading ? (
                <CustomCardLoader />
              ) : (
                <>
                  {/* FORM FIELDS */}
                  <Card.Body align="left">
                    <Grid
                      style={{
                        alignItems: 'end',
                      }}
                    >
                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="Project Name"
                          placeholder="Project Name"
                          value={projectData?.project_name}
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="Project Type"
                          placeholder="Project Type"
                          value={projectData?.project_type}
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="Status"
                          placeholder="Status"
                          // value={plantData?.p_bom_status !== null ? plantData?.p_bom_status : ''}
                          value={mapStatusToValue(plantData?.p_bom_status)}
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="SKU Id"
                          placeholder="SKU Id"
                          value={
                            materialData?.existing_item === 'Yes'
                              ? materialData?.existing_item_number
                              : materialData?.sku_id
                          }
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="SKU Description"
                          placeholder="SKU Description"
                          value={
                            materialData?.fg_type === 'Virtual'
                              ? materialData?.virtual_sku_description
                              : materialData?.sku_description
                          }
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="Plant"
                          placeholder="Plant"
                          value={plantData?.plant}
                        />
                      </Grid.Col>

                      {materialData?.existing_item === 'No' && (
                        <Grid.Col span={4}>
                          <DisabledTextInput
                            label="FG Type"
                            placeholder="FG Type"
                            value={materialData?.fg_type}
                          />
                        </Grid.Col>
                      )}

                      <Grid.Col span={4}>
                        <DisabledTextInput
                          label="Material Type"
                          placeholder="Material Type"
                          value={materialData?.material_type}
                        />
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <MultiSelect
                          label="Production Line"
                          placeholder="Production Line"
                          radius={'lg'}
                          value={formik.values.production_line}
                          onChange={(e) => {
                            //console.log(e);
                            formik.setFieldValue('production_line', e);
                          }}
                          data={productionLineList}
                          searchable
                          nothingFound="Nothing found"
                          // error={formik.errors.production_line}
                          // disabled={plantData?.p_bom_status == 2 || plantData?.p_bom_status == 4}
                        />
                      </Grid.Col>

                      {/* <Grid.Col span={4}></Grid.Col> */}

                      <Grid.Col
                        span={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Grid.Col span={2}>
                          <Checkbox
                            label="No similar SKU Required"
                            color="yellow"
                            checked={formik.values.skuNotRequired}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setSimilarSkuSerachValue('');
                                setExistingSimilarSkuId('');
                                formik.setFieldValue('similarSku', '');
                                formik.setFieldValue('similarSkuDescription', '');
                              }
                              formik.setFieldValue('skuNotRequired', e.target.checked);
                              // formik.setFieldValue('notInTheList', e);
                            }}
                            radius="xl"
                            size="xs"
                            disabled={
                              formik.values.skuNotExist ||
                              plantData?.p_bom_status == 2 ||
                              plantData?.p_bom_status == 4
                            }
                            error={formik.errors.skuNotRequired}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Checkbox
                            label="No similar SKU Exist"
                            color="yellow"
                            checked={formik.values.skuNotExist}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setSimilarSkuSerachValue('');
                                setExistingSimilarSkuId('');
                                formik.setFieldValue('similarSku', '');
                                formik.setFieldValue('similarSkuDescription', '');
                              }
                              formik.setFieldValue('skuNotExist', e.target.checked);
                              // formik.setFieldValue('notInTheList', e);
                            }}
                            radius="xl"
                            size="xs"
                            disabled={
                              formik.values.skuNotRequired ||
                              plantData?.p_bom_status == 2 ||
                              plantData?.p_bom_status == 4
                            }
                            error={formik.errors.skuNotExist}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <Select
                            type="number"
                            label="Similar SKU ID"
                            placeholder="Similar SKU ID"
                            radius={'lg'}
                            searchable
                            onSearchChange={(e) => {
                              //console.log('ON SEARCH CHANGE', e);
                              setSimilarSkuSerachValue(e);
                              serchSimilarSku(e);
                            }}
                            searchValue={similarSkuSerachValue}
                            onChange={(e) => {
                              //console.log('ON CHANGE', e);
                              formik.setFieldValue('similarSku', e);
                              referenceFormik.setFieldValue('sku', e);
                              handleSelectSimilarSku(e);
                            }}
                            data={similarSkuList?.length > 0 ? similarSkuList : []}
                            value={formik.values.similarSku}
                            error={!formik.values.similarSku && formik.errors.similarSku}
                            disabled={formik.values.skuNotRequired || formik.values.skuNotExist}
                          />
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <TextInput
                            disabled
                            label="Similar SKU Description"
                            placeholder="Similar SKU Description"
                            radius={'lg'}
                            value={formik.values.similarSkuDescription}
                            error={
                              !formik.values.similarSkuDescription &&
                              formik.errors.similarSkuDescription
                            }
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col span={4}>
                        <Button
                          color="yellow"
                          radius="xl"
                          onClick={(e) => {
                            // //console.log("Value:",showSimilarSkuModal)
                            setShowSimilarSkuModal(true);
                          }}
                        >
                          Helpdesk
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Card.Body>

                  {/* TABS */}
                  <Card.Body align="left">
                    <Container
                      style={{
                        width: '100%',
                        maxWidth: '100%',
                      }}
                    >
                      <Tabs color="yellow" variant="pills" radius="xl" defaultValue="fg_bom">
                        <Tabs.List>
                          <Tabs.Tab value="fg_bom">FG BOM</Tabs.Tab>
                          <Tabs.Tab value="phantom">Phantom</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="fg_bom" pt="xs">
                          <BOMtable
                            list={FgBOMList}
                            plant={plantData.plant}
                            setList={setFgBOMList}
                            formik={fgBOM_formik}
                            updateData={updateFgBOM}
                            deleteData={deleteFgBOM}
                            copyEnabled={copyEnabled}
                            setCopyEnabled={setCopyEnabled}
                            copiedItem={copiedItem}
                            enabled={materialData?.material_status == 4 && plantData?.status == 5}
                            // disabled={
                            //   plantData?.p_bom_status == 2 ||
                            //   plantData?.p_bom_status == 4 ||
                            //   plantData?.p_bom_status == 6 ||
                            //   plantData?.p_bom_status == 7 ||
                            //   materialData?.material_status != 4
                            // }
                          />
                        </Tabs.Panel>

                        <Tabs.Panel value="phantom" pt="xs">
                          <BOMtable
                            list={phantomList}
                            plant={plantData.plant}
                            setList={setPhantomList}
                            formik={phantomBOM_formik}
                            updateData={updatePhantomBOM}
                            deleteData={deletePhantomBOM}
                            copyEnabled={copyEnabled}
                            setCopyEnabled={setCopyEnabled}
                            copiedItem={copiedItem}
                            enabled={materialData?.material_status == 4 && plantData?.status == 5}

                            // disabled={
                            //   plantData?.p_bom_status == 2 ||
                            //   plantData?.p_bom_status == 4 ||
                            //   plantData?.p_bom_status == 6 ||
                            //   plantData?.p_bom_status == 7 ||
                            //   materialData?.material_status != 4
                            // }
                          />
                        </Tabs.Panel>
                      </Tabs>

                      <Text>
                        <IconInfoCircle size={16} color="black" style={{ marginRight: '5px' }} />
                        Note :{' '}
                        <b>
                          Please click save after every component is added or any change is made.
                        </b>
                      </Text>
                    </Container>
                  </Card.Body>

                  {/* HELPDESK */}
                  <ReferenceHelpdesk
                    plantData={plantData}
                    setCopiedItem={setCopiedItem}
                    setCopyEnabled={setCopyEnabled}
                    formik={referenceFormik}
                  />

                  <Card.Footer>
                    <Col>
                      {/* {plantData?.p_bom_status == 2 ||
                  plantData?.p_bom_status == 4 ||
                  plantData?.p_bom_status == 7 ||
                  plantData?.p_bom_status == 6 ? null : ( */}
                      {check_roles(userState, ['admin', 'logistics']) && (
                        <>
                          {materialData?.material_status == 4 && plantData?.status == 5 && (
                            <Group position="right">
                              <Button
                                color="gray"
                                radius="xl"
                                leftIcon={<IconX size={16} color="white" />}
                                onClick={() => setShowDeletePopup(true)}
                                // onClick={() => setShowRejectMaterialModal(true)}
                              >{`Delete Request`}</Button>
                              <Button
                                color="yellow"
                                radius="xl"
                                className="btn btn-primary"
                                style={{ borderRadius: '40px' }}
                                disabled={submitLoader}
                                leftIcon={
                                  submitLoader ? (
                                    <IconLoader size={16} color="white" />
                                  ) : (
                                    <IconCheck size={16} color="white" />
                                  )
                                }
                                onClick={() => {
                                  formik.handleSubmit();
                                }}
                              >
                                {!submitLoader && 'Submit'}
                              </Button>
                            </Group>
                          )}
                        </>
                      )}
                      {/* )} */}
                    </Col>
                    <Space h="xl"></Space>
                  </Card.Footer>
                </>
              )}
            </Card>
          )}
        </Col>

        {/* <Col xs={12} sm={9}>
          <Card radius="xl">
            <Card.Body>
              <Group position="apart">
                <Text
                  size="xl"
                  weight={700}
                  color="yellow"
                  align="left"
                  style={{ fontSize: '18px' }}
                >
                {` CA04 - LONDON Prod-DC (LDN) - Finished BOM`}
                </Text>
              </Group>
            </Card.Body>
            <Card.Body>
              <Group position="apart">
              <Card.Body p="xl">
          <Stepper
            color="yellow"
            active={active}
            onStepClick={setActive}
            completedIcon={<IconCircleCheck />}
          >
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="Finished BOM"
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="Phantom BOM"
            />
            <Stepper.Step
              color="yellow"
              icon={<IconBuildingFactory2 size={16} color="black" />}
              label="Liquid BOM"
            />
          </Stepper>
        </Card.Body>

                
              </Group>
              <Space h="xl" />
              <Row position="left" align="left" >
              <Col span={4}>
          <TextInput
            label="Material Type"
            placeholder="Price Class"
            radius={'lg'}
            required
          
            
          />
        </Col>
        <Col span={4}>
          <Select
            label="Similar SKU"
            placeholder=""
            radius={'lg'}
            required
            rightSection={<IconChevronDown size={14} />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            data={['34852', '84563', '97456', '31567']}
        
          
          />
        </Col>
        <Col span={4}>
          <TextInput
            label="FG SAP ID"
            placeholder="Marketing brewer"
            radius={'lg'}
            required
           
           
          />
        </Col>
        </Row>
        <Space h="xl" />
              <Row position="left" align="left" >
        <Col span={4}>
          <Textarea
            label="FG Discription"
            placeholder="Blend Discription"
            radius={'lg'}
            required
            autosize
            minRows={4}
           
          />
        </Col>

        </Row>
        
            </Card.Body>
            <Card.Body>
            <Group position="apart">
                <Text
                  size="xl"
                  weight={700}
                  color="yellow"
                  align="left"
                  style={{ fontSize: '18px' }}
                >
                {`Component Details`}

                </Text>
  
              
      

 
      <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Add Components"
      >
       
    
      </Modal>

      <Group position="center">
        <Button   color="yellow"
          radius="xl" onClick={() => setOpened(true)}>Add</Button>
      </Group>
    </>
  
              </Group>
              <Space h="xl" />
          
            <Table hover>
      <thead>
        <tr>
        <th> <Center fw={500}>Components</Center></th>
          <th> <Center fw={500}>Description</Center></th>
          <th> <Center fw={500}>Quantity/SKU</Center></th>
          <th> <Center fw={500}>Scrap</Center></th>
   
        </tr>
      </thead>
      </Table>
            </Card.Body>
            <Card.Footer>
              <Center>
                <Pagination total={3} color="yellow" radius="xl" withEdges size="xs" />
              </Center>
            </Card.Footer>
            <Card.Footer align="right" justify="right">
            <Button 
          color="yellow"
          radius="xl"
        >{`Submit`}</Button>
            </Card.Footer>
          </Card>
        </Col> */}
      </Row>
      {/* </Container> */}

      <RejectMaterial
        showRejectMaterialModal={showDeletePopup}
        closeRejectMaterialModal={() => setShowDeletePopup(false)}
        rejectMaterial={(comment) => {
          rejectMaterial(comment);
        }}
        message="Please provide your comments for Deleting this Request"
      />
      <SimilarSkuHelpdesk
        type="PBOM"
        plant={plantData.plant}
        showSimilarSkuModal={showSimilarSkuModal}
        closeSimilarSkuModal={() => {
          // //console.log("Similar Sku Modal Close:",false, showSimilarSkuModal)
          setShowSimilarSkuModal(false);
        }}
        setSimilarSkuAndDescription={(val1, val2, val3 = '') => {
          setShowSimilarSkuModal(false);
          //console.log(val1, val2);
          formik.setFieldValue('similarSku', val1);
          formik.setFieldValue('similarSkuDescription', val2);
          referenceFormik.setFieldValue('sku', val1);
          referenceFormik.setFieldValue('skuDescription', val2);
          referenceFormik.setFieldValue('plant', val3);
          handleSetSimilarSkuDropDown(val1);
        }}
        skuId={
          materialData?.existing_item === 'Yes'
            ? materialData?.existing_item_number
            : materialData?.sku_id
        }
        skuDescription={
          materialData?.fg_type === 'Virtual'
            ? materialData?.virtual_sku_description
            : materialData?.sku_description
        }
      />
    </>
  );
}

export default PackagingBOMView;
