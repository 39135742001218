/* eslint-disable import/no-anonymous-default-export */
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';

export default {
  sendSkuDescriptionRejectMail: async function (body) {
    try {
      const { data } = await axios.post(urls.sendSkuDescriptionApprovalRejectMailUrl, body);
      return data;
    } catch (error) {
      // console.log(error);
    }
  },
  sendSkuIdRejectMail: async function (body) {
    try {
      const { data } = await axios.post(urls.rejectSkuIdMailUrl, body);
      return data;
    } catch (error) {
      // console.log(error);
    }
  },
};
