import { Center, Table, Pagination, Grid, Text, Group, TextInput, Checkbox } from '@mantine/core';
import { React, useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useTable } from 'react-table';

function SimilarSkuTable({ data, setSimilarSkuData }) {
  const [searchFilter, setSearchFilter] = useState('');
  const [currentPage, setCurrentpage] = useState(1);
  const [similarSkuNumber, setSimilarSkuNumber] = useState([]);
  const [similarSkuDescription, setSimilarSkuDescription] = useState([]);

  const [skuTableData, setSkuTableData] = useState([]);

  useEffect(() => {
    setSkuTableData(data);
  }, [data]);

  return (
    <>
      <hr />
      <div>
        <Text weight={700} className="golden-text">{`Similar SKUs`}</Text>
      </div>
      <div lg={4}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              radius="xl"
              label="Filter"
              placeholder="Filter"
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
            />
          </Grid.Col>
        </Grid>
      </div>
      <div className="table-responsive-sm">
        <Table hover>
          <thead>
            <tr>
              <th></th>
              <th>
                <Center fw={500}>SKU ID</Center>
              </th>
              <th>
                <Center fw={500}>SKU Description</Center>
              </th>
            </tr>
          </thead>
          <tbody>
            {skuTableData
              ?.filter((item) => {
                if (searchFilter === '') {
                  return item;
                } else if (
                  item?.sku_description?.toLowerCase()?.includes(searchFilter.toLowerCase()) ||
                  item?.sku_number?.toLowerCase()?.includes(searchFilter.toLowerCase())
                ) {
                  return item;
                }
              })
              ?.slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
              ?.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <td>
                    <Center>
                      <Checkbox
                        size="xs"
                        color="yellow"
                        checked={item?.selected}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            setSkuTableData((prev) =>
                              prev.map((prev_item) =>
                                prev_item.row_id == item.row_id
                                  ? {
                                      ...prev_item,
                                      selected: true,
                                    }
                                  : prev_item,
                              ),
                            );
                            setSimilarSkuData(item.sku_number, item.sku_description);
                          } else {
                            setSimilarSkuData(undefined, undefined);
                            setSkuTableData((prev) =>
                              prev.map((prev_item) =>
                                prev_item.row_id == item.row_id
                                  ? {
                                      ...prev_item,
                                      selected: false,
                                    }
                                  : prev_item,
                              ),
                            );
                          }
                        }}
                      />
                    </Center>
                  </td>
                  <td>
                    <Center>{item?.sku_number}</Center>
                  </td>
                  <td>
                    <Center>{item?.sku_description}</Center>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div>
        <Container>
          <Center>
            <Pagination
              total={
                Math.floor(
                  skuTableData?.filter((item) => {
                    if (searchFilter === '') {
                      return item;
                    } else if (
                      item?.sku_description?.toLowerCase()?.includes(searchFilter.toLowerCase())
                    ) {
                      return item;
                    }
                  })?.length / 5,
                ) + 1
              }
              color="yellow"
              radius="xl"
              withEdges
              page={currentPage}
              boundaries={1}
              initialPage={currentPage}
              onChange={(e) => {
                // console.log(e);
                setCurrentpage(e);
              }}
            />
          </Center>
        </Container>
      </div>
    </>
  );
}

export default SimilarSkuTable;
