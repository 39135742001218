import React, { useReducer, createContext } from 'react';

export const ActiveUserContext = createContext();

const initialState = {
  user_id: null,
  name: null,
  email: null,
  roles: null,
  profile_photo: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        name: action.payload?.name,
        email: action.payload?.email,
      };

    case 'SET_ID_TOKEN':
      return {
        ...state,
        id_token: action.payload,
      };

    case 'SET_PROFILE_PHOTO':
      return {
        ...state,
        profile_photo: action.payload,
      };

    case 'SET_ROLES':
      return {
        ...state,
        roles: action.payload,
        // roles: ['stakeholder'],
      };

    case 'SET_USER_ID':
      return {
        ...state,
        user_id: action.payload,
      };

    default:
    // console.log('Incorrect action type');
    //   throw new Error();
  }
};

export const ActiveUserContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ActiveUserContext.Provider value={[state, dispatch]}>
      {props.children}
    </ActiveUserContext.Provider>
  );
};
