export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphProfilePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};

// const API_ROOT_URLS = {
//   // prod: `http://localhost:5000`,
//   prod: `https://acoecanadaappdev.azurewebsites.net`,
//   dev: `https://acoecanadaappdev.azurewebsites.net`,
//   // dev: `https://acoecanadaappdev.azurewebsites.net`,
//   // dev: `dev-url-here`,
// };

// export const API_ROOT_URL_PREFIX =
//   process.env.REACT_APP_STAGE == 'prod' ? API_ROOT_URLS.prod : API_ROOT_URLS.dev;

// export const API_ROOT_URL_PREFIX = 'http://localhost:8000';

export const API_ROOT_URL_PREFIX = process.env.REACT_APP_API_ROOT_URL_PREFIX;
// export const API_ROOT_URL_PREFIX = 'https://acoecanadaappdev.azurewebsites.net';
// export const API_ROOT_URL_PREFIX = 'http://localhost:8000';

export const getAnyProfilePhotoURL = (email) => {
  return `https://graph.microsoft.com/v1.0/users/${email}/photo/$value`;
};

export const urls = {
  getAllProjectsURL: `${API_ROOT_URL_PREFIX}/api/projects`,
  createProjectURL: `${API_ROOT_URL_PREFIX}/api/projects`,
  getSkuDescriptionURL: `${API_ROOT_URL_PREFIX}/api/materials/skudescription`,
  getMaterialFGTypesURL: `${API_ROOT_URL_PREFIX}/api/materials/fgtypes`,
  getMaterialContainerTypesURL: `${API_ROOT_URL_PREFIX}/api/materials/containertypes`,
  getMaterialContainerVolumesURL: `${API_ROOT_URL_PREFIX}/api/materials/containervolumes`,
  getMaterialLanguagesURL: `${API_ROOT_URL_PREFIX}/api/materials/languages`,
  getProjectTypesURL: `${API_ROOT_URL_PREFIX}/api/projects/projecttypes`,
  getAllPlantsURL: `${API_ROOT_URL_PREFIX}/api/materials/plant`,
  getAllRegionsURL: `${API_ROOT_URL_PREFIX}/api/materials/region`,
  getAllLiquidsURL: `${API_ROOT_URL_PREFIX}/api/materials/liquid`,
  getAllBrandsURL: `${API_ROOT_URL_PREFIX}/api/materials/brand`,
  getAllSubBrandsURL: `${API_ROOT_URL_PREFIX}/api/materials/subbrand`,
  getMaterialByIdURL: `${API_ROOT_URL_PREFIX}/api/materials/material/`,
  createMaterialURL: `${API_ROOT_URL_PREFIX}/api/materials/`,
  updateMaterialURL: `${API_ROOT_URL_PREFIX}/api/materials/`,
  getAllTasteSegmentURL: `${API_ROOT_URL_PREFIX}/api/materials/taste-segment`,
  getAllLiquidClassURL: `${API_ROOT_URL_PREFIX}/api/materials/liquid-class`,
  getAllPriceClassURL: `${API_ROOT_URL_PREFIX}/api/materials/price-class`,
  getAllImportedFromURL: `${API_ROOT_URL_PREFIX}/api/materials/imported-from`,
  getAllDraftProjectsURL: `${API_ROOT_URL_PREFIX}/api/projects/draftprojects`,
  getAllMaterialsByProjectIdURL: `${API_ROOT_URL_PREFIX}/api/materials/all-materials`,
  getDraftProjectByIdURL: `${API_ROOT_URL_PREFIX}/api/projects/draftproject/`,
  updateProjectURL: `${API_ROOT_URL_PREFIX}/api/projects`,
  getAllMaterialApprovalURL: `${API_ROOT_URL_PREFIX}/api/materials/all-approval-materials`,
  approveMaterialURL: `${API_ROOT_URL_PREFIX}/api/materials/approve-material`,
  getPlantsDetailsURL: `${API_ROOT_URL_PREFIX}/api/materials/get-plants-details`,
  getPlantsDetailsForFgFormURL: `${API_ROOT_URL_PREFIX}/api/materials/get-plants-details-fg-form`,
  getContainerReturnableListURL: `${API_ROOT_URL_PREFIX}/api/materials/container-returnable-list`,
  getContainerTypeListURL: `${API_ROOT_URL_PREFIX}/api/materials/container-type-list`,
  getContainerMaterialListURL: `${API_ROOT_URL_PREFIX}/api/materials/container-material-list`,
  getPackSize2ListURL: `${API_ROOT_URL_PREFIX}/api/materials/pack-size-2-list`,
  getPackSize1ListURL: `${API_ROOT_URL_PREFIX}/api/materials/pack-size-1-list`,
  getContainerVolumeListURL: `${API_ROOT_URL_PREFIX}/api/materials/container-volume-list`,
  getExistingSkuListURL: `${API_ROOT_URL_PREFIX}/api/materials/existing-sku-list`,
  getExistingSkuDescURL: `${API_ROOT_URL_PREFIX}/api/materials/existing-sku-desc`,
  getSimilarSkuListURL: `${API_ROOT_URL_PREFIX}/api/materials/similar-sku-list`,
  getSimilarSkuDescURL: `${API_ROOT_URL_PREFIX}/api/materials/similar-sku-desc`,
  getGrossWeightURL: `${API_ROOT_URL_PREFIX}/api/materials/gross-weight`,
  createPlantURL: `${API_ROOT_URL_PREFIX}/api/plants/create-plant`,
  updatePlantURL: `${API_ROOT_URL_PREFIX}/api/plants/update-plant/`,
  deletePlantURL: `${API_ROOT_URL_PREFIX}/api/plants/delete-plant/`,
  deleteMaterialURL: `${API_ROOT_URL_PREFIX}/api/materials/delete-material`,
  submitFGformURL: `${API_ROOT_URL_PREFIX}/api/plants/submit-plant/`,
  submitMDMApprovalURL: `${API_ROOT_URL_PREFIX}/api/materials/mdm-approval/`,
  getprodhSuggestionURL: `${API_ROOT_URL_PREFIX}/api/materials/get-prodh-suggestion`,
  getPlantByIdURL: `${API_ROOT_URL_PREFIX}/api/plants/plant/`,
  getProjectByIdURL: `${API_ROOT_URL_PREFIX}/api/projects/project/`,
  getAllBrandsForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-all-brands-for-similar-sku`,
  getAllSubBrandsForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-all-sub-brands-for-similar-sku`,
  getContainerReturnableListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-container-returnable-list-for-similar-sku`,
  getContainerTypeListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-container-type-list-for-similar-sku`,
  getContainerMaterialListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-container-material-list-for-similar-sku`,
  getPackSize2ListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-pack-size-2-list-for-similar-sku`,
  getPackSize1ListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-pack-size-1-list-for-similar-sku`,
  getContainerVolumeListForSimilarSkuURL: `${API_ROOT_URL_PREFIX}/api/materials/get-container-volume-list-for-similar-sku`,
  skuDescValidateURL: `${API_ROOT_URL_PREFIX}/api/materials/sku-desc-validate`,
  deprioritizedPlantURL: `${API_ROOT_URL_PREFIX}/api/materials/deprioritized-plant`,
  holdPlantURL: `${API_ROOT_URL_PREFIX}/api/materials/hold-plant`,
  getStopShipDateURL: `${API_ROOT_URL_PREFIX}/api/materials/get-stop-ship-date`,
  // BOM
  getFGandPhantomBomURL: `${API_ROOT_URL_PREFIX}/api/bom/get-packaging-bom`,

  // FG BOM CRUD
  saveFGbomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-fg-bom`,
  deleteFGbomURL: `${API_ROOT_URL_PREFIX}/api/bom/delete-fg-bom`,
  updateFGbomURL: `${API_ROOT_URL_PREFIX}/api/bom/update-fg-bom`,

  // PHANTOM BOM CRUD
  savePhantomBomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-phantom-bom`,
  deletePhantomBomURL: `${API_ROOT_URL_PREFIX}/api/bom/delete-phantom-bom`,
  updatePhantomBomURL: `${API_ROOT_URL_PREFIX}/api/bom/update-phantom-bom`,

  referenceSkuHelpdeskURL: `${API_ROOT_URL_PREFIX}/api/bom/reference-sku-helpdesk`,

  getProductionLineURL: `${API_ROOT_URL_PREFIX}/api/bom/get-production-line-list`,
  getProductionLinesForPackagingBomURL: `${API_ROOT_URL_PREFIX}/api/bom/get-production-lines-p-bom`,
  getSimilarSkuHelpDeskDropDownURL: `${API_ROOT_URL_PREFIX}/api/bom/similar-sku-helpdesk`,
  getAllComponentsByPlantURL: `${API_ROOT_URL_PREFIX}/api/bom/get-all-component-numbers`,
  getAllComponentsURL: `${API_ROOT_URL_PREFIX}/api/bom/get-all-components`,
  getReferenceSkuDetailsURL: `${API_ROOT_URL_PREFIX}/api/bom/get-reference-sku-details`,
  getReferenceSkuDescURL: `${API_ROOT_URL_PREFIX}/api/bom/get-reference-sku-desc`,
  getComponentDetailsURL: `${API_ROOT_URL_PREFIX}/api/bom/get-component-details`,

  // PACKAGE BOM
  createPackagingBomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-packaging-bom`,
  rejectPackagingBomURL: `${API_ROOT_URL_PREFIX}/api/bom/reject-packaging-bom`,
  acceptPackagingBomURL: `${API_ROOT_URL_PREFIX}/api/bom/accept-packaging-bom`,
  packageSAPBomURL: `${API_ROOT_URL_PREFIX}/api/bom/upload-to-sap-packaging-bom`,

  // LIQUID BOM
  getLiquidBomURL: `${API_ROOT_URL_PREFIX}/api/bom/get-liquid-bom`,
  saveLiquidBomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-liquid-bom`,
  rejectLiquidBomURL: `${API_ROOT_URL_PREFIX}/api/bom/reject-liquid-bom`,
  liquidSAPBomURL: `${API_ROOT_URL_PREFIX}/api/bom/upload-to-sap-liquid-bom`,

  // BLEND BOM CRUD
  saveBlendBomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-blend-bom`,
  deleteBlendBomURL: `${API_ROOT_URL_PREFIX}/api/bom/delete-blend-bom`,
  updateBlendBomURL: `${API_ROOT_URL_PREFIX}/api/bom/update-blend-bom`,
  // TANKER BOM CRUD
  saveTankerBomURL: `${API_ROOT_URL_PREFIX}/api/bom/save-tanker-bom`,
  deleteTankerBomURL: `${API_ROOT_URL_PREFIX}/api/bom/delete-tanker-bom`,
  updateTankerBomURL: `${API_ROOT_URL_PREFIX}/api/bom/update-tanker-bom`,
  // BRAND BOM CRUD
  saveLiquidSubTypeURL: `${API_ROOT_URL_PREFIX}/api/bom/save-liquid-sub-type`,
  deleteLiquidSubTypeURL: `${API_ROOT_URL_PREFIX}/api/bom/delete-liquid-sub-type`,
  updateLiquidSubTypeURL: `${API_ROOT_URL_PREFIX}/api/bom/update-liquid-sub-type`,

  // RM Costing
  getCostingTableURL: `${API_ROOT_URL_PREFIX}/api/cost/get-rm-costing-table-by-plants`,
  saveCostingTableURL: `${API_ROOT_URL_PREFIX}/api/cost/save-rm-costing-table`,
  deleteCostingTableDataURL: `${API_ROOT_URL_PREFIX}/api/cost/delete-rm-costing-table-data`,
  updateCostingTableDataURL: `${API_ROOT_URL_PREFIX}/api/cost/update-rm-costing-table-data`,
  sendBackMdmURL: `${API_ROOT_URL_PREFIX}/api/cost/send-back-to-mdm`,
  submitCostForApprovalURL: `${API_ROOT_URL_PREFIX}/api/cost/submit-for-approval`,
  uploadCostingFilesURL: `${API_ROOT_URL_PREFIX}/api/cost/upload-costing-files`,
  getCostingFilesByPlantURL: `${API_ROOT_URL_PREFIX}/api/cost/get-costing-files-by-plant`,
  downloadCostingFileURL: `${API_ROOT_URL_PREFIX}/api/cost/download-costing-file`,
  deleteCostingFileURL: `${API_ROOT_URL_PREFIX}/api/cost/delete-costing-file`,
  saveCostingApprovalDraftURL: `${API_ROOT_URL_PREFIX}/api/cost/save-costing-approval-draft`,
  submitCostingApprovalURL: `${API_ROOT_URL_PREFIX}/api/cost/submit-costing-controller`,
  rejectCostingApprovalURL: `${API_ROOT_URL_PREFIX}/api/cost/reject-costing-controller`,
  saveCostingNotificationURL: `${API_ROOT_URL_PREFIX}/api/cost/save-costing-notification`,
  saveAndSendCostingNotificationURL: `${API_ROOT_URL_PREFIX}/api/cost/save-and-send-costing-notification`,
  costCompletedOnSapURL: `${API_ROOT_URL_PREFIX}/api/cost/cost-completed-on-sap`,
  sendBackForReapprovalURL: `${API_ROOT_URL_PREFIX}/api/cost/send-back-reapproval`,
  deleteCostingDataByPlantIdURL: `${API_ROOT_URL_PREFIX}/api/cost/delete-by-plant-id`,

  // User Related
  getUsersViewUrl: `${API_ROOT_URL_PREFIX}/api/users/getUsersView`,
  getAllUsers: `${API_ROOT_URL_PREFIX}/api/users/getAllUsers`,
  saveUserUrl: `${API_ROOT_URL_PREFIX}/api/users/saveUser`,
  deleteUserUrl: `${API_ROOT_URL_PREFIX}/api/users/deleteUser`,
  getSearchedUsersUrl: `${API_ROOT_URL_PREFIX}/api/users/getSearchedUsers`,

  // User Role Related
  getUserRolesUrl: `${API_ROOT_URL_PREFIX}/api/user-roles/getUserRoles`,
  sendUserRequestsUrl: `${API_ROOT_URL_PREFIX}/api/user-roles/sendUserRequest`,
  getRolesForSummaryUrl: `${API_ROOT_URL_PREFIX}/api/user-roles/getRoleForSummary`,

  //Admin Related
  saveAdminUrl: `${API_ROOT_URL_PREFIX}/api/admin/saveAdmin`,
  deleteAdminUrl: `${API_ROOT_URL_PREFIX}/api/admin/deleteAdmin`,
  getAllAdminsUrl: `${API_ROOT_URL_PREFIX}/api/admin/getAllAdmins`,
  getSearchedAdminUrl: `${API_ROOT_URL_PREFIX}/api/admin/getSearchedAdmin`,

  //Project Requestor Related
  saveProjectRequestorUrl: `${API_ROOT_URL_PREFIX}/api/project-requestors/saveRequestor`,
  deleteProjectRequestorUrl: `${API_ROOT_URL_PREFIX}/api/project-requestors/deleteRequestor`,
  getAllProjectRequestorUrl: `${API_ROOT_URL_PREFIX}/api/project-requestors/getAllRequestors`,
  getSearchedRequestorsUrl: `${API_ROOT_URL_PREFIX}/api/project-requestors/getSearchedRequestors`,

  //Demand Planners Related
  saveDemandPlannersUrl: `${API_ROOT_URL_PREFIX}/api/demand-planners/saveDemandPlanners`,
  deleteDemandPlannersUrl: `${API_ROOT_URL_PREFIX}/api/demand-planners/deleteDemandPlanners`,
  getAllDemandPlannersUrl: `${API_ROOT_URL_PREFIX}/api/demand-planners/getAllPlanners`,
  getSearchedPlannersUrl: `${API_ROOT_URL_PREFIX}/api/demand-planners/getSearchedPlanners`,

  //Demand Planners Related
  saveSupplyPlannersUrl: `${API_ROOT_URL_PREFIX}/api/supply-planners/saveSupplyPlanners`,
  deleteSupplyPlannersUrl: `${API_ROOT_URL_PREFIX}/api/supply-planners/deleteSupplyPlanners`,
  getAllSupplyPlannersUrl: `${API_ROOT_URL_PREFIX}/api/supply-planners/getAllSupplyPlanners`,
  getSearchedSupplyPlannersUrl: `${API_ROOT_URL_PREFIX}/api/supply-planners/getSearchedSupplyPlanners`,

  //MDM Team Related
  saveMDMUrl: `${API_ROOT_URL_PREFIX}/api/mdm-team/saveMDM`,
  deleteMDMUrl: `${API_ROOT_URL_PREFIX}/api/mdm-team/deleteMDM`,
  getAllMDMUrl: `${API_ROOT_URL_PREFIX}/api/mdm-team/getAllMDM`,
  getSearchedMDMUrl: `${API_ROOT_URL_PREFIX}/api/mdm-team/getSearchedMDM`,

  //BSC Logistics Related
  saveLogisticsUrl: `${API_ROOT_URL_PREFIX}/api/bsc-logistics/saveLogistics`,
  deleteLogisticsUrl: `${API_ROOT_URL_PREFIX}/api/bsc-logistics/deleteLogistics`,
  getAllLogisticsUrl: `${API_ROOT_URL_PREFIX}/api/bsc-logistics/getAllLogistics`,
  getSearchedLogisticsUrl: `${API_ROOT_URL_PREFIX}/api/bsc-logistics/getSearchedLogistics`,

  //Brew Masters Related
  saveBrewMastersUrl: `${API_ROOT_URL_PREFIX}/api/brew-masters/saveBrewMaster`,
  deleteBrewMastersUrl: `${API_ROOT_URL_PREFIX}/api/brew-masters/deleteBrewMaster`,
  getAllBrewMastersUrl: `${API_ROOT_URL_PREFIX}/api/brew-masters/getAllBrewMasters`,
  getSearchedBrewMastersUrl: `${API_ROOT_URL_PREFIX}/api/brew-masters/getSearchedBrewMasters`,

  //GCC Costing Users Related
  saveGCC_CostingUrl: `${API_ROOT_URL_PREFIX}/api/gcc-costing/saveGccCosting`,
  deleteGCC_CostingUrl: `${API_ROOT_URL_PREFIX}/api/gcc-costing/deleteGccCosting`,
  getAllGCC_CostingUrl: `${API_ROOT_URL_PREFIX}/api/gcc-costing/getAllGccCosting`,
  getSearchedGCC_CostingUrl: `${API_ROOT_URL_PREFIX}/api/gcc-costing/getSearchedGccCosting`,

  //RM Cost Users Related
  saveRMCostUrl: `${API_ROOT_URL_PREFIX}/api/rm-cost-users/saveRMCost`,
  deleteRMCostUrl: `${API_ROOT_URL_PREFIX}/api/rm-cost-users/deleteRMCost`,
  getAllRMCostUrl: `${API_ROOT_URL_PREFIX}/api/rm-cost-users/getAllRMCost`,
  getSearchedRMCostUrl: `${API_ROOT_URL_PREFIX}/api/rm-cost-users/getSearchedRMCost`,

  //Brewery Controllers Users Related
  saveControllerUrl: `${API_ROOT_URL_PREFIX}/api/brewery-controllers/saveControllers`,
  deleteControllerUrl: `${API_ROOT_URL_PREFIX}/api/brewery-controllers/deleteControllers`,
  getAllControllersUrl: `${API_ROOT_URL_PREFIX}/api/brewery-controllers/getAllControllers`,
  getSearchedControllersUrl: `${API_ROOT_URL_PREFIX}/api/brewery-controllers/getSearchedControllers`,

  //PlantDetails Related
  addPlantsUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/addPlants`,
  getAllPlantsUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/getAllPlants`,
  deletePlantsUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/deletePlants`,
  updatePlantsUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/updatePlants`,
  getPlantsByIDUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/getPlantsByID`,
  getSearchedPlantUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/getSearchedPlant`,

  //Plant Members Related
  savePlantMemberUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/addPlantMember`,
  getAllPlantMembersUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/getAllPlantMembers`,
  deletePlantMemberUrl: `${API_ROOT_URL_PREFIX}/api/plants-details/deletePlantMembers`,

  //SLA Hub Related
  getProjectSLAUrl: `${API_ROOT_URL_PREFIX}/api/sla-hub/getProjectSLA`,
  updateSLAUrl: `${API_ROOT_URL_PREFIX}/api/sla-hub/updateSLA`,

  // sku description approval reject mail
  sendSkuDescriptionApprovalRejectMailUrl: `${API_ROOT_URL_PREFIX}/api/materials/sku-description-reject-mail`,
  // reject fg form -> Control in SKUU ID Creation
  rejectFgFormUrl: `${API_ROOT_URL_PREFIX}/api/materials/reject-fg-form`,
  // sku id reject mail
  rejectSkuIdMailUrl: `${API_ROOT_URL_PREFIX}/api/materials/sku-id-reject-mail`,

  // HOME PAGE ROUTES
  getProjectRequestDataURL: `${API_ROOT_URL_PREFIX}/api/home/project-request`,
  getSkuDescriptionDataURL: `${API_ROOT_URL_PREFIX}/api/home/sku-description-approval`,
  getFullGoodsFormDataURL: `${API_ROOT_URL_PREFIX}/api/home/full-goods-form`,
  getSkuIdCreationDataURL: `${API_ROOT_URL_PREFIX}/api/home/sku-id-creation`,
  getBomValidationDataURL: `${API_ROOT_URL_PREFIX}/api/home/bom-validation`,
  getSapBomCreationDataURL: `${API_ROOT_URL_PREFIX}/api/home/sap-bom-creation`,
  getPackagingBomDataURL: `${API_ROOT_URL_PREFIX}/api/home/packaging-bom`,
  getLiquidBomDataURL: `${API_ROOT_URL_PREFIX}/api/home/liquid-bom`,
  getSkuCostingDataURL: `${API_ROOT_URL_PREFIX}/api/home/sku-costing`,
  getCostingApprovalDataURL: `${API_ROOT_URL_PREFIX}/api/home/costing-approval`,
  getSapCostUpdateDataURL: `${API_ROOT_URL_PREFIX}/api/home/sap-cost-update`,
  getSummaryDataURL: `${API_ROOT_URL_PREFIX}/api/home/summary`,
  getCostingNotificationDataURL: `${API_ROOT_URL_PREFIX}/api/home/costing-notifications`,
  getRmCostingDataURL: `${API_ROOT_URL_PREFIX}/api/home/rm-costing`,
  saveRmCostingDataURL: `${API_ROOT_URL_PREFIX}/api/home/save-rm-costing`,
  checkRmCostingDataURL: `${API_ROOT_URL_PREFIX}/api/home/check-rm-costing`,
  getSnowflakeRefreshedAtURL: `${API_ROOT_URL_PREFIX}/api/home/snowflake-refreshed-at`,

  // reject sku desc approval
  rejectSkuDescApprovalUrl: `${API_ROOT_URL_PREFIX}/api/materials/reject-sku-description-approval`,

  // EMAIL RECIPIENT
  skuDescriptionApprovalMailRecipientURL: `${API_ROOT_URL_PREFIX}/api/mail-recipient/sku-description-approval-mail`,
  skuCostingApprovalMailRecipientURL: `${API_ROOT_URL_PREFIX}/api/mail-recipient/sku-costing-approval-mail`,

  // L Bom Distinct Component List
  getDistinctComponentSkuListURL: `${API_ROOT_URL_PREFIX}/api/materials/distinct-component-sku-list`,
  getDistinctComponentDescURL: `${API_ROOT_URL_PREFIX}/api/materials/distinct-component-sku-description`,
};
