import { useState, useRef } from 'react';
import axios from '../utils/axios';
import costService from '../services/costService';
import { AlertCircle } from 'tabler-icons-react';
import {
  Group,
  Button,
  Text,
  Space,
  ActionIcon,
  Modal,
  FileButton,
  Collapse,
  SimpleGrid,
  UnstyledButton,
} from '@mantine/core';
import CustomCardLoader from './CustomCardLoader';
import { IconUpload, IconFileDescription, IconX } from '@tabler/icons';
import CustomLoader from './CustomLoader';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { urls } from '../utils/apiConfigs';
export const FileUploader = ({ project_id, material_id, plant_id, refreshTable }) => {
  const [files, setFiles] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const resetFileRef = useRef();

  const clearFiles = () => {
    setFiles(null);
    resetFileRef.current?.();
  };

  const [showFileUploadLoader, setShowFileUploadLoader] = useState();
  const [fileUploading, setFileUploading] = useState(false);

  const uploadFiles = () => {
    setFileUploading(true);
    const body = new FormData();
    //body.append('sessionid', sessionid);
    body.append('project_id', project_id);
    body.append('material_id', material_id);
    body.append('plant_id', plant_id);
    body.append('customFile', files);
    costService.uploadCostingFiles(project_id, material_id, plant_id, body).then((res) => {
      setFileUploading(false);
      if (res) {
        // console.log(res)
        toast.success('The file is uploaded successfully');
      } else {
        toast.error('An Error has occured, please check if the File Size is less than 7 MB');
      }
      setShowUploadModal(false);
      clearFiles();
      refreshTable();
      // new Promise((resolve) => {
      //   setTimeout(() => {
      //   }, 1000);
      // });
    });
  };
  return (
    <>
      <FileButton
        resetRef={resetFileRef}
        onChange={(e) => {
          setFiles(e);
          setShowUploadModal(true);
        }}
        accept=".ppt,.pptx,.xlsx,.xls,.msg,.doc,.docx,image/png,image/jpeg,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/msword,.msg,application/vnd.ms-outlook,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/pdf,application/zip,message/rfc822"
      >
        {(props) => (
          <Button
            color="blue"
            radius="xl"
            leftIcon={<IconUpload size={16} color="white" />}
            onClick={() => {}}
            {...props}
          >
            {`Upload a File`}
          </Button>
        )}
      </FileButton>
      <Modal
        radius={'lg'}
        opened={showUploadModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {`Upload Attachment`}
          </Text>
        }
        onClose={() => {
          setShowFileUploadLoader(false);
        }}
        withCloseButton={true}
      >
        <Collapse in={files}>
          <Space h="xl" />
          <Row>
            <Group position="left">
              <SimpleGrid cols={1} spacing="xl">
                <UnstyledButton>
                  <Group>
                    <ActionIcon size={'xl'} color="yellow" variant={'light'}>
                      <IconFileDescription size={18} strokeWidth={1} />
                    </ActionIcon>

                    <div style={{ flex: 1 }}>
                      <Text size="sm" weight={500}>
                        {files?.name}
                      </Text>

                      {/* <Text color="dimmed" size="xs">
                                  {`andiand`}
                                </Text> */}
                    </div>
                    <ActionIcon disabled={showFileUploadLoader}></ActionIcon>
                  </Group>
                </UnstyledButton>
              </SimpleGrid>
            </Group>
          </Row>
          <Space h="xl" />
          <Row>
            {fileUploading != true && (
              <Group position="right">
                <Button
                  color={'gray'}
                  radius="xl"
                  onClick={() => {
                    clearFiles();
                    setShowUploadModal(false);
                  }}
                  disabled={showFileUploadLoader}
                >{`Cancel`}</Button>
                <Button
                  color={'yellow'}
                  radius="xl"
                  onClick={() => {
                    uploadFiles();
                  }}
                  disabled={showFileUploadLoader}
                >{`Upload`}</Button>
              </Group>
            )}
            {fileUploading == true && <CustomLoader />}
          </Row>
        </Collapse>
      </Modal>
    </>
  );
};
