import { Checkbox, Grid, TextInput } from '@mantine/core';
import React from 'react';
import SearchableSelectLiquid from '../SearchableSelect/SearchableSelectLiquid';

function DescriptionForm({
  formik,
  referenceFormik = {},
  name,
  similarId,
  similarDescription,
  idNotRequired,
  idNotExist,
  disabled = false,
}) {
  return (
    <Grid.Col
      span={12}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid.Col span={2}>
        <Checkbox
          label={`No similar ${name} Required`}
          color="yellow"
          checked={formik.values[idNotRequired]}
          onChange={(e) => {
            if (e.target.checked === true) {
              formik.setFieldValue(similarId, '');
              formik.setFieldValue(similarDescription, '');
            }
            formik.setFieldValue(idNotRequired, e.target.checked);
            // formik.setFieldValue('notInTheList', e);
          }}
          radius="xl"
          size="xs"
          disabled={formik.values[idNotExist] || disabled}
          error={formik.errors[idNotRequired]}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <Checkbox
          label={`No similar ${name} Exist`}
          color="yellow"
          checked={formik.values[idNotExist]}
          onChange={(e) => {
            if (e.target.checked === true) {
              formik.setFieldValue(similarId, '');
              formik.setFieldValue(similarDescription, '');
            }
            formik.setFieldValue(idNotExist, e.target.checked);
            // formik.setFieldValue('notInTheList', e);
          }}
          radius="xl"
          size="xs"
          disabled={formik.values[idNotRequired] || disabled}
          error={formik.errors[idNotExist]}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <SearchableSelectLiquid
          setId={(e) => {
            formik.setFieldValue(similarId, e);
            referenceFormik.setFieldValue('sku', e);
          }}
          setDescription={(e) => {
            formik.setFieldValue(similarDescription, e);
            referenceFormik.setFieldValue('skuDescription', e);
          }}
          error={formik.errors[similarId]}
          value={formik.values[similarId]}
          label={`Similar ${name} ID`}
          disabled={formik.values[idNotRequired] || formik.values[idNotExist] || disabled}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          disabled
          label={`Similar ${name} Description`}
          placeholder={`Similar ${name} Description`}
          radius={'lg'}
          value={formik.values[similarDescription]}
          error={formik.errors[similarDescription]}
        />
      </Grid.Col>
    </Grid.Col>
  );
}

export default DescriptionForm;
