import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from './utils/axios';
import { msalConfig } from './utils/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { Helmet } from 'react-helmet';

axios.interceptors.request.use(
  function (successfulReq) {
    //   ...modify code;
    if (successfulReq?.url?.includes('/api'))
      successfulReq.headers['Authorization'] = `${localStorage.getItem('access_token_backend')}`;
    return successfulReq;
  },
  function (error) {
    // ...

    return Promise.reject(error);
  },
);

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Helmet>
      <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
      <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      <meta name="referrer" content="same-origin" />
      {/* permission policy */}
      <meta
        http-equiv="Permissions-Policy"
        content="geolocation=(self),
        microphone=(), camera=(), fullscreen=(self), payment=(), sync-xhr=(self),
      "
      />
      {/* add iframe in permission policy */}
      {/* <meta http-equiv="X-Frame-Options" content="SAMEORIGIN" /> */}
      <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
      <meta http-equiv="Referrer-Policy" content="strict-origin-when-cross-origin" />
      {/* allow content security policy */}
      <meta http-equiv="Content-Security-Policy" content="use-default" />
      {/* <meta http-equiv="X-Frame-Options" content="DENY" /> */}
      {/* <meta http-equiv="Content-Security-Policy" content="default-src 'self'" /> */}
    </Helmet>
    <App msalInstance={msalInstance} />
  </>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const configuration = {
  onUpdate: (registration) => {
    // console.log('New version available!  Ready to update?');
    if (registration && registration?.waiting) {
      registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
};
serviceWorkerRegistration.register(configuration);
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
