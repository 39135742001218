import { Select } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import bomService from '../../services/bomService';

function CostingSkuSelect({ label, value, error, setId, setDescription, disabled = false }) {
  const [searchValue, setSearchValue] = useState('');
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    if (searchValue?.length >= 3) {
      bomService.getAllComponentsByPlant('', searchValue).then((res) => {
        setItemList(res?.sort((a, b) => Number(b?.label) - Number(a?.label)));
      });
    }
  }, [searchValue]);

  const handleSelectSearchValue = (val) => {
    bomService.getComponentDetails(val).then((res) => {
      if (res) {
        setDescription(res[0]?.component_description);
        return true;
      }
    });
  };

  useEffect(() => {
    if (value) {
      setId(value);
      setSearchValue(value);
    }
  }, [value]);

  return (
    <>
      <Select
        label={label}
        placeholder={label}
        radius={'lg'}
        searchable
        onSearchChange={(e) => {
          setSearchValue(e);
        }}
        searchValue={searchValue}
        onChange={(e) => {
          setId(e);
          handleSelectSearchValue(e);
        }}
        data={itemList && itemList.length > 0 ? itemList : []}
        value={value}
        error={error}
        disabled={disabled}
        type="number"
      />
    </>
  );
}

export default CostingSkuSelect;
