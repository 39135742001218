/* eslint-disable import/no-anonymous-default-export */
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';

export default {
  getRmCostingTableData: async function (data) {
    try {
      const response = await axios.post(urls.getCostingTableURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  saveRMCostingTable: async function (data) {
    try {
      const response = await axios.post(urls.saveCostingTableURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  deleteRMcostingTableData: async function (data) {
    try {
      const response = await axios.post(urls.deleteCostingTableDataURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  updateRMCostingTableData: async function (data) {
    try {
      const response = await axios.post(urls.updateCostingTableDataURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  sendBackToMdmTeam: async function (data) {
    try {
      const response = await axios.post(urls.sendBackMdmURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },

  submitForApproval: async function (data) {
    try {
      const response = await axios.post(urls.submitCostForApprovalURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  uploadCostingFiles: async function (project_id, material_id, plant_id, file_data) {
    try {
      const response = await axios.post(
        urls.uploadCostingFilesURL +
          '?' +
          `project_id=${project_id}&material_id=${material_id}&plant_id=${plant_id}`,
        file_data,
      );
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  getCostingFilesByPlant: async function (project_id, material_id, plant_id) {
    try {
      const response = await axios.post(urls.getCostingFilesByPlantURL, {
        project_id: project_id,
        material_id: material_id,
        plant_id: plant_id,
      });
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  downloadCostingFile: async function (project_id, material_id, plant_id, file_name) {
    try {
      const response = await axios.post(urls.downloadCostingFileURL, {
        project_id: project_id,
        material_id: material_id,
        plant_id: plant_id,
        file_name: file_name,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  deleteCostingFile: async function (project_id, material_id, plant_id, file_name) {
    try {
      const response = await axios.post(urls.deleteCostingFileURL, {
        project_id: project_id,
        material_id: material_id,
        plant_id: plant_id,
        file_name: file_name,
      });
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  saveCostingApprovalDraft: async function (data) {
    try {
      const response = await axios.post(urls.saveCostingApprovalDraftURL, data);
      return response.data;
    } catch (error) {
      return error.responses;
    }
  },
  submitCostingController: async function (data) {
    try {
      const response = await axios.post(urls.submitCostingApprovalURL, data);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },
  rejectCostingController: async function (data) {
    try {
      const response = await axios.post(urls.rejectCostingApprovalURL, data);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },
  saveCostingNotification: async function (data) {
    try {
      const response = await axios.post(urls.saveCostingNotificationURL, data);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },

  costCompletedOnSAP: async function (body) {
    try {
      const response = await axios.post(urls.costCompletedOnSapURL, body);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },
  saveAndSendCostingNotification: async function (data) {
    try {
      const response = await axios.post(urls.saveAndSendCostingNotificationURL, data);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },
  sendReapprovalRequest: async function (body) {
    try {
      const response = await axios.post(urls.sendBackForReapprovalURL, body);
      return response.data;
    } catch (err) {
      return err.responses;
    }
  },
  deleteCostingDataByPlantId: async function (body) {
    try {
      const resp = await axios.post(urls.deleteCostingDataByPlantIdURL, body);
      return resp.data;
    } catch (err) {
      return err.responses;
    }
  },
};
