/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';
import { graphConfig } from '../utils/authConfig';

export default {
  getMaterialFGTypes: async function () {
    try {
      const response = await axios.get(urls.getMaterialFGTypesURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getMaterialContainerTypes: async function () {
    try {
      const response = await axios.get(urls.getMaterialContainerTypesURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getMaterialContainerVolumes: async function () {
    try {
      const response = await axios.get(urls.getMaterialContainerVolumesURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getMaterialLanguages: async function () {
    try {
      const response = await axios.get(urls.getMaterialLanguagesURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  createMaterial: async function (data) {
    try {
      const response = await axios.post(urls.createMaterialURL, {
        data,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSKUDescription: async function (data) {
    try {
      const response = await axios.post(urls.getSkuDescriptionURL, {
        data,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllPlants: async function () {
    try {
      const response = await axios.get(urls.getAllPlantsURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllRegions: async function () {
    try {
      const response = await axios.get(urls.getAllRegionsURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllLiquids: async function () {
    try {
      const response = await axios.get(urls.getAllLiquidsURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllBrands: async function (data, liquid_prodh_element, search_val) {
    try {
      const response = await axios.post(urls.getAllBrandsURL, {
        liquid: data,
        liquid_prodh_element: liquid_prodh_element,
        search_val: search_val,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllSubBrands: async function (
    liquid,
    brand,
    liquid_prodh_element,
    brand_prodh_element,
    search_val,
  ) {
    try {
      const response = await axios.post(urls.getAllSubBrandsURL, {
        liquid: liquid,
        brand: brand,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        search_val: search_val,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getMaterialById: async function (id) {
    try {
      const response = await axios.get(urls.getMaterialByIdURL + id);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateMaterial: async function (data) {
    try {
      const response = await axios.post(urls.updateMaterialURL + data?.id, { data });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllPriceClass: async function () {
    try {
      const response = await axios.get(urls.getAllPriceClassURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllTasteSegment: async function () {
    try {
      const response = await axios.get(urls.getAllTasteSegmentURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllLiquidClass: async function () {
    try {
      const response = await axios.get(urls.getAllLiquidClassURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllImportedFrom: async function () {
    try {
      const response = await axios.get(urls.getAllImportedFromURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllMaterialsByProjectId: async function (id) {
    try {
      const response = await axios.post(urls.getAllMaterialsByProjectIdURL, {
        project_id: id,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getAllMaterialApproval: async function () {
    try {
      const response = await axios.get(urls.getAllMaterialApprovalURL);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  approveMaterial: async function (id, sku_description, send_request_to) {
    try {
      const response = await axios.post(urls.approveMaterialURL, {
        material_id: id,
        sku_description: sku_description,
        send_request_to,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getPlantsDetails: async function (id) {
    try {
      const response = await axios.post(urls.getPlantsDetailsURL, {
        material_id: id,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getPlantsDetailsForFgForm: async function (id) {
    try {
      const response = await axios.post(urls.getPlantsDetailsForFgFormURL, {
        material_id: id,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getContainerReturnableList: async function (
    liquid,
    brand,
    sub_brand,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getContainerReturnableListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getContainerTypeList: async function (
    liquid,
    brand,
    sub_brand,
    container_returnable,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getContainerTypeListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_returnable: container_returnable,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getContainerMaterialList: async function (
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
    container_type_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getContainerMaterialListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_returnable: container_returnable,
        container_type: container_type,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
        container_type_prodh_element: container_type_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getPackSize2List: async function (
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
    container_type_prodh_element,
    container_material_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getPackSize2ListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_returnable: container_returnable,
        container_type: container_type,
        container_material: container_material,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
        container_type_prodh_element: container_type_prodh_element,
        container_material_prodh_element: container_material_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getPackSize1List: async function (
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
    container_type_prodh_element,
    container_material_prodh_element,
    pack_size_2_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getPackSize1ListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_returnable: container_returnable,
        container_type: container_type,
        container_material: container_material,
        pack_size_2: pack_size_2,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
        container_type_prodh_element: container_type_prodh_element,
        container_material_prodh_element: container_material_prodh_element,
        pack_size_2_prodh_element: pack_size_2_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getContainerVolumeList: async function (
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    containerVolumeSearchValue,
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
    container_type_prodh_element,
    container_material_prodh_element,
    pack_size_2_prodh_element,
    pack_size_1_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getContainerVolumeListURL, {
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_returnable: container_returnable,
        container_type: container_type,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        containerVolumeSearchValue: containerVolumeSearchValue,
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
        container_type_prodh_element: container_type_prodh_element,
        container_material_prodh_element: container_material_prodh_element,
        pack_size_2_prodh_element: pack_size_2_prodh_element,
        pack_size_1_prodh_element: pack_size_1_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getExistingSkuList: async function (search_val) {
    try {
      const response = await axios.post(urls.getExistingSkuListURL, {
        search_val: search_val,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getExistingSkuDesc: async function (sku) {
    try {
      const response = await axios.post(urls.getExistingSkuDescURL, {
        sku: sku,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSimilarSkuList: async function (search_val) {
    try {
      const response = await axios.post(urls.getSimilarSkuListURL, {
        search_val: search_val,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSimilarSkuDesc: async function (sku) {
    try {
      const response = await axios.post(urls.getSimilarSkuDescURL, {
        sku: sku,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getGrossWeight: async function (
    container_type,
    container_material,
    container_volume,
    pack_size_2,
    pack_size_1,
  ) {
    try {
      const response = await axios.post(urls.getGrossWeightURL, {
        container_type: container_type,
        container_material: container_material,
        container_volume: container_volume,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteMaterial: async function (body) {
    try {
      const response = await axios.post(urls.deleteMaterialURL, body);
      return response;
    } catch (error) {
      return error.response;
    }
  },

  mdmApproval: async function (id, body) {
    try {
      const response = await axios.post(urls.submitMDMApprovalURL + id, body);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getProdhSuggestion: async function (
    liquid_prodh_element,
    brand_prodh_element,
    sub_brand_prodh_element,
    container_returnable_prodh_element,
    container_type_prodh_element,
    container_material_prodh_element,
    pack_size_2_prodh_element,
    pack_size_1_prodh_element,
    container_volume_prodh_element,
  ) {
    try {
      const response = await axios.post(urls.getprodhSuggestionURL, {
        liquid_prodh_element: liquid_prodh_element,
        brand_prodh_element: brand_prodh_element,
        sub_brand_prodh_element: sub_brand_prodh_element,
        container_returnable_prodh_element: container_returnable_prodh_element,
        container_type_prodh_element: container_type_prodh_element,
        container_material_prodh_element: container_material_prodh_element,
        pack_size_2_prodh_element: pack_size_2_prodh_element,
        pack_size_1_prodh_element: pack_size_1_prodh_element,
        container_volume_prodh_element: container_volume_prodh_element,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSimilarSkuHelpDeskDropDown: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
    plant,
  ) {
    try {
      const response = await axios.post(urls.getSimilarSkuHelpDeskDropDownURL, {
        type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
        plant: plant,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getAllBrandsForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getAllBrandsForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getAllSubBrandsForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getAllSubBrandsForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getContainerReturnableListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getContainerReturnableListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getContainerTypeListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getContainerTypeListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getContainerMaterialListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getContainerMaterialListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getPackSize2ListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getPackSize2ListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getPackSize1ListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getPackSize1ListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  getContainerVolumeListForSimilarSku: async function (
    type,
    liquid,
    brand,
    sub_brand,
    container_returnable,
    container_type,
    container_material,
    pack_size_2,
    pack_size_1,
    container_volume,
  ) {
    try {
      const response = await axios.post(urls.getContainerVolumeListForSimilarSkuURL, {
        form_type:type,
        liquid: liquid,
        brand: brand,
        sub_brand: sub_brand,
        container_type: container_type,
        container_returnable: container_returnable,
        container_material: container_material,
        pack_size_2: pack_size_2,
        pack_size_1: pack_size_1,
        container_volume: container_volume,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  skuDescValidate: async function (sku_desc) {
    try {
      const response = await axios.post(urls.skuDescValidateURL, {
        sku_desc: sku_desc,
      });
      return response;
    } catch (error) {
      return error.responses;
    }
  },
  deprioritizedPlant: async function (body) {
    try {
      const { data } = await axios.post(urls.deprioritizedPlantURL, body);
      return data;
    } catch (error) {
      return error.responses;
    }
  },
  holdPlant: async function (body) {
    try {
      const { data } = await axios.post(urls.holdPlantURL, body);
      return data;
    } catch (error) {
      return error.responses;
    }
  },
  rejectFgForm: async function (body) {
    try {
      const { data } = await axios.post(urls.rejectFgFormUrl, body);
      return data;
    } catch (error) {
      return error.responses;
    }
  },

  rejectSkuDescriptionApproval: async function (body) {
    try {
      const { data } = await axios.post(urls.rejectSkuDescApprovalUrl, body);
      return data;
    } catch (error) {
      return error.responses;
    }
  },

  getStopShipDate: async function (body) {
    try {
      const { data } = await axios.get(urls.getStopShipDateURL);
      return data;
    } catch (error) {
      return error.responses;
    }
  },
  getDistinctComponentSkuList: async function (search_val) {
    try {
      const response = await axios.post(urls.getDistinctComponentSkuListURL, {
        search_val: search_val,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getDistinctComponentDescList: async function (sku) {
    try {
      const response = await axios.post(urls.getDistinctComponentDescURL, {
        sku: sku,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
