import React, { useEffect, useState, useContext } from 'react';
import { Container, Card, Table, Row } from 'react-bootstrap';
import { IconCheck, IconCopy, IconEdit, IconPlus, IconTrash, IconChevronDown } from '@tabler/icons';
import {
  Text,
  Group,
  Textarea,
  MultiSelect,
  Button,
  TextInput,
  ActionIcon,
  Center,
  Select,
  Flex,
  Tooltip,
  List,
} from '@mantine/core';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { UserContext } from '../../context/userContext';
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { InfoCircle, Space } from 'tabler-icons-react';
import CustomCardLoader from '../../components/CustomCardLoader';
import userService from '../../services/userService';

function RequestRole() {
  const [admin, setAdmin] = useState();

  const getAdminsApi = (offset) => {
    setAdmin();
    userService.getAllAdmins((offset - 1) * 10).then((res) => {
      if (res?.data?.Success) {
        setAdmin(res?.data?.Admins);
        //console.log(res?.data?.Admins)
      }
    });
  };

  const data = [
    { value: 'Project-Requestors', label: 'Project Requestors' },
    { value: 'Demand-Planners', label: 'Demand Planners' },
    { value: 'Supply-Planners', label: 'Supply Planners' },
    { value: 'MDM', label: 'MDM' },
    { value: 'BSC-Logistics', label: 'BSC Logistics - Packaging Materials' },
    { value: 'Brewmasters', label: 'Brewmasters' },
    { value: 'GCC-Costing', label: 'GCC Costing' },
    { value: 'Brewery-Controllers', label: 'Brewery Controllers' },
  ];
  const [roles, setRoles] = useState([]);
  const [description, setDescription] = useState();

  const formik = useFormik({
    initialValues: {
      roles: roles,
      description: description,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (values.roles && values.roles.length === 0) errors.roles = 'Roles are required';
      if (!values.description) errors.description = 'Description is required';

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      //console.log(values)
      userService.sendUserRequests(values?.roles, values?.description).then((res) => {
        if (res?.data?.Success) {
          setSubmitting(false);
          toast.success('The request has been sent successfully');
        } else {
          toast.error('An Error has occured');
        }
        //nothing
      });
      formik.resetForm();
    },
  });
  useEffect(() => {
    getAdminsApi(1);
  }, []);
  return (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      <Card style={{ width: '65%' }}>
        <Card.Body>
          <Group>
            <Text
              size="xl"
              weight={700}
              color="yellow"
              align="center"
              style={{ fontSize: '18px', alignItems: 'center' }}
            >{`Request New Role/Access`}</Text>
          </Group>
        </Card.Body>
        <Card.Body>
          <Row>
            <Group position="center">
              <Col lg="3" md="12" sm="12" xs="12" align="right">
                <Text>Choose Roles</Text>
              </Col>
              <Col lg="6">
                <MultiSelect
                  data={data}
                  radius="xl"
                  placeholder="Choose Role"
                  style={{ width: '100%' }}
                  clearable
                  onChange={(e) => {
                    formik.setFieldValue('roles', e);
                  }}
                  value={formik.values.roles}
                  error={formik.errors.roles}
                  required
                />
              </Col>
            </Group>
          </Row>
          <br />
          <Row>
            <Group position="center">
              <Col lg="3" md="12" sm="12" xs="12" align="right">
                <Text>Reason</Text>
              </Col>
              <Col lg="6">
                <Textarea
                  radius={'xl'}
                  placeholder={`Describe your Requirement`}
                  onChange={(e) => {
                    formik.setFieldValue('description', e.target.value);
                  }}
                  value={formik.values.description}
                  error={formik.errors.description}
                  required
                ></Textarea>
              </Col>
            </Group>
          </Row>
        </Card.Body>
        <Card.Body style={{ maxHeight: '5vh', textAlign: 'left' }}>
          <Text color="yellow" fw={700}>
            Admins
          </Text>
        </Card.Body>
        <Card.Body style={{ maxHeight: '20vh', overflow: 'auto', textAlign: 'left' }}>
          {!admin && <CustomCardLoader />}
          {admin && (
            <Col>
              <Group position="Left" spacing="xl">
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <List>
                      {admin.map((admin) => (
                        <List.Item key={admin.id}>{admin.name}</List.Item>
                      ))}
                    </List>
                  </Col>
                </Row>
              </Group>
            </Col>
          )}
        </Card.Body>
        <Card.Body>
          <Group position="right" spacing="xl">
            <Button
              color="yellow"
              className="btn btn-primary"
              style={{ borderRadius: '40px' }}
              align="right"
              radius="xl"
              leftIcon={<IconCheck size={20} color="white" />}
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
            >{`Submit`}</Button>
          </Group>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RequestRole;
