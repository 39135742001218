import React from 'react';

import { Space, Loader } from '@mantine/core';
import { Col, Row } from 'react-bootstrap';

const CustomLoader = () => {
  return (
    <>
      <Space h="xl" />

      <Row>
        <Col xs={`auto`}>
          <Loader color="yellow" />
        </Col>
        <Col className="text-left justify-content-center align-items-center align-middle">
          <span className="align-middle">&nbsp;{`Please wait`}</span>
        </Col>
      </Row>
    </>
  );
};

export default CustomLoader;
