import {
  Center,
  Group,
  Pagination,
  Space,
  Table,
  Text,
  Button,
  Modal,
  TextInput,
  Select,
  Avatar,
  ActionIcon,
  Loader,
  SimpleGrid,
  Grid,
} from '@mantine/core';
import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import {
  IconCheck,
  IconEdit,
  IconInfoCircle,
  IconPlus,
  IconSearch,
  IconTrash,
  IconUsers,
  IconUser,
  IconX,
} from '@tabler/icons';
import { useFormik } from 'formik';
// import dataService from '../../services/dataService';
// import teamService from '../../services/teamService';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import userService from '../../services/userService';
import { pageContext } from '../../context/pageContext';
import { UserContext } from '../../context/userContext';
// import CustomLoader from '../../components/CustomLoader';
import DataTable from '../../components/DataTable';
import CustomCardLoader from '../../components/CustomCardLoader';
import plantDetailsService from '../../services/plantDetailsService';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
function ManagePlants() {
  const [teams, setTeams] = useState();
  const [pages, setPages] = React.useState(0);

  // Add Modal States
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [addTeamModalActivePage, setAddTeamModalActivePage] = useState('first');
  const [showAddTeamModalLoader, setShowAddTeamModalLoader] = useState(false);
  const [addTeamModalMessage, setAddTeamModalMessage] = useState();

  // Edit Modal States
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [activeTeamForEdit, setActiveTeamForEdit] = useState();
  const [editTeamModalActivePage, setEditTeamModalActivePage] = useState('first');
  const [showEditTeamModalLoader, setShowEditTeamModalLoader] = useState(false);
  const [editTeamModalMessage, setEditTeamModalMessage] = useState();

  // User Context
  //const [userState, userDispatch] = useContext(UserContext);
  const [userState, dispatch] = useContext(ActiveUserContext);

  // Role States
  const [towers, setTowers] = useState([]);
  const [towerFunctions, setTowerFunctions] = useState([]);
  const [processOwners, setProcessOwners] = useState([]);
  const [seniorManagers, setSeniorManagers] = useState([]);
  const [towerLeads, setTowerLeads] = useState([]);

  // Plant States
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [deleteTeamModalActivePage, setDeleteTeamModalActivePage] = useState('first');
  const [showDeleteTeamModalLoader, setShowDeleteTeamModalLoader] = useState(false);
  const [deleteTeamModalMessage, setDeleteTeamModalMessage] = useState();

  // Search States
  const [searchName, setSearchName] = useState();
  const [searchSeniorManager, setSeniorManagerName] = useState();
  const [searchTowerLead, setTowerLeadName] = useState();
  const [searchTeamFunction, setTeamFunctionName] = useState();

  const [activeTeamForDelete, setActiveTeamForDelete] = useState();

  const { searchPlants, setSearchPlants, plantsPagination, setPlantsPagination } =
    useContext(pageContext);

  const getAllPlantsApi = (offset) => {
    setTeams();
    plantDetailsService.getAllPlants((offset - 1) * 10).then((res) => {
      if (res.data?.Success) {
        // //console.log(res?.data?.data)
        setTeams(res?.data);
        setPages(res?.data?.total);
      }
    });
  };

  const deletePlantApi = (data) => {
    plantDetailsService.deleteExistingPlant(data).then((res) => {
      setShowDeleteTeamModalLoader(false);
      setDeleteTeamModalActivePage('response');
      setDeleteTeamModalMessage(res?.data?.message);
    });
  };

  const getSearchedParamsApi = () => {
    setTeams();
    // //console.log("getting hit",searchName)

    plantDetailsService.getSearchedPlant(searchName).then((res) => {
      // //console.log(res?.data)
      if (res?.data?.Success) {
        setTeams(res?.data);
        setPages(0);
        //console.log(res?.data);
      }
    });
  };

  const addTeamFormik = useFormik({
    initialValues: {
      name: null,
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) errors.name = 'Plant name is required';

      return errors;
    },

    onSubmit: (values, { setSubmitting }) => {
      setShowAddTeamModalLoader(true);

      //console.log(values);

      plantDetailsService.createNewPlant(values).then((res) => {
        setSubmitting(false);
        setShowAddTeamModalLoader(false);
        setAddTeamModalActivePage('response');
        setAddTeamModalMessage(res?.data?.message);
      });
    },
  });

  const editTeamFormik = useFormik({
    initialValues: {
      id: activeTeamForEdit?.id,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      if (!values.name) errors.name = 'Plant name is required';

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setShowEditTeamModalLoader(true);

      //console.log(values);

      plantDetailsService.updateExistingPlant(values).then((res) => {
        setSubmitting(false);
        setShowEditTeamModalLoader(false);
        setEditTeamModalActivePage('response');
        setEditTeamModalMessage(res?.data?.message);
      });
    },
  });

  const table_columns = useMemo(
    () => [
      { header: '', accessor: 'id' },
      {
        header: '',
        accessor: 'edit-delete',
        Cell: (props) => {
          const isAdmin = check_roles(userState, ['admin']);

          if (!isAdmin) {
            return null;
          }
          return (
            <ActionIcon
              variant="light"
              color={'yellow'}
              radius="xl"
              onClick={() => {
                setActiveTeamForEdit(props.row?.original);
                setShowEditTeamModal(true);
              }}
            >
              <IconEdit size={16} color="black" />
            </ActionIcon>
          );
        },
      },
      {
        header: '',
        accessor: 'delete',
        Cell: (props) => {
          const isAdmin = check_roles(userState, ['admin']);

          if (!isAdmin) {
            return null;
          }
          return (
            <ActionIcon
              variant="light"
              color={'yellow'}
              radius="xl"
              onClick={() => {
                setActiveTeamForDelete(props.row?.original);
                setShowDeleteTeamModal(true);
              }}
            >
              <IconTrash size={16} color="black" />
            </ActionIcon>
          );
          //  return check_roles(userState, ['admin']) ? (
          //  ) : null;
        },
      },
      {
        header: 'Production Plant Name',
        accessor: 'plant',
        Cell: (props) => {
          return (
            <Link
              to={{
                pathname: `/plants/${props.row?.original?.id}`,
                search: `value=${props.value}`,
              }}
            >
              {props?.value}
            </Link>
          );
        },
      },
    ],
    [],
  );
  function addTeamModalCleanup() {
    setAddTeamModalActivePage('first');
    setShowAddTeamModal(false);
    setAddTeamModalMessage();
    setShowAddTeamModalLoader(false);
    getAllPlantsApi(1);
  }

  function editTeamModalCleanup() {
    setActiveTeamForEdit();
    setEditTeamModalActivePage('first');
    setShowEditTeamModal(false);
    setEditTeamModalMessage();
    setShowEditTeamModalLoader(false);
    getAllPlantsApi(1);
  }

  function deleteTeamModalCleanup() {
    //console.log(activeTeamForDelete);
    setActiveTeamForDelete();
    setDeleteTeamModalActivePage('first');
    setShowDeleteTeamModal(false);
    setDeleteTeamModalMessage();
    setShowDeleteTeamModalLoader(false);
    getAllPlantsApi(1);
  }

  useEffect(() => {
    getAllPlantsApi(1);
    //console.log(teams);
  }, []);

  return (
    <>
      <Modal
        radius={'lg'}
        size="lg"
        opened={showAddTeamModal}
        centered
        title={<Text weight={700} className="golden-text">{`Add a Production Plant`}</Text>}
        onClose={() => {
          addTeamModalCleanup();
        }}
        withCloseButton={false}
      >
        {addTeamModalActivePage === 'first' && (
          <>
            <Row>
              <Col xs={12} sm={12}>
                <TextInput
                  label="Production Plant Name"
                  name="name"
                  key="name"
                  required
                  radius={'lg'}
                  placeholder={'Name of the Plant'}
                  onChange={addTeamFormik.handleChange}
                  value={addTeamFormik.values.name}
                  error={addTeamFormik.errors.name}
                  disabled={addTeamFormik.isSubmitting}
                />
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  addTeamModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                className="btn btn-primary"
                style={{ borderRadius: '40px' }}
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={addTeamFormik.handleSubmit}
              >{`Save`}</Button>
            </Group>
          </>
        )}

        {addTeamModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {addTeamModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  addTeamModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {showAddTeamModalLoader ? (
          <>
            <Space h="xl" />

            <Row>
              <Col xs={`auto`}>
                <Loader color="yellow" />
              </Col>
              <Col className="text-left justify-content-center align-items-center align-middle">
                <span className="align-middle">&nbsp;{`Please wait`}</span>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal>
      <Modal
        radius={'lg'}
        size="lg"
        opened={showEditTeamModal}
        centered
        title={
          <Text weight={700} className="golden-text">{`Update ${activeTeamForEdit?.plant}`}</Text>
        }
        onClose={() => {
          editTeamModalCleanup();
        }}
      >
        {editTeamModalActivePage === 'first' && (
          <>
            <Row>
              <Col xs={12} sm={6}>
                <TextInput
                  label="Production Plant Name"
                  name="name"
                  key="name"
                  required
                  radius={'lg'}
                  placeholder={activeTeamForEdit?.plant}
                  disabled={editTeamFormik.isSubmitting}
                  value={editTeamFormik.values.name}
                  error={editTeamFormik.errors.name}
                  onChange={editTeamFormik.handleChange}
                />
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  editTeamModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                className="btn btn-primary"
                style={{ borderRadius: '40px' }}
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={editTeamFormik.handleSubmit}
              >{`Save`}</Button>
            </Group>
          </>
        )}

        {editTeamModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {editTeamModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  editTeamModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {showEditTeamModalLoader ? <CustomCardLoader /> : null}
      </Modal>
      <Modal
        radius={'lg'}
        size="lg"
        opened={showDeleteTeamModal}
        centered
        title={
          <Text weight={700} className="golden-text">
            {`Delete Plant`}
          </Text>
        }
        onClose={() => {
          deleteTeamModalCleanup();
        }}
        withCloseButton={false}
      >
        {deleteTeamModalActivePage === 'first' && (
          <>
            <Row>
              <Col xs={12}>
                <Text>{`Are you sure you want to delete the team ${activeTeamForDelete?.plant}?`}</Text>
              </Col>
            </Row>
            <Space h="xl" />
            <Group position="right">
              <Button
                color="gray"
                radius="xl"
                leftIcon={<IconX size={16} color="white" />}
                onClick={() => {
                  deleteTeamModalCleanup();
                }}
              >{`Cancel`}</Button>
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  setShowDeleteTeamModalLoader(true);
                  deletePlantApi(activeTeamForDelete);
                }}
              >{`Delete`}</Button>
            </Group>
          </>
        )}

        {deleteTeamModalActivePage === 'response' && (
          <>
            <Group>
              <Text align="left" weight={500}>
                {deleteTeamModalMessage}
              </Text>
            </Group>

            <Space h="xl" />
            <Group position="right">
              <Button
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
                onClick={() => {
                  deleteTeamModalCleanup();
                }}
              >{`OK`}</Button>
            </Group>
          </>
        )}

        {showDeleteTeamModalLoader ? (
          <>
            <CustomCardLoader />
          </>
        ) : null}
      </Modal>
      <Card>
        <Card.Body>
          <Group position="apart">
            <Text
              size="xl"
              weight={700}
              color="yellow"
              align="left"
            >{`Production Plants Hub`}</Text>
            {check_roles(userState, ['admin']) && (
              <Button
                radius="xl"
                color={'yellow'}
                leftIcon={<IconPlus size={14} color="white" />}
                onClick={() => {
                  setShowAddTeamModal(true);
                }}
              >{`Add`}</Button>
            )}
          </Group>
        </Card.Body>
        <Card.Body>
          <Container>
            <Row>
              <Group position="left">
                <Group>
                  <TextInput
                    radius={'lg'}
                    placeholder={`Plant's name`}
                    value={searchName}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                    icon={<IconUser size={14} />}
                  />
                  <ActionIcon
                    variant="filled"
                    color={'yellow'}
                    radius="xl"
                    size="lg"
                    // disabled={!searchName}
                    onClick={() => {
                      if (searchName) getSearchedParamsApi();
                      else getAllPlantsApi(1);
                    }}
                  >
                    <IconSearch size={16} color="black" />
                  </ActionIcon>
                </Group>
              </Group>
            </Row>
          </Container>
        </Card.Body>
        {!teams && <CustomCardLoader />}
        {teams && teams?.data?.length === 0 && (
          <Card.Body>
            <Group position="center">
              <Text>{`No teams found.`}</Text>
            </Group>
          </Card.Body>
        )}
        {teams && teams?.data && teams?.data.length > 0 && (
          <>
            <DataTable
              columns={table_columns}
              data={teams}
              records={teams?.data}
              // getDataApi={getAllPlantsApi}
              // page={tablePage}
              hiddenColumns={['id']}
            />
          </>
        )}
        <Card.Footer>
          <Center>
            <Pagination
              total={pages}
              color="yellow"
              radius="xl"
              withEdges
              page={plantsPagination}
              boundaries={1}
              initialPage={plantsPagination}
              value={plantsPagination}
              onChange={(e) => {
                setPlantsPagination(e);
                getAllPlantsApi(e);
              }}
            />
          </Center>
        </Card.Footer>
      </Card>
    </>
  );
}

export default ManagePlants;
