import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import { Text, Stack, Group, Space, Center, Button, Tabs, Switch } from '@mantine/core';
import { Pagination, Badge } from '@mantine/core';
import { TextInput } from '@mantine/core';
import {
  IconSearch,
  IconCopy,
  IconEdit,
  IconTrash,
  IconPhoto,
  IconMessageCircle,
  IconRefresh,
  IconDeviceFloppy,
  IconCheck,
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import AddMaterial from '../ProjectRequest/AddMaterial';
import { UserContext } from '../../context/userContext';
import CustomCardLoader from '../../components/CustomCardLoader';
import moment from 'moment';
import { pageContext } from '../../context/pageContext';
import {
  DateAndDayFormatter,
  DateDifference,
  RowColors,
  check_roles,
  mapStatusToValue,
} from '../../utils/Utils';
import homeServices from '../../services/homeServices';
import { toast } from 'react-toastify';
import { ActiveUserContext } from '../../context/activerUserContext';
import LastRefreshedAt from '../../components/LastRefreshedAt/LastRefreshedAt';

function RmCosting() {
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const {
    searchRMCost,
    setSearchRMCost,
    RMCostPagination,
    setRMCostPagination,
    totalRMCost,
    setTotalRMCost,
  } = useContext(pageContext);
  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [lastRefreshedAt, setLastRefreshedAt] = useState({});

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);
    setLoadingData(true);
    homeServices
      .getRmCostingData({
        offset: (offset - 1) * 10,
        search: search,
        sort_column: sort_column,
        sort_type: sort_type,
      })
      .then((res) => {
        //console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(res?.data);
        setPages(res?.total);
        setLoadingData(false);
        setTotalRMCost(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  const fetchRefreshStatus = async () => {
    homeServices
      ?.snowflakeRefreshedAt()
      .then((res) => {
        //console.log('REFRESHED AT: ', res);
        setLastRefreshedAt(res?.data);
      })
      .catch((err) => {
        //console.log('REFRESHED AT ERROR: ', err);
      });
  };

  useEffect(() => {
    fetchRefreshStatus();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(1, searchRMCost);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchRMCost]);
  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Card radius="xl">
        <Card.Body>
          <Group position="apart">
            <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
              {}
            </Text>
          </Group>
        </Card.Body>
        <Card.Body>
          <Group position="apart">
            <Text
              size="xl"
              weight={700}
              color="yellow"
              style={{ fontSize: '18px' }}
            >{`RM Costing`}</Text>
          </Group>
          <Space h="xl" />

          <Group
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <Group
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
              }}
            >
              <TextInput
                radius={'xl'}
                placeholder={`Plant`}
                sx={{ width: '16vw' }}
                rightSection={
                  <ActionIcon
                    variant="filled"
                    color={'yellow'}
                    radius="xl"
                    size="md"
                    onClick={() => {
                      setSearchRMCost('');
                      setRMCostPagination(1);
                    }}
                  >
                    <IconRefresh size={14} color="black" />
                  </ActionIcon>
                }
                value={searchRMCost}
                onChange={(e) => {
                  setSearchRMCost(e.target.value);
                  setLoadingData(true);
                  setRMCostPagination(1);
                }}
              />
            </Group>

            <Group>
              <LastRefreshedAt lastRefreshedAt={lastRefreshedAt} screen={'Costing'} />
            </Group>
          </Group>
        </Card.Body>
        {loadingData ? (
          <CustomCardLoader />
        ) : (
          <div className="table-responsive-sm">
            <Table hover>
              <thead>
                <tr>
                  {[
                    'Material ID',
                    'Material Description',
                    'Plant',
                    'Bom ID',
                    'Bom Description',
                    check_roles(userState, ['admin', 'rm_cost', 'costing', 'mdm']) &&
                      'Standard Cost',
                    'Date On Platform',
                    'Due By',
                    check_roles(userState, ['admin', 'rm_cost']) && 'Action',
                    check_roles(userState, ['admin', 'costing', 'mdm']) && 'Uploaded To SAP',
                  ].map((item, index) => (
                    <th
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Center fw={500}>
                        {item}
                        <>
                          {sortedColumnData !== item && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchRMCost, item, 'ascending');
                              }}
                            >
                              <IconArrowsSort />
                            </ActionIcon>
                          )}
                          {sortedColumnData == item && sortType == 'ascending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchRMCost, item, 'descending');
                              }}
                            >
                              <IconSortAscending />
                            </ActionIcon>
                          )}
                          {sortedColumnData == item && sortType == 'descending' && (
                            <ActionIcon
                              onClick={() => {
                                fetchData(1, searchRMCost, null, null);
                              }}
                            >
                              <IconSortDescending />
                            </ActionIcon>
                          )}
                        </>
                      </Center>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {projectList?.map((project, index) => (
                  <tr
                    key={index}
                    // onClick={() => {
                    //   navigate(`/material/${project?.material_id}`, {
                    //     state: {
                    //       plant_id: project?.plant_id,
                    //       plant: project?.plant,
                    //       status: project?.plant_status,
                    //     },
                    //   });
                    // }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>{project?.material_id}</td>
                    <td>{project?.material_description}</td>
                    <td>{project?.plant}</td>
                    <td>{project?.bom_id}</td>
                    <td>{project?.bom_description}</td>
                    <td>
                      {check_roles(userState, ['admin', 'rm_cost']) ? (
                        <TextInput
                          placeholder="Enter Standard Price/1000"
                          value={project?.standard_cost}
                          onChange={(e) => {
                            let temp = [...projectList];
                            temp[index].standard_cost = e.target.value;
                            setProjectList(temp);
                          }}
                          radius={'xl'}
                          type="number"
                          disabled={project?.checked}
                        />
                      ) : (
                        <TextInput
                          placeholder="Enter Standard Price/1000"
                          value={project?.standard_cost}
                          onChange={(e) => {
                            let temp = [...projectList];
                            temp[index].standard_cost = e.target.value;
                            setProjectList(temp);
                          }}
                          radius={'xl'}
                          type="number"
                          disabled={true}
                        />
                      )}
                    </td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {moment(project?.date_time).format('MM-DD-YYYY')}
                    </td>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {/* {moment(project?.date_time).add(4, 'days').format('MM-DD-YYYY')} */}

                      {DateAndDayFormatter(project?.date_time, 4).day}
                    </td>
                    <td>
                      {check_roles(userState, ['admin', 'rm_cost']) && (
                        <ActionIcon
                          variant="light"
                          color={'yellow'}
                          radius="xl"
                          disabled={project?.checked}
                          style={{
                            margin: 'auto',
                          }}
                        >
                          <IconDeviceFloppy
                            size={16}
                            color="black"
                            onClick={() => {
                              if (!project?.standard_cost) {
                                toast.error('Please Enter Standard Cost');
                                return;
                              }
                              homeServices
                                .saveRmCostingData({
                                  material_id: project?.material_id,
                                  bom_id: project?.bom_id,
                                  standard_cost: Number(project?.standard_cost)?.toFixed(3),
                                })
                                .then((res) => {
                                  toast.success('RM Costing Saved Successfully');
                                })
                                .catch((err) => {
                                  toast.error('Error Saving RM Costing');
                                });
                            }}
                          />
                        </ActionIcon>
                      )}
                    </td>
                    <td>
                      {check_roles(userState, ['admin', 'costing', 'mdm']) && (
                        <ActionIcon
                          variant="light"
                          color={'yellow'}
                          radius="xl"
                          disabled={!project?.standard_cost || project?.checked}
                          onClick={() => {
                            let temp = [...projectList];
                            temp[index].checked = true;
                            setProjectList(temp);
                            homeServices
                              .checkRmCostingData({
                                material_id: project?.material_id,
                                bom_id: project?.bom_id,
                                standard_cost: Number(project?.standard_cost)?.toFixed(3),
                              })
                              .then((res) => {
                                toast.success('Item Checked Successfully');
                              })
                              .catch((err) => {
                                toast.error('Error Saving RM Costing');
                              });
                          }}
                          style={{
                            margin: 'auto',
                          }}
                        >
                          <IconCheck size={16} color="black" onClick={() => {}} />
                        </ActionIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <Card.Footer>
          <Container>
            <Center>
              <Pagination
                total={pages + 1}
                color="yellow"
                radius="xl"
                withEdges
                page={RMCostPagination}
                boundaries={1}
                initialPage={RMCostPagination}
                value={RMCostPagination}
                onChange={(e) => {
                  setRMCostPagination(e);
                  fetchData(e, searchRMCost, sortedColumnData, sortType);
                }}
              />
            </Center>
          </Container>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default RmCosting;
