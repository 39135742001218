import React, { useContext, useEffect, useState, useMemo } from 'react';
import { MsalProvider, useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, backendApiRequest } from './utils/authConfig';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/App.css';
import TopBar from './parts/TopBar/TopBar';
import Footer from './parts/Footer/Footer';
import Login from './pages/Login/Login';
import Home from './pages/Home';
import NoMatch from './pages/NoMatch/NoMatch';
import { UserContext, UserContextProvider } from './context/userContext';
import { ActiveUserContext, ActiveUserContextProvider } from './context/activerUserContext';
import ServiceWorkerWrapper from './parts/ServiceWorkerWrapper/ServiceWorkerWrapper';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import dataService from './services/dataService';
import ProjectRequest from './pages/ProjectRequest';
import axios from './utils/axios';
import FgForm from './pages/FgForm/FgForm';
import MaterialForm from './pages/MaterialForm/MaterialForm';
import { PageContextProvider } from './context/pageContext';
import ManageHub from './pages/ManageHub/ManageHub';
import Plants from './pages/Plants/Plants';
import RequestRole from './pages/RequestRole/RequestRole';
import userService from './services/userService';

// import userService from './services/userService';

const Pages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  // const account = useAccount(accounts[0] || {});
  const [userState, userDispatch] = useContext(ActiveUserContext);
  // const {} = useContext(UserContext);

  axios.interceptors.response.use(
    function (successRes) {
      // console.log(successRes);
      return successRes;
    },
    function axiosLogoutInterceptor(err) {
      const originalRequest = err.config;

      // console.log(err.response?.config?.url);

      if (
        err.response?.config?.url.includes('/api') &&
        err.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return axios.request(originalRequest);
      } else if (originalRequest?._retry) {
        instance.logout();
        localStorage.clear();
        window.location.reload();
        instance.loginRedirect(loginRequest);
      }
      return Promise.reject(err);
    },
  );

  useEffect(() => {
    // console.log(`AUTH LOG = ${isAuthenticated}, InProgress = ${inProgress}`);
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      // console.log('You are not authenticated!');
      navigate('/login');
    }
  }, [inProgress, accounts]);

  function authFlow() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(async (response) => {
        await instance
          .acquireTokenSilent({
            ...backendApiRequest,
            account: accounts[0],
          })
          .then(async (response) => {
            await localStorage.setItem('access_token_backend', response?.accessToken);
          });

        // apiService
        await userDispatch({
          type: 'SET_USER',
          payload: {
            name: accounts[0]?.name,
            email: accounts[0]?.username,
          },
        });

        await localStorage.setItem('id_token', response?.idToken);
        await localStorage.setItem('access_token', response?.accessToken);

        // console.log('Response Auth: ', response);

        // .catch((err) => {
        //   console.log(`Error occured while acquiring token: ${err}`);
        //   // navigate('/login');
        //   localStorage.clear();
        //   instance.logout({
        //     account: accounts.length > 0 ? accounts[0] : null,
        //   });
        // });

        // setOid(accounts[0]?.idTokenClaims?.oid);

        await userService.getUserRoles().then((res) => {
          if (res?.data?.Success) userDispatch({ type: 'SET_ROLES', payload: res?.data?.roles });
        });

        dataService
          .getMSGraphPhoto(response.accessToken)
          .then((image) => {
            if (image.type === 'image/jpeg') {
            }
            userDispatch({ type: 'SET_PROFILE_PHOTO', payload: image });
          })
          .catch((err) => {});

        // userService.getUserId().then((res) => {
        //   if (res?.data?.success) userDispatch({ type: 'SET_USER_ID', payload: res?.data?.data });
        // });
      })
      .catch((err) => {
        // console.log(`Error occured while acquiring token: ${err}`);
        // navigate('/login');
        localStorage.clear();
        instance.logout({
          account: accounts.length > 0 ? accounts[0] : null,
        });
      });
  }

  useEffect(() => {
    // console.log('Account: ', accounts[0]);

    if (accounts?.length > 0) {
      // console.log(accounts[0].name);
      // console.log('starting below');
      authFlow();
    }
  }, [accounts, inProgress]);

  // useEffect(() => {
  //   console.log(userState);
  // }, [userState]);

  return (
    <div className="page">
      <div className="flex-fill">
        {!['/login'].includes(location?.pathname) && <TopBar />}
        <div className="my-3 my-md-5" style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/project-request" element={<ProjectRequest />} />
            <Route path="/project-request/:id" element={<ProjectRequest />} />
            <Route path="/manage-hub" element={<ManageHub />} />
            <Route path="/role-request" element={<RequestRole />} />
            <Route path="/plants/:id" element={<Plants />} />
            {/* <Route path="/material" element={<
 />} /> */}

            <Route path="/material/:id/:plant_id" element={<MaterialForm />} />
            <Route path="/fgform" element={<FgForm />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

function App({ msalInstance }) {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <MsalProvider instance={msalInstance}>
            <MantineProvider
              theme={{
                components: {
                  Select: {
                    styles: {
                      input: {
                        '&:focus': { borderColor: '#e3af32' },
                      },
                      selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                      hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                    },
                  },
                  TextInput: {
                    styles: {
                      input: {
                        '&:focus': { borderColor: '#e3af32' },
                      },
                      selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                      hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                    },
                  },
                  DatePicker: {
                    styles: {
                      input: {
                        '&:focus': { borderColor: '#e3af32' },
                      },
                      selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                      hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                    },
                  },
                },
              }}
              styles={{
                Calendar: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: {
                    backgroundColor: '#e3af3221',
                    color: '#e3af32',
                  },
                }),
                DatePicker: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                }),
                DateRangePicker: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: {
                    backgroundColor: 'transparent',
                    color: 'unset',
                  },
                }),
                Select: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                  hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                }),
                NativeSelect: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                  hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                }),
                TextInput: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                  hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                }),

                Textarea: (theme) => ({
                  input: {
                    '&:focus': { borderColor: '#e3af32' },
                  },
                  selected: { color: '#e3af32', backgroundColor: '#e3af3221' },
                  hovered: { color: '#e3af32', backgroundColor: '#e3af3221' },
                }),
              }}
            >
              <Notifications />
              <ActiveUserContextProvider>
                <UserContextProvider>
                  <PageContextProvider>
                    {navigator.onLine && <Pages />}
                    {/* {!navigator.onLine && <NoInternet />} */}
                  </PageContextProvider>
                </UserContextProvider>
              </ActiveUserContextProvider>
            </MantineProvider>
          </MsalProvider>
        </Router>
      </header>
      <br />
      <br />
      {/* toaster */}
      <ToastContainer position="bottom-right" autoClose={2000} />
      <Footer />
      {/* <ServiceWorkerWrapper /> */}
    </div>
  );
}
export default App;
