/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
import {
  Button,
  Checkbox,
  Grid,
  Group,
  Modal,
  MultiSelect,
  Select,
  Space,
  Table,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../context/userContext';
import bomService from '../../../../services/bomService';
import materialService from '../../../../services/materialService';
import plantService from '../../../../services/plantService';
import projectService from '../../../../services/projectService';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DisabledTextInput from '../../../../components/DisabledTextInput/DisabledTextInput';
import ReferenceHelpdesk from '../../../../components/ReferenceHelpdesk/ReferenceHelpdesk';
import CustomCardLoader from '../../../../components/CustomCardLoader';
import SearchableSelect from '../../../../components/SearchableSelect/SearchableSelect';
import { IconCheck, IconLoader, IconX } from '@tabler/icons';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import DataTable from '../../../../components/DataTable/DataTable';
import { mapStatusToValue } from '../../../../utils/Utils';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';
import DescriptionForm from '../../../../components/DescriptionForm/DescriptionForm';

function MDMView3({ setPlantStatus }) {
  // PARAMS
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // CONTEXT
  const { loadProductionLineList } = useContext(UserContext);

  // STATE
  const [materialData, setMaterialData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [selectedPlant, setSelectedPlant] = useState();
  const [plantList, setPlantList] = useState([]);
  const [plantDataLoading, setPlantDataLoading] = useState(false);

  // function
  const fetchData = () => {
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          setMaterialData(res?.data?.material);
          projectService.getProjectById(res?.data?.material?.project_id).then((res) => {
            if (res?.data) {
              setProjectData(res?.data?.project);
            }
          });
        }
      });
    }

    if (params?.id) {
      materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
        if (res?.data) {
          //console.log('PLANT LENGTH', res?.data);
          setPlantList(res?.data?.plants);
          if (!params?.plant_id) {
            setSelectedPlant(res?.data?.plants[0]?.id);
          }
          if (!location?.state?.plant) {
            loadProductionLineList(res?.data?.plants[0]?.plant);
          }
        }
      });
    }
  };

  // USE EFFECTS
  useEffect(() => {
    if (params?.plant_id) {
      setSelectedPlant(params?.plant_id);
    }
    if (location?.state?.plant) {
      loadProductionLineList(location?.state?.plant);
    }
  }, [location?.state?.plant_id]);

  useEffect(() => {
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  useEffect(() => {
    setPlantDataLoading(true);

    setTimeout(() => {
      setPlantDataLoading(false);
    }, 2000);
  }, [selectedPlant]);

  useEffect(() => {
    //console.log('PARAMS', params);
    fetchData();
  }, [params?.id]);

  return (
    <>
      <Row>
        <Col xs={12} sm={2}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              >{`Plant List`}</Text>
              <Space h="sm" />
            </Card.Body>

            <Card.Body>
              {plantList?.length > 0 ? (
                <Group position="center">
                  <div className="table-responsive-sm">
                    <Table hover striped highlightOnHover withBorder>
                      <tbody>
                        {plantList?.map((plant, index) => (
                          <tr
                            style={{
                              cursor: 'pointer',
                              // backgroundColor: plant?.id === selectedPlant ? '#fab005' : '',
                              // color: plant?.id === selectedPlant ? '#fff' : '',
                            }}
                            onClick={() => {
                              loadProductionLineList(plant?.plant);
                              setSelectedPlant(plant?.id);
                              //console.log('Navigating');
                              navigate(`/material/${params?.id}/${plant?.id}`);
                            }}
                          >
                            <td
                              className={
                                plant?.id === selectedPlant ? 'table-row-selected' : 'table-color'
                              }
                            >
                              {plant?.plant}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Group>
              ) : (
                <CustomCardLoader />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={10}>
          {!plantList?.length > 0 ? (
            <CustomCardLoader />
          ) : (
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {'SAP BOMs Creation'}
                  </Text>
                </Group>
              </Card.Body>

              {plantDataLoading ? (
                <CustomCardLoader />
              ) : (
                <Card.Body>
                  <Group>
                    <Container>
                      <Tabs color="yellow" variant="pills" radius="xl" defaultValue="package">
                        <Tabs.List>
                          <Tabs.Tab value="package">PACKAGE BOM</Tabs.Tab>
                          <Tabs.Tab value="liquid">LIQUID BOM</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="package">
                          <PackageBOM
                            materialData={materialData}
                            projectData={projectData}
                            selectedPlant={selectedPlant}
                            setPlantStatus={setPlantStatus}
                            refresh={fetchData}
                          />
                        </Tabs.Panel>
                        <Tabs.Panel value="liquid">
                          <LiquidBOM
                            materialData={materialData}
                            projectData={projectData}
                            selectedPlant={selectedPlant}
                            setPlantStatus={setPlantStatus}
                          />
                        </Tabs.Panel>
                      </Tabs>
                    </Container>
                  </Group>
                </Card.Body>
              )}
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}

export default MDMView3;

// TAB DATA FOR PACKAGE BOM
function PackageBOM({ selectedPlant, projectData, materialData, setPlantStatus, refresh }) {
  const [userState, userDispatch] = useContext(ActiveUserContext);
  const navigate = useNavigate();
  // CONTEXT
  const { productionLineList } = useContext(UserContext);

  // STATE
  const [submitLoader, setSubmitLoader] = useState(false);
  const [plantData, setPlantData] = useState({});
  const [similarSkuSerachValue, setSimilarSkuSerachValue] = useState('');
  const [ExistingSimilarSkuId, setExistingSimilarSkuId] = useState('');
  const [similarSkuList, setsimilarSkuList] = useState([]);
  const [helpdeskPopup, setHelpdeskPopup] = useState(false);
  const [FgBOMList, setFgBOMList] = useState([]);
  const [copyEnabled, setCopyEnabled] = useState(false);
  const [copiedItem, setCopiedItem] = useState({});
  const [phantomList, setPhantomList] = useState([]);

  // USE EFFECTS
  const fetchData = () => {
    if (selectedPlant) {
      plantService.getPlantById(selectedPlant).then((res) => {
        if (res?.data) {
          setPlantData(res?.data?.data);
          setPlantStatus(res?.data?.data?.status);
        }
      });

      bomService.getFgAndPhantomBom({ plant_id: selectedPlant }).then((res) => {
        setFgBOMList(res?.fgBomList);
        setPhantomList(res?.phantomBomList);
        formik.setFieldValue('similarSku', res?.packagingBOM?.similar_sku_id);
        formik.setFieldValue('similarSkuDescription', res?.packagingBOM?.similar_sku_description);
        formik.setFieldValue(
          'skuNotExist',
          Boolean(res?.packagingBOM?.no_similar_sku_exists) ? true : false,
        );
        formik.setFieldValue(
          'skuNotRequired',
          Boolean(res?.packagingBOM?.no_similar_sku_required) ? true : false,
        );
        res?.packagingBOM?.id &&
          bomService
            .getProductionLinesByPackagingBOM({
              plant_id: selectedPlant,
            })
            .then((res) => {
              formik.setFieldValue(
                'production_line',
                res?.data?.map((item) => item?.production_line),
              );
            });
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPlant]);

  // SEARCHING EXISTING
  const serchSimilarSku = (val) => {
    materialService.getSimilarSkuList(val).then((res) => {
      if (res && res.data) {
        setsimilarSkuList(res?.data?.sku);
        return true;
      }
    });
  };

  // HANDLE SEARCHING EXISTING
  const handleSelectSimilarSku = (val) => {
    materialService.getSimilarSkuDesc(val).then((res) => {
      if (res && res.data) {
        setExistingSimilarSkuId(res?.data?.skudesc[0]?.sku_description);
        formik.setFieldValue('similarSkuDescription', res?.data?.skudesc[0]?.sku_description);
        return true;
      }
    });
  };

  // FORMIK GLOBAL
  const formik = useFormik({
    initialValues: {
      similarSku: '',
      similarSkuDescription: '',
      skuNotRequired: false,
      skuNotExist: false,
      production_line: [],
      sku: '',
      skuDescription: '',
      approvePackagingBOM: '',
      isLiquidBOMrequired: '',
      isLiquidBOMrequiredComment: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // FORMIK FG BOM TABLE
  const fgBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {},
  });

  // FORMIK PHANTOM BOM TABLE
  const phantomBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
    },
    validationSchema: Yup.object({
      component: Yup.string().required('Component is required'),
      component_description: Yup.string().required('Description is required'),
      quantity: Yup.string().required('Quantity is required'),
      scrap: Yup.string().required('Scrap is required'),
      uom: Yup.string().required('UOM is required'),
      standard_cost: Yup.string().required('Standard Cost is required'),
    }),
    onSubmit: (values, { setSubmitting }) => {},
  });

  return (
    <>
      {/* <Container> */}
      <Row>
        {' '}
        <Col xs={12} sm={12}>
          <Grid radius="xl">
            {/* FORM FIELDS */}
            <Card.Body align="left">
              <Grid
                style={{
                  alignItems: 'end',
                }}
              >
                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="Project Name"
                    placeholder="Project Name"
                    value={projectData?.project_name}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="Project Type"
                    placeholder="Project Type"
                    value={projectData?.project_type}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="Status"
                    placeholder="Status"
                    value={mapStatusToValue(plantData?.p_bom_status)}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="SKU Id"
                    placeholder="SKU Id"
                    value={
                      materialData?.existing_item === 'Yes'
                        ? materialData?.existing_item_number
                        : materialData?.sku_id
                    }
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="SKU Description"
                    placeholder="SKU Description"
                    value={materialData?.sku_description}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
                </Grid.Col>

                {materialData?.existing_item === 'No' && (
                  <Grid.Col span={4}>
                    <TextInput
                      placeholder="FG Type"
                      label="FG Type"
                      radius={'lg'}
                      value={materialData.fg_type}
                      disabled
                    />
                  </Grid.Col>
                )}

                <Grid.Col span={4}>
                  <DisabledTextInput
                    label="Material Type"
                    placeholder="Material Type"
                    value={materialData?.material_type}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <MultiSelect
                    label="Production Line"
                    placeholder="Production Line"
                    radius={'lg'}
                    value={formik.values.production_line}
                    onChange={(e) => {
                      //console.log(e);
                      formik.setFieldValue('production_line', e);
                    }}
                    data={productionLineList}
                    searchable
                    nothingFound="Nothing found"
                    // error={formik.errors.production_line}
                    disabled
                  />
                </Grid.Col>

                {/* <Grid.Col span={4}></Grid.Col> */}

                <Grid.Col
                  span={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid.Col span={2}>
                    <Checkbox
                      label="No similar SKU Required"
                      color="yellow"
                      checked={formik.values.skuNotRequired}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setSimilarSkuSerachValue('');
                          setExistingSimilarSkuId('');
                          formik.setFieldValue('similarSku', '');
                          formik.setFieldValue('similarSkuDescription', '');
                        }
                        formik.setFieldValue('skuNotRequired', e.target.checked);
                        // formik.setFieldValue('notInTheList', e);
                      }}
                      radius="xl"
                      size="xs"
                      // disabled={formik.values.skuNotExist}
                      disabled
                      error={formik.errors.skuNotRequired}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Checkbox
                      label="No similar SKU Exist"
                      color="yellow"
                      checked={formik.values.skuNotExist}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setSimilarSkuSerachValue('');
                          setExistingSimilarSkuId('');
                          formik.setFieldValue('similarSku', '');
                          formik.setFieldValue('similarSkuDescription', '');
                        }
                        formik.setFieldValue('skuNotExist', e.target.checked);
                        // formik.setFieldValue('notInTheList', e);
                      }}
                      radius="xl"
                      size="xs"
                      // disabled={formik.values.skuNotRequired}
                      disabled
                      error={formik.errors.skuNotExist}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Select
                      label="Similar SKU ID"
                      placeholder="Similar SKU ID"
                      radius={'lg'}
                      searchable
                      onSearchChange={(e) => {
                        setSimilarSkuSerachValue(e);
                        serchSimilarSku(e);
                      }}
                      searchValue={similarSkuSerachValue}
                      onChange={(e) => {
                        formik.setFieldValue('similarSku', e);
                        handleSelectSimilarSku(e);
                      }}
                      data={similarSkuList}
                      value={formik.values.similarSku}
                      error={formik.errors.similarSku}
                      // disabled={formik.values.skuNotRequired || formik.values.skuNotExist}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      disabled
                      label="Similar SKU Description"
                      placeholder="Similar SKU Description"
                      radius={'lg'}
                      value={formik.values.similarSkuDescription}
                      error={formik.errors.similarSkuDescription}
                    />
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Button
                    color="yellow"
                    radius="xl"
                    onClick={() => {
                      setHelpdeskPopup(true);
                    }}
                  >
                    Helpdesk
                    <Modal
                      centered
                      radius={'lg'}
                      size="lg"
                      opened={helpdeskPopup}
                      title={<Text weight={700} className="golden-text">{`Helpdesk`}</Text>}
                      onClose={() => setHelpdeskPopup(false)}
                    >
                      <Card>
                        <Card.Body>
                          <Space h={40} />
                        </Card.Body>
                      </Card>
                    </Modal>
                  </Button>
                </Grid.Col>
              </Grid>
            </Card.Body>

            {/* TABS */}
            <Card.Body align="left">
              <Container>
                <Tabs color="yellow" variant="pills" radius="xl" defaultValue="fg_bom">
                  <Tabs.List>
                    <Tabs.Tab value="fg_bom">FG BOM</Tabs.Tab>
                    <Tabs.Tab value="phantom">Phantom</Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="fg_bom" pt="xs">
                    {/* <BOMtable
                      list={FgBOMList}
                      plant={plantData.plant}
                      setList={setFgBOMList}
                      formik={fgBOM_formik}
                      updateData={() => {}}
                      deleteData={() => {}}
                      copyEnabled={copyEnabled}
                      setCopyEnabled={setCopyEnabled}
                      copiedItem={copiedItem}
                      disabled={true}
                    /> */}

                    <DataTable
                      data={FgBOMList}
                      columns={[
                        {
                          title: 'Component',
                          field: 'component',
                        },
                        {
                          title: 'Description',
                          field: 'component_description',
                        },
                        {
                          title: 'Quantity',
                          field: 'quantity',
                        },
                        {
                          title: 'Scrap',
                          field: 'scrap',
                        },
                        {
                          title: 'UOM',
                          field: 'uom',
                        },
                        {
                          title: 'Standard Cost',
                          field: 'standard_cost',
                        },
                      ]}
                    />
                  </Tabs.Panel>

                  <Tabs.Panel value="phantom" pt="xs">
                    {/* <BOMtable
                      list={phantomList}
                      plant={plantData.plant}
                      setList={setPhantomList}
                      formik={phantomBOM_formik}
                      updateData={() => {}}
                      deleteData={() => {}}
                      copyEnabled={copyEnabled}
                      setCopyEnabled={setCopyEnabled}
                      copiedItem={copiedItem}
                      disabled={true}
                    /> */}

                    <DataTable
                      data={phantomList}
                      columns={[
                        {
                          title: 'Component',
                          field: 'component',
                        },
                        {
                          title: 'Description',
                          field: 'component_description',
                        },
                        {
                          title: 'Quantity',
                          field: 'quantity',
                        },
                        {
                          title: 'Scrap',
                          field: 'scrap',
                        },
                        {
                          title: 'UOM',
                          field: 'uom',
                        },
                        {
                          title: 'Standard Cost',
                          field: 'standard_cost',
                        },
                      ]}
                    />
                  </Tabs.Panel>
                </Tabs>
              </Container>
            </Card.Body>

            {/* HELPDESK */}
            {/* <ReferenceHelpdesk
              plantData={plantData}
              setCopiedItem={setCopiedItem}
              setCopyEnabled={setCopyEnabled}
            /> */}
          </Grid>
        </Col>
        <Card.Footer>
          <Col>
            {check_roles(userState, ['admin', 'mdm']) && (
              <>
                {materialData?.material_status == 4 &&
                plantData?.status == 8 &&
                plantData?.p_bom_status == 4 ? (
                  <Group position="right">
                    <Button
                      color="yellow"
                      radius="xl"
                      disabled={submitLoader}
                      leftIcon={
                        submitLoader ? (
                          <IconLoader size={16} color="white" />
                        ) : (
                          <IconCheck size={16} color="white" />
                        )
                      }
                      onClick={() => {
                        setSubmitLoader(true);
                        bomService
                          .uploadTOSapPackageBOM({
                            id: selectedPlant,
                          })
                          .then((res) => {
                            refresh();
                            fetchData();
                            setSubmitLoader(false);

                            if (
                              materialData?.material_status == 4 &&
                              plantData?.status == 8 &&
                              plantData?.l_bom_status == 2
                            ) {
                              toast.success(
                                'Successfully uploaded to SAP, Now you can submit for Liquid Bom',
                              );
                            } else {
                              toast.success('Successfully uploaded to SAP');
                              setTimeout(() => {
                                navigate('/');
                              }, 1500);
                            }
                          })
                          .catch((err) => {
                            toast.error('Error uploading to SAP, Try again later');
                          });
                      }}
                    >
                      {submitLoader ? <CustomCardLoader /> : 'Uploaded to SAP'}
                    </Button>
                  </Group>
                ) : null}
              </>
            )}
          </Col>
          <Space h="xl"></Space>
        </Card.Footer>
      </Row>
    </>
  );
}

// TAB DATA FOR LIQUID BOM;

function LiquidBOM({ selectedPlant, projectData, materialData, setPlantStatus }) {
  const params = useParams();
  const [userState, userDispatch] = useContext(ActiveUserContext);
  // STATE DATAS
  const [submitLoading, setSubmitLoading] = useState(false);
  const [plantData, setPlantData] = useState({});
  const [copiedItem, setCopiedItem] = useState({});
  const [copyEnabled, setCopyEnabled] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);

  // BOM SUB TABLES DATA
  const [brandBOMList, setBrandBOMList] = useState([]);
  const [wortBOMList, setWortBOMList] = useState([]);
  const [fermentBOMList, setFermentBOMList] = useState([]);
  const [ageBOMList, setAgeBOMList] = useState([]);
  const [blendBOMList, setBlendBOMList] = useState([]);
  const [tankerBOMList, setTankerBOMList] = useState([]);

  // FORMIK
  const formik = useFormik({
    initialValues: {
      bomExits: '',
      bomType: '',
      brandId: '',
      brandDescription: '',
      wortId: '',
      wortDescription: '',
      fermentId: '',
      fermentDescription: '',
      ageId: '',
      ageDescription: '',
      blendId: '',
      blendDescription: '',
      tankerId: '',
      tankerDescription: '',
      production_line: [],
      similarSku: '',
      similarSkuDescription: '',
      skuNotRequired: false,
      skuNotExist: false,
      // BRAND
      similarBrand: '',
      similarBrandDescription: '',
      brandNotRequired: false,
      brandNotExist: false,

      // AGE
      similarAge: '',
      similarAgeDescription: '',
      ageNotRequired: false,
      ageNotExist: false,
      // FERMENT
      similarFerment: '',
      similarFermentDescription: '',
      fermentNotRequired: false,
      fermentNotExist: false,

      // WORT
      similarWort: '',
      similarWortDescription: '',
      wortNotRequired: false,
      wortNotExist: false,

      // BLEND
      similarBlend: '',
      similarBlendDescription: '',
      blendNotRequired: false,
      blendNotExist: false,

      // TANKER
      similarTanker: '',
      similarTankerDescription: '',
      tankerNotRequired: false,
      tankerNotExist: false,
    },

    onSubmit: (values, { setSubmitting }) => {},
  });

  // FORMIK VALIDATION SCHEMA FOR SUB TABLES

  // BRAND BOM FORMIK & FUNCTIONS
  const brandBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // AFE BOM FORMIK & FUNCTIONS
  const ageBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // FERMENTATION BOM FORMIK & FUNCTIONS
  const fermentBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // WORT BOM FORMIK & FUNCTIONS
  const wortBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // BLEND BOM FORMIK & FUNCTIONS
  const blendBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // TANKER BOM FORMIK & FUNCTIONS
  const tankerBOM_formik = useFormik({
    initialValues: {
      component: '',
      component_description: '',
      quantity: '',
      scrap: '',
      uom: '',
      standard_cost: '',
      new_component: '',
    },
    onSubmit: (values, { setSubmitting }) => {},
  });

  // FUNCTIONS
  const fetchInitialData = () => {
    const getLiquidBomType = (type) => {
      switch (type) {
        case 1:
          return 'Liquid';
        case 2:
          return 'Blend';
        case 3:
          return 'Tanker';
        default:
          return '';
      }
    };
    const getLiquidExist = (type) => {
      switch (type) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return '';
      }
    };
    bomService
      .getLiquidBOM({
        plant_id: selectedPlant,
      })
      .then((res) => {
        setBlendBOMList(res?.blendBomList);
        setTankerBOMList(res?.tankerBomList);
        setBrandBOMList(res?.brandBomList);
        setAgeBOMList(res?.ageBomList);
        setWortBOMList(res?.wortBomList);
        setFermentBOMList(res?.fermentBomList);

        formik.setFieldValue('bomExits', getLiquidExist(res?.liquidBom?.existing_liquid_bom));
        formik.setFieldValue('bomType', getLiquidBomType(res?.liquidBom?.liquid_bom_type));
        formik.setFieldValue('brandId', res?.liquidBom?.brand_id ? res?.liquidBom?.brand_id : '');
        formik.setFieldValue(
          'brandDescription',
          res?.liquidBom?.brand_description ? res?.liquidBom?.brand_description : '',
        );
        formik.setFieldValue('wortId', res?.liquidBom?.wort_id ? res?.liquidBom?.wort_id : '');
        formik.setFieldValue(
          'wortDescription',
          res?.liquidBom?.wort_description ? res?.liquidBom?.wort_description : '',
        );
        formik.setFieldValue(
          'fermentId',
          res?.liquidBom?.ferment_id ? res?.liquidBom?.ferment_id : '',
        );
        formik.setFieldValue(
          'fermentDescription',
          res?.liquidBom?.ferment_description ? res?.liquidBom?.ferment_description : '',
        );
        formik.setFieldValue('ageId', res?.liquidBom?.age_id ? res?.liquidBom?.age_id : '');
        formik.setFieldValue(
          'ageDescription',
          res?.liquidBom?.age_description ? res?.liquidBom?.age_description : '',
        );
        formik.setFieldValue('blendId', res?.liquidBom?.blend_id ? res?.liquidBom?.blend_id : '');
        formik.setFieldValue(
          'blendDescription',
          res?.liquidBom?.blend_description ? res?.liquidBom?.blend_description : '',
        );
        formik.setFieldValue(
          'tankerId',
          res?.liquidBom?.tanker_id ? res?.liquidBom?.tanker_id : '',
        );
        formik.setFieldValue(
          'tankerDescription',
          res?.liquidBom?.tanker_description ? res?.liquidBom?.tanker_description : '',
        );
        formik.setFieldValue(
          'production_line',
          res?.production_line?.length > 0
            ? res?.production_line?.map((item) => item?.production_line)
            : [],
        );
        formik.setFieldValue(
          'similarSku',
          Boolean(res?.liquidBom?.similar_sku_id) ? res?.liquidBom?.similar_sku_id : '',
        );
        formik.setFieldValue(
          'similarSkuDescription',
          Boolean(res?.liquidBom?.similar_sku_description)
            ? res?.liquidBom?.similar_sku_description
            : '',
        );
        formik.setFieldValue(
          'skuNotExist',
          Boolean(res?.liquidBom?.no_similar_sku_exists) ? true : false,
        );
        formik.setFieldValue(
          'skuNotRequired',
          Boolean(res?.liquidBom?.no_similar_sku_required) ? true : false,
        );
        // BRAND
        formik.setFieldValue(
          'similarBrand',
          Boolean(res?.liquidBom?.similar_brand_id) ? res?.liquidBom?.similar_brand_id : '',
        );
        formik.setFieldValue(
          'similarBrandDescription',
          Boolean(res?.liquidBom?.similar_brand_description)
            ? res?.liquidBom?.similar_brand_description
            : '',
        );
        formik.setFieldValue(
          'brandNotExist',
          Boolean(res?.liquidBom?.no_similar_brand_exist) ? true : false,
        );
        formik.setFieldValue(
          'brandNotRequired',
          Boolean(res?.liquidBom?.no_similar_brand_required) ? true : false,
        );

        // AGE
        formik.setFieldValue(
          'similarAge',
          Boolean(res?.liquidBom?.similar_age_id) ? res?.liquidBom?.similar_age_id : '',
        );
        formik.setFieldValue(
          'similarAgeDescription',
          Boolean(res?.liquidBom?.similar_age_description)
            ? res?.liquidBom?.similar_age_description
            : '',
        );
        formik.setFieldValue(
          'ageNotExist',
          Boolean(res?.liquidBom?.no_similar_age_exist) ? true : false,
        );
        formik.setFieldValue(
          'ageNotRequired',
          Boolean(res?.liquidBom?.no_similar_age_required) ? true : false,
        );

        // FERMENT
        formik.setFieldValue(
          'similarFerment',
          Boolean(res?.liquidBom?.similar_ferment_id) ? res?.liquidBom?.similar_ferment_id : '',
        );
        formik.setFieldValue(
          'similarFermentDescription',
          Boolean(res?.liquidBom?.similar_ferment_description)
            ? res?.liquidBom?.similar_ferment_description
            : '',
        );
        formik.setFieldValue(
          'fermentNotExist',
          Boolean(res?.liquidBom?.no_similar_ferment_exist) ? true : false,
        );
        formik.setFieldValue(
          'fermentNotRequired',
          Boolean(res?.liquidBom?.no_similar_ferment_required) ? true : false,
        );

        // WORT
        formik.setFieldValue(
          'similarWort',
          Boolean(res?.liquidBom?.similar_wort_id) ? res?.liquidBom?.similar_wort_id : '',
        );
        formik.setFieldValue(
          'similarWortDescription',
          Boolean(res?.liquidBom?.similar_wort_description)
            ? res?.liquidBom?.similar_wort_description
            : '',
        );
        formik.setFieldValue(
          'wortNotExist',
          Boolean(res?.liquidBom?.no_similar_wort_exist) ? true : false,
        );
        formik.setFieldValue(
          'wortNotRequired',
          Boolean(res?.liquidBom?.no_similar_wort_required) ? true : false,
        );

        // BLEND
        formik.setFieldValue(
          'similarBlend',
          Boolean(res?.liquidBom?.similar_blend_id) ? res?.liquidBom?.similar_blend_id : '',
        );
        formik.setFieldValue(
          'similarBlendDescription',
          Boolean(res?.liquidBom?.similar_blend_description)
            ? res?.liquidBom?.similar_blend_description
            : '',
        );
        formik.setFieldValue(
          'blendNotExist',
          Boolean(res?.liquidBom?.no_similar_blend_exist) ? true : false,
        );
        formik.setFieldValue(
          'blendNotRequired',
          Boolean(res?.liquidBom?.no_similar_blend_required) ? true : false,
        );

        // TANKER
        formik.setFieldValue(
          'similarTanker',
          Boolean(res?.liquidBom?.similar_tanker_id) ? res?.liquidBom?.similar_tanker_id : '',
        );
        formik.setFieldValue(
          'similarTankerDescription',
          Boolean(res?.liquidBom?.similar_tanker_description)
            ? res?.liquidBom?.similar_tanker_description
            : '',
        );
        formik.setFieldValue(
          'tankerNotExist',
          Boolean(res?.liquidBom?.no_similar_tanker_exist) ? true : false,
        );
        formik.setFieldValue(
          'tankerNotRequired',
          Boolean(res?.liquidBom?.no_similar_tanker_required) ? true : false,
        );
      });
  };

  // USE EFFECTS

  useEffect(() => {
    if (selectedPlant) {
      plantService.getPlantById(selectedPlant).then((res) => {
        if (res?.data) {
          //console.log('PLANT', res?.data?.data);
          setPlantData(res?.data?.data);
          setPlantStatus(res?.data?.data?.status);
        }
      });

      fetchInitialData();
    }
  }, [selectedPlant]);

  const rejectLiquidBOM = (comment) => {
    bomService
      ?.rejectLiquidBOM({
        id: selectedPlant,
        comment,
        status: 7,
        l_bom_status: 5,
        material_id: params?.id,
      })
      .then((res) => {
        toast.success('Liquid BOM Rejected');
        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  const navigate = useNavigate();

  return (
    <>
      {plantData?.l_bom_status == 3 ? (
        <Row>
          {/* LEFT CARD SHOWING PLANT LISTS AND PLANT SELECTION */}

          {/* RIGHT CARD PLANT DETAILS AND LIQUID BOM FORMS */}
          <Col xs={12} sm={12}>
            {/* FORM FIELDS */}
            <Card.Body align="left">
              <Grid
                style={{
                  alignItems: 'end',
                }}
              >
                <Grid.Col span={4}>
                  <Select
                    label="Is Liquid BOM Required?"
                    placeholder="Select"
                    radius={'lg'}
                    data={[
                      {
                        label: 'Yes',
                        value: 'Yes',
                      },
                      {
                        label: 'Not Required',
                        value: 'Not Required',
                      },
                    ]}
                    value={'Not Required'}
                    disabled
                  />
                </Grid.Col>
              </Grid>
            </Card.Body>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {/* LEFT CARD SHOWING PLANT LISTS AND PLANT SELECTION */}

            {/* RIGHT CARD PLANT DETAILS AND LIQUID BOM FORMS */}
            <Col xs={12} sm={12}>
              {/* FORM FIELDS */}
              <Card.Body align="left">
                <Grid
                  style={{
                    alignItems: 'end',
                  }}
                >
                  <Grid.Col span={4}>
                    <Select
                      label="Does the BOM already exist?"
                      placeholder="Select"
                      radius={'lg'}
                      data={[
                        {
                          label: 'Yes',
                          value: 'Yes',
                        },
                        {
                          label: 'No',
                          value: 'No',
                        },
                      ]}
                      value={formik.values.bomExits}
                      onChange={(value) => {
                        formik.setFieldValue('bomExits', value);
                      }}
                      disabled
                    />
                  </Grid.Col>
                </Grid>

                {/* IF BOM EXISTS */}
                <Space h="sm" />
                {formik.values.bomExits === 'Yes' && (
                  <BOMexists
                    formik={formik}
                    loading={submitLoading}
                    materialData={materialData}
                    selectedPlant={selectedPlant}
                    plantData={plantData}
                  />
                )}

                {/* IF BOM DOES NOT EXISTS */}
                <Space h="sm" />
                {formik.values.bomExits === 'No' && (
                  <BOMnotexists
                    formik={formik}
                    projectData={projectData}
                    materialData={materialData}
                    plantData={plantData}
                    copiedItem={copiedItem}
                    setCopiedItem={setCopiedItem}
                    copyEnabled={copyEnabled}
                    setCopyEnabled={setCopyEnabled}
                    loading={submitLoading}
                    // BRAND BOM
                    brandBOMList={brandBOMList}
                    setBrandBOMList={setBrandBOMList}
                    brandBOM_formik={brandBOM_formik}
                    updateBrandBom={() => {}}
                    deleteBrandBom={() => {}}
                    // AGE BOM
                    ageBOMList={ageBOMList}
                    setAgeBOMList={setAgeBOMList}
                    ageBOM_formik={ageBOM_formik}
                    updateAgeBom={() => {}}
                    deleteAgeBom={() => {}}
                    // FERMENT BOM
                    fermentBOMList={fermentBOMList}
                    setFermentBOMList={setFermentBOMList}
                    fermentBOM_formik={fermentBOM_formik}
                    updateFermentBom={() => {}}
                    deleteFermentBom={() => {}}
                    // WORT BOM
                    wortBOMList={wortBOMList}
                    setWortBOMList={setWortBOMList}
                    wortBOM_formik={wortBOM_formik}
                    updateWortBom={() => {}}
                    deleteWortBom={() => {}}
                    // BLEND BOM
                    blendBOMList={blendBOMList}
                    setBlendBOMList={setBlendBOMList}
                    blendBOM_formik={blendBOM_formik}
                    updateBlendBom={() => {}}
                    deleteBlendBom={() => {}}
                    // TANKER BOM
                    tankerBOMList={tankerBOMList}
                    setTankerBOMList={setTankerBOMList}
                    tankerBOM_formik={tankerBOM_formik}
                    updateTankerBom={() => {}}
                    deleteTankerBom={() => {}}
                  />
                )}
              </Card.Body>
            </Col>
          </Row>

          <Card.Footer>
            <Col>
              {/* {(plantData?.p_bom_status == 4 || plantData?.p_bom_status == 6) &&
                plantData?.l_bom_status == 2 && (
                  <> */}
              {check_roles(userState, ['admin', 'mdm']) && (
                <>
                  {materialData?.material_status == 4 &&
                  plantData?.status == 8 &&
                  plantData?.l_bom_status == 2 ? (
                    <Group position="right">
                      <Button
                        color="gray"
                        radius="xl"
                        leftIcon={<IconX size={16} color="white" />}
                        onClick={() => setShowRejectPopup(true)}
                      >{`Reject Request`}</Button>
                      <Button
                        color="yellow"
                        radius="xl"
                        disabled={submitLoader}
                        leftIcon={
                          submitLoader ? (
                            <IconLoader size={16} color="white" />
                          ) : (
                            <IconCheck size={16} color="white" />
                          )
                        }
                        onClick={() => {
                          setSubmitLoader(true);
                          bomService
                            .uploadTOSapLiquidBOM({
                              id: selectedPlant,
                            })
                            .then((res) => {
                              toast.success('Successfully uploaded to SAP');
                              setSubmitLoader(false);
                              navigate('/');
                            })
                            .catch((err) => {
                              toast.error('Error uploading to SAP, Try again later');
                            });
                        }}
                      >
                        {submitLoader ? <CustomCardLoader /> : 'Uploaded to SAP'}
                      </Button>
                    </Group>
                  ) : null}
                </>
              )}
              {/* </>
                )} */}
            </Col>
            <Space h="xl"></Space>
          </Card.Footer>
          <RejectMaterial
            showRejectMaterialModal={showRejectPopup}
            closeRejectMaterialModal={() => setShowRejectPopup(false)}
            rejectMaterial={(comment) => {
              rejectLiquidBOM(comment);
            }}
            message="Please provide your comments for Rejecting this Request"
          />
        </>
      )}
    </>
  );
}

function BOMexists({ formik, loading, materialData, selectedPlant, plantData }) {
  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <Select
            label="BOM Type"
            placeholder="Select BOM Type"
            radius={'lg'}
            data={[
              {
                label: 'Liquid',
                value: 'Liquid',
              },
              {
                label: 'Blend',
                value: 'Blend',
              },
              {
                label: 'Tanker',
                value: 'Tanker',
              },
            ]}
            value={formik.values.bomType}
            error={formik.errors.bomType}
            onChange={(value) => {
              formik.setFieldValue('bomType', value);
            }}
            disabled
          />
        </Grid.Col>

        {/* RENDERING FOR LIQUID */}
        {formik.values.bomType === 'Liquid' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Brand Id'}
                  value={formik.values.brandId}
                  error={formik.errors.brandId}
                  setId={(e) => formik.setFieldValue('brandId', e)}
                  setDescription={(e) => formik.setFieldValue('brandDescription', e)}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Brand Description"
                  placeholder="Brand Description"
                  radius={'lg'}
                  value={formik.values.brandDescription}
                  disabled
                  error={formik.errors.brandDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Wort Id'}
                  value={formik.values.wortId}
                  error={formik.errors.wortId}
                  setId={(e) => formik.setFieldValue('wortId', e)}
                  setDescription={(e) => formik.setFieldValue('wortDescription', e)}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Wort Description"
                  placeholder="Wort Description"
                  radius={'lg'}
                  value={formik.values.wortDescription}
                  disabled
                  error={formik.errors.wortDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Ferment Id'}
                  value={formik.values.fermentId}
                  error={formik.errors.fermentId}
                  setId={(e) => formik.setFieldValue('fermentId', e)}
                  setDescription={(e) => formik.setFieldValue('fermentDescription', e)}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Ferment Description"
                  placeholder="Ferment Description"
                  radius={'lg'}
                  value={formik.values.fermentDescription}
                  disabled
                  error={formik.errors.fermentDescription}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Age Id'}
                  value={formik.values.ageId}
                  error={formik.errors.ageId}
                  setId={(e) => formik.setFieldValue('ageId', e)}
                  setDescription={(e) => formik.setFieldValue('ageDescription', e)}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Age Description"
                  placeholder="Age Description"
                  radius={'lg'}
                  value={formik.values.ageDescription}
                  disabled
                  error={formik.errors.ageDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}

        {/* RENDERING FOR BLEND */}
        {formik.values.bomType === 'Blend' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Blend Id'}
                  value={formik.values.blendId}
                  error={formik.errors.blendId}
                  setId={(e) => formik.setFieldValue('blendId', e)}
                  setDescription={(e) => formik.setFieldValue('blendDescription', e)}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Blend Description"
                  placeholder="Blend Description"
                  radius={'lg'}
                  value={formik.values.blendDescription}
                  disabled
                  error={formik.errors.blendDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}

        {/* RENDERING FOR TANKER */}
        {formik.values.bomType === 'Tanker' && (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={4}>
                <SearchableSelect
                  label={'Tanker Id'}
                  value={formik.values.tankerId}
                  error={formik.errors.tankerId}
                  setId={(e) => formik.setFieldValue('tankerId', e)}
                  setDescription={(e) => formik.setFieldValue('tankerDescription', e)}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  label="Tanker Description"
                  placeholder="Tanker Description"
                  radius={'lg'}
                  value={formik.values.tankerDescription}
                  disabled
                  error={formik.errors.tankerDescription}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}
      </Grid>
    </>
  );
}

function BOMnotexists({
  formik,
  projectData,
  materialData,
  plantData,
  copiedItem,
  setCopiedItem,
  copyEnabled,
  setCopyEnabled,
  loading,
  // FORMIK AND BOM SUB TABLES
  // BRAND BOM
  brandBOMList,
  setBrandBOMList,
  brandBOM_formik,
  updateBrandBom,
  deleteBrandBom,

  // AGE BOM
  ageBOMList,
  setAgeBOMList,
  ageBOM_formik,
  updateAgeBom,
  deleteAgeBom,

  // FERMENT BOM
  fermentBOMList,
  setFermentBOMList,
  fermentBOM_formik,
  updateFermentBom,
  deleteFermentBom,

  // WORT BOM
  wortBOMList,
  setWortBOMList,
  wortBOM_formik,
  updateWortBom,
  deleteWortBom,

  // BLEND BOM
  blendBOMList,
  setBlendBOMList,
  blendBOM_formik,
  updateBlendBom,
  deleteBlendBom,

  // TANKER BOM
  tankerBOMList,
  setTankerBOMList,
  tankerBOM_formik,
  updateTankerBom,
  deleteTankerBom,
}) {
  let params = useParams();
  const navigate = useNavigate();

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const rejectMaterial = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        toast.success('Material Deleted ');

        navigate(-1);
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later');
      });
  };

  return (
    <Grid>
      <Grid.Col span={4}>
        <Select
          label="BOM Type"
          placeholder="Select BOM Type"
          radius={'lg'}
          data={[
            {
              label: 'Liquid',
              value: 'Liquid',
            },
            {
              label: 'Blend',
              value: 'Blend',
            },
            {
              label: 'Tanker',
              value: 'Tanker',
            },
          ]}
          value={formik.values.bomType}
          onChange={(value) => {
            formik.setFieldValue('bomType', value);
          }}
          disabled
        />
      </Grid.Col>

      {/* RENDERING FOR LIQUID (BEER) */}
      {formik.values.bomType === 'Liquid' && (
        <BOMnotexists_Liquid
          projectData={projectData}
          materialData={materialData}
          plantData={plantData}
          formik={formik}
          loading={loading}
          setShowDeletePopup={() => setShowDeletePopup(true)}
          // FORMIK AND BOM SUB TABLES
          // BRAND BOM
          brandBOMList={brandBOMList}
          setBrandBOMList={setBrandBOMList}
          brandBOM_formik={brandBOM_formik}
          updateBrandBom={updateBrandBom}
          deleteBrandBom={deleteBrandBom}
          // AGE BOM
          ageBOMList={ageBOMList}
          setAgeBOMList={setAgeBOMList}
          ageBOM_formik={ageBOM_formik}
          updateAgeBom={updateAgeBom}
          deleteAgeBom={deleteAgeBom}
          // FERMENT BOM
          fermentBOMList={fermentBOMList}
          setFermentBOMList={setFermentBOMList}
          fermentBOM_formik={fermentBOM_formik}
          updateFermentBom={updateFermentBom}
          deleteFermentBom={deleteFermentBom}
          // WORT BOM
          wortBOMList={wortBOMList}
          setWortBOMList={setWortBOMList}
          wortBOM_formik={wortBOM_formik}
          updateWortBom={updateWortBom}
          deleteWortBom={deleteWortBom}
          // COPY FUNCTIONS
          copyEnabled={copyEnabled}
          copiedItem={copiedItem}
          setCopyEnabled={setCopyEnabled}
          setCopiedItem={setCopiedItem}
        />
      )}

      {
        // RENDERING FOR BLEND
        formik.values.bomType === 'Blend' && (
          <BOMnotexists_Blend
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            formik={formik}
            loading={loading}
            setShowDeletePopup={() => setShowDeletePopup(true)}
            // BRAND BOM
            blendBOMList={blendBOMList}
            setBlendBOMList={setBlendBOMList}
            blendBOM_formik={blendBOM_formik}
            updateBlendBom={updateBlendBom}
            deleteBlendBom={deleteBlendBom}
            // COPY FUNCTIONS
            copyEnabled={copyEnabled}
            copiedItem={copiedItem}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
          />
        )
      }

      {
        // RENDERING FOR BLEND
        formik.values.bomType === 'Tanker' && (
          <BOMnotexists_Tanker
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            formik={formik}
            loading={loading}
            setShowDeletePopup={() => setShowDeletePopup(true)}
            // BRAND BOM
            tankerBOMList={tankerBOMList}
            setTankerBOMList={setTankerBOMList}
            tankerBOM_formik={tankerBOM_formik}
            updateTankerBom={updateTankerBom}
            deleteTankerBom={deleteTankerBom}
            // COPY FUNCTIONS
            copyEnabled={copyEnabled}
            copiedItem={copiedItem}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
          />
        )
      }

      {/* {
        // RENDERING FOR TANKER
        formik.values.bomType === 'Tanker' && (
          <BOMnotexists_Blend_Tanker
            projectData={projectData}
            materialData={materialData}
            plantData={plantData}
            setCopyEnabled={setCopyEnabled}
            setCopiedItem={setCopiedItem}
            formik={formik}
            
          />
        )
      } */}
    </Grid>
  );
}

function BOMnotexists_Liquid({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  // BOM SUB TABLES
  brandBOMList,
  setBrandBOMList,
  brandBOM_formik,
  updateBrandBom,
  deleteBrandBom,
  // AGE BOM
  ageBOMList,
  setAgeBOMList,
  ageBOM_formik,
  updateAgeBom,
  deleteAgeBom,
  // FERMENT BOM
  fermentBOMList,
  setFermentBOMList,
  fermentBOM_formik,
  updateFermentBom,
  deleteFermentBom,
  // WORT BOM
  wortBOMList,
  setWortBOMList,
  wortBOM_formik,
  updateWortBom,
  deleteWortBom,

  // COPY FUNCTIONS
  copyEnabled,
  copiedItem,
  setCopyEnabled,
  setCopiedItem,
}) {
  const { loadProductionLineList, productionLineList } = useContext(UserContext);

  // REFERENCE FORMIK
  const referenceFormik = useFormik({
    initialValues: {
      sku: '',
      skuDescription: '',
      plant: '',
    },
  });

  return (
    <>
      {/* FORM FIELDS */}
      <Grid
        style={{
          alignItems: 'center',
        }}
      >
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>
        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={true}
          />
        </Grid.Col>

        {/* <Grid.Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Required"
              color="yellow"
              checked={formik.values.skuNotRequired}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotRequired', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled
              // error={formik.errors.skuNotRequired}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Checkbox
              label="No similar SKU Exist"
              color="yellow"
              checked={formik.values.skuNotExist}
              onChange={(e) => {
                if (e.target.checked === true) {
                  formik.setFieldValue('similarSku', '');
                  formik.setFieldValue('similarSkuDescription', '');
                }
                formik.setFieldValue('skuNotExist', e.target.checked);
                // formik.setFieldValue('notInTheList', e);
              }}
              radius="xl"
              size="xs"
              disabled
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <TextInput
              disabled
              value={formik.values.similarSku}
              label="Similar SKU ID"
              placeholder="Similar SKU Description"
              radius={'lg'}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              disabled
              label="Similar SKU Description"
              placeholder="Similar SKU Description"
              radius={'lg'}
              value={formik.values.similarSkuDescription}
              error={formik.errors.similarSkuDescription}
            />
          </Grid.Col>
        </Grid.Col> */}

        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Brand'}
          similarId={'similarBrand'}
          similarDescription={'similarBrandDescription'}
          idNotRequired={'brandNotRequired'}
          idNotExist={'brandNotExist'}
          disabled={true}
        />

        {/* AGE */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Age'}
          similarId={'similarAge'}
          similarDescription={'similarAgeDescription'}
          idNotRequired={'ageNotRequired'}
          idNotExist={'ageNotExist'}
          disabled={true}
        />

        {/* FERMENT */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Ferment'}
          similarId={'similarFerment'}
          similarDescription={'similarFermentDescription'}
          idNotRequired={'fermentNotRequired'}
          idNotExist={'fermentNotExist'}
          disabled={true}
        />

        {/* WORT */}
        <DescriptionForm
          formik={formik}
          referenceFormik={referenceFormik}
          name={'Wort'}
          similarId={'similarWort'}
          similarDescription={'similarWortDescription'}
          idNotRequired={'wortNotRequired'}
          idNotExist={'wortNotExist'}
          disabled
        />
      </Grid>

      {/* BOM TABLES TABS*/}
      <Card.Body>
        <Container>
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Brand</Tabs.Tab>
              <Tabs.Tab value="age">Age</Tabs.Tab>
              <Tabs.Tab value="ferment">Ferment</Tabs.Tab>
              <Tabs.Tab value="wort">Wort</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              {/* <BOMtable
                list={brandBOMList}
                plant={plantData.plant}
                setList={setBrandBOMList}
                formik={brandBOM_formik}
                updateData={updateBrandBom}
                deleteData={deleteBrandBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}
              <DataTable
                data={brandBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
            <Tabs.Panel value="age">
              {/* <BOMtable
                list={ageBOMList}
                plant={plantData.plant}
                setList={setAgeBOMList}
                formik={ageBOM_formik}
                updateData={updateAgeBom}
                deleteData={deleteAgeBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}
              <DataTable
                data={ageBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
            <Tabs.Panel value="ferment">
              {/* <BOMtable
                list={fermentBOMList}
                plant={plantData.plant}
                setList={setFermentBOMList}
                formik={fermentBOM_formik}
                updateData={updateFermentBom}
                deleteData={deleteFermentBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}
              <DataTable
                data={fermentBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
            <Tabs.Panel value="wort">
              {/* <BOMtable
                list={wortBOMList}
                plant={plantData.plant}
                setList={setWortBOMList}
                formik={wortBOM_formik}
                updateData={updateWortBom}
                deleteData={deleteWortBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}

              <DataTable
                data={wortBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
          </Tabs>
        </Container>
      </Card.Body>

      {/* REFERENCE HELPDESK */}
      <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
        formik={referenceFormik}
      />
    </>
  );
}

function BOMnotexists_Blend({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  // BLEND BOM
  blendBOMList,
  setBlendBOMList,
  blendBOM_formik,
  updateBlendBom,
  deleteBlendBom,

  // COPY FUNCTIONS
  setCopyEnabled,
  setCopiedItem,
  copyEnabled,
  copiedItem,
}) {
  const { productionLineList } = useContext(UserContext);

  return (
    <>
      {/* FORM FIELDS */}
      <Grid>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>
        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={true}
          />
        </Grid.Col>
        <DescriptionForm
          formik={formik}
          // referenceFormik={}
          name={'Blend'}
          similarId={'similarBlend'}
          similarDescription={'similarBlendDescription'}
          idNotExist={'blendNotExist'}
          idNotRequired={'blendNotRequired'}
          disabled={true}
        />
      </Grid>

      {/* SUB TABLE TABS */}
      <Card.Body>
        <Container>
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Blend</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              {/* <BOMtable
                list={blendBOMList}
                plant={plantData?.plant}
                setList={setBlendBOMList}
                formik={blendBOM_formik}
                updateData={updateBlendBom}
                deleteData={deleteBlendBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}

              <DataTable
                data={blendBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
          </Tabs>
        </Container>
      </Card.Body>

      {/* REF. HELPDESK */}
      {/* <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
      /> */}
    </>
  );
}

function BOMnotexists_Tanker({
  projectData,
  materialData,
  plantData,
  formik,
  loading,
  setShowDeletePopup,
  // BRAND BOM
  tankerBOMList,
  setTankerBOMList,
  tankerBOM_formik,
  updateTankerBom,
  deleteTankerBom,

  // COPY FUNCTIONS
  setCopyEnabled,
  setCopiedItem,
  copyEnabled,
  copiedItem,
}) {
  const { productionLineList } = useContext(UserContext);

  return (
    <>
      {/* FORM FIELDS */}
      <Grid>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Name"
            label="Project Name"
            radius={'lg'}
            value={projectData.project_name}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Project Type"
            label="Project Type"
            radius={'lg'}
            value={projectData.project_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU ID"
            label="SKU ID"
            radius={'lg'}
            value={materialData.sku_id}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="SKU Description"
            label="SKU Description"
            radius={'lg'}
            value={materialData.sku_description}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Plant"
            label="Plant"
            radius={'lg'}
            value={plantData.plant}
            disabled
          />
        </Grid.Col>
        {materialData?.existing_item === 'No' && (
          <Grid.Col span={4}>
            <TextInput
              placeholder="FG Type"
              label="FG Type"
              radius={'lg'}
              value={materialData.fg_type}
              disabled
            />
          </Grid.Col>
        )}
        <Grid.Col span={4}>
          <TextInput
            placeholder="Material Type"
            label="Material Type"
            radius={'lg'}
            value={materialData.material_type}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <MultiSelect
            label="Production Line"
            placeholder="Production Line"
            radius={'lg'}
            value={formik?.values?.production_line}
            onChange={(e) => {
              //console.log(e);
              formik.setFieldValue('production_line', e);
            }}
            data={productionLineList}
            searchable
            nothingFound="Nothing found"
            // error={formik.errors.production_line}
            disabled={true}
          />
        </Grid.Col>

        <DescriptionForm
          formik={formik}
          // referenceFormik={referenceFormik}
          name={'Tanker'}
          similarId={'similarTanker'}
          similarDescription={'similarTankerDescription'}
          idNotExist={'tankerNotExist'}
          idNotRequired={'tankerNotRequired'}
          disabled={true}
        />
      </Grid>

      {/* SUB TABLE TABS */}
      <Card.Body>
        <Container>
          <Tabs color={'yellow'} variant={'pills'} radius={'xl'} defaultValue="brand">
            <Tabs.List>
              <Tabs.Tab value="brand">Tanker</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="brand">
              {/* <BOMtable
                list={tankerBOMList}
                plant={plantData?.plant}
                setList={setTankerBOMList}
                formik={tankerBOM_formik}
                updateData={updateTankerBom}
                deleteData={deleteTankerBom}
                copyEnabled={copyEnabled}
                setCopyEnabled={setCopyEnabled}
                copiedItem={copiedItem}
                disabled={true}
              /> */}
              <DataTable
                data={tankerBOMList}
                columns={[
                  {
                    title: 'Component',
                    field: 'component',
                  },
                  {
                    title: 'Description',
                    field: 'component_description',
                  },
                  {
                    title: 'Quantity',
                    field: 'quantity',
                  },
                  {
                    title: 'Scrap',
                    field: 'scrap',
                  },
                  {
                    title: 'UOM',
                    field: 'uom',
                  },
                  {
                    title: 'Standard Cost',
                    field: 'standard_cost',
                  },
                ]}
              />
            </Tabs.Panel>
          </Tabs>
        </Container>
      </Card.Body>

      {/* REF. HELPDESK */}
      {/* <ReferenceHelpdesk
        plantData={plantData}
        setCopiedItem={setCopiedItem}
        setCopyEnabled={setCopyEnabled}
      /> */}
    </>
  );
}
