/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../utils/authConfig';

import '../../assets/styles/TopBar.css';
import FeatherIcon from 'feather-icons-react';
import abiLogo from '../../assets/images/abi_logo.png';
import abiShortLogo from '../../assets/images/abi_logo_short.png';
import defaultProfilePhoto from '../../assets/images/profile.jpg';
import { UserContext } from '../../context/userContext';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import UpdateAppServiceWorkerWrapper from '../ServiceWorkerWrapper/UpdateAppServiceWorkerWrapper';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
import FIGOLogo from '../../assets/images/figo_white_logo.png';
import LabattLogo from '../../assets/images/labatt_corporate_logo.png';
import FigoShortLogo from '../../assets/images/figo_white_short_logo.png';

const TopBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const isAuthenticated = useIsAuthenticated();

  const [userState, userDispatch] = useContext(ActiveUserContext);

  const { instance, accounts, inProgress } = useMsal();

  const [isDropDownOpen, setisDropDownOpen] = useState(false);

  const [profilePhoto, setProfilePhoto] = useState(null);

  // const [userState, userDispatch] = useContext(UserContext);

  // const [location, setLocation] = useState();

  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };

  const [isHeaderOpen, setisHeaderOpen] = useState(false);

  const togglingHeader = () => {
    setisHeaderOpen(!isHeaderOpen);
  };

  function handleProfile() {
    navigate('/myprofile');
  }

  const handleLogout = () => {
    // console.log('logout');
    instance.logout({
      account: accounts[0],
      // account: accounts.length > 0 ? accounts[0] : null,
    });
  };

  // const [state, dispatch] = useContext(UserContext);

  // useEffect(() => {
  //   console.log('Account: ', accounts[0]);

  //   if (accounts?.length > 0) {
  //     console.log(accounts[0].name);
  //     console.log('starting below');
  //     instance
  //       .acquireTokenSilent({
  //         ...loginRequest,
  //         account: accounts[0],
  //       })
  //       .then((response) => {
  //         // console.log(response);
  //         // console.log(response?.accessToken);
  //         apiService
  //           .getMSGraphPhoto(response.accessToken)
  //           .then((image) => {
  //             if (image.type === 'image/jpeg') setProfilePhoto(image);
  //           })
  //           .catch((err) => console.log(err));

  //         // apiService
  //         //   .getMSProfile(response?.accessToken)
  //         //   .then((profile) => {
  //         //     console.log(profile);
  //         //     dispatch({
  //         //       type: 'SET_JOB_TITLE',
  //         //       payload: profile?.jobTitle,
  //         //     });
  //         //   })
  //         //   .catch((err) => console.log(err));
  //       })
  //       .catch((err) => {
  //         console.log(`Error occured while acquiring token: ${err}`);
  //         handleLogout();
  //         history.push('/login');
  //       });
  //     // dispatch({
  //     //   type: 'SET_NAME_EMAIL_ROLE',
  //     //   payload: {
  //     //     name: accounts[0]?.name,
  //     //     email: accounts[0]?.username,
  //     //     role:
  //     //       accounts[0]?.idTokenClaims?.roles?.length > 0
  //     //         ? accounts[0]?.idTokenClaims?.roles[0]
  //     //         : null,
  //     //   },
  //     // });
  //   }

  //   // localStorage.setItem('account_name', accounts[0]['name']);
  //   // localStorage.setItem('email', accounts[0]['username']);
  // }, [accounts, inProgress, dispatch]);

  // useEffect(() => {
  //   if (accounts.length > 0) {

  //   }
  // }, [accounts]);

  const [rtoHubAccess, setRtoHubAccess] = useState();

  useEffect(() => {
    // console.log(location);
  }, [location]);

  // useEffect(() => {
  //   if (state?.email) {
  //     // employeeService.getEmployeeData('abhay.kumar@ab-inbev.com').then((res) => {
  //     employeeService.getEmployeeData(state.email).then((res) => {
  //       if (res?.result)
  //         dispatch({
  //           type: 'SET_PROFILE',
  //           payload: res.result,
  //         });
  //     });
  //     // rtoService.checkRtoHubAccess('abhay.kumar@ab-inbev.com').then((res) => {
  //     rtoService.checkRtoHubAccess(state.email).then((res) => {
  //       dispatch({
  //         type: 'SET_RTO_HUB_ACCESS',
  //         payload: res?.result,
  //       });
  //       // setRtoHubAccess(res?.result)
  //     });
  //   }
  // }, [state?.email]);

  // useEffect(() => {
  //   console.log(userState);
  // }, [userState]);

  return (
    <div className="top-nav">
      <div className="header py-4">
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          <div className="d-flex">
            <a className="header-brand" href="/">
              <img
                src={isMobile ? FigoShortLogo : FIGOLogo}
                className="header-brand-img"
                style={{ height: '2.6rem', marginRight: '1rem' }}
                alt="App Logo"
              />
              <img
                src={isMobile ? abiShortLogo : abiLogo}
                className="header-brand-img"
                alt="AB InBev Logo"
                style={{ borderLeft: '1px solid #c9c9c9', paddingLeft: '0.5rem', height: '1.5rem' }}
              />
            </a>

            <div
              className="d-flex order-lg-2 ml-auto text-left"
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
              <span style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '1rem' }}>
                {/* <UpdateAppServiceWorkerWrapper /> */}
              </span>
              {!isMobile && (
                <img
                  src={LabattLogo}
                  className="header-brand-img"
                  alt="Labatt Logo"
                  style={{ paddingLeft: '0.5rem', height: '1.9rem' }}
                />
              )}
              <div className="dropdown">
                <a className="nav-link pr-0 leading-none" onClick={togglingDropDown}>
                  <img
                    className="avatar"
                    src={
                      userState?.profile_photo
                        ? URL.createObjectURL(userState?.profile_photo)
                        : defaultProfilePhoto
                    }
                    alt=""
                  />
                  {/* <img className="avatar" src={defaultProfilePhoto} alt="" /> */}
                  <span className="ml-2 d-none d-lg-block">
                    <span className="golden-text">
                      <strong>{accounts.length > 0 ? accounts[0].name : ''}</strong>
                    </span>
                    <span>
                      &emsp;&emsp;&emsp;
                      <FeatherIcon icon="chevron-down" size={14} />
                    </span>
                    <small className="text-muted d-block mt-1">
                      {accounts.length > 0 ? accounts[0].username : ''}
                    </small>
                  </span>
                </a>
                {isDropDownOpen && (
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                    style={{ cursor: 'pointer' }}
                  >
                    <a className="dropdown-item text-left" onClick={handleProfile}>
                      My Profile
                    </a>
                    <a
                      className="dropdown-item text-left"
                      href="mailto:DL-COEAutomationRM@AnheuserBuschInBev.onmicrosoft.com"
                    >
                      Support
                    </a>
                    <a className="dropdown-item text-left" onClick={handleLogout}>
                      Sign out
                    </a>
                  </div>
                )}
              </div>
            </div>
            <a
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              className={`header-toggler d-lg-none ml-3 ml-lg-0 ${isHeaderOpen ? '' : 'collapsed'}`}
              onClick={togglingHeader}
            >
              <span className="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div
        className={`header collapse d-lg-flex p-0 ${isHeaderOpen ? 'show' : ''}`}
        id="headerMenuCollapse"
        style={{ background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)' }}
      >
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          <div className="row align-items-center">
            <div
              className="col-lg order-lg-first"
              style={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 2rem',
                margin: 0,
              }}
            >
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      ['/', '/register'].includes(location?.pathname) ? ' active' : ''
                    }`}
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    <FeatherIcon icon="home" size={14} />
                    &nbsp;{'Home'}
                  </a>
                </li>

                {check_roles(userState, ['admin', 'requestor']) && (
                  <li>
                    <a
                      style={{ marginLeft: '0.5rem' }}
                      className={`nav-link ${
                        ['/project-request'].includes(location?.pathname) ? ' active' : ''
                      }`}
                      onClick={() => {
                        params.id = null;
                        navigate('/project-request');
                      }}
                    >
                      <FeatherIcon icon="plus" size={14} />
                      &nbsp;{'Project Request'}
                    </a>
                  </li>
                )}
                {/* {check_roles(userState, ['user']) && ( */}
                <li>
                  <a
                    className={`nav-link nav-margin ${
                      ['/manage-hub'].includes(location?.pathname) ? ' active' : ''
                    }`}
                    onClick={() => {
                      params.id = null;
                      navigate('/manage-hub');
                    }}
                  >
                    &nbsp;
                    <FeatherIcon icon="settings" size={14} />
                    &nbsp; {'Manage Hub'}
                  </a>
                </li>
                {/* )} */}
                <li>
                  <a
                    className={`nav-link nav-margin ${
                      ['/role-request'].includes(location?.pathname) ? ' active' : ''
                    }`}
                    onClick={() => {
                      params.id = null;
                      navigate('/role-request');
                    }}
                  >
                    &nbsp;
                    <FeatherIcon icon="user-plus" size={14} />
                    &nbsp;{'Request Role'}
                  </a>
                </li>
                <li>
                  <a
                    className={`nav-link nav-margin`}
                    href="https://anheuserbuschinbev.sharepoint.com/sites/FIGO_USER_SOPs/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FFIGO%5FUSER%5FSOPs%2FShared%20Documents%2FSOPs&p=true&ga=1"
                    target="_blank"
                  >
                    &nbsp;
                    <FeatherIcon icon="info" size={14} />
                    &nbsp;{'User Guides'}
                  </a>
                </li>

                {/* POWER BI */}
                <li>
                  <a
                    className={`nav-link nav-margin`}
                    href="https://app.powerbi.com/groups/me/reports/1a93c8c3-323f-4f05-8ddf-705a263e6eb9/ReportSection?ctid=cef04b19-7776-4a94-b89b-375c77a8f936&experience=power-bi"
                    target="_blank"
                  >
                    &nbsp;
                    <FeatherIcon icon="bar-chart" size={14} />
                    &nbsp;{'Power BI'}
                  </a>
                </li>
              </ul>

              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                <li>
                  <a
                    className={`nav-link nav-margin`}
                    href="https://digitalsolutions.ab-inbev.com/"
                    target="_blank"
                  >
                    &nbsp;
                    <FeatherIcon icon="headphones" size={14} />
                    &nbsp;{'Tech Support'}
                  </a>
                </li>
                <li>
                  <a
                    className={`nav-link nav-margin`}
                    href="https://solutionsnow.one.ofc.loc/plugins/servlet/desk/portal/7?requestGroup=75"
                    target="_blank"
                  >
                    &nbsp;
                    <FeatherIcon icon="help-circle" size={14} />
                    &nbsp;{'Existing SKU Support'}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
