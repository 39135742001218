/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Group, Space, Text, Table, Select, Input, TextInput, Button } from '@mantine/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import materialService from '../../../../services/materialService';
import plantService from '../../../../services/plantService';
import projectService from '../../../../services/projectService';
import CustomCardLoader from '../../../../components/CustomCardLoader';
import DisabledTextInput from '../../../../components/DisabledTextInput/DisabledTextInput';
import { IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons';
import bomService from '../../../../services/bomService';
import costService from '../../../../services/costService';
import { toast } from 'react-toastify';
import RejectMaterial from '../../../MaterialApproval/RejectMaterial';
import { FileUploader } from '../../../../components/FileUploader';
import { FilesTable } from '../../../../components/FilesTable';
import { ActiveUserContext } from '../../../../context/activerUserContext';
import { check_roles } from '../../../../utils/Utils';

function ControllerView({ setPlantStatus }) {
  const [userState] = useContext(ActiveUserContext);

  // PARAMS
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // STATE
  const [materialData, setMaterialData] = useState({});
  const [plantData, setPlantData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [selectedPlant, setSelectedPlant] = useState();
  const [plantList, setPlantList] = useState([]);
  const [sendBackDialog, setSendBackDialog] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [costingTableData, setCostingTableData] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [plantDataLoading, setPlantDataLoading] = useState(false);

  // FUNCTIONS

  const fetchTableData = (id) => {
    costService
      .getRmCostingTableData({
        plant_id: id,
      })
      .then((res) => {
        //console.log('TABLE DATA', res);
        setCostingTableData(res?.data);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  const getFilesData = () => {
    costService
      .getCostingFilesByPlant(projectData.id, materialData.id, selectedPlant)
      .then((res) => {
        if (res) {
          //console.log('Attachments:', res);
          setFilesData(res);
        }
      });
  };

  // EFFECTS
  useEffect(() => {
    if (params?.plant_id) {
      setSelectedPlant(params?.plant_id);
      fetchTableData(params?.plant_id);
    }
  }, [params?.plant_id]);

  useEffect(() => {
    if (location?.state?.status) {
      setPlantStatus(location?.state?.status);
    }
  }, [location?.state?.status]);

  useEffect(() => {
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          setMaterialData(res?.data?.material);
          projectService.getProjectById(res?.data?.material?.project_id).then((res) => {
            if (res?.data) {
              setProjectData(res?.data?.project);
            }
          });
        }
      });
    }

    if (params?.id) {
      materialService.getPlantsDetailsForFgForm(params?.id).then((res) => {
        if (res?.data) {
          //console.log('PLANT LENGTH', res?.data);
          setPlantList(res?.data?.plants);
          if (!params?.plant_id) {
            setSelectedPlant(res?.data?.plants[0]?.id);
            fetchTableData(res?.data?.plants[0]?.id);
          }
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    //console.log('IDs', selectedPlant, projectData.id, materialData.id);
    if (selectedPlant?.length > 0 && projectData?.id?.length > 0 && materialData?.id?.length > 0)
      getFilesData();
  }, [selectedPlant, projectData.id, materialData.id]);

  useEffect(() => {
    setPlantDataLoading(true);
    if (selectedPlant) {
      plantService.getPlantById(selectedPlant).then((res) => {
        //console.log();
        if (res?.data) {
          //console.log('PLANT', res?.data?.data?.status);
          setPlantData(res?.data?.data);
          setPlantStatus(res?.data?.data?.status);
          setPlantDataLoading(false);
        }
      });
    }
  }, [selectedPlant]);

  // CONSTANTS
  const IS_EXISTING = materialData?.existing_item === 'Yes';

  const IS_DOMESTIC_COPACK_REPACK =
    materialData?.existing_item === 'No' &&
    (materialData?.fg_type === 'Copack' ||
      materialData?.fg_type === 'Domestic' ||
      materialData?.fg_type === 'Repack');

  const IS_VIRTUAL = materialData?.existing_item === 'No' && materialData?.fg_type === 'Virtual';

  const IS_IMPORT = materialData?.existing_item === 'No' && materialData?.fg_type === 'Import';

  const ENABLE_DRAFT_BUTTON =
    (materialData?.existing_item === 'Yes' &&
      (materialData?.existing_item_fg_type === 'Domestic' ||
        materialData?.existing_item_fg_type === 'Copack' ||
        materialData?.existing_item_fg_type === 'Repack')) ||
    (materialData?.existing_item === 'No' && materialData?.fg_type === 'Domestic') ||
    (materialData?.existing_item === 'No' && materialData?.fg_type === 'Virtual') ||
    (materialData?.existing_item === 'No' && materialData?.fg_type === 'Copack') ||
    (materialData?.existing_item === 'No' && materialData?.fg_type === 'Repack');
  const ENABLE_SUBMIT_REJECT_SIMULTANEOUSLY =
    materialData?.existing_item === 'No' && materialData?.fg_type === 'Import';

  return (
    <>
      <Row>
        <Col xs={12} sm={2}>
          <Card radius="xl">
            <Card.Body>
              <Text
                size="xl"
                weight={700}
                color="yellow"
                align="left"
                style={{ fontSize: '18px' }}
              >{`Plant List`}</Text>
              <Space h="sm" />
            </Card.Body>

            {plantList?.length > 0 ? (
              <Card.Body>
                <Group position="center">
                  {plantList?.length > 0 ? (
                    <div className="table-responsive-sm">
                      <Table striped highlightOnHover withBorder>
                        <tbody>
                          {plantList?.map((plant, index) => (
                            <tr
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedPlant(plant?.id);
                                fetchTableData(plant?.id);
                                navigate(`/material/${params?.id}/${plant?.id}`);
                              }}
                            >
                              <td
                                className={
                                  plant?.id === selectedPlant ? 'table-row-selected' : 'table-color'
                                }
                              >
                                {plant?.plant}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <CustomCardLoader />
                  )}
                </Group>
              </Card.Body>
            ) : (
              <CustomCardLoader />
            )}
          </Card>
        </Col>

        <Col xs={12} sm={10}>
          {!plantList?.length > 0 ? (
            <CustomCardLoader />
          ) : (
            <Card radius="xl">
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >
                    {'Costing Approval'}
                  </Text>
                </Group>
              </Card.Body>

              {plantDataLoading ? (
                <CustomCardLoader />
              ) : (
                <>
                  {IS_EXISTING && (
                    <RenderExisting
                      costingTableData={costingTableData}
                      setCostingTableData={setCostingTableData}
                      materialData={materialData}
                      plantData={plantData}
                      refreshTable={() => fetchTableData(selectedPlant)}
                      setSendBack={() => setSendBackDialog(true)}
                      setSubmit={() => setSubmitDialog(true)}
                      // enable={plantData?.p_bom_status == 6}
                    />
                  )}

                  {IS_DOMESTIC_COPACK_REPACK && (
                    <RenderDomestic
                      costingTableData={costingTableData}
                      setCostingTableData={setCostingTableData}
                      materialData={materialData}
                      plantData={plantData}
                      refreshTable={() => fetchTableData(selectedPlant)}
                      setSendBack={() => setSendBackDialog(true)}
                      setSubmit={() => setSubmitDialog(true)}
                      // enable={plantData?.p_bom_status == 6}
                    />
                  )}

                  {IS_VIRTUAL && (
                    <RenderVirtual
                      costingTableData={costingTableData}
                      setCostingTableData={setCostingTableData}
                      materialData={materialData}
                      plantData={plantData}
                      refreshTable={() => fetchTableData(selectedPlant)}
                      setSendBack={() => setSendBackDialog(true)}
                      setSubmit={() => setSubmitDialog(true)}
                      // enable={plantData?.p_bom_status == 6}
                    />
                  )}

                  {IS_IMPORT && (
                    <RenderRest
                      costingTableData={costingTableData}
                      setCostingTableData={setCostingTableData}
                      materialData={materialData}
                      plantData={plantData}
                      refreshTable={() => fetchTableData(selectedPlant)}
                      setSendBack={() => setSendBackDialog(true)}
                      setSubmit={() => setSubmitDialog(true)}
                      // enable={plantData?.p_bom_status == 6}
                    />
                  )}

                  {filesData?.length > 0 && (
                    <>
                      <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                        <Row>
                          <div>
                            <h5>
                              <Text align="left">Attachments</Text>
                            </h5>
                          </div>
                        </Row>
                      </div>
                      <Row>
                        <FilesTable
                          project_id={projectData.id}
                          material_id={materialData.id}
                          plant_id={selectedPlant}
                          files_data={filesData}
                          refreshTable={() => {
                            getFilesData();
                          }}
                        />
                      </Row>
                    </>
                  )}

                  {materialData?.material_status == 4 && plantData?.status == 10 && (
                    <>
                      {costingTableData?.length > 0 && (
                        <Grid
                          style={{
                            margin: '1rem 0',
                          }}
                        >
                          <>
                            {check_roles(userState, ['admin', 'controller']) && (
                              <Grid.Col span={3}>
                                <FileUploader
                                  project_id={projectData.id}
                                  material_id={materialData.id}
                                  plant_id={selectedPlant}
                                  refreshTable={() => {
                                    getFilesData();
                                  }}
                                />
                              </Grid.Col>
                            )}
                          </>

                          {check_roles(userState, ['admin', 'controller']) && (
                            <>
                              {ENABLE_SUBMIT_REJECT_SIMULTANEOUSLY ? (
                                <>
                                  <Grid.Col span={3}>
                                    <Button
                                      color="yellow"
                                      radius="xl"
                                      leftIcon={<IconCheck size={16} color="white" />}
                                      className="btn btn-primary"
                                      style={{ borderRadius: '40px' }}
                                      onClick={() =>
                                        costService
                                          .submitCostingController({
                                            plant_id: selectedPlant,
                                            costingData: costingTableData,
                                          })
                                          .then((res) => {
                                            if (res?.status) {
                                              toast.success(res?.message);
                                              navigate('/');
                                            }
                                          })
                                          .catch((err) => {
                                            toast.error('Something went wrong.');
                                          })
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Grid.Col>
                                  <Grid.Col span={3}>
                                    <Button
                                      color="gray"
                                      radius="xl"
                                      leftIcon={<IconX size={16} color="white" />}
                                      onClick={() => {
                                        setSendBackDialog(true);
                                      }}
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      Reject with comments
                                    </Button>
                                  </Grid.Col>
                                </>
                              ) : (
                                <>
                                  {costingTableData?.filter((item) => item?.status === 'Yes')
                                    ?.length === costingTableData?.length ? (
                                    <Grid.Col span={3}>
                                      <Button
                                        color="yellow"
                                        radius="xl"
                                        className="btn btn-primary"
                                        style={{ borderRadius: '40px' }}
                                        leftIcon={<IconCheck size={16} color="white" />}
                                        onClick={() =>
                                          costService
                                            .submitCostingController({
                                              plant_id: selectedPlant,
                                              costingData: costingTableData,
                                            })
                                            .then((res) => {
                                              if (res?.status) {
                                                toast.success(res?.message);
                                                navigate('/');
                                              }
                                            })
                                            .catch((err) => {
                                              toast.error('Something went wrong.');
                                            })
                                        }
                                      >
                                        Submit
                                      </Button>
                                    </Grid.Col>
                                  ) : (
                                    <Grid.Col span={3}>
                                      {costingTableData?.filter((item) => item?.status === 'No')
                                        ?.length > 0 && (
                                        <Button
                                          color="gray"
                                          radius="xl"
                                          leftIcon={<IconX size={16} color="white" />}
                                          onClick={() => {
                                            if (
                                              costingTableData?.filter(
                                                (item) => item?.status === 'No',
                                              )?.length !==
                                              costingTableData?.filter(
                                                (item) =>
                                                  item?.status === 'No' &&
                                                  item?.comment?.trim()?.length > 0,
                                              )?.length
                                            ) {
                                              toast.error(
                                                'Please provide comments for all rejected materials.',
                                              );

                                              return;
                                            }
                                            setSendBackDialog(true);
                                          }}
                                          style={{
                                            width: '100%',
                                          }}
                                        >
                                          Reject with comments
                                        </Button>
                                      )}
                                    </Grid.Col>
                                  )}
                                </>
                              )}

                              <Grid.Col span={3}></Grid.Col>

                              {!ENABLE_DRAFT_BUTTON && <Grid.Col span={3}></Grid.Col>}

                              <Grid.Col span={3}>
                                {ENABLE_DRAFT_BUTTON && (
                                  <Button
                                    style={{
                                      width: '100%',
                                    }}
                                    radius="xl"
                                    leftIcon={<IconCheck size={16} color="white" />}
                                    onClick={() => {
                                      costService
                                        .saveCostingApprovalDraft({
                                          costingData: costingTableData,
                                          plant_id: selectedPlant,
                                        })
                                        .then((res) => {
                                          //console.log(res);
                                          toast.success(res?.message);
                                        })
                                        .catch((err) => {
                                          //console.log(err);
                                          toast.error(err || 'Something went wrong');
                                        });
                                    }}
                                  >
                                    Save Draft
                                  </Button>
                                )}
                              </Grid.Col>
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          )}
        </Col>
      </Row>
      <RejectMaterial
        closeRejectMaterialModal={() => setSendBackDialog(false)}
        showRejectMaterialModal={sendBackDialog}
        message={'Provide comment for rejecting.'}
        rejectMaterial={(comment) => {
          costService
            .rejectCostingController({
              plant_id: selectedPlant,
              comment: comment,
              costingData: costingTableData,
              material_id: params?.id,
            })
            .then((res) => {
              toast.success(res?.message);
              navigate('/');
            })
            .catch((err) => {
              toast.error(err || 'Something went wrong');
            });
        }}
      />
    </>
  );
}

export default ControllerView;

function RenderExisting({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
}) {
  const [stageList, setStageList] = useState([
    {
      value: 'FG',
      label: 'FG',
    },
    {
      value: 'Brand',
      label: 'Brand',
    },
    {
      value: 'Age',
      label: 'Age',
    },
    {
      value: 'Ferment',
      label: 'Ferment',
    },
    {
      value: 'Wort',
      label: 'Wort',
    },
    {
      value: 'Phantom',
      label: 'Phantom',
    },
    {
      value: 'Blend',
      label: 'Blend',
    },
    {
      value: 'Tanker',
      label: 'Tanker',
    },
    {
      value: 'Phantom Case',
      label: 'Phantom Case',
    },
    {
      value: 'Phantom Co-Pack',
      label: 'Phantom Co-Pack',
    },
  ]);
  const [value, setValue] = useState([]);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
      cost_by_100: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  const updateRmCostingTableItem = (body) => {
    costService
      .updateRMCostingTableData({
        comment: body.comment,
        cost_by_100: body.cost_by_100,
        generic_sku_cost: body.generic_sku_cost,
        id: body.id,
        sku_description: body.sku_description,
        sku_id: body.sku_id,
        stage: body.stage,
      })
      .then((res) => {
        //console.log('Updated', res);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };
  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput
            label="SKU ID"
            placeholder="SKU ID"
            value={
              materialData?.existing_item === 'Yes'
                ? materialData?.existing_item_number
                : materialData?.sku_id
            }
          />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Existing Item FG Type"
            placeholder="FG Type"
            value={materialData?.existing_item_fg_type}
          />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  'Action',
                  'Plant',
                  'SKU ID',
                  'Description',
                  'Stage',
                  'Cost/100',
                  'Approved Status',
                  'Comments',
                ].map((item, index) => (
                  <th>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {materialData?.material_status == 4 && plantData?.status == 10 ? (
                    <td>
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          updateRmCostingTableItem(item);
                        }}
                      >
                        <IconDeviceFloppy size={24} color="black" />
                      </Button>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{plantData?.plant}</td>
                  <td>{item?.sku_id}</td>
                  <td>{item?.sku_description}</td>
                  <td>{item?.stage}</td>
                  <td>
                    {/* {materialData?.existing_item_fg_type === 'Domestic' ? (
                      item?.cost_by_100
                    ) : ( */}
                    <TextInput
                      type={'number'}
                      style={{
                        width: '90px',
                      }}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                    {/* )} */}
                  </td>
                  {materialData?.existing_item_fg_type === 'Domestic' ||
                  materialData?.existing_item_fg_type === 'Copack' ||
                  materialData?.existing_item_fg_type === 'Repack' ? (
                    <>
                      <td>
                        <Select
                          style={{
                            width: '90px',
                          }}
                          radius={'xl'}
                          placeholder="Select"
                          data={[
                            {
                              value: 'Yes',
                              label: 'Yes',
                            },
                            {
                              value: 'No',
                              label: 'No',
                            },
                          ]}
                          value={item?.status}
                          onChange={(e) => {
                            e === 'Yes'
                              ? setCostingTableData(
                                  costingTableData?.map((curr, index) =>
                                    curr?.id === item?.id
                                      ? {
                                          ...curr,
                                          status: e,
                                        }
                                      : curr,
                                  ),
                                )
                              : setCostingTableData(
                                  costingTableData?.map((curr, index) =>
                                    curr?.id === item?.id ? { ...curr, status: e } : curr,
                                  ),
                                );
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="If selected No, please provide comments"
                          value={item?.comment}
                          onChange={(e) => {
                            setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                              ),
                            );
                          }}
                          error={!item?.comment}
                          // disabled={item?.status !== 'No'}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td></td>
                      <td></td>
                    </>
                  )}
                  {/* <td>
                    {materialData?.existing_item_fg_type === 'Domestic' ? (
                      ''
                    ) : (
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          costService
                            .updateRMCostingTableData({
                              comment: item.comment,
                              cost_by_100: item.cost_by_100,
                              generic_sku_cost: item.generic_sku_cost,
                              id: item.id,
                              sku_description: item.sku_description,
                              sku_id: item.sku_id,
                              stage: item.stage,
                            })
                            .then((res) => {
                              toast.success(res?.message);
                            })
                            .catch((err) => {
                              toast.error(err || 'Something went wrong');
                            });
                        }}
                      >
                        <IconDeviceFloppy size={24} color="black" />
                      </Button>
                    )}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}

function RenderDomestic({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
}) {
  const [stageList, setStageList] = useState([
    {
      value: 'FG',
      label: 'FG',
    },
    {
      value: 'Brand',
      label: 'Brand',
    },
    {
      value: 'Age',
      label: 'Age',
    },
    {
      value: 'Ferment',
      label: 'Ferment',
    },
    {
      value: 'Wort',
      label: 'Wort',
    },
    {
      value: 'Phantom',
      label: 'Phantom',
    },
    {
      value: 'Blend',
      label: 'Blend',
    },
    {
      value: 'Tanker',
      label: 'Tanker',
    },
    {
      value: 'Phantom Case',
      label: 'Phantom Case',
    },
    {
      value: 'Phantom Co-Pack',
      label: 'Phantom Co-Pack',
    },
  ]);
  const [value, setValue] = useState([]);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
      cost_by_100: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  const updateRmCostingTableItem = (body) => {
    costService
      .updateRMCostingTableData({
        comment: body.comment,
        cost_by_100: body.cost_by_100,
        generic_sku_cost: body.generic_sku_cost,
        id: body.id,
        sku_description: body.sku_description,
        sku_id: body.sku_id,
        stage: body.stage,
      })
      .then((res) => {
        //console.log('Updated', res);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };
  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput
            label="SKU ID"
            placeholder="SKU ID"
            value={
              materialData?.existing_item === 'Yes'
                ? materialData?.existing_item_number
                : materialData?.sku_id
            }
          />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  'Action',
                  'Plant',
                  'SKU ID',
                  'Description',
                  'Stage',
                  'Cost/100',
                  'Approved Status',
                  'Comments',
                  // 'Action',
                ].map((item, index) => (
                  <th>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {materialData?.material_status == 4 && plantData?.status == 10 ? (
                    <td>
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          updateRmCostingTableItem(item);
                        }}
                      >
                        <IconDeviceFloppy size={24} color="black" />
                      </Button>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{plantData?.plant}</td>
                  <td>{item?.sku_id}</td>
                  <td>{item?.sku_description}</td>
                  <td>{item?.stage}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      style={{
                        width: '90px',
                      }}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                    {/* {item?.cost_by_100} */}
                  </td>
                  <td>
                    <Select
                      style={{
                        width: '90px',
                      }}
                      radius={'xl'}
                      placeholder="Select"
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                      value={item?.status}
                      onChange={(e) => {
                        e === 'Yes'
                          ? setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id
                                  ? {
                                      ...curr,
                                      status: e,
                                    }
                                  : curr,
                              ),
                            )
                          : setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id ? { ...curr, status: e } : curr,
                              ),
                            );
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      placeholder="If selected No, please provide comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // disabled={item?.status !== 'No'}
                    />
                  </td>
                  {/* <td>
                    <Button
                      variant="light"
                      color={'yellow'}
                      radius="xl"
                      onClick={() => {
                        costService
                          .updateRMCostingTableData({
                            comment: item.comment,
                            cost_by_100: item.cost_by_100,
                            generic_sku_cost: item.generic_sku_cost,
                            id: item.id,
                            sku_description: item.sku_description,
                            sku_id: item.sku_id,
                            stage: item.stage,
                          })
                          .then((res) => {
                            toast.success(res?.message);
                          })
                          .catch((err) => {
                            toast.error(err || 'Something went wrong');
                          });
                      }}
                    >
                      <IconDeviceFloppy size={24} color="black" />
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}

function RenderVirtual({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
}) {
  const [value, setValue] = useState([]);
  const [genericData, setGenericData] = useState({
    description: '',
    cost: '',
  });

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      cost_by_100: '',
    },

    validationSchema: Yup.object({
      cost_by_100: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  const fetchGenericData = async () => {
    try {
      const response = await bomService.getAllComponentsByPlant(
        plantData?.plant,
        materialData?.real_item,
      );

      //console.log('FETCHING GENERIC DATA', response);
      setGenericData({
        description: response[0]?.component_description,
        cost: response[0]?.standard_cost,
      });
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    if (materialData?.real_item && plantData?.plant) {
      fetchGenericData();
    }
  }, [materialData?.real_item, plantData?.plant]);

  const updateRmCostingTableItem = (body) => {
    costService
      .updateRMCostingTableData({
        comment: body.comment,
        cost_by_100: body.cost_by_100,
        generic_sku_cost: body.generic_sku_cost,
        id: body.id,
        sku_description: body.sku_description,
        sku_id: body.sku_id,
        stage: body.stage,
      })
      .then((res) => {
        //console.log('Updated', res);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };

  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput label="SKU ID" placeholder="SKU ID" value={materialData?.sku_id} />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  'Action',
                  'Virtual SKU ID',
                  'Virtual SKU Description',
                  'Virtual SKU Cost',
                  'Generic SKU ID',
                  'Generic SKU Description',
                  'Generic SKU Cost',
                  'Production Location',
                  'Approval Status',
                  'Comments',
                  // 'Action',
                ].map((item, index) => (
                  <th>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {materialData?.material_status == 4 && plantData?.status == 10 ? (
                    <td>
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          updateRmCostingTableItem(item);
                        }}
                      >
                        <IconDeviceFloppy size={24} color="black" />
                      </Button>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{materialData?.sku_id}</td>
                  <td>{materialData?.sku_description}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      style={{
                        width: '90px',
                      }}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                    {/* {item?.cost_by_100} */}
                  </td>
                  <td>{materialData?.real_item}</td>
                  <td>{materialData?.sku_description?.replace(/_VIRT([^_VIRT]*)$/, '')}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      style={{
                        width: '90px',
                      }}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].generic_sku_cost = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.generic_sku_cost}
                      required
                    />
                  </td>
                  <td>{plantData?.plant}</td>
                  <td>
                    <Select
                      style={{
                        width: '90px',
                      }}
                      radius={'xl'}
                      placeholder="Select"
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                      value={item?.status}
                      onChange={(e) => {
                        e === 'Yes'
                          ? setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id
                                  ? {
                                      ...curr,
                                      status: e,
                                    }
                                  : curr,
                              ),
                            )
                          : setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id ? { ...curr, status: e } : curr,
                              ),
                            );
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      style={{
                        minWidth: '200px',
                      }}
                      placeholder="If selected No, please provide comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // disabled={item?.status !== 'No'}
                    />
                  </td>
                  {/* <td>
                    <Button
                      variant="light"
                      color={'yellow'}
                      radius="xl"
                      onClick={() => {
                        costService
                          .updateRMCostingTableData({
                            comment: item.comment,
                            cost_by_100: item.cost_by_100,
                            generic_sku_cost: item.generic_sku_cost,
                            id: item.id,
                            sku_description: item.sku_description,
                            sku_id: item.sku_id,
                            stage: item.stage,
                          })
                          .then((res) => {
                            toast.success(res?.message);
                          })
                          .catch((err) => {
                            toast.error(err || 'Something went wrong');
                          });
                      }}
                    >
                      <IconDeviceFloppy size={24} color="black" />
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}

function RenderRest({
  materialData,
  plantData,
  costingTableData,
  setCostingTableData,
  refreshTable,
  enable = true,
}) {
  const [stageList, setStageList] = useState([
    {
      value: 'FG',
      label: 'FG',
    },
    {
      value: 'Brand',
      label: 'Brand',
    },
    {
      value: 'Age',
      label: 'Age',
    },
    {
      value: 'Ferment',
      label: 'Ferment',
    },
    {
      value: 'Wort',
      label: 'Wort',
    },
    {
      value: 'Phantom',
      label: 'Phantom',
    },
    {
      value: 'Blend',
      label: 'Blend',
    },
    {
      value: 'Tanker',
      label: 'Tanker',
    },
    {
      value: 'Phantom Case',
      label: 'Phantom Case',
    },
    {
      value: 'Phantom Co-Pack',
      label: 'Phantom Co-Pack',
    },
  ]);

  // FORMIK
  const tableFormik = useFormik({
    initialValues: {
      sku_id: '',
      sku_description: '',
      stage: '',
      cost_by_100: '',
    },

    validationSchema: Yup.object({
      sku_id: Yup.string().required('Required')?.nullable(),
      sku_description: Yup.string().required('Required')?.nullable(),
      stage: Yup.string().required('Required')?.nullable(),
      cost_by_100: Yup.string().required('Required')?.nullable(),
    }),
    onSubmit: (values) => {
      costService
        .saveRMCostingTable({
          ...values,
          plant_id: plantData?.id,
        })
        .then((res) => {
          refreshTable();
          toast.success('Saved Successfully');
        })
        .catch((err) => {
          toast.error(err || 'Something went wrong');
        });
      tableFormik.resetForm();
    },
  });

  const updateRmCostingTableItem = (body) => {
    costService
      .updateRMCostingTableData({
        comment: body.comment,
        cost_by_100: body.cost_by_100,
        generic_sku_cost: body.generic_sku_cost,
        id: body.id,
        sku_description: body.sku_description,
        sku_id: body.sku_id,
        stage: body.stage,
      })
      .then((res) => {
        //console.log('Updated', res);
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err || 'Something went wrong');
      });
  };
  return (
    <Card.Body align="left">
      <Grid>
        <Grid.Col span={4}>
          <DisabledTextInput label="SKU ID" placeholder="SKU ID" value={materialData?.sku_id} />
        </Grid.Col>

        <Grid.Col span={8}>
          <DisabledTextInput
            label="SKU Description"
            placeholder="SKU Description"
            value={materialData?.sku_description}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="Plant" placeholder="Plant" value={plantData?.plant} />
        </Grid.Col>
        {materialData?.existing_item === 'Yes' || materialData?.fg_type === 'Virtual' ? null : (
          <Grid.Col span={3}>
            <DisabledTextInput
              label="Liquid Type"
              placeholder="Liquid Type"
              value={materialData?.liquid}
            />
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <DisabledTextInput
            label="Production Date"
            placeholder="Production Date"
            value={new Date(plantData?.production_date).toDateString()}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DisabledTextInput label="FG Type" placeholder="FG Type" value={materialData?.fg_type} />
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{
            overflowX: 'scroll',
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {[
                  'Action',
                  'SKU ID',
                  'Description',
                  'Plant',
                  'Stage',
                  'Production Date',
                  'Cost/100',
                  // 'Approved Status',
                  // 'Comments',
                  // 'Action',
                ].map((item, index) => (
                  <th>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {costingTableData?.map((item, index) => (
                <tr
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {materialData?.material_status == 4 && plantData?.status == 10 ? (
                    <td>
                      <Button
                        variant="light"
                        color={'yellow'}
                        radius="xl"
                        onClick={() => {
                          updateRmCostingTableItem(item);
                        }}
                      >
                        <IconDeviceFloppy size={24} color="black" />
                      </Button>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td
                    style={{
                      minWidth: '120px',
                    }}
                  >
                    {item?.sku_id}
                  </td>
                  <td>{item?.sku_description}</td>
                  <td>{plantData?.plant}</td>
                  <td>{item?.stage}</td>
                  <td>{new Date(plantData?.production_date).toDateString()}</td>
                  <td>
                    <TextInput
                      type={'number'}
                      style={{
                        width: '90px',
                      }}
                      radius={'lg'}
                      placeholder="Cost"
                      onChange={(e) => {
                        setCostingTableData((prev) => {
                          prev[index].cost_by_100 = e.target.value;
                          return [...prev];
                        });
                      }}
                      value={item?.cost_by_100}
                      required
                    />
                  </td>
                  {/* <td>
                    <Select
                      style={{
                        width: '90px',
                      }}
                      radius={'xl'}
                      placeholder="Select"
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                      value={item?.status}
                      onChange={(e) => {
                        e === 'Yes'
                          ? setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id
                                  ? {
                                      ...curr,
                                      status: e,
                                      
                                    }
                                  : curr,
                              ),
                            )
                          : setCostingTableData(
                              costingTableData?.map((curr, index) =>
                                curr?.id === item?.id ? { ...curr, status: e } : curr,
                              ),
                            );
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      style={{
                        minWidth: '200px',
                      }}
                      placeholder="If selected No, please provide comments"
                      value={item?.comment}
                      onChange={(e) => {
                        setCostingTableData(
                          costingTableData?.map((curr, index) =>
                            curr?.id === item?.id ? { ...curr, comment: e.target.value } : curr,
                          ),
                        );
                      }}
                      // disabled={item?.status !== 'No'}
                    />
                  </td> */}
                  {/* <td>
                    <Button
                      variant="light"
                      color={'yellow'}
                      radius="xl"
                      onClick={() => {
                        costService
                          .updateRMCostingTableData({
                            comment: item.comment,
                            cost_by_100: item.cost_by_100,
                            generic_sku_cost: item.generic_sku_cost,
                            id: item.id,
                            sku_description: item.sku_description,
                            sku_id: item.sku_id,
                            stage: item.stage,
                          })
                          .then((res) => {
                            toast.success(res?.message);
                          })
                          .catch((err) => {
                            toast.error(err || 'Something went wrong');
                          });
                      }}
                    >
                      <IconDeviceFloppy size={24} color="black" />
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Card.Body>
  );
}
