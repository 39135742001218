import React, { useEffect, useState } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';

import { ActionIcon, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons';

const UpdateAppServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Tooltip label="Check for Updates" position="bottom">
      <ActionIcon size="md" radius="xl" variant="filled" color="yellow" onClick={reloadPage}>
        <IconRefresh size={18} />
      </ActionIcon>
    </Tooltip>
  );
};

export default UpdateAppServiceWorkerWrapper;
