import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Group, Loader } from '@mantine/core';

const CustomCardLoader = () => {
  return (
    <Card.Body>
      <Group position="center">
        <Row>
          <Col xs={`auto`}>
            <Loader variant="dots" color="yellow" />
          </Col>
          <Col className="text-left justify-content-center align-items-center align-middle">
            <span className="align-middle">&nbsp;{`Please wait`}</span>
          </Col>
        </Row>
      </Group>
    </Card.Body>
  );
};

export default CustomCardLoader;
