import { Button, Group, TextInput, Space, Text, Modal, Select, MultiSelect } from '@mantine/core';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';

function ApproveMaterial({ showApproveMaterialModal, onClose, onSubmit, message }) {
  return (
    <Modal
      radius={'lg'}
      size="lg"
      opened={showApproveMaterialModal}
      centered
      title={
        <Text weight={700} className="golden-text">
          {message}
        </Text>
      }
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <>
        <Row>
          <Col xs={12}></Col>
        </Row>
        <Space h="xl" />
        <Group position="right">
          <Button
            color="gray"
            radius="xl"
            leftIcon={<IconX size={16} color="white" />}
            onClick={onClose}
          >{`No`}</Button>
          <Button
            color="yellow"
            radius="xl"
            leftIcon={<IconCheck size={16} color="white" />}
            onClick={onSubmit}
          >{`Yes`}</Button>
        </Group>
      </>
    </Modal>
  );
}

export default ApproveMaterial;
