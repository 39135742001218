/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Container, Table } from 'react-bootstrap';
import {
  Text,
  Stack,
  Group,
  Space,
  Center,
  Button,
  Tabs,
  Checkbox,
  Switch,
  Select,
} from '@mantine/core';
import { Pagination, Badge } from '@mantine/core';
import { TextInput } from '@mantine/core';
import {
  IconSearch,
  IconCopy,
  IconEdit,
  IconTrash,
  IconPhoto,
  IconMessageCircle,
  IconRefresh,
  IconNavigation,
  IconExternalLink,
} from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconPlus, IconArrowsSort, IconSortAscending, IconSortDescending } from '@tabler/icons';
import AddMaterial from '../ProjectRequest/AddMaterial';
import { UserContext } from '../../context/userContext';
import CustomCardLoader from '../../components/CustomCardLoader';
import moment from 'moment';
import { pageContext } from '../../context/pageContext';
import {
  mapStatusToValue,
  materialStatusToValue,
  plantStatusToValue,
  plantStatusToOwner,
  materialStatusToOwner,
} from '../../utils/Utils';
import materialService from '../../services/materialService';
import { toast } from 'react-toastify';
import RejectMaterial from '../MaterialApproval/RejectMaterial';
import homeServices from '../../services/homeServices';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
import userService from '../../services/userService';
import plantService from '../../services/plantService';
import LastRefreshedAt from '../../components/LastRefreshedAt/LastRefreshedAt';

function SummaryView({ refresh }) {
  const navigate = useNavigate();

  const {
    searchSummary,
    setSearchSummary,
    summaryPagination,
    setSummaryPagination,
    totalSummary,
    setTotalSummary,
  } = useContext(pageContext);

  const [searchColumn, setSearchColumn] = useState('');

  // const [projectList, setProjectList] = useState([]);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const [showRejectMaterialModal, setShowRejectMaterialModal] = useState(false);

  const [selectedMaterial, setSelectedMaterial] = useState(null);

  // useEffect(() => {
  //   let temp = [];
  //   projects?.map((project) => {
  //     project?.materials?.map((material) => {
  //       material.plants?.map((plant) => {
  //         temp.push({
  //           ...plant,
  //           plant: plant?.plant,
  //           project_type: project?.project_type,
  //           project_name: project?.project_name,
  //           project_year: project?.project_year,
  //           project_owner: project?.project_owner,
  //           material_status: material?.material_status,
  //           material_id: material?.id,
  //           sku_description: material?.sku_description,
  //           sku_id: material?.sku_id,
  //           fg_type: material?.fg_type,
  //           liquid: material?.liquid,
  //           m_deleted: material?.deleted,
  //           deleted: material?.deleted,
  //           pl_deleted: plant?.deleted,
  //           pr_deleted: project?.deleted,
  //           plant_status: plant?.status,
  //           plant_id: plant?.id,
  //           existing_item: material?.existing_item,
  //           existing_item_number: material?.existing_item_number,
  //           virtual_sku_description: material?.virtual_sku_description,
  //           draft_status: material?.draft_status,
  //           // rejected_comment: plant?.rejected_comment,
  //         });
  //       });
  //     });
  //   });

  //   //console.log(
  //     'Summary',
  //     temp?.filter((item) => item?.deleted === false && item?.draft_status === false),
  //   );
  //   setProjectList(temp?.filter((item) => item?.deleted === false && item?.draft_status === false));
  // }, [projects]);

  function weeksBetween(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = React.useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [sortedColumnData, setSortedColumnData] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [lastRefreshedAt, setLastRefreshedAt] = useState({});

  const fetchData = async (offset, search, sort_column = null, sort_type = null) => {
    setSortedColumnData(sort_column);
    setSortType(sort_type);

    //console.log('Sort:', sortedColumnData, sortType);
    setLoadingData(true);
    homeServices
      .getSummaryData({
        search: search,
        offset: (offset - 1) * 10,
        column: searchColumn,
        sort_column: sort_column,
        sort_type: sort_type,
      })
      .then((res) => {
        //console.log('PROJECT REQUEST DATA: ', res);
        setProjectList(res?.data);
        setPages(res?.total);
        setLoadingData(false);
        setTotalSummary(res?.rows);
      })
      .catch((err) => {
        //console.log('PROJECT REQUEST ERROR: ', err);
      });
  };

  const fetchRefreshStatus = async () => {
    homeServices
      ?.snowflakeRefreshedAt()
      .then((res) => {
        //console.log('REFRESHED AT: ', res);
        setLastRefreshedAt(res?.data);
      })
      .catch((err) => {
        //console.log('REFRESHED AT ERROR: ', err);
      });
  };

  useEffect(() => {
    fetchRefreshStatus();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // setSummaryPagination(1);
      fetchData(1, searchSummary);
    }, 1500);
    return () => clearTimeout(timer);
  }, [searchSummary]);

  const rejectMaterial = (comment) => {
    // setProjectList(projectList?.filter((item) => item?.material_id !== selectedMaterial));
    plantService
      .deleteExistingPlant(selectedMaterial, {
        id: selectedMaterial,
        deleted_comment: comment,
      })
      .then((res) => {
        toast.success('Request Deleted Successfully');
        window.location.reload();
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  useEffect(() => {
    setSearchSummary('');
  }, [searchColumn]);

  return (
    <>
      <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
        <Card radius="xl">
          <Card.Body>
            <Group position="apart">
              <Text size="xl" weight={700} color="yellow" align="left" style={{ fontSize: '18px' }}>
                {}
              </Text>
            </Group>
          </Card.Body>
          <Card.Body>
            <Group position="apart">
              <Text
                size="xl"
                weight={700}
                color="yellow"
                style={{ fontSize: '18px' }}
              >{`Summary View`}</Text>
            </Group>
            <Space h="xl" />
            <Group
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              <Group
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '1rem',
                }}
              >
                <Select
                  data={[
                    {
                      label: 'Project Name',
                      value: 'project_name',
                    },
                    {
                      label: 'Project Type',
                      value: 'project_type',
                    },
                    {
                      label: 'SKU ID',
                      value: 'sku_id',
                    },
                    {
                      value: 'sku_description',
                      label: 'SKU Description',
                    },
                    {
                      value: 'plant',
                      label: 'Plant',
                    },
                    {
                      value: 'step_owner',
                      label: 'Step Owner',
                    },
                  ]}
                  value={searchColumn}
                  placeholder={`Search Filter`}
                  radius={'xl'}
                  onChange={(e) => {
                    //console.log(e);
                    setSearchColumn(e);
                  }}
                  style={{
                    width: '16vw',
                  }}
                  rightSection={
                    <ActionIcon
                      variant="filled"
                      color={'yellow'}
                      radius="xl"
                      size="md"
                      onClick={() => {
                        setSearchColumn('');
                        setSummaryPagination(1);
                        setSearchSummary('');
                      }}
                    >
                      <IconRefresh size={14} color="black" />
                    </ActionIcon>
                  }
                />
                {searchColumn && (
                  <TextInput
                    radius={'xl'}
                    placeholder={`Search ${
                      [
                        {
                          label: 'Project Name',
                          value: 'project_name',
                        },
                        {
                          label: 'Project Type',
                          value: 'project_type',
                        },
                        {
                          label: 'SKU ID',
                          value: 'sku_id',
                        },
                        {
                          value: 'sku_description',
                          label: 'SKU Description',
                        },
                        {
                          value: 'plant',
                          label: 'Plant',
                        },
                        {
                          value: 'step_owner',
                          label: 'Step Owner',
                        },
                      ].find((item) => item.value === searchColumn).label
                    }`}
                    sx={{ width: '16vw' }}
                    rightSection={
                      <ActionIcon
                        variant="filled"
                        color={'yellow'}
                        radius="xl"
                        size="md"
                        onClick={() => {
                          setSearchColumn('');
                          setSearchSummary('');
                          setSummaryPagination(1);
                        }}
                      >
                        <IconRefresh size={14} color="black" />
                      </ActionIcon>
                    }
                    value={searchSummary}
                    onChange={(e) => {
                      setLoadingData(true);
                      setSearchSummary(e.target.value);
                      setSummaryPagination(1);
                    }}
                  />
                )}
              </Group>

              <Group>
                <LastRefreshedAt lastRefreshedAt={lastRefreshedAt} screen={'Summary'} />
              </Group>
            </Group>
          </Card.Body>
          {loadingData ? (
            <CustomCardLoader />
          ) : (
            <div className="table-responsive-sm">
              <Table hover>
                <thead>
                  <tr>
                    {[
                      '',
                      'Project Name',
                      'Project Type',
                      'Project Year',
                      'SKU ID',
                      'SKU Description',
                      'Plant',
                      'Current Stage',
                      'Step Owner',
                      'Production Date',
                      'Less than 10 week?',
                      'Deprioritized',
                      'Deprioritized By Weeks',
                      'Latest By',
                      check_roles(userState, ['admin']) && 'On Hold',
                      'Delete Request',
                    ].map((item, index) => {
                      if (index >= 9 && !check_roles(userState, ['admin'])) {
                        return null;
                      } else {
                        return (
                          <th
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Center fw={500}>
                              {item}
                              {item != 'Delete Request' &&
                                item != 'Step Owner' &&
                                item != 'Less than 10 week?' &&
                                item != 'Latest By' &&
                                item != '' && (
                                  <>
                                    {sortedColumnData !== item && (
                                      <ActionIcon
                                        onClick={() => {
                                          fetchData(1, searchSummary, item, 'ascending');
                                        }}
                                      >
                                        <IconArrowsSort />
                                      </ActionIcon>
                                    )}
                                    {sortedColumnData == item && sortType == 'ascending' && (
                                      <ActionIcon
                                        onClick={() => {
                                          fetchData(1, searchSummary, item, 'descending');
                                        }}
                                      >
                                        <IconSortAscending />
                                      </ActionIcon>
                                    )}
                                    {sortedColumnData == item && sortType == 'descending' && (
                                      <ActionIcon
                                        onClick={() => {
                                          fetchData(1, searchSummary, null, null);
                                        }}
                                      >
                                        <IconSortDescending />
                                      </ActionIcon>
                                    )}
                                  </>
                                )}
                            </Center>
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {projectList?.map((project, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          project?.plant_deleted || project?.deleted ? '#f5f5f5' : 'white',
                      }}
                    >
                      <td>
                        {project?.plant_deleted || project?.deleted ? null : (
                          <Button
                            color={'yellow'}
                            onClick={() => {
                              !project?.plant_deleted &&
                                !project?.deleted &&
                                navigate(
                                  `/material/${project?.material_id}/${project?.plant_id}`,
                                  //   {
                                  //   state: {
                                  //     plant_id: project?.plant_id,
                                  //     plant: project?.plant,
                                  //     status: project?.plant_status,
                                  //   },
                                  // }
                                );
                            }}
                            style={{
                              cursor: project?.plant_deleted ? 'not-allowed' : 'pointer',
                              width: '24px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: 'auto',
                            }}
                          >
                            <IconExternalLink
                              style={{
                                width: '16px',
                              }}
                            />
                          </Button>
                        )}
                      </td>
                      <td>{project?.project_name}</td>
                      <td>{project?.project_type}</td>
                      <td>{project?.project_year}</td>
                      <td>
                        {project?.existing_item === 'Yes'
                          ? project?.existing_item_number
                          : project?.sku_id}
                      </td>
                      <td>
                        {!project?.sku_description
                          ? project?.virtual_sku_description
                          : project?.sku_description}
                      </td>
                      <td>{project.plant}</td>
                      {/* <td>
                        {project?.plant_deleted || project?.deleted ? (
                          'Deleted'
                        ) : (
                          <>
                            {project?.plant_status
                              ? plantStatusToValue(project?.plant_status)
                              : materialStatusToValue(String(project?.material_status))}
                          </>
                        )}
                      </td> */}
                      <td>{project?.final_status}</td>

                      <td style={{ overflow: 'auto', maxWidth: '200px', whiteSpace: 'nowrap' }}>
                        {project?.step_owner
                          ? project.step_owner.split('|').map((element, index) => (
                              <React.Fragment key={index}>
                                {element}
                                <br />
                              </React.Fragment>
                            ))
                          : ''}
                      </td>

                      <td>
                        {project?.production_date
                          ? moment(project?.production_date).format('MM-DD-YYYY')
                          : ''}
                      </td>
                      {check_roles(userState, ['admin']) && (
                        <>
                          <td>
                            {weeksBetween(new Date(project?.production_date), new Date()) > 10
                              ? 'No'
                              : 'Yes'}
                          </td>
                          <td>
                            {project?.plant_deleted ||
                              (project?.deleted ? null : (
                                <Checkbox
                                  color={'yellow'}
                                  checked={project?.deprioritized}
                                  label="Deprioritized"
                                  onChange={(e) => {
                                    e.target.checked
                                      ? setProjectList(
                                          projectList?.map((item) => {
                                            if (item?.plant_id === project?.plant_id) {
                                              item.deprioritized = e.target.checked;
                                              item.deprioritized_date = new Date();
                                            }
                                            return item;
                                          }),
                                        )
                                      : setProjectList(
                                          projectList?.map((item) => {
                                            if (item?.plant_id === project?.plant_id) {
                                              item.deprioritized = e.target.checked;
                                              item.deprioritized_date = null;
                                            }
                                            return item;
                                          }),
                                        );
                                    //console.log(e.target.checked);
                                    materialService
                                      .deprioritizedPlant({
                                        plant_id: project?.plant_id,
                                        status: e.target.checked,
                                      })
                                      .then((res) => {
                                        //console.log(res);
                                        toast.success(res?.message);
                                      })
                                      .catch((err) => {
                                        toast.error(err?.message || 'Something went wrong');
                                      });
                                  }}
                                />
                              ))}
                          </td>
                          <td>
                            {/* {weeksBetween(new Date(), new Date(project?.deprioritized_date))} */}
                            {weeksBetween(new Date(), new Date(project?.deprioritized_date)) >= 0
                              ? weeksBetween(new Date(), new Date(project?.deprioritized_date))
                              : null}
                          </td>
                          <td>
                            {(() => {
                              if (project?.deprioritized) {
                                const numWeeks = weeksBetween(
                                  new Date(project?.production_date),
                                  new Date(project?.deprioritized_date),
                                );
                                const now = new Date(project?.production_date);
                                now.setDate(now.getDate() + numWeeks * 7);
                                return moment(now).format('DD/MM/YYYY');
                              } else {
                                return null;
                              }
                            })()}
                          </td>
                          <td>
                            {check_roles(userState, ['admin']) && (
                              <Switch
                                disabled={project?.plant_deleted || project?.deleted}
                                label="Hold"
                                size="xs"
                                color="yellow"
                                checked={project?.on_hold}
                                onChange={(e) => {
                                  setProjectList(
                                    projectList?.map((item) => {
                                      if (item?.plant_id === project?.plant_id) {
                                        item.on_hold = e.target.checked;
                                      }
                                      return item;
                                    }),
                                  );
                                  materialService
                                    .holdPlant({
                                      plant_id: project?.plant_id,
                                      status: e.target.checked,
                                      formtype_id: project?.plant_status
                                        ? project?.plant_status
                                        : project?.material_status,
                                    })
                                    .then((res) => {
                                      //console.log(res);
                                      toast.success(res?.message);
                                    })
                                    .catch((err) => {
                                      toast.error(err?.message || 'Something went wrong');
                                    });
                                }}
                              />
                            )}
                          </td>

                          <td>
                            {project?.plant_deleted ||
                              (project?.deleted ? null : (
                                <Button
                                  color={'yellow'}
                                  onClick={() => {
                                    setSelectedMaterial(project?.plant_id);
                                    setShowRejectMaterialModal(true);
                                  }}
                                  style={{
                                    width: '24px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: 'auto',
                                  }}
                                >
                                  <IconTrash
                                    style={{
                                      width: '16px',
                                    }}
                                  />
                                </Button>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          <Card.Footer>
            <Container>
              <Center>
                <Pagination
                  total={pages + 1}
                  color="yellow"
                  radius="xl"
                  withEdges
                  page={summaryPagination}
                  boundaries={1}
                  initialPage={summaryPagination}
                  value={summaryPagination}
                  onChange={(e) => {
                    // //console.log(e);
                    setSummaryPagination(e);
                    fetchData(e, searchSummary, sortedColumnData, sortType);
                  }}
                />
              </Center>
            </Container>
          </Card.Footer>
        </Card>
      </div>

      <RejectMaterial
        showRejectMaterialModal={showRejectMaterialModal}
        closeRejectMaterialModal={() => setShowRejectMaterialModal(false)}
        rejectMaterial={(comment) => {
          rejectMaterial(comment);
        }}
        message="Are you sure you want to reject this request?"
      />
    </>
  );
}
export default SummaryView;
