import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  Text,
  Paper,
  Center,
  Box,
  Divider,
  Button,
  Grid,
  Space,
  Card,
  TextInput,
  CloseButton,
  Group,
  Modal,
} from '@mantine/core';
import { IconX, IconSearch, IconUsers, IconCheck, IconPlus } from '@tabler/icons';
import materialService from '../../services/materialService';
import dayjs from 'dayjs';

function SkuDescription({
  showSkuDescriptionModal,
  closeMaterialModal,
  closeSkuDescriptionModal,
  handleMaterial,
  materialObject,
  productionPlants = [],
  strProductionPlants = [],
}) {
  // const [plantNames,setPlantNames]= useState([])

  function productionWarner() {
    var plants = [];
    const FOUR_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 4;
    const now = new Date();

    const expiredPlants = materialObject.plants.filter((plant) => {
      plant.expired = false;
      const productionDate = new Date(plant.production_date);
      //console.log(productionDate);
      const timeDiff = productionDate.getTime() - now.getTime();
      //console.log(timeDiff);
      return timeDiff < FOUR_WEEKS_IN_MS;
    });

    if (expiredPlants.length > 0) {
      //console.log('Warning: The following dates are now enough:');
      expiredPlants.forEach((plant) => {
        plant.expired = true;
        plants.push(plant.plant);
      });
      //console.log("expired ones",expiredPlants)
      return plants;
    }
  }

  function strWarner() {
    var plants = [];

    const expiredPlants = materialObject.plants.filter((plant) => {
      const productionDate = dayjs(plant.production_date, 'MM-DD-YYYY');
      const strDate = dayjs(plant.str_date, 'MM-DD-YYYY');
      const timeDiff = strDate.diff(productionDate, 'day');
      //console.log(productionDate);
      //console.log(timeDiff);
      return timeDiff < 14;
    });

    if (expiredPlants.length > 0) {
      //console.log('Warning: The following dates are now enough:');
      expiredPlants.forEach((plant) => plants.push(plant.plant));
      return plants;
    }
  }
  if (showSkuDescriptionModal) {
    productionPlants = productionWarner();
    strProductionPlants = strWarner();
    //console.log(strProductionPlants);
    //console.log(productionPlants);
    if (productionPlants) {
      //console.log('we are putting this on hold and sending emails lol');
    }
    //  setPlantNames(plantNames)
  }

  return (
    <Modal
      radius={'lg'}
      size="xl"
      opened={showSkuDescriptionModal}
      centered
      title={
        <Text weight={700} className="golden-text">{`Material Confirmation Description`}</Text>
      }
      onClose={() => {
        closeSkuDescriptionModal();
      }}
      withCloseButton={true}
    >
      <Card radius="xl">
        <Text size="md" weight={700}>
          {' '}
          The material description according to your below request is:
        </Text>
        <Text size="md" weight={700}>
          {materialObject?.fgType === 'Virtual'
            ? materialObject.virtualSku
            : materialObject.skuDescription}
        </Text>
        <Space h={40} />
        {productionPlants ? (
          <>
            <Text size="md" weight={500}>
              You are submitting a project that is less than 4 weeks from Production Date. The
              project will be put ON HOLD. Admin will reach out to deprioritize another SKU and
              release the project.
            </Text>
            <Space h={10} />
            <ul>
              {productionPlants.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </>
        ) : null}
        <Space h={40} />
        {strProductionPlants ? (
          <>
            <Text size="md" weight={500}>
              You are submitting a Project with STR date less than 14 days from Production Date.
              Please confirm this is aligned with Planning and Logistics team to execute
            </Text>
            <Space h={10} />
            <ul>
              {strProductionPlants.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </>
        ) : null}
        <Space h={40} />
        <Text size="md" weight={700}>
          Do you want to proceed adding this material to this project?
        </Text>
        <Space h={40} />
        <Group position="right" spacing="md">
          <Button
            color="gray"
            radius="xl"
            leftIcon={<IconX size={16} color="white" />}
            onClick={() => {
              closeSkuDescriptionModal();
            }}
          >{`Cancel`}</Button>
          <Button
            color="yellow"
            radius="xl"
            className="btn btn-primary"
            style={{ borderRadius: '40px' }}
            leftIcon={<IconCheck size={16} color="white" />}
            onClick={() => {
              handleMaterial();
              closeMaterialModal();
              closeSkuDescriptionModal();
            }}
          >{`Submit`}</Button>
        </Group>
      </Card>
    </Modal>
  );
}
export default SkuDescription;
