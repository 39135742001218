/* eslint-disable import/no-anonymous-default-export */
import axios from '../utils/axios';
import { urls } from '../utils/apiConfigs';

export default {
  createNewPlant: async function (data) {
    try {
      const response = await axios.post(urls.createPlantURL, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateExistingPlant: async function (id, data) {
    try {
      const response = await axios.post(urls.updatePlantURL + id, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteExistingPlant: async function (id, data) {
    try {
      const response = await axios.post(urls.deletePlantURL + id, data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  submitFGform: async function (id, body) {
    try {
      const response = await axios.post(urls.submitFGformURL + id, body);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getPlantById: async function (id) {
    try {
      const response = await axios.get(urls.getPlantByIdURL + id);
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
