import React, { useState, useContext } from 'react';
import { Button, Group, TextInput, Space, Text, Modal, Select, MultiSelect } from '@mantine/core';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { Card, Col, Row } from 'react-bootstrap';
import { UserContext } from '../../context/userContext';

function ConfirmationBox({ open, onSave, onDiscard, message }) {
  return (
    <Modal
      radius={'lg'}
      size="lg"
      opened={open}
      centered
      title={
        <Text weight={700} className="golden-text">
          {message}
        </Text>
      }
      onClose={onDiscard}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <>
        <Space h="xl" />
        <Group position="right">
          <Button
            color="gray"
            radius="xl"
            leftIcon={<IconX size={16} color="white" />}
            onClick={onDiscard}
          >{`Discard`}</Button>
          <Button
            color="yellow"
            radius="xl"
            className="btn btn-primary"
            style={{ borderRadius: '40px'} }
            leftIcon={<IconCheck size={16} color="white" />}
            onClick={onSave}
          >{`Save`}</Button>
        </Group>
      </>
    </Modal>
  );
}
export default ConfirmationBox;
