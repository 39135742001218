import React, { useState, useContext } from 'react';
import { Button, Group, TextInput, Space, Text, Modal, Select, MultiSelect } from '@mantine/core';
import { IconX, IconCheck, IconChevronDown, IconTrash } from '@tabler/icons';
import { Card, Col, Row } from 'react-bootstrap';
import { UserContext } from '../../context/userContext';

function RejectMaterial({
  showRejectMaterialModal,
  closeRejectMaterialModal,
  rejectMaterial,
  message = 'Please provide your comments for Rejecting this Request',
}) {
  const [comment, setComment] = useState();
  return (
    <Modal
      radius={'lg'}
      size="lg"
      opened={showRejectMaterialModal}
      centered
      title={
        <Text weight={700} className="golden-text">
          {message}
        </Text>
      }
      onClose={() => {
        closeRejectMaterialModal();
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <>
        <Row>
          <Col xs={12}>
            <TextInput
              label="Comment"
              radius={'lg'}
              required
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Space h="xl" />
        <Group position="right">
          <Button
            color="gray"
            radius="xl"
            leftIcon={<IconX size={16} color="white" />}
            onClick={() => {
              closeRejectMaterialModal();
            }}
          >{`Cancel`}</Button>
          <Button
            color="yellow"
            radius="xl"
            className="btn btn-primary"
            style={{ borderRadius: '40px'} }
            disabled={!comment}
            leftIcon={<IconCheck size={16} color="white" />}
            onClick={() => {
              rejectMaterial(comment);
              closeRejectMaterialModal(true);
            }}
          >{`Save`}</Button>
        </Group>
      </>
    </Modal>
  );
}
export default RejectMaterial;
