import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Button,
  Group,
  TextInput,
  Space,
  Text,
  MultiSelect,
  Input,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import {
  IconCheck,
  IconX,
  IconTrash,
  IconBuildingFactory2,
  IconChevronDown,
  IconCircleCheck,
  IconChevronRight,
  IconChevronLeft,
} from '@tabler/icons';
import { useState, useContext, useEffect, useMemo } from 'react';
import { Select } from '@mantine/core';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { DatePickerInput } from '@mantine/dates';
import { useFormik } from 'formik';
import { NumberInput, Grid, Flex } from '@mantine/core';
import { UserContext } from '../../context/userContext';
import materialService from '../../services/materialService';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import RejectMaterial from './RejectMaterial';
import { InfoCircle, AsteriskSimple } from 'tabler-icons-react';
import DataTable from '../../components/DataTable';
import ApproveMaterial from './ApproveMaterial';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import reverseMailService from '../../services/reverseMailService';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
import userService from '../../services/userService';
import mailService from '../../services/mailService';

function MaterialApproval() {
  const { setSelectedProjectMaterials } = useContext(UserContext);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  const [emailReceivers, setEmailReceivers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveMaterialModal, setShowApproveMaterialModal] = useState(false);
  const [skuDescription, setSkuDescription] = useState();
  // const [virtualSkuDescription, setVirtualSkuDescription] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [materialStatus, setMaterialStatus] = useState();
  const [plantsDetails, setPlantsDetails] = useState([]);
  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      materialService.getMaterialById(params?.id).then((res) => {
        if (res?.data) {
          setSelectedMaterial(res.data?.material);
          setMaterialStatus(res.data.material?.material_status);
          res.data.material?.fg_type === 'Virtual'
            ? setSkuDescription(res.data.material?.virtual_sku_description)
            : setSkuDescription(res.data.material?.sku_description);

          res.data.material?.fg_type === 'Virtual'
            ? formik.setFieldValue('skuDescription', res.data.material?.virtual_sku_description)
            : formik.setFieldValue('skuDescription', res.data.material?.sku_description);

          // setVirtualSkuDescription(res.data.material?.virtual_sku_description);
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    //console.log('loading material');
    if (params?.id) {
      getPlants();
    }
  }, [params?.id]);

  useEffect(() => {
    mailService.skuDescriptionApprovalMail().then((res) => {
      setEmailReceivers(
        res?.data.data?.map((item) => ({
          label: item?.name,
          value: item?.email,
        })),
      );
    });
  }, []);

  const getPlants = () => {
    materialService.getPlantsDetails(params?.id).then((res) => {
      if (res?.data) {
        setPlantsDetails(res?.data?.plants);
      }
    });
  };

  const approveMaterial = () => {
    materialService
      .approveMaterial(params?.id, skuDescription, formik.values?.sendRequestTo)
      .then((res) => {
        if (res?.data?.status === false) {
          toast.error(res?.data?.message);
          setShowApproveMaterialModal(false);
          return;
        } else {
          //console.log(res.data);
          toast.success('Material Approved Successfully');
          navigate('/');
        }
        // if (res?.data) {
        //   //console.log(res.data);
        //   toast.success('Material Approved Successfully');
        //   navigate('/');
        // }
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  // const deleteMaterial = () => {
  //   if (params?.id) {
  //     materialService.getMaterialById(params?.id).then((res) => {
  //       if (res?.data) {
  //         setSelectedMaterial(res.data?.material);
  //         setSkuDescription(res.data.material?.sku_description);
  //       }
  //     });
  //   }
  // };

  const deleteMaterial = (comment) => {
    materialService
      .deleteMaterial({
        id: params?.id,
        comment,
      })
      .then((res) => {
        setSelectedProjectMaterials([]);
        toast.success('Material Deleted Successfully');

        navigate('/');
      })
      .catch((err) => {
        toast.error('Something went wrong, please try again later.');
      });
  };

  const rejectMaterial = (comment) => {
    materialService
      .rejectSkuDescriptionApproval({
        material_id: params?.id,
      })
      .then((res) => {
        setSelectedProjectMaterials([]);
        toast.success('Material Rejected Successfully');
        reverseMailService.sendSkuDescriptionRejectMail({
          material_id: params?.id,
          comment,
        });
        navigate('/');
      })
      .catch((err) => {
        toast.error(err.message || 'Something went wrong, please try again later.');
      });
  };

  const formik = useFormik({
    initialValues: {
      materialType: selectedMaterial?.material_type,
      valuationClass: selectedMaterial?.valuation_class,
      existingItem: selectedMaterial?.existing_item,
      existingItemNumber: selectedMaterial?.existing_item_number,
      fgType: selectedMaterial?.fg_type,
      liquid: selectedMaterial?.liquid,
      brand: selectedMaterial?.brand,
      subBrand: selectedMaterial?.sub_brand,
      packSize1: selectedMaterial?.pack_size_1,
      packSize2: selectedMaterial?.pack_size_2,
      containerType: selectedMaterial?.container_type,
      containerMaterial: selectedMaterial?.container_material,
      containerVolume: selectedMaterial?.container_volume,
      decoType: selectedMaterial?.deco_type,
      palletization1: selectedMaterial?.palletization_1,
      palletization2: selectedMaterial?.palletization_2,
      suffix: selectedMaterial?.suffix,
      alcohol: selectedMaterial?.alcohol,
      grossWeight: selectedMaterial?.gross_weight,
      language: selectedMaterial?.language,
      importedFrom: selectedMaterial?.imported_from,
      virtualSku: selectedMaterial?.virtual_sku_description,
      realItem: selectedMaterial?.real_item,
      containerReturnable: selectedMaterial?.container_returnable,
      length: selectedMaterial?.length,
      tasteSegment: selectedMaterial?.taste_segment,
      priceClass: selectedMaterial?.price_class,
      liquidClass: selectedMaterial?.liquid_class,
      upcTbsCodes: selectedMaterial?.upc_tbs_codes,
      width: selectedMaterial?.width,
      height: selectedMaterial?.height,
      skuDescription: skuDescription,
      plants: selectedMaterial?.plants,
      sendRequestTo: selectedMaterial?.send_request_to,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      skuDescription: Yup.string()
        ?.test('len', 'Should be less than 40 characters', (val) => val?.length <= 40)
        ?.nullable(),
      // sendRequestTo: Yup.string().required('Required').nullable(),
      sendRequestTo: Yup.string().when(['existingItem', 'fgType'], {
        is: (existingItem, fgType) => existingItem === 'Yes' || fgType === 'Virtual',
        then: Yup.string().notRequired().nullable(),
        otherwise: Yup.string().required('Required').nullable(),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      //console.log('Form data', values);
      setShowApproveMaterialModal(true);
    },
  });

  const table_columns = useMemo(() => [
    {
      header: '',
      accessor: 'id',
    },
    {
      header: 'Production Plant',
      accessor: 'plant',
    },
    {
      header: 'Selling Region',
      accessor: 'selling_region',
    },
    {
      header: 'Production Date',
      accessor: 'production_date',
    },
    {
      header: 'STR Date',
      accessor: 'str_date',
    },
    {
      header: 'Stop Ship Date',
      accessor: 'stop_ship_date',
    },
  ]);

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Card radius="xl" p="lg">
        <Card.Body>
          <Group position="apart">
            <Text
              size="xl"
              weight={700}
              color="yellow"
              align="left"
              style={{ fontSize: '18px' }}
            >{`SKU Description Approval`}</Text>
          </Group>
        </Card.Body>
        <Card.Body align="left">
          <Grid>
            <Grid.Col span={4}>
              <TextInput
                label="Material Type"
                placeholder="Material Type"
                radius={'lg'}
                required
                disabled
                value={'Z001'}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                label="Valuation Class"
                placeholder="Valuation Class"
                radius={'lg'}
                required
                disabled
                value={'Z081'}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                disabled
                label={
                  <Flex gap="xm" justify="right" align="center" direction="row" wrap="wrap">
                    {'Existing Item '}
                    <Tooltip
                      multiline
                      width={220}
                      withArrow
                      transition="fade"
                      transitionDuration={200}
                      label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                    >
                      <ActionIcon size="sm" variant="transparent">
                        <InfoCircle size={14} color={'#fab005'} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                }
                placeholder="Existing Item"
                radius={'lg'}
                rightSection={<IconChevronDown size={14} />}
                rightSectionWidth={30}
                searchable
                styles={{ rightSection: { pointerEvents: 'none' } }}
                data={['Yes', 'No']}
                onChange={(e) => {
                  formik.setFieldValue('existingItem', e);
                }}
                value={formik.values.existingItem}
                error={formik.errors.existingItem}
              />
            </Grid.Col>
            {/* {formik.values.existingItem && formik.values.existingItem !== 'Yes' ? <></> : */}

            {formik.values.existingItem === 'Yes' && (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Existing Item SKU Number"
                  placeholder="Existing Item SKU Number"
                  radius={'lg'}
                  onChange={(e) => {
                    formik.setFieldValue('existingItemNumber', e.target.value);
                  }}
                  value={formik.values.existingItemNumber}
                  error={formik.errors.existingItemNumber}
                />
              </Grid.Col>
            )}

            {/* //} */}
            {formik?.values?.existingItem !== 'Yes' && (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="FG Type"
                  placeholder="FG TYPE"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  // data={fgTypeList}
                  onChange={(e) => {
                    formik.setFieldValue('fgType', e);
                  }}
                  value={formik?.values?.fgType}
                  error={formik.errors.fgType}
                />
              </Grid.Col>
            )}
            {formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem !== 'Yes' && (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Liquid Type"
                  placeholder="Liquid Type"
                  radius={'lg'}
                  searchable
                  required
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  //   data={liquidDropDown}
                  //   onChange={(e) => {
                  //     formik.setFieldValue('liquid', e);
                  //     handleChangeLiquid(e)

                  //   }}
                  value={formik.values.liquid}
                  error={formik.errors.liquid}
                />
              </Grid.Col>
            )}
            {formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem !== 'Yes' && (
              <>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Brand"
                    placeholder="Brand"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={brandDropDown}
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    //   onCreate={(query) => {
                    //     const item = { value: query, label: query };
                    //     setBrandList((current) => [...current, item]);
                    //     return item;
                    //   }}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('brand', e);
                    //     handleChangeBrand(e)
                    //   }}
                    value={formik.values.brand}
                    error={formik.errors.brand}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Sub Brand"
                    placeholder="Sub Brand"
                    radius={'lg'}
                    required
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={subBrandDropDown}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    //   onCreate={(query) => {
                    //     const item = { value: query, label: query };
                    //     setSubBrandList((current) => [...current, item]);
                    //     return item;
                    //   }}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('subBrand', e);
                    //     checkSKUDescription('subBrand', e)
                    //   }}
                    value={formik.values.subBrand}
                    error={formik.errors.subBrand}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex
                    gap="xs"
                    justify="center"
                    // align="center"
                    direction="row"
                    wrap="nowrap"
                  >
                    <TextInput
                      disabled
                      radius={'lg'}
                      type="number"
                      placeholder="Pack Size 1"
                      label={
                        <Flex gap="xm" justify="right" align="center" direction="row" wrap="nowrap">
                          {'Pack Size '}
                          <Tooltip
                            multiline
                            width={220}
                            withArrow
                            transition="fade"
                            transitionDuration={200}
                            label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                          >
                            <ActionIcon size="sm" variant="transparent">
                              <InfoCircle size={14} color={'#fab005'} />
                              <AsteriskSimple size={6} strokeWidth={3} color={'#fa5252'} />
                            </ActionIcon>
                          </Tooltip>
                        </Flex>
                      }
                      onChange={(e) => {
                        formik.setFieldValue('packSize1', e.target.value);
                        //   checkSKUDescription('packSize1', e.target.value)
                      }}
                      value={formik.values.packSize1}
                      error={formik.errors.packSize1}
                    />
                    <Text mt={'6vh'}>X</Text>
                    <TextInput
                      disabled
                      radius={'lg'}
                      required
                      type="number"
                      placeholder="Pack Size 2"
                      label=" "
                      // onChange={(e) => {
                      //   formik.setFieldValue('packSize2', e.target.value);
                      //   checkSKUDescription('packSize2', e.target.value)
                      // }}
                      value={formik.values.packSize2}
                      error={formik.errors.packSize2}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Container Type"
                    placeholder="Container Type"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={containerTypeList}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('containerType', e);
                    //     checkSKUDescription('containerType', e)

                    //   }}
                    value={formik.values.containerType}
                    error={formik.errors.containerType}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Container Material"
                    placeholder="Container Material"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={['Glass', 'Plastic', 'Aluminium', 'Stainless Steel']}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('containerMaterial', e);
                    //   }}
                    value={formik.values.containerMaterial}
                    error={formik.errors.containerMaterial}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Flex
                    gap="xs"
                    justify="center"
                    // align="center"
                    direction="row"
                    wrap="nowrap"
                  >
                    <TextInput
                      disabled
                      radius={'lg'}
                      required
                      type="number"
                      placeholder="Palletization 1"
                      label="Palletization"
                      // onChange={(e) => {
                      //   formik.setFieldValue('palletization1', e.target.value);
                      //   checkSKUDescription('palletization1', e.target.value)

                      // }}
                      value={formik.values.palletization1}
                      error={formik.errors.palletization1}
                    />
                    <Text mt={'6vh'}>X</Text>
                    <TextInput
                      disabled
                      radius={'lg'}
                      required
                      type="number"
                      placeholder="Palletization 2"
                      label=" "
                      // onChange={(e) => {
                      //   formik.setFieldValue('palletization2', e.target.value);
                      //   checkSKUDescription('palletization2', e.target.value)

                      // }}
                      value={formik.values.palletization2}
                      error={formik.errors.palletization2}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Container Volume"
                    className="containerVolume"
                    name="name"
                    key="name"
                    required
                    radius={'lg'}
                    placeholder={'Container Volume'}
                    //   {/* onChange={(e) => {
                    //     formik.setFieldValue('containerVolume', e.target.value);
                    //     checkSKUDescription('containerVolume', e.target.value)

                    //   }} */}
                    value={formik.values.containerVolume}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Liquid Class"
                    placeholder="Liquid Class"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={liquidClassDropDown}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('liquidClass', e);
                    //   }}
                    value={formik.values.liquidClass}
                    error={formik.errors.liquidClass}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label={
                      <Flex gap="xm" justify="right" align="center" direction="row" wrap="nowrap">
                        {'Suffix/ Other Comments '}
                        <Tooltip
                          multiline
                          width={220}
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label="Use this button to save this information in your profile, after that you will be able to access it any time and share it via email."
                        >
                          <ActionIcon size="sm" variant="transparent">
                            <InfoCircle size={14} color={'#fab005'} />
                            <AsteriskSimple size={6} strokeWidth={3} color={'#fa5252'} />
                          </ActionIcon>
                        </Tooltip>
                      </Flex>
                    }
                    radius={'lg'}
                    placeholder={'Suffix/ Other Comments'}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('suffix', e.target.value);
                    //     checkSKUDescription('suffix', e.target.value)
                    //   }}
                    value={formik.values.suffix}
                    error={formik.errors.suffix}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Alcohol %"
                    required
                    radius={'lg'}
                    placeholder={'Alcohol %'}
                    onChange={(e) => {
                      formik.setFieldValue('alcohol', e.target.value);
                    }}
                    value={formik.values.alcohol}
                    error={formik.errors.alcohol}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Gross Weight"
                    radius={'lg'}
                    placeholder={'Gross Weight'}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('grossWeight', e.target.value);
                    //   }}
                    value={formik.values.grossWeight}
                    // error={formik.errors.grossWeight}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Language"
                    placeholder="Language"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={languageList}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('language', e);
                    //     checkSKUDescription('language', e)

                    //   }}
                    value={formik.values.language}
                    error={formik.errors.language}
                  />
                </Grid.Col>
              </>
            )}
            {formik?.values?.existingItem == 'Yes' || formik?.values?.fgType != 'Import' ? (
              <></>
            ) : (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Imported From"
                  placeholder="Imported From"
                  radius={'lg'}
                  searchable
                  required={formik?.values?.fgType === 'Import'}
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  //   data={importedFromDropDown}
                  //   onChange={(e) => {
                  //     formik.setFieldValue('importedFrom', e);
                  //   }}
                  value={formik.values.importedFrom}
                  error={formik.errors.importedFrom}
                  // disabled={formik?.values?.fgType !== 'Import'}
                />
              </Grid.Col>
            )}

            {formik?.values?.existingItem == 'Yes' || formik?.values?.fgType !== 'Virtual' ? (
              <></>
            ) : (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Real Item SKU ID"
                  required={formik?.values?.fgType !== 'Import'}
                  radius={'lg'}
                  placeholder={'Real Item SKU ID'}
                  //   onChange={(e) => {
                  //     formik.setFieldValue('realItem', e.target.value);
                  //   }}
                  value={formik.values.realItem}
                  error={formik.errors.realItem}
                  // disabled={formik?.values?.fgType === 'Import'}
                />
              </Grid.Col>
            )}

            {formik?.values?.existingItem == 'Yes' || formik?.values?.fgType != 'Virtual' ? (
              <></>
            ) : (
              <>
                <Grid.Col span={4}>
                  <TextInput
                    disable={formik?.values?.fgType !== 'Virtual'}
                    label="Virtual SKU Description"
                    name="name"
                    key="name"
                    required={formik?.values?.fgType !== 'Import'}
                    radius={'lg'}
                    placeholder={'Virtual SKU Description'}
                    onChange={(e) => {
                      // if (e.target.value?.length <= 40) {
                      setSkuDescription(e.target.value);
                      formik.setFieldValue('skuDescription', e.target.value.toUpperCase());
                      // }
                    }}
                    error={formik.errors.skuDescription}
                    value={skuDescription}
                    // disabled={formik?.values?.fgType === 'Import'}
                  />
                </Grid.Col>
                <Grid.Col span={1} justify="center">
                  <TextInput label="Length" radius={'lg'} disabled value={skuDescription?.length} />
                </Grid.Col>
              </>
            )}

            {formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem !== 'Yes' && (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Container Returnable"
                  placeholder="Container Returnable"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  //   data={['Yes', 'No']}
                  //   onChange={(e) => {
                  //     formik.setFieldValue('containerReturnable', e);
                  //   }}
                  value={formik.values.containerReturnable}
                  error={formik.errors.containerReturnable}
                />
              </Grid.Col>
            )}
            {/* <Grid.Col span={4}>
          <TextInput
      disabled
            label="UPC Codes"
            name="name"
            key="name"
            radius={'lg'}
            placeholder={'UPC Codes'}
            // onChange={(e) => {
            //   formik.setFieldValue('upcTbsCodes', e.target.value);
            // }}
            value={formik.values.upcTbsCodes}
          />
        </Grid.Col> */}
            {formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem !== 'Yes' && (
              <Grid.Col span={4}>
                <TextInput
                  disabled
                  label="Deco/Non Deco"
                  placeholder="Deco/Non Deco"
                  radius={'lg'}
                  required
                  searchable
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  //   data={['NonDeco', 'Deco']}
                  //   onChange={(e) => {
                  //     formik.setFieldValue('decoType', e);
                  //     checkSKUDescription('decoType', e)
                  //   }}
                  value={formik.values.decoType}
                  error={formik.errors.decoType}
                />
              </Grid.Col>
            )}
            {formik?.values?.fgType !== 'Virtual' && formik?.values?.existingItem !== 'Yes' && (
              <>
                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Taste Segment"
                    placeholder="Taste Segment"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={tasteSegmentDropDown}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('tasteSegment', e);
                    //   }}
                    value={formik.values.tasteSegment}
                    error={formik.errors.tasteSegment}
                  />
                </Grid.Col>
                <Grid.Col span={8}>
                  <Flex
                    gap="sm"
                    // justify="center"
                    align="flex-end"
                    direction="row"
                    wrap="nowrap"
                  >
                    <TextInput
                      disabled
                      radius={'lg'}
                      type="number"
                      placeholder="Length"
                      label="Dimension"
                      // onChange={(e) => {
                      //   formik.setFieldValue('length', e.target.value);
                      // }}
                      value={formik.values.length}
                      // error={formik.errors.length}
                    />
                    <Text mb={'1vh'}>X</Text>
                    <TextInput
                      disabled
                      radius={'lg'}
                      type="number"
                      placeholder="Width"
                      label={' '}
                      // onChange={(e) => {
                      //   formik.setFieldValue('width', e.target.value);
                      // }}
                      value={formik.values.width}
                      // error={formik.errors.width}
                    />
                    <Text mb={'1vh'}>X</Text>
                    <TextInput
                      disabled
                      radius={'lg'}
                      type="number"
                      placeholder="Height"
                      label={' '}
                      // onChange={(e) => {
                      //   formik.setFieldValue('height', e.target.value);
                      // }}
                      value={formik.values.height}
                      // error={formik.errors.height}
                    />
                  </Flex>
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextInput
                    disabled
                    label="Price Class"
                    placeholder="Price Class"
                    radius={'lg'}
                    required
                    searchable
                    rightSection={<IconChevronDown size={14} />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    //   data={priceClassDropDown}
                    //   onChange={(e) => {
                    //     formik.setFieldValue('priceClass', e);
                    //   }}
                    value={formik.values.priceClass}
                    error={formik.errors.priceClass}
                  />
                </Grid.Col>
              </>
            )}
            {formik?.values?.fgType !== 'Virtual' && (
              <>
                <Grid.Col span={6}>
                  <TextInput
                    label="SKU Description"
                    radius={'lg'}
                    value={skuDescription}
                    disabled={formik.values.existingItem == 'Yes'}
                    onChange={(e) => {
                      // if (e.target.value?.length <= 40) {
                      setSkuDescription(e.target.value);
                      formik.setFieldValue('skuDescription', e.target.value.toUpperCase());
                      // }
                    }}
                    error={formik.errors.skuDescription}
                  />
                  {skuDescription?.length > 40 && (
                    <Text color="red" size="sm">
                      Max length is 40
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col span={1} justify="center">
                  <TextInput label="Length" radius={'lg'} disabled value={skuDescription?.length} />
                </Grid.Col>
              </>
            )}

            {formik?.values?.existingItem !== 'Yes' && formik?.values?.fgType !== 'Virtual' && (
              <Grid.Col span={5}>
                <Select
                  label="Send request to"
                  placeholder="Send request to"
                  required
                  radius={'lg'}
                  data={emailReceivers}
                  value={formik.values.sendRequestTo}
                  onChange={(e) => {
                    // //console.log(e);
                    formik.setFieldValue('sendRequestTo', e);
                  }}
                  error={formik.errors.sendRequestTo}
                />
              </Grid.Col>
            )}
          </Grid>
          <Space h="xl" />
          <div>
            <DataTable
              columns={table_columns}
              data={plantsDetails}
              records={plantsDetails}
              getDataApi={getPlants}
              // page={tablePage}
              hiddenColumns={['id']}
            />
          </div>
        </Card.Body>
        <Card.Footer>
          <Col>
            {check_roles(userState, ['admin', 'demand_planner']) && (
              <Group position="right">
                {materialStatus === 1 && (
                  <>
                    <Button
                      color="gray"
                      radius="xl"
                      leftIcon={<IconX size={16} color="white" />}
                      onClick={() => setShowRejectModal(true)}
                    >{`Reject`}</Button>
                    <Button
                      color="gray"
                      radius="xl"
                      leftIcon={<IconX size={16} color="white" />}
                      onClick={() => setShowDeleteModal(true)}
                    >{`Delete`}</Button>
                    <Button
                      color="yellow"
                      radius="xl"
                      leftIcon={<IconCheck size={16} color="white" />}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >{`Approve`}</Button>
                  </>
                )}
              </Group>
            )}
          </Col>
          <Space h="xl"></Space>
        </Card.Footer>
      </Card>
      <RejectMaterial
        showRejectMaterialModal={showDeleteModal}
        closeRejectMaterialModal={() => setShowDeleteModal(false)}
        rejectMaterial={(comment) => {
          deleteMaterial(comment);
        }}
      />
      <ApproveMaterial
        onClose={() => setShowApproveMaterialModal(false)}
        showApproveMaterialModal={showApproveMaterialModal}
        onSubmit={() => approveMaterial()}
        message={'Are you sure you want to Approve this request ?'}
      />
      {/* REJECT MODAL */}
      <RejectMaterial
        showRejectMaterialModal={showRejectModal}
        closeRejectMaterialModal={() => setShowRejectModal(false)}
        rejectMaterial={(comment) => rejectMaterial(comment)}
        message={'Are you sure you want to Reject this request ?'}
      />
    </div>
  );
}
export default MaterialApproval;
