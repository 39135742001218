import { createContext, useState } from 'react';

export const pageContext = createContext({});

export const PageContextProvider = ({ children }) => {
  // PROJECT REQUEST TAB
  const [searchProject, setSearchProject] = useState('');
  const [projectPagination, setProjectPagination] = useState(1);
  const [searchMaterial, setSearchMaterial] = useState('');
  const [materialPagination, setMaterialPagination] = useState(1);
  const [totalSubmitted, setTotalSubmitted] = useState('');

  // DRAFT TAB
  const [draftPagination, setDraftPagination] = useState(1);
  const [totalDrafts, setTotalDrafts] = useState('');

  // MATERIAL APPROVAL TAB
  const [searchApproval, setSearchApproval] = useState('');
  const [approvalPagination, setApprovalPagination] = useState(1);
  const [totalApproval, setTotalApproval] = useState('');

  // FG FORM TAB
  const [searchFgForm, setSearchFgForm] = useState('');
  const [fgFormPagination, setFgFormPagination] = useState(1);
  const [totalFgForm, setTotalFgForm] = useState('');

  // MDM VIEW1 TAB
  const [searchMdmView1, setSearchMdmView1] = useState('');
  const [mdmViewPagination1, setMdmViewPagination1] = useState(1);
  const [totalMdmView1, setTotalMdmView1] = useState('');

  // MDM VIEW2 TAB
  const [searchMdmView2, setSearchMdmView2] = useState('');
  const [mdmViewPagination2, setMdmViewPagination2] = useState(1);
  const [totalMdmView2, setTotalMdmView2] = useState('');

  // MDM VIEW3 TAB
  const [searchMdmView3, setSearchMdmView3] = useState('');
  const [mdmViewPagination3, setMdmViewPagination3] = useState(1);
  const [totalMdmView3, setTotalMdmView3] = useState('');

  // PACKAGING BOM TAB
  const [searchPackagingBom, setSearchPackagingBom] = useState('');
  const [packagingBomPagination, setPackagingBomPagination] = useState(1);
  const [totalPackagingBom, setTotalPackagingBom] = useState('');

  // LIQUID BOM TAB
  const [searchLiquidBom, setSearchLiquidBom] = useState('');
  const [liquidBomPagination, setLiquidBomPagination] = useState(1);
  const [totalLiquidBom, setTotalLiquidBom] = useState('');

  // RM COSTING 1 TAB
  const [searchRmCosting1, setSearchRmCosting1] = useState('');
  const [rmCosting1Pagination, setRmCosting1Pagination] = useState(1);
  const [totalRmCosting1, setTotalRmCosting1] = useState('');

  const [searchCostingNotifications, setSearchCostingNotifications] = useState('');
  const [costingNotificationPagination, setCostingNotificationPagination] = useState(1);
  const [totalCostingNotifications, setTotalCostingNotifications] = useState('');
  // CONTROLLER TAB
  const [searchController, setSearchController] = useState('');
  const [controllerPagination, setControllerPagination] = useState(1);
  const [totalController, setTotalController] = useState('');

  // RM COSTING 2 TAB
  const [searchRmCosting2, setSearchRmCosting2] = useState('');
  const [rmCosting2Pagination, setRmCosting2Pagination] = useState(1);
  const [totalRmCosting2, setTotalRmCosting2] = useState('');

  // SUMMARY
  const [searchSummary, setSearchSummary] = useState('');
  const [summaryPagination, setSummaryPagination] = useState(1);
  const [totalSummary, setTotalSummary] = useState('');

  // Users
  const [searchUsers, setSearchUsers] = useState('');
  const [usersPagination, setUsersPagination] = useState(1);

  // BrewMasters
  const [searchBrewMasters, setSearchBrewMasters] = useState('');
  const [brewMasterPagination, setBrewMasterPagination] = useState(1);

  // Requestor
  const [searchRequestor, setSearchRequestor] = useState('');
  const [requestorPagination, setRequestorPagination] = useState(1);

  // DemandPlanner
  const [searchDemandPlanner, setSearchDemandPlanner] = useState('');
  const [demandPlannerPagination, setDemandPlannerPagination] = useState(1);

  // SupplyPlanner
  const [searchSupplyPlanner, setSearchSupplyPlanner] = useState('');
  const [supplyPlannerPagination, setSupplyPlannerPagination] = useState(1);

  // MDM
  const [searchMDM, setSearchMDM] = useState('');
  const [mdmPagination, setMDMPagination] = useState(1);

  // Costing
  const [searchCosting, setSearchCosting] = useState('');
  const [costingPagination, setCostingPagination] = useState(1);

  // Logistics
  const [searchLogistics, setSearchLogistics] = useState('');
  const [logisticsPagination, setLogisticsPagination] = useState(1);

  // Controllers
  const [searchControllers, setSearchControllers] = useState('');
  const [controllersPagination, setControllersPagination] = useState(1);

  // Plants
  const [searchPlants, setSearchPlants] = useState('');
  const [plantsPagination, setPlantsPagination] = useState(1);

  // Admins
  const [searchAdmins, setSearchAdmins] = useState('');
  const [adminsPagination, setAdminsPagination] = useState(1);

  // RM Cost
  const [searchRMCost, setSearchRMCost] = useState('');
  const [RMCostPagination, setRMCostPagination] = useState(1);
  const [totalRMCost, setTotalRMCost] = useState('');

  // PlantMembers
  const [searchPlantMembers, setSearchPlantMembers] = useState('');
  const [plantMembersPagination, setPlantMembersPagination] = useState(1);

  // PlantMembers
  const [searchRmCosting, setSearchRmCosting] = useState('');
  const [rmCostingPagination, setRmCostingPagination] = useState(1);

  return (
    <pageContext.Provider
      value={{
        //   PROJECT REQUEST TAB
        searchProject,
        setSearchProject,
        projectPagination,
        setProjectPagination,
        searchMaterial,
        setSearchMaterial,
        materialPagination,
        setMaterialPagination,
        totalSubmitted,
        setTotalSubmitted,

        //   DRAFT TAB
        draftPagination,
        setDraftPagination,
        totalDrafts,
        setTotalDrafts,

        //   MATERIAL APPROVAL TAB
        searchApproval,
        setSearchApproval,
        approvalPagination,
        setApprovalPagination,
        totalApproval,
        setTotalApproval,

        //   FG FORM TAB
        searchFgForm,
        setSearchFgForm,
        fgFormPagination,
        setFgFormPagination,
        totalFgForm,
        setTotalFgForm,

        //   MDM VIEW 1 TAB
        searchMdmView1,
        setSearchMdmView1,
        mdmViewPagination1,
        setMdmViewPagination1,
        totalMdmView1,
        setTotalMdmView1,

        //   MDM VIEW 2 TAB
        searchMdmView2,
        setSearchMdmView2,
        mdmViewPagination2,
        setMdmViewPagination2,
        totalMdmView2,
        setTotalMdmView2,

        //   MDM VIEW 3 TAB
        searchMdmView3,
        setSearchMdmView3,
        mdmViewPagination3,
        setMdmViewPagination3,
        totalMdmView3,
        setTotalMdmView3,

        //   PACKAGING BOM TAB
        searchPackagingBom,
        setSearchPackagingBom,
        packagingBomPagination,
        setPackagingBomPagination,
        totalPackagingBom,
        setTotalPackagingBom,

        //   LIQUID BOM TAB
        searchLiquidBom,
        setSearchLiquidBom,
        liquidBomPagination,
        setLiquidBomPagination,
        totalLiquidBom,
        setTotalLiquidBom,

        //   RM COSTING 1 TAB
        searchRmCosting1,
        setSearchRmCosting1,
        rmCosting1Pagination,
        setRmCosting1Pagination,
        totalRmCosting1,
        setTotalRmCosting1,

        searchCostingNotifications,
        setSearchCostingNotifications,
        costingNotificationPagination,
        setCostingNotificationPagination,
        totalCostingNotifications,
        setTotalCostingNotifications,
        //   CONTROLLER TAB
        searchController,
        setSearchController,
        controllerPagination,
        setControllerPagination,
        totalController,
        setTotalController,

        //   RM COSTING 2 TAB
        searchRmCosting2,
        setSearchRmCosting2,
        rmCosting2Pagination,
        setRmCosting2Pagination,
        totalRmCosting2,
        setTotalRmCosting2,

        // SUMMARY TAB
        searchSummary,
        setSearchSummary,
        summaryPagination,
        setSummaryPagination,
        totalSummary,
        setTotalSummary,

        // Users
        searchUsers,
        setSearchUsers,
        usersPagination,
        setUsersPagination,

        // BrewMasters
        searchBrewMasters,
        setSearchBrewMasters,
        brewMasterPagination,
        setBrewMasterPagination,

        // BrewMasters
        searchRequestor,
        setSearchRequestor,
        requestorPagination,
        setRequestorPagination,

        // DemandPlanner
        searchDemandPlanner,
        setSearchDemandPlanner,
        demandPlannerPagination,
        setDemandPlannerPagination,

        // SupplyPlanner
        searchSupplyPlanner,
        setSearchSupplyPlanner,
        supplyPlannerPagination,
        setSupplyPlannerPagination,

        // MDM
        searchMDM,
        setSearchMDM,
        mdmPagination,
        setMDMPagination,

        // Costing
        searchCosting,
        setSearchCosting,
        costingPagination,
        setCostingPagination,

        // Logistics
        searchLogistics,
        setSearchLogistics,
        logisticsPagination,
        setLogisticsPagination,

        // Controllers
        searchControllers,
        setSearchControllers,
        controllersPagination,
        setControllersPagination,

        // Plants
        searchPlants,
        setSearchPlants,
        plantsPagination,
        setPlantsPagination,

        // Admins
        searchAdmins,
        setSearchAdmins,
        adminsPagination,
        setAdminsPagination,

        // Plant Members
        searchPlantMembers,
        setSearchPlantMembers,
        plantMembersPagination,
        setPlantMembersPagination,

        // RM Cost Users
        searchRMCost,
        setSearchRMCost,
        RMCostPagination,
        setRMCostPagination,
        totalRMCost,
        setTotalRMCost,
      }}
    >
      {children}
    </pageContext.Provider>
  );
};
